import React from "react";
import { observer } from "mobx-react";
import { ArrowRight, CloudDownload } from "@mui/icons-material";
import Button from "@mui/material/Button";

import { NumericFormat } from "react-number-format";

import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ArrowLeft } from "@mui/icons-material";
import "./CrediteSimulare.scss";

const CrediteSimulareGrafic = (props) => {
  const {
    state,
    simulare,
    comisioane,
    scadentar,
    creditNew,
    downloadDocument,
    //isRequest,
    title,
    subtitle,
    goPrev,
  } = props;

  return (
    <>
      {/*!isRequest && (*/}
      <div className="doc-nav">
        <Button
          color="primary"
          className="link-btn"
          startIcon={<ArrowLeft />}
          //onClick={() => updateStore({ uid: null }, "simulare")}
          onClick={goPrev}
        >
          Modificare date calcul
        </Button>
      </div>
      {/*})*/}

      <p className="page-title">{title}</p>
      <p className="subtitle-text">{subtitle}</p>
      <div className="simulare">
        <div className="simulare-doc">
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">{`${
              state.credit.descriere
            } cu dobanda ${state.dobanda_fixa ? "fixa" : "variabila"} ${
              state.asigurare ? "si asigurare" : "fara asigurare"
            } Allianz`}</div>
          </div>
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">Credit solicitat</div>
            <div className="value-suma">
              <NumericFormat
                value={state.suma}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix={` ${state.credit.valuta}`}
                //decimalScale={2}
                fixedDecimalScale
                renderText={(value) => <div className="interest">{value}</div>}
              />
            </div>
          </div>
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">Perioada solicitata</div>
            <div className="value-suma">
              <NumericFormat
                value={state.perioada}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix=" Luni"
                //decimalScale={2}
                fixedDecimalScale
                renderText={(value) => <div className="interest">{value}</div>}
              />
            </div>
          </div>
          {!state.dobanda_fixa && (
            <div className="doc-table-simulare h-list-max">
              <div className="label-title">Marja fixa de dobanda</div>
              <div className="value-suma">
                <NumericFormat
                  value={simulare.marja}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix="%"
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={(value) => <div className="percent">{value}</div>}
                />
              </div>
            </div>
          )}
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">Procent dobanda *</div>
            <div className="value-suma">
              <NumericFormat
                value={simulare.procent_dobanda}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix="%"
                decimalScale={2}
                fixedDecimalScale
                renderText={(value) => <div className="percent">{value}</div>}
              />
            </div>
          </div>

          {state.asigurare && (
            <div className="doc-table-simulare h-list-max">
              <div className="label-title">Prima unica de asigurare</div>
              <div className="value-suma">
                <NumericFormat
                  value={simulare.prima_asigurare}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  //suffix="%"
                  suffix={` ${state.credit.valuta}`}
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={(value) => <div className="percent">{value}</div>}
                />
              </div>
            </div>
          )}
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">Prima rata</div>
            <div className="value-suma">
              <NumericFormat
                value={simulare.prima_rata}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix={` ${state.credit.valuta}`}
                decimalScale={2}
                fixedDecimalScale
                renderText={(value) => <div className="percent">{value}</div>}
              />
            </div>
          </div>
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">Valoare totala de rambursare</div>
            <div className="value-suma">
              <NumericFormat
                value={simulare.total_rambursare}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix={` ${state.credit.valuta}`}
                decimalScale={2}
                fixedDecimalScale
                renderText={(value) => <div className="percent">{value}</div>}
              />
            </div>
          </div>
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">DAE **</div>
            <div className="value-suma">
              <NumericFormat
                value={simulare.dae}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix="%"
                decimalScale={2}
                fixedDecimalScale
                renderText={(value) => <div className="percent">{value}</div>}
              />
            </div>
          </div>
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">Tip rate lunare</div>
            <div className="value-suma interest">
              {state.rate_egale ? "egale (anuitati)" : "descrescatoare"}
            </div>
          </div>
          <div className="doc-table-simulare h-list-max">
            <div className="label-title">Ziua scadenta rata</div>
            <div className="value-suma interest">
              {state.scadenta.zi}
              {/*moment(state.scadenta.data).format("DD/MM/YYYY")*/}
            </div>
          </div>

          <div className="label-title">Taxe si comisioane:</div>

          {comisioane &&
            comisioane.map((comision) => (
              <div
                key={comision.denumire_comision}
                className="doc-table-simulare h-list-max"
              >
                <div className="label-title left full">
                  {comision.denumire_comision}
                </div>
                <div className="value-suma">
                  <NumericFormat
                    value={comision.valoare_comision}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={
                      comision.comision_procentual
                        ? "%"
                        : ` ${state.credit.valuta}`
                    }
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={(value) => (
                      <div className="interest">{value}</div>
                    )}
                  />
                </div>
              </div>
            ))}
        </div>

        <div className="simulare-doc">
          <div>{state.credit.nota && state.credit.nota}</div>

          <div>
            ** DAE este Dobanda Anuala Efectiva si include taxele si
            comisioanele aferente creditului, percepute de banca.
          </div>
          {comisioane &&
            comisioane
              .filter((x) => x.nota)
              .map((comision) => (
                <div key={comision.denumire_comision}>{comision.nota}</div>
              ))}
        </div>
      </div>

      <div className="login-btn-group download-grafic">
        <Button
          variant="contained"
          color="primary"
          onClick={downloadDocument}
          className="login-btn"
          startIcon={<CloudDownload fontSize="large" />}
        >
          Genereaza graficul in PDF
        </Button>
      </div>
      <div className="table-title">Grafic de rambursare informativ</div>
      <div className="table-subtitle">{`din data de: ${moment(
        simulare.data_curenta
      ).format("DD/MM/YYYY")}`}</div>
      <div className="table-note">
        Atentie: Va atragem atentia asupra caracterului pur informativ al
        prezentului grafic, care NU CONSTITUIE un angajament din partea Bancii
        in vederea incheierii contractului de credit. In vederea analizei
        creditului, Banca va realiza analiza conform conditiilor din normele
        interne ale Bancii si va verifica informatiile care au stat la baza
        prezentului grafic de rambursare, printre care si gradul de indatorare!
        <br></br>
        Graficul de rambursare informativ este generat in vederea informarii
        clientilor cu privire la modul de rambursare si componentele ratei
        lunare. In scopul calcularii dobanzii se considera anul de 365 de zile
        (sau 366 de zile in cazul anului bisect) si luna din numărul efectiv de
        zile ale lunii.<br></br>
        Prima rata lunara se va plati incepand cu luna urmatoare acordarii
        creditului sau in aceeasi luna, daca data de scadenta a primei rate
        depaseste 35 zile de la data acordarii creditului! Pentru datele de
        scadenta din zilele nelucratoare, data de rambursare este prima zi
        lucratoare care ii urmeaza, astfel cum reise din prezentul Grafic de
        rambursare informativ
      </div>

      {scadentar && (
        <div className="table-scadentar">
          <TableContainer component={Paper}>
            <Table aria-label="sticky table" size="small" stickyHeader>
              <TableHead className="table-scadentar-header">
                <TableRow>
                  <TableCell align="center">Nr</TableCell>
                  <TableCell>Data scadenta</TableCell>
                  <TableCell align="right">Anuitate</TableCell>
                  <TableCell align="right">Principal</TableCell>
                  <TableCell align="right">Dobanda</TableCell>
                  <TableCell align="right">Comision administrare</TableCell>
                  <TableCell align="right">Suma lunara de plata</TableCell>
                  <TableCell align="right">Sold credit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scadentar.map((row) => (
                  <TableRow key={row.nr_rata} className="table-scadentar-row">
                    <TableCell align="center">{row.nr_rata}</TableCell>
                    <TableCell>
                      {moment(row.data_rata).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="right">
                      <NumericFormat
                        value={row.anuitate}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </TableCell>
                    <TableCell align="right">
                      <NumericFormat
                        value={row.principal}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </TableCell>
                    <TableCell align="right">
                      <NumericFormat
                        value={row.dobanda}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </TableCell>
                    <TableCell align="right">
                      <NumericFormat
                        value={row.comision_administrare}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </TableCell>

                    <TableCell align="right">
                      <NumericFormat
                        value={row.suma}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </TableCell>
                    <TableCell align="right">
                      <NumericFormat
                        value={row.sold}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow key="total" className="table-scadentar-row tfoot">
                  <TableCell align="center">Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <NumericFormat
                      value={simulare.total_anuitate}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumericFormat
                      value={simulare.total_principal}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumericFormat
                      value={simulare.total_dobanda}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumericFormat
                      value={simulare.total_comision}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>

                  <TableCell align="right">
                    <NumericFormat
                      value={simulare.total_plata}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <div className="login-btn-group">
        <Button
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={creditNew}
          endIcon={<ArrowRight fontSize="large" />}
        >
          Vreau credit
        </Button>
      </div>
    </>
  );
};

export default observer(CrediteSimulareGrafic);
