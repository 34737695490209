import React from "react";
import { observer } from "mobx-react";
import Tooltip from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import word from "../img/WordLogo-80.png";
import excel from "../img/ExcelLogo-80.png";
import pdf from "../img/PdfLogo-80.png";

import "../index.css";

function Document1 (props) {
    const { doc, /*downloadPrintFile,*/ openPrintParameters } = props;
    var logoIcon = null;
    switch (doc.Afisare) {
      case 0:
        logoIcon = excel;
        break;
      case 1:
        //logoIcon = html;
        break;
      case 2:
        logoIcon = pdf;
        break;
      case 3:
        logoIcon = word;
        break;
      case 4:
        logoIcon = excel;
        break;
      case 5:
        logoIcon = pdf;
        break;
      default:
        logoIcon = null;
    }

    return (
      <div className="download-file" onClick={() => openPrintParameters(doc)}>
        <Tooltip title={`Listeaza ${doc.Descriere}`}>
          {logoIcon ? (
            <img src={logoIcon} className="download-icon-img" alt="" />
          ) : (
            <DescriptionIcon className="download-icon" />
          )}
        </Tooltip>
        <div className="download-file-name">{doc.Descriere}</div>
      </div>
    );
  }
export default observer(Document1);

//<div className="download-file" onClick={()=>downloadPrintFile(doc)} >
