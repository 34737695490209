import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import styled from "styled-components";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getStyle } from "Utils";
import DivCss from "../components/divCss";
import TextField from "@mui/material/TextField";

import "../index.css";
function Field2(props) {
  const { item, inline, actions, gridItem } = props;
  const styleProps = getStyle(item.eventProps);
  const DatePickerCss = styled(DatePicker)`
    ${styleProps.input}
  `;
  const getWidth = () => {
    return gridItem ? "100%" : 210;
  };

  return (
    !!item.isVisible && (
      <DivCss {...props} id={`f2-${item.Id}`} key={item.Id}>
        {!inline && (
          <div id={`f2l-${item.Id}`} className="doc-field-label">
            {item.Caption + (item.Obligatoriu ? " *" : "")}
          </div>
        )}
        <DatePickerCss
          id={`f2f-${item.Id}`}
          value={
            item.Valoare && item.Valoare !== "01/01/1900"
              ? moment(item.Valoare, "DD/MM/YYYY")
              : null
          }
          label={inline ? item.Caption : null}
          onAccept={(prop) => {
            if (!prop || prop._isValid) actions.updateValue(item, prop);
          }}
          format="DD/MM/YYYY"
          disabled={item.isReadonly || item.docReadOnly}
          clearable
          className={item.isReadonly || item.docReadOnly ? "disabled" : null}
          inputVariant="outlined"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          style={{ width: getWidth() }}
          size="small"
          sx={{
            width: "-webkit-fill-available",
            input: { padding: "8.5px 14px" },
          }}
          required={item.Obligatoriu}
          error={!!item.error}
          helperText={item.error || item.Jprop.helperText}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                defaultValue={params.value}
                onBlur={(prop) => {
                  var value = moment(prop.target.value, "DD/MM/YYYY");
                  actions.updateValue(item, value.isValid() ? value : null);
                }}
                helperText={item.error || item.Jprop.helperText}
                error={!!item.error}
              />
            ),
          }}
        />
      </DivCss>
    )
  );
}
export default observer(Field2);
