import { action, observable, extendObservable, set } from "mobx";

import { renameObjectKeys, runEvent } from "Utils";
import moment from "moment";
//import { Api } from "Common/src";

const modelHeader = {
  //isLoad: false,
  docListareCerere: [],
  id: null,
  categorie: null,
  cod: null,
  tipClient: "X",
  nume: null,
  ci: null,
  cimpuri: [],
  fieldsVisible: false,
  identificator: { F: "CNP", J: "Cod Fiscal", checked: "F", value: null },

  nomenclatoare: {
    judete: null,
    localitati: null,
    segmentRisc: null,
    formeLegale: [
      { cod: "SA" },
      { cod: "SRL" },
      { cod: "SNC" },
      { cod: "PFA" },
      { cod: "II" },
      { cod: "IF" },
      { cod: "CA" },
    ],
    caen: [],
  },
};
const modelDetails = {
  client: {
    Id: null,
    Nume: null,
    Categorie: null,
    Atribut_Fiscal: null,
    Cod: null,
    Cod_Registru: null,
    Judet: null,
    Cod_Judet: null,
    Localitate: null,
    Cod_Localitate: null,
    Strada: null,
    Numar: null,
    CAEN: null,
    CAEN_Denumire: null,
    Procent: null,
    Forma_Legala: null,
    Segment_Risc: null,
    Sucursala: null,
    Analitic: null,
    Data_Introducerii: null,
    Data_Infiintare: null,
  },
  cereri: [],
  idVechi: null,
  verificare: null,
  erroare: null,
  //changedFields: {},
  changedParams: {},
};

class CerereStore {
  //api = null;
  @observable type = null;
  @observable uidCerere = null;
  @observable error = null;
  @observable eventsFields = [];
  //liste = [];

  constructor(props) {
    this.rootStore = props;
    //this.rootStore.api = new Api({ logout: props.mainStore.logout });
    extendObservable(this, modelHeader);
    extendObservable(this, modelDetails);
  }
  @observable isUser = null;
  @action
  doActivate = async (params, isUser) => {
    if (this.rootStore.mainStore.tryLogin) {
      return;
    }
    this.isUser = isUser;
    this.rootStore.mainStore.isWaiting = true;
    this.fieldsVisible = false;
    var apiPath = `/api/request/new`;
    this.type = params.type;
    var result = await this.rootStore.mainStore.getFlow(params.type);
    if (result && result.noMatch) {
      this.rootStore.mainStore.isWaiting = false;
      return;
    }
    //this.getRequest();

    var results = await this.rootStore.api.fetch(apiPath, { id: params.type });
    if (results && !results.error && results != "401") {
      var resultsJson = await renameObjectKeys(results);
      if (resultsJson.init != 1) {
        this.rootStore.mainStore.isWaiting = false;
        this.rootStore.mainStore.errors.open(
          "Nu aveti dreptul sa initiati cereri pe acest flux!"
        );
        this.clear();
        return;
      }
      this.rootStore.commonStore.liste = resultsJson.liste;
      set(this, resultsJson);
      this.rootStore.commonStore.allFields = {};
      set(this.cimpuri, resultsJson.cimpuri);
      set(this.rootStore.commonStore.fld, this.cimpuri);
      await this.getAllFields(this.rootStore.commonStore.allFields);
      //rulez evenimentele
      Object.values(this.rootStore.commonStore.allFields)
        .filter((item) => item.event)
        .forEach((item) =>
          runEvent(
            {
              showErrors: this.rootStore.mainStore.errors,
              allFields: this.rootStore.commonStore.allFields,
              filterSursaLista: this.rootStore.commonStore.filterSursaLista,
              refreshDocument: this.refreshDocument,
            },
            item,
            true,
            false
          )
        );

      if (this.tipClient !== "%") {
        this.identificator.checked = this.tipClient;
      }
      if (this.tipClient === "X") {
        this.fieldsVisible = true;
      }
    }
    if (results && results.error) {
      this.rootStore.mainStore.errors.open(results.error);

      this.rootStore.mainStore.isWaiting = false;
      return;
    }

    await this.getNomenclatoare();
    this.rootStore.mainStore.isWaiting = false;
  };

  @action
  getNomenclatoare = async () => {
    var judete = await this.rootStore.api.fetch("/api/common/judete");
    this.nomenclatoare.judete = judete;
    var segmentRisc = await this.rootStore.api.fetch(
      "/api/common/segment-risc"
    );
    this.nomenclatoare.segmentRisc = segmentRisc;
  };

  @action
  getCaen = async (cod) => {
    if (this.client.CAEN !== cod) {
      this.client.CAEN = cod;
      var caen = await this.rootStore.api.fetch("/api/common/caen", {
        caen: cod,
      });
      this.client.CAEN_Denumire = caen;
      this.rootStore.commonStore.changedFields["CAEN"] = cod;
    }
  };

  @action
  getLocalitati = async ({ id, update }) => {
    var localitati = await this.rootStore.api.fetch("/api/common/localitati", {
      id: id,
    });
    this.nomenclatoare.localitati = localitati;
    if (update) {
      this.client["Cod_Localitate"] = null;
      this.client["Localitate"] = null;
    }
  };

  @action
  doDeactivate = async () => {
    this.clear();
  };
  @action
  changeClientType = async (value) => {
    set(this, modelDetails);
    this.identificator.checked = value;
    this.rootStore.commonStore.changedFields["cat"] = value;
  };

  @action
  setClient = async (value) => {
    //se sterg datele completate anterior
    set(this.client, modelDetails.client);
    this.rootStore.commonStore.changedFields = {};

    this.erroare = null;
    this.rootStore.mainStore.isWaiting = true;
    var apicheck = `/api/request/check`;
    var params = {
      cod: this.identificator.value,
      cat: this.identificator.checked,
      idFlux: this.type,
    };
    var checkClient = await this.rootStore.api.fetch(apicheck, params);
    this.verificare = checkClient.TIP;
    if (this.verificare === "OK") {
      var apiClient = `/api/request/client`;
      var results = await this.rootStore.api.fetch(apiClient, params);
      var resultsJson = await renameObjectKeys(results);
      extendObservable(this, resultsJson);
      if (this.clienti) {
        var client = await this.clienti
          .sort(function (a, b) {
            var firstDate =
              a.Data_Introducerii && a.Data_Introducerii.length !== 0
                ? moment(
                    a.Data_Introducerii.substr(0, 10),
                    "DD.MM.YYYY"
                  ).format("YYYY.MM.DD")
                : moment();
            var secondeDate =
              b.Data_Introducerii && b.Data_Introducerii.length !== 0
                ? moment(
                    b.Data_Introducerii.substr(0, 10),
                    "DD.MM.YYYY"
                  ).format("YYYY.MM.DD")
                : moment();
            return firstDate - secondeDate;
          })
          .reverse()[0];
        if (client) {
          if (client.Cod_Judet) {
            await this.getLocalitati({ id: client.Cod_Judet });
          }
          if (client.Data_Infiintare) {
            client.Data_Infiintare = client.Data_Infiintare.substr(0, 10);
          }
          set(this.client, client);

          Object.keys(this.client).forEach(
            (x) => (this.rootStore.commonStore.changedFields[x] = client[x])
          );
        } else {
          this.rootStore.commonStore.changedFields["Cod"] =
            this.identificator.value;
          this.rootStore.commonStore.changedFields["Categorie"] =
            this.identificator.checked;
        }

        this.fieldsVisible = true;
      }
    } else {
      this.erroare = checkClient.Mesaj;
    }
    //this.isLoad = true;
    this.rootStore.mainStore.isWaiting = false;
  };

  @action
  sursaLista = (item) => {
    var data;
    if (item.coldef && item.coldef.dt) {
      data = item.coldef.dt.Rows;
    }
    if (item.coldef && item.coldef.list) {
      data = item.coldef.list;
    }
    if (!item.coldef || (!item.coldef.dt && !item.coldef.list)) {
      data = this.liste[item.SursaLista.toUpperCase()];
    }
    //var keys =  Object.getOwnPropertyNames(data[0]);
    var keys = Object.keys(data[0]);
    return { data: data, fields: { text: keys[1], id: keys[0] } };
  };

  getAllFields = (_allFields) => {
    //resetare cimpuri
    //this.allFields = {};
    //var _allFields = this.allFields;
    //var cimpuri = []
    var _setupFields = [];

    this.rootStore.commonStore.fld.forEach((fld) => {
      fld.Incarcare = 1;
      _allFields[fld.Id] = fld;
      _setupFields.push(fld);
    });
    this.rootStore.commonStore.setupFields(_setupFields, {
      type: this.type,
      uidCerere: this.uidCerere,
    });
  };

  @action
  rowChecked = (row, item) => {
    row.data.isChecked = row.isChecked;
    var sursa = this.sursaLista(item);
    var value = sursa.data
      .filter((s) => s.isChecked)
      .map((x) => x[sursa.fields.id])
      .toString();
    this.updateValue(item, value);
  };

  @action
  setCerere = (idCerere) => {
    this.idVechi = idCerere;
  };

  @action
  updateField = (value, object, callback) => {
    var keys = Object.keys(value);
    var key = keys[0];
    var update = false;
    if (object && this[object][key] !== value[key]) {
      set(this[object], value);
      update = true;
      //this.changedFields[key]=value[key];
    }

    if (!object && this[key] !== value[key]) {
      set(this, value);
      update = true;
    }
    this.rootStore.commonStore.changedFields[key] = value[key];

    if (callback && update) {
      callback.function(callback.value);
    }
  };

  @action
  updateValue = (item, value) => {
    /* if (value && item.Valoare !== value) {
      this.changedParams[item.Id] = value;*/

    if (value === undefined) {
      value = null;
    }
    /*trebuie sa permit schimbarea daca value=""*/
    var sValue = "";
    switch (item.Tip) {
      case 0: //textbox
        sValue = value;
        if (item.Lungime > 1000) {
          sValue = sValue?.toUpperCase();
        }
        break;
      case 1: //memo
        sValue = value;
        break;
      case 2: //data
        sValue = value ? moment(value).format("DD/MM/YYYY") : "";
        //sValue =  value ? value : "";
        break;
      case 3: //numeric
        sValue = value.replace(/[.]/g, "").replace(",", ".");
        break;
      case 4: //listbox
        sValue = value;
        break;
      case 5: //checkbox
        //sValue = value ? "1" : "0";
        sValue = value ?? "0";
        break;
      case 6: //grid
        sValue = value;
        break;
      case 7: //richedit
        sValue = value;
        break;
      default:
        sValue = value;
    }
    if (item.Valoare != sValue) {
      this.changedParams[item.Id] = sValue;
      item.Valoare = sValue;
      this.rootStore.mainStore.setLogoutTime();
    }
  };

  @action
  save = async (navigate) => {
    this.rootStore.mainStore.isWaiting = true;
    var err = [];
    this.cimpuri.forEach((cimp) => {
      if (cimp?.Obligatoriu && cimp?.Valoare === "") {
        err.push(`Nu ati completat ${cimp.Caption}`);
      }
    });
    if (err.length > 0) {
      this.rootStore.mainStore.isWaiting = false;
      this.rootStore.mainStore.errors.open(err);
      return;
    }

    var apiPath = `/api/request/add`;
    var params = {
      name: this.type,
      idVechi: this.idVechi,
      cid: "",
      fld: this.rootStore.commonStore.changedFields,
      par: {
        ...this.changedParams,
        latitude: this.rootStore.mainStore.position.latitude,
        longitude: this.rootStore.mainStore.position.longitude,
      },
    };
    var results = await this.rootStore.api.post(apiPath, params);

    if (results.r === 0) {
      this.uidCerere = results.msg;
      this.rootStore.mainStore.isWaiting = false;
      if (navigate) {
        var url = `/request/${this.type}/${this.uidCerere}`;
        if (this.isUser) {
          url = `/${this.rootStore.mainStore.appType}${url}`;
        }
        navigate(url);
      }
    } else {
      this.rootStore.mainStore.preventGoBack = true;
      this.rootStore.mainStore.isWaiting = false;
      this.rootStore.mainStore.errors.open(results.msg || results.error);
    }
  };
  clear = () => {
    set(this, modelHeader);
    set(this, modelDetails);
    this.rootStore.mainStore.isWaiting = false;
  };
}
//export default new CerereStore();
export default CerereStore;
