import React from "react";

export default function PachetContOption(props) {
  const { caption, helperText, icon, className, type, supText} = props;
  return (
    <div className={["pachet-cont-option", `pachet-cont-option-${type}`, className].join(" ")}>
        <div className="pachet-cont-option-icon">
          {icon}
        </div>
        <div>
        <div className="pachet-cont-option-caption">
          {caption}
          <sup>{supText}</sup>
        </div>
        <div className="pachet-cont-option-helper">
          {helperText}
        </div>
        </div>
    </div>
  );
}
