import React from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
import { ArrowRight, Refresh } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import OtpInput from "react-otp-input";
import FormHelperText from "@mui/material/FormHelperText";

function EmailPage(props) {
  const {
    updateValue,
    email,
    email2,
    sendEmailCode,
    smsCode,
    handlerChangePage,
    updateError,
    codeRequest,
    timeLeft,
  } = props;
  return (
    <>
      <p className="page-title">Care este adresa ta de e-mail?</p>
      <p className="subtitle-text">
        Aceasta va fi utilizata pentru corespondenta cu Patria Bank pe 
        parcursul procesului online de simulare si acordare a creditului de
        nevoi personale in Lei
      </p>

      <div className="form">
        <TextField
          autoFocus
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Adresa e-mail"
          name="Email"
          value={email && email.value ? email.value : ""}
          onChange={(prop) => updateValue("email", prop.target.value)}
          helperText={email.error || "Adresa trebuie sa fie scrisa corect"}
          error={!!email.error}
          className="login-txt"
          type="email"
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email2"
          label="Confirmare adresa e-mail"
          name="Email2"
          defaultValue={email2 && email2.value ? email2.value : ""}
          onChange={(prop) => updateValue("email2", prop.target.value)}
          className="login-txt"
          helperText={
            email2.error ||
            "Confirmarea adresei de e-mail folosita pentru corespondenta"
          }
          error={!!email2.error}
          type="email"
          onPaste={(e) => {
            e.preventDefault();
            updateError("email2", "Te rugam sa completezi acest camp.");
            return false;
          }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={sendEmailCode}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Trimite Cod prin email
      </Button>

      {!codeRequest && (
        <>
          <Typography variant="subtitle2">
            Introdu codul primit prin email:
          </Typography>
          <OtpInput
            value={smsCode ? smsCode : ""}
            onChange={(value) => updateValue("smsCode", value)}
            numInputs={8}
            renderInput={(props) => (
              <input {...props} disabled={!!codeRequest} />
            )}
            inputStyle="MuiInputBase-root MuiInputBase-input MuiOutlinedInput-input otp-input"
            focusStyle="otp-focus"
            disabledStyle="MuiInputBase-root Mui-disabled"
            inputType="number"
            containerStyle="otp"
          />
          <FormHelperText error={false}></FormHelperText>
          <div className="h-list">
            <Typography variant="caption">
              Nu ai primit cod? Asteapta <b>{timeLeft} sec.</b>
            </Typography>

            <Button
              color="primary"
              /*className="link-btn"*/
              endIcon={<Refresh />}
              onClick={sendEmailCode}
              disabled={codeRequest}
            >
              Retrimite cod prin email
            </Button>
          </div>
          <Button
            variant="contained"
            color="primary"
            className="login-btn"
            onClick={handlerChangePage}
            endIcon={<ArrowRight fontSize="large" />}
            disabled={!smsCode}
          >
            Confirma adresa de e-mail
          </Button>
        </>
      )}
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
    </>
  );
}
export default observer(EmailPage);
