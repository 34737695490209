import React from "react";
import { observer } from "mobx-react";
import { actiuniDepozite } from "Common/src";
import { jsonParse } from "Utils";

import Slider from "@mui/material/Slider";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
//import Field6_Edit_N from "../field/field6_edit_n";
import TextFieldMask from "../components/textFieldMask";
import { NumericFormat } from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";

import { IconGift } from "TemplateCerere";
import SvgIcon from "@mui/material/SvgIcon";

class OnboardingDepozitePF_Edit extends React.Component {
  constructor(props) {
    super(props);
    const { row } = this.props;
    const codProdus = row["COD_PRODUS"];
    const idProdus = row["CBS_PRODUCT"];
    const tipActiune = row["ACTIUNE"];
    const procent = row["PROCENT"];
    const sumaTotal = row["SUMA_TOTAL"];
    const suma = row["SUMA"];
    const denumire = row["DENUMIRE"];
    var headers = this.visibleHeaders();
    const colPerioada = headers[0].columns.find(
      (x) => x.ColumnName === "CBS_PRODUCT"
    );

    const colActiune = headers[0].columns.find(
      (x) => x.ColumnName === "ACTIUNE"
    );
    //determin care sune perioadele disponibile
    const getInterval = (cod) => {
      var list = [];
      colPerioada.lista.forEach((x) => {
        if (x[2] == cod && !list.find((l) => l.value == x[3]))
          list.push({
            value: x[3],
            label: ` ${x[3]} ${x[3] == 1 ? "luna" : "luni"}`,
          });
      });
      return list.sort((x) => x.value * 1);
    };
    //extrag suma
    const colSuma = headers[0].columns.find((x) => x.ColumnName === "SUMA");
    const intervale = getInterval(codProdus).sort((x) => x.value);
    const min = intervale[0].value;
    const max = intervale[intervale.length - 1].value;
    const produs = colPerioada.lista.find((x) => x[0] == idProdus);
    const plataLunara = produs ? produs[4] : "N";
    const valuta = produs ? produs[9] : "RON";
    const perioada = produs ? produs[3] : null;
    //const procent = produs ? produs[3] : null;
    const smin = produs ? produs[6] : null;

    var listaActiune = JSON.parse(JSON.stringify(actiuniDepozite));
    var actiuniDisponibile = produs ? produs[8].split(",") : [];

    listaActiune.forEach(
      (x) => (x.hide = actiuniDisponibile.indexOf(x.id.toString()) < 0)
    );
    /*
       var listaActiune = actiuniDepozite.map((x) => {
      var act = JSON.parse(JSON.stringify(x));
      act.hide = actiuniDisponibile.indexOf(act.id.toString()) < 0;
      return act;
    });*/

    this.state = {
      produs: produs ? produs[0] : null,
      perioada: perioada,
      intervale: intervale,
      colSuma: colSuma,
      min: min,
      max: max,
      codProdus: codProdus,
      colPerioada: {
        tip: colPerioada.tip,
        key: colPerioada.key,
        lista: colPerioada.lista,
      },
      colActiune: colActiune,
      plataLunara: plataLunara, //"N", // "D"/"N"
      tipActiune: tipActiune, //{"L", "PC", "P"}
      listaPlata: [
        { value: "N", label: "NU", hide: false },
        { value: "D", label: "DA", hide: false },
      ],
      listaActiune: listaActiune,
      suma: suma,
      procent: procent,
      interest: sumaTotal,
      smin: smin,
      valuta: valuta,
      denumire: denumire,
      error: null,
    };
  }
  componentDidMount() {
    if (this.state.produs) {
      //this.getNewDepositInfo(this.state.produs[0], this.state.suma);
      return;
    }
    if (this.state.perioada == null) {
      this.setPerioada(this.state.min || 1);
    }

    this.setSuma(this.state.suma ? this.state.suma : "0");

    if (!this.state.tipActiune) {
      this.setActiune();
    }
  }
  visibleHeaders = () => {
    const { columns, selectedHeader } = this.props;
    var cols = columns.filter((x) => x.isVisibleEdit);
    var h = [];
    cols.forEach((c) => {
      var hIndex = h.length - 1;
      var hInfo = c.header || { text: "", expanded: true, inline: false };
      if (
        selectedHeader == null ||
        hInfo.text == "" ||
        selectedHeader.toLowerCase() === hInfo.text.toLowerCase()
      ) {
        if (
          hIndex > -1 &&
          hInfo.text.toLowerCase() === h[hIndex].text.toLowerCase()
        ) {
          //sunt pe acelasi header, adaug coloana la header
          h[hIndex].columns.push(c);
        } //creez un header nou
        else {
          h.push({ ...hInfo, columns: [c] });
        }
      }
    });
    return h;
  };
  getNewDepositInfo = (produs, suma, callback) => {
    const { updateRow, row, tableId } = this.props;
    this.setState({ procent: null, interest: null });
    this.props.queryData(
      "OnboardingPF.DepozitDobanda",
      { produs: produs, suma: suma },
      (msg) => {
        //console.log("msg", msg);
        if (msg.r == 0) {
          if (msg.msg) {
            this.setState({ procent: msg.msg });
          } else {
            var t = msg.o.Table[0];
            this.setState({
              procent: t.procent,
              interest: suma + t.sumaDobanda,
            });
            updateRow(row, null, "PROCENT", t.procent, tableId);
            updateRow(row, null, "SUMA_TOTAL", suma + t.sumaDobanda, tableId);
          }
        } else {
          this.setState({ procent: "Eroare la preluare procent!" });
        }
      }
    );
  };
  getProdus = (perioada, plataLunara) => {
    var produse = this.state.colPerioada.lista.filter(
      (x) =>
        x[2] == this.state.codProdus &&
        x[3] == perioada /*&& x[4]==plataLunara */
    );

    //determin tipurile de plata si setez vizibilitatea
    var plataDisp = produse.map((x) => x[4]);
    if (plataDisp.length === 0) {
      return {};
    }
    this.state.listaPlata.forEach(
      (x) => (x.hide = plataDisp.indexOf(x.value) < 0)
    );
    //verific daca tipul de plata este disponibil pentru perioada selectata
    var plata = this.state.listaPlata.find(
      (x) => x.value == plataLunara && !x.hide
    );
    if (!plata) {
      plataLunara = this.state.listaPlata.find((x) => !x.hide).value;
    }
    //determin produsul corespunzator parametrilor
    var produs = produse.find((x) => x[4] == plataLunara);
    //console.log("produs", produs);

    //setez vizibilitatea pentru actiunile de la scadenta
    var actiuniDisponibile = produs[8].split(",");
    //var listaActiune =
    this.state.listaActiune.forEach(
      (x) => (x.hide = actiuniDisponibile.indexOf(x.id.toString()) < 0)
    );
    var actiune = this.state.listaActiune.find(
      (x) => x.value == this.state.tipActiune && !x.hide
    );
    //daca actiunea nu mai este disponibila setez alta actiune
    if (!actiune) {
      this.setActiune();
    }
    //procent si dobanda pe perioada
    this.setState({
      smin: produs[6],
      valuta: produs[9],
      produs: produs[0],
    });
    return {
      produs: produs[0],
      plataLunara: plataLunara,
      smin: produs[6],
    };
  };
  setPerioada = (perioada) => {
    const { updateRow, tableId, row } = this.props;
    var rezultat = this.getProdus(perioada, this.state.plataLunara);
    this.setState({ perioada: perioada, plataLunara: rezultat.plataLunara });
    updateRow(
      row,
      this.state.colPerioada.tip,
      this.state.colPerioada.key,
      rezultat.produs,
      tableId
    );
    this.getNewDepositInfo(rezultat.produs, this.state.suma);
  };
  setPlata = (plataLunara) => {
    const { updateRow, tableId, row } = this.props;
    var rezultat = this.getProdus(this.state.perioada, plataLunara);
    this.setState({ plataLunara: rezultat.plataLunara });
    updateRow(
      row,
      this.state.colPerioada.tip,
      this.state.colPerioada.key,
      rezultat.produs,
      tableId
    );
    this.getNewDepositInfo(rezultat.produs, this.state.suma);
  };
  setActiune = (tipActiune) => {
    const { updateRow, tableId, row } = this.props;
    //daca nu am actiune caut prima actiune vizibila
    if (!tipActiune) {
      var h = this.state.listaActiune.find(x=>!x.hide);
      if (h) {
        tipActiune = h.value;
      }
    }
    //daca nu am actiune seteze prima actiune disponibila
    if (!tipActiune) {
      tipActiune = this.state.listaActiune[0].value;
    }
    this.setState({ tipActiune: tipActiune });
    updateRow(
      row,
      this.state.colActiune.tip,
      this.state.colActiune.key,
      tipActiune,
      tableId
    );
  };
  setSuma = (value) => {
    value += "";
    const { updateRow, tableId, row } = this.props;
    var sValue = parseFloat(value.replace(/[.]/g, "").replace(",", "."));
    var error = this.validate(sValue);
    this.setState({ suma: sValue, error: error });

    updateRow(
      row,
      this.state.colSuma.tip,
      this.state.colSuma.key,
      value,
      tableId
    );
    this.getNewDepositInfo(this.state.produs, sValue);
  };
  validate = (value) => {
    const { processMsg } = this.props;
    var err = "";
    /*if (!value) {
      err = "[OPF72] Completati suma"; // codul de erroare este gresit
    }*/
    if (value < this.state.smin) {
      err = "[OPF73] Suma trebuie sa fie mai mare sau egala cu suma minima";
    }
    return processMsg(err);
  };
  render() {
    const { row, /*tableId, updateRow, */ isReadonly, isMobile } = this.props;
    var info = jsonParse(row.INFO) || {};
    return (
      <div id="edf6">
        <div className="card-header">
          <div className="e-card-content-document">
            <div className="depozit-title">{this.state.denumire}</div>
            <div className="depozit-list">
              <div className="doc-table-label-suma doc-table-label-text-field">
                Introdu suma pe care vrei sa o depui:
              </div>
              <div className="doc-table-value-suma">
                <TextFieldMask
                  id="suma"
                  className={`doc-table-value${isReadonly ? " disabled" : ""}`}
                  variant="outlined"
                  value={this.state.suma ? this.state.suma : 0}
                  onBlur={(prop) => this.setSuma(prop.target.value)}
                  error={!!this.state.error}
                  helperText={
                    (this.state.error ? this.state.error : "Suma minima") +
                    (this.state.smin &&
                      ` ${this.state.smin.toLocaleString("ro-RO")} ${
                        this.state.valuta
                      }`)
                  }
                  type="decimal"
                  disabled={isReadonly}
                  isDecimal={true}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {this.state.valuta}
                      </InputAdornment>
                    ),
                    inputProps: {
                      decimalScale: 2,
                      tip: this.state.colSuma.tip,
                      colkey: this.state.colSuma.key,
                    },
                  }}
                  size="small"
                />
              </div>
            </div>

            <div className="doc-table-depozit">
              <div className="doc-table-label-slider">Perioada</div>
              <div className="doc-table-value-slider">
                {isMobile ? (
                  <RadioGroup
                    id="actiune"
                    value={
                      this.state.perioada != null
                        ? this.state.perioada.toString()
                        : null
                    }
                    onChange={(event) => {
                      //this.setPerioada(event.target.value);
                      this.setPerioada(parseInt(event.target.value));
                    }}
                    disabled={isReadonly}
                  >
                    {this.state.intervale.map((tip) => (
                      <FormControlLabel
                        key={tip.value}
                        value={tip.value.toString()}
                        control={<Radio color="primary" />}
                        label={
                          info.bonusPeriod ? (
                            <div className="h-list bonus-label">
                              {tip.label}
                              <SvgIcon
                                component={IconGift}
                                className="depozit-bonus-icon"
                                viewBox="0 0 16 16.001"
                              />
                            </div>
                          ) : (
                            tip.label
                          )
                        }
                        disabled={isReadonly}
                      />
                    ))}
                  </RadioGroup>
                ) : (
                  <Slider
                    value={this.state.perioada}
                    step={null}
                    onChangeCommitted={(event, value) =>
                      this.setPerioada(value)
                    }
                    valueLabelDisplay="auto"
                    marks={this.state.intervale}
                    min={this.state.min}
                    max={this.state.max}
                    disabled={isReadonly}
                    className={isReadonly ? "slider-disabled" : null}
                  />
                )}
              </div>
            </div>
            <div className="doc-table-depozit">
              <div className="doc-table-label-slider">
                Plata lunara a dobanzii
              </div>
              <div className="doc-table-value-slider">
                <RadioGroup
                  id="plata"
                  value={this.state.plataLunara}
                  row //={controlType === "radio-h"}
                  onChange={(event) => {
                    this.setPlata(event.target.value);
                  }}
                >
                  {this.state.listaPlata.map((tip) => (
                    <FormControlLabel
                      key={tip.value}
                      value={tip.value}
                      control={<Radio color="primary" />}
                      label={tip.label}
                      disabled={tip.hide || isReadonly}
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>

            <div className="doc-table-depozit">
              <div className="doc-table-label-slider">Actiune Scadenta</div>
              <div className="doc-table-value-slider">
                <RadioGroup
                  id="actiune"
                  value={this.state.tipActiune}
                  //row//={controlType === "radio-h"}
                  onChange={(event) => {
                    this.setActiune(event.target.value);
                  }}
                  disabled={isReadonly}
                >
                  {this.state.listaActiune.map((tip) => (
                    <FormControlLabel
                      key={tip.value}
                      value={tip.value}
                      control={<Radio color="primary" />}
                      label={tip.label}
                      disabled={tip.hide || isReadonly}
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>
            <div className="doc-table-line-top">
              <div className="doc-table-depozit h-list-max">
                <div className="doc-table-label-suma">Dobanda</div>
                <div className="doc-table-value-suma">
                  <NumericFormat
                    value={this.state.procent}
                    displayType="text"
                    thousandSeparator={true}
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={(value) => (
                      <div className="deposit-percent">{value}</div>
                    )}
                  />
                </div>
              </div>
              {info.procentBonus &&
                `${this.state.perioada},`.includes(
                  `${info.procentBonus.perioada},`
                ) &&
                info.procentBonus.procent && (
                  <div className="doc-table-depozit h-list-max">
                    <div className="doc-table-label-suma">
                      Bonus (inclus in dobanda)
                    </div>
                    <div className="doc-table-value-suma">
                      <NumericFormat
                        value={info.procentBonus.procent}
                        displayType="text"
                        thousandSeparator={true}
                        suffix="%"
                        decimalScale={2}
                        fixedDecimalScale
                        renderText={(value) => (
                          <div className="deposit-percent">{value}</div>
                        )}
                      />
                    </div>
                  </div>
                )}
              <div className="doc-table-depozit h-list-max">
                <div className="doc-table-label-suma">
                  Suma finala, cu dobanda, fara impozit
                </div>
                <div className="doc-table-value-suma">
                  <NumericFormat
                    value={this.state.interest}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={(value) => (
                      <div className="deposit-interest">{value}</div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(OnboardingDepozitePF_Edit);
