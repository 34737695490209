import React, { useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import Button from "@mui/material/Button";
import { TextFieldMask } from "TemplateCerere";
import { ArrowRight, Refresh } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import OtpInput from "react-otp-input";
import FormHelperText from "@mui/material/FormHelperText";
import { IconsSvg } from "TemplateCerere";
import CssBaseline from "@mui/material/CssBaseline";
import { onEnter } from "Utils";
import Link from "@mui/material/Link";

function EmailPage(props) {
  const { timeLeft, codeRequest, flow } = props.store;

  const [statePage, setStatePage] = useState({
    email: { value: null, error: null },
    smsCode: null,
  });

  const handlerUpdateValue = (name, value) => {
    var s = { ...statePage };
    s[name] = name === "smsCode" ? value : { value: value, error: null };
    setStatePage(s);
  };

  const validare = () => {
    const { email } = statePage;
    const { errors } = props.store;
    var errorMsgs = [];
    var s = { ...statePage };

    errorMsgs.forEach((e) => {
      if (e.ctrl) {
        s[e.ctrl] = {
          value: statePage[e.ctrl].value,
          error: errors.processMsg(e.msg),
        };
        e.ctrl = null;
      }
      if (e.check) {
        s.acord[e.check + "_err"] = errors.processMsg(e.msg);
      }
    });
    setStatePage(s);
    return errorMsgs;
  };

  const handlerConfirmEmail = () => {
    const { email, smsCode } = statePage;
    const { confirmEmailCode, errors } = props.store;

    var errorMsgs = validare();
    if (!smsCode || smsCode.length !== 8) {
      errorMsgs.push("[OPF10]Introduceti codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    //updateValue({ email: email.value, smsCode: smsCode });
    confirmEmailCode(
      email.value,
      smsCode,
      { email: email.value },
      props.history
    );
  };
  const handlerSendEmail = async () => {
    var s = { ...statePage };
    const { sendEmailCode, errors } = props.store;
    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs, false);
      return;
    }
    await sendEmailCode({
      email: s.email.value,
    });
    setStatePage(s);
  };

  return (
    <div className="paper-toolbar first">
      <CssBaseline />
      <IconsSvg icon="IllustrationSleep" />
      <div className="e-card-title">Sesiunea a expirat!</div>
      <div className="e-card-subtitle">
        Completeaza email si codul primit pentru a continua.
      </div>

      <div className="form">
        <TextFieldMask
          autoFocus
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          value={statePage.email?.value}
          onChange={(prop) => handlerUpdateValue("email", prop.target.value)}
          className="email"
          error={!!statePage.email.error}
          helperText={statePage.email.error}
          type="email"
          onKeyPress={(event) => onEnter(event, handlerSendEmail)}
        />
      </div>
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={handlerSendEmail}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Trimite Cod
      </Button>
      {!codeRequest && (
        <>
          <Typography variant="subtitle2">
            Introdu codul primit prin email:
          </Typography>

          <OtpInput
            value={statePage.smsCode ? statePage.smsCode : ""}
            onChange={(value) => handlerUpdateValue("smsCode", value)}
            numInputs={8}
            renderInput={(prop) => <input {...prop} disabled={!!codeRequest} />}
            inputStyle="MuiInputBase-root MuiInputBase-input MuiOutlinedInput-input otp-input"
            focusStyle="otp-focus"
            disabledStyle="MuiInputBase-root Mui-disabled"
            inputType="number"
            containerStyle="otp"
          />
          <FormHelperText error={false}></FormHelperText>
          <div className="h-list">
            <Typography variant="caption">
              Nu ai primit cod? Asteapta <b>{timeLeft} sec.</b>
            </Typography>

            <Button
              color="primary"
              /*className="link-btn"*/
              endIcon={<Refresh />}
              onClick={handlerSendEmail}
            >
              Retrimite email
            </Button>
          </div>
          <Button
            variant="contained"
            color="primary"
            /*className="login-btn"*/
            onClick={handlerConfirmEmail}
            endIcon={<ArrowRight fontSize="large" />}
            disabled={!statePage.smsCode}
          >
            Confirma email
          </Button>
        </>
      )}
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(EmailPage))
);
