import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import { checkName, checkEmail, checkCNP } from "Utils";

//import { InitialPage, AcordPage, EmailPage, PhonePage } from "TemplateCerere";
import { OnBoarding } from "TemplateCerere";

import Button from "@mui/material/Button";
import { ArrowLeft } from "@mui/icons-material";
import { ProgressBar } from "TemplateCerere";
import { Prompt } from "react-router-dom";

function RegisterOnboardingPF(props) {
  //const { updateValue } = props;
  const { updateValue, confirmPhone, sendPhone, errors } = props.store;
  const {
    isMobile,
    getStepsInfo,
    preventGoBack,
    preventGoBackMessage,
    stepId,
  } = props.store;
  let { jprop } = props.store;
  jprop = jprop || {};

  const [statePage, setStatePage] = useState({
    page: 1,
    acord: {
      tc: false, //termeni si conditii
      ni: false, //nota de informare
      proc: false, //acord general de procesare a datelor. bifarea lui arata pe ecran cele de mai jos
      com: false, //comunicari marketing
      sondaj: false, //sondaje
      grup: false, //procesarea datelor de catre grup patria,
      sms: false, //comunicare prin SMS
      email: false, //comunicare prin SMS
      canale: false, //comunicare prin SMS
    },
    name: { value: null, error: null },
    email: { value: null, error: null },
    email2: { value: null, error: null },
    cnp: { value: null, error: null },
    phone: { value: null, error: null },
    smsCode: null,
  });

  const [pages, setPages] = useState({
    initial: 1,
    acord: 2,
    email: 3,
    phone: 4,
  });
  const [prevPage, setPrevPage] = useState({
    2: "Conditii",
    3: "Numele tau",
    4: "Adresa e-mail",
  });
  //handlerUpdateValue = handlerUpdateValue.bind(this);
  //handlerUpdateAcord = handlerUpdateAcord.bind(this);

  var toolbarCss = "paper-toolbar";
  useEffect(() => {
    toolbarCss =
      isMobile && !prevPage[statePage.page]
        ? toolbarCss + " first"
        : toolbarCss;
  }, [isMobile, statePage.page]);

  useEffect(() => {
    updateValue({ preventGoBack: true });
    window.addEventListener("load", (event) => {
      event.preventDefault();
    });
  }, []);

  const handlerUpdateValue = (name, value) => {
    var s = { ...statePage };
    s[name] = name === "smsCode" ? value : { value: value, error: null };
    setStatePage(s);
  };

  const handlerUpdateAcord = (event) => {
    var s = { ...statePage }; //{ acord: { ...statePage.acord } };
    s.acord[event.target.name] = event.target.checked;
    s.acord[event.target.name + "_err"] = false;
    if (event.target.name === "proc" && !event.target.checked) {
      s.acord.com = false;
      s.acord.sondaj = false;
      s.acord.grup = false;
      s.acord.sms = false;
      s.acord.email = false;
      s.acord.canale = false;
      s.acord.com_err = false;
    }
    if (
      event.target.name === "sms" ||
      event.target.name === "email" ||
      event.target.name === "canale"
    ) {
      s.acord.com_err = false;
    }
    if (
      event.target.name === "com" ||
      event.target.name === "sondaj" ||
      event.target.name === "grup"
    ) {
      s.acord.proc_err = false;
    }
    setStatePage(s);
  };

  const validare = () => {
    const { phone, name, email, email2, cnp, page, acord } = statePage;
    var errorMsgs = [];

    if (page === pages.acord) {
      if (!checkName(name.value)) {
        errorMsgs.push({
          msg: "[OPF1]Nu ati introdus corect numele dumneavoastra",
          ctrl: "name",
        });
      }
      if (!acord.tc) {
        errorMsgs.push({
          msg: "[OPFA1]Trebuie sa acceptati termenii si conditiile",
          check: "tc",
        });
      }
      if (!acord.ni) {
        errorMsgs.push({
          msg: "[OPFA2]Trebuie sa acceptati nota de informare",
          check: "ni",
        });
      }
      if (acord.proc && !(acord.com || acord.sondaj || acord.grup)) {
        errorMsgs.push({
          msg: "[OPFA4]Daca sunteti de acord cu procesarea datelor trebuie sa acceptati o comunicare",
          check: "proc",
        });
      }
      if (
        acord.proc &&
        (acord.com || acord.sondaj || acord.grup) &&
        !(acord.sms || acord.email || acord.canale)
      ) {
        errorMsgs.push({
          msg: "[OPFA5]Trebuie sa alegeti cel putin o modalitate de comunicare",
          check: "com",
        });
      }
    }

    if (page === pages.email) {
      if (!checkName(name.value)) {
        errorMsgs.push({
          msg: "[OPF1]Nu ati introdus corect numele dumneavoastra",
          ctrl: "name",
        });
      }
      if (!checkEmail(email.value)) {
        errorMsgs.push({
          msg: "[OPF5]Adresa de email nu are un format valid",
          ctrl: "email",
        });
      }
      if (!checkEmail(email2.value)) {
        errorMsgs.push({
          msg: "[OPF5]Adresa de email nu are un format valid",
          ctrl: "email2",
        });
      }
      if (email.value.toUpperCase() !== email2.value.toUpperCase()) {
        errorMsgs.push({
          msg: "[OPF77]Adresele de email nu corespund",
          ctrl: "email2",
        });
      }
    }
    if (page === pages.phone) {
      if (!checkName(name.value)) {
        errorMsgs.push({
          msg: "[OPF1]Nu ati introdus corect numele dumneavoastra",
          ctrl: "name",
        });
      }
      if (!(phone && phone.value && phone.value.length === 10)) {
        errorMsgs.push({
          msg: "[OPF8]Introduceti corect numarul de telefon (de forma: 07xxxxxxxx)!",
          ctrl: "phone",
        });
      }
      if (!checkCNP(cnp.value)) {
        errorMsgs.push({ msg: "[OPF2]Campul CNP este incorect!", ctrl: "cnp" });
      }
    }
    var s = { ...statePage };
    errorMsgs.forEach((e) => {
      if (e.ctrl) {
        s[e.ctrl] = {
          value: s[e.ctrl].value,
          error: errors.processMsg(e.msg),
        };
        e.ctrl = null;
      }
      if (e.check) {
        s.acord[e.check + "_err"] = errors.processMsg(e.msg);
      }
    });
    setStatePage(s);
    return errorMsgs;
  };

  const handlerConfirmPhone = () => {
    const { phone, name, email, smsCode, cnp, acord } = statePage;

    var errorMsgs = validare();
    if (!smsCode || smsCode.length !== 6) {
      errorMsgs.push("[OPF10]Introduceti codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    updateValue({ phone: phone.value, smsCode: smsCode, preventGoBack: false });
    confirmPhone(
      {
        acord_tc: acord.tc ? "1" : "0", //termeni si conditii
        acord_ni: acord.ni ? "1" : "0", //nota de informare
        acord_proc: acord.proc ? "1" : "0", //acord general de procesare a datelor. bifarea lui arata pe ecran cele de mai jos
        acord_com: acord.com ? "1" : "0", //comunicari marketing
        acord_sondaj: acord.sondaj ? "1" : "0", //sondaje
        acord_grup: acord.grup ? "1" : "0", //procesarea datelor de catre grup patria,
        acord_sms: acord.sms ? "1" : "0", //comunicare prin SMS
        acord_email: acord.email ? "1" : "0", //comunicare prin email
        acord_canale: acord.canale ? "1" : "0", //comunicare prin canale
        cnp: cnp.value,
        name: name.value,
        email: email.value,
      },
      props.history
    );
  };
  const handlerSendPhone = async () => {
    const { phone, name, email, cnp } = statePage;
    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs, false);
      return;
    }
    updateValue({ phone: phone.value });
    await sendPhone({
      cnp: cnp.value,
      name: name.value,
      email: email.value,
    });
    var s = { ...statePage };
    s.smsCode = props.store.smsCode;
    setStatePage(s);
  };
  const handlerChangePage = () => {
    var s = { ...statePage };
    if (s.page === pages.phone) {
      handlerConfirmPhone();
    } else {
      var errorMsgs = validare();
      if (errorMsgs.length !== 0) {
        errors.open(errorMsgs, false);
        return;
      }
      s.page++;
      setStatePage(s);
      props.store.stepId = "O" + s.page;
    }
  };
  const handlerPrevPage = () => {
    var s = { ...statePage };
    s.page--;
    setStatePage(s);
    props.store.stepId = "O" + s.page;
  };

  const handlerExit = () => {
    updateValue({ preventGoBack: false });
    props.history.push("/goodbye");
  };

  const Page = (className) => {
    switch (statePage.page) {
      case pages.initial:
        return (
          <OnBoarding.InitialPage
            handlerExit={handlerExit}
            handlerChangePage={handlerChangePage}
            className={className}
          />
        );
      case pages.acord:
        return (
          <OnBoarding.AcordPage
            {...statePage.acord}
            handleChange={handlerUpdateAcord}
            downloadAcord={props.store.downloadAcord}
            handlerChangePage={handlerChangePage}
            updateValue={handlerUpdateValue}
            name={statePage.name}
            className={className}
          />
        );
      case pages.email:
        return (
          <OnBoarding.EmailPage
            updateValue={handlerUpdateValue}
            name={statePage.name}
            email={statePage.email}
            email2={statePage.email2}
            handlerChangePage={handlerChangePage}
            className={className}
          />
        );
      case pages.phone:
        return (
          <OnBoarding.PhonePage
            updateValue={handlerUpdateValue}
            codeRequest={props.store.codeRequest}
            name={statePage.name}
            phone={statePage.phone}
            cnp={statePage.cnp}
            smsCode={statePage.smsCode}
            timeLeft={props.store.timeLeft}
            sendPhone={handlerSendPhone}
            confirmPhone={handlerConfirmPhone}
            className={className}
          />
        );
      default:
        return <OnBoarding.InitialPage />;
    }
  };

  return (
    <div className={toolbarCss}>
      <Prompt when={preventGoBack} message={preventGoBackMessage} />
      {isMobile && jprop.theme === "retail" && (
        <div className="nav-bar">
          <ProgressBar
            goBack={handlerPrevPage}
            prevStep={!!prevPage[statePage.page]}
            stepId={stepId}
            getStepsInfo={getStepsInfo}
          />
        </div>
      )}

      <CssBaseline />
      <div className="login-content">
        {!isMobile && prevPage[statePage.page] && (
          <div className="doc-nav">
            <Button
              color="primary"
              className="link-btn"
              startIcon={<ArrowLeft />}
              onClick={handlerPrevPage}
            >
              {prevPage[statePage.page]}
            </Button>
          </div>
        )}

        {Page(jprop.className || "")}
      </div>
    </div>
  );
}

export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(
    observer(RegisterOnboardingPF)
  )
);
