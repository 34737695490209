import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { CardContent, CardHeader } from "@mui/material";
import { getStyle, objectIsNull } from "Utils";
import "../index.css";

function Field8(props) {
  const { item, withOutLabel } = props;
  const styleProps = getStyle(item.eventProps);
  const CardCss = styled.div`
    ${styleProps.input}
  `;
  const jprop = item.Jprop || {};
  var withLabel = objectIsNull(withOutLabel) || !withOutLabel;
  withLabel = objectIsNull(jprop.withLabel) ? withLabel : jprop.withLabel;

  //exemplu de stilizare
  //const css = ` #cardD${item.Id} {text-align: center; color: red;} #cardH${item.Id} {text-align: right; color: blue;}`;
  //const CardCss = styled.div`${css}`;

  return (
    !!item.isVisible && (
      <CardCss
        id={`f8-${item.Id}`}
        className={`note ${item.Jprop.className ? item.Jprop.className : ""}`}
        variant="outlined"
      >
        {withLabel && (
          <CardHeader
            id={`f8h-${item.Id}`}
            title={item.Caption + (item.Obligatoriu ? " *" : "")}
            className="note-header"
            disableTypography
          />
        )}
        <CardContent id={`f8c-${item.Id}`}>
          <div
            id={`f8d-${item.Id}`}
            key={`tf8-${item.Id}`}
            className="note-area"
            /*fullWidth*/ dangerouslySetInnerHTML={{ __html: item.Valoare }}
          />
        </CardContent>
      </CardCss>
    )
  );
}
export default observer(Field8);
