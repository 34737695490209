import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";

import "../index.css";
//import logo from './img/patria_logo.svg';

import LoginPhone from "./loginPhone";
import LoginPhoneOCR from "./loginPhoneOCR";
import LoginPhoneCNP from "./loginPhoneCNP";
import LoginPhoneCard from "./loginPhoneCard";
import RegisterOnboardingPF from "./registerOnboardingPF";
import RegisterOnboardingPFOld from "./registerOnboardingPFOld";
import LoginPhoneRetail from "./loginPhoneRetail";
import LoginEmailRetail from "./loginEmailRetail";
import RegisterRetailLending from "./registerRetailLending";
import RegisterActualizareDate from "./registerActualizareDate";
import RegisterCustomRetail from "./registerCustomRetail";
import RegisterPrescoring from "./registerPrescoring";

function Login(props) {
  const { flowLogin, noMatch } = props.store;
  let query = new URLSearchParams(props.location.search);
  const url = query.get("ref");
  useEffect(() => {
    console.log("Intrare in login xx", props, url, flowLogin);
    props.store.loginSelect(url);
  }, []);
  const LoginPage = () => {
    switch (flowLogin) {
      case "phone":
        return <LoginPhone />;
      case "phoneCNP":
        return <LoginPhoneCNP />;
      case "phoneOCR":
        return <LoginPhoneOCR />;
      case "phoneCard":
        return <LoginPhoneCard />;
      case "registerOnboardingPF":
        return <RegisterOnboardingPF />;
      case "registerOnboardingPFOld":
        return <RegisterOnboardingPFOld />;
      case "phoneRetail":
        return <LoginPhoneRetail />;
      case "emailRetail":
        return <LoginEmailRetail />;
      case "registerRetailLending":
        return <RegisterRetailLending />;
      case "registerActualizareDate":
        return <RegisterActualizareDate />;
      case "registerCustomRetail":
        return <RegisterCustomRetail />;
      case "registerPrescoring":
        return <RegisterPrescoring />;

      default:
        return <LoginPhone />;
    }
  };
  if (noMatch) {
    return (
      <div className="nomatch" dangerouslySetInnerHTML={{ __html: noMatch }} />
    );
  }
  return <div className="login-card">{LoginPage()}</div>;
}

export default inject((stores) => ({ store: stores.mainStore }))(
  observer(Login)
);
