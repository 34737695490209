import React, { useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import {
  IconLinesHelpdesk,
  IconLinesList,
  MobileDialog,
  siglaPBKWhite,
} from "TemplateCerere";
import RetailMenu from "./retailMenu";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Close, ExitToApp, Home } from "@mui/icons-material/";

const Logo = (props) => {
  return (
    <div className="menu-group">
      {/*<img src={patriaFromAnywhereWhite} className="logo-menu" alt="" />*/}
      <div className="logo-menu" />
      <div className="menu-flux">{props.title}</div>
      {/*<div className="menu-name">
        <div>PATRIA</div>
        <div>BANK</div>
  </div>*/}
    </div>
  );
};

const Footer = (props) => {
  const { logout, url, version, isAuthenticated, appType, handleHome } = props;
  return (
    <div className="menu-footer">
      {appType !== "desk" && (
        <>
          <div className="text">Ai nevoie de ajutor?</div>
          <div className="h-list">
            <div className="info">0800 410 310</div>
            <div className="help">(gratuit, apelabil national)</div>
          </div>
          <div className="h-list">
            <div className="info">+40 372 271 403</div>
            <div className="help">(tarif normal, apelabil international)</div>
          </div>
          <div className="info">L-V: 09:00 - 19:00</div>
        </>
      )}
      <div className="bottom">
        <img src={siglaPBKWhite} className="logo-bottom" alt="" />
        <div className="copyright">
          <div>{`Copyright © Patria Bank, ${new Date().getFullYear()}`}</div>
          <div>
            {appType} {version}
          </div>
        </div>
        {isAuthenticated && (
          <>
            {appType === "desk" && (
              <Tooltip title="Acasa">
                <IconButton
                  aria-label="home"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleHome}
                  color="inherit"
                >
                  <Home />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Iesire">
              <IconButton onClick={() => logout({ url: url })} color="inherit">
                <ExitToApp />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

const retailMenu = ({ jprop, stepId }) => {
  return (
    <div className="vlist">
      <div className="title">
        Unde te afli in procesul de creare al contului tau:
      </div>
      <div className="sidebar">
        <RetailMenu steps={jprop.steps} id={stepId} />
      </div>
    </div>
  );
};
const retailFooter = ({ logout, url, version }) => {
  return <Footer logout={logout} url={url} version={version} />;
};
const RetailToolbar = (props) => {
  const {
    logout,
    isAuthenticated,
    isMobile,
    appType,
    fluxuri,
    flow,
    changeFlux,
  } = props.store;
  let { stepId, jprop, version } = props.store;
  jprop = jprop || {};
  const url = props.history.location.pathname;
  //const isMobile = useMediaQuery('(max-width:960px)');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [content, setContent] = useState(null);

  const handlerHelp = () => {
    //const retailFooter = <Footer logout={logout} url={url} version={version} />;
    console.log("help");
    setDialogOpen(true);
    setContent(retailFooter({ logout, url, version }));
  };

  const handlerCloseDialog = () => {
    setDialogOpen(false);
  };

  const handlerLineList = () => {
    setDialogOpen(true);
    setContent(retailMenu({ jprop, stepId }));
  };

  const handleHome = () => {
    var flux = fluxuri.find((x) => x.Flow == flow);
    changeFlux(flux, props.history);
    /*props.store.updateValue({
      preventGoBack: false,
      themeSelected: false,
      theme: null,
      jprop: null,
    });
    props.history.push(`/${appType}/${flow}`);*/
  };

  const Dialog = () => {
    return (
      <div className="retail">
        <nav>
          <Logo />
          <div className="menu-icons">
            <Close onClick={handlerCloseDialog} color="primary" />
          </div>
        </nav>
        {content}
      </div>
    );
  };
  return (
    <>
      <nav>
        <Logo title={jprop.title} />
        {isMobile ? (
          <div className="menu-icons">
            <IconLinesHelpdesk className="icon" onClick={handlerHelp} />
            <MobileDialog
              isOpen={dialogOpen}
              className={(jprop?.className ?? "") + " mobile dialog-paper"}
            >
              <Dialog />
            </MobileDialog>
            <IconLinesList className="icon" onClick={handlerLineList} />
            {isAuthenticated && (
              <ExitToApp
                onClick={() => logout({ url: url })}
                className="icon"
              />
            )}
          </div>
        ) : (
          <>
            <div className="sidebar">
              <RetailMenu steps={jprop.steps} id={stepId} />
            </div>
            <Footer
              logout={logout}
              url={url}
              version={version}
              isAuthenticated={isAuthenticated}
              appType={appType}
              handleHome={handleHome}
            />
          </>
        )}
      </nav>
    </>
  );
};
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(RetailToolbar))
);
