import React from "react";
import { observer } from "mobx-react";
import PachetContOption from "./pachetContOption";
import PachetContIcon from "./pachetContIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArchiveIcon from "@mui/icons-material/Archive";
/*
import {
  IconLinesBankAccount,
  IconLinesCard,
  IconLinesInternetBanking,
  IconLinesSMS,
} from "TemplateCerere";
*/
import Button from "@mui/material/Button";
import { ArrowRight } from "@mui/icons-material";
import * as Field from "../field";
import { TooltipIcon } from "TemplateCerere";
//import { ContValutaIcon } from "./icons";

const PachetSelectat = (props) => {
  const getIcon = (e) => {
    return e ? (
      <CheckCircleIcon style={{ color: "#009479" }} />
    ) : (
      <RemoveCircleOutlineIcon style={{ fill: "#C22821" }} />
    );
  };
  const { pachet, readonly, onSelect, onReset, doc, fields, actions } = props;
  const { cod_produs, info, opt } = pachet;

  const ctrlSetup = (c) => {
    var ctrl = actions.getField(c);
    ctrl.error = false;
    var r = { ctrl: ctrl, opt: opt.find((o) => o.id === c) || {} };
    r.ctrl.Caption = r.opt.captionSelected ?? r.ctrl.Caption;
    return r;
  };

  const cont = ctrlSetup(fields.cont);
  const card = ctrlSetup(fields.card);
  const ib = ctrlSetup(fields.ib);
  const sms = ctrlSetup(fields.sms);
  const contval = ctrlSetup(fields.contval);
  const cont_extra = ctrlSetup(fields.cont_extra);
  const card_extra = ctrlSetup(fields.card_extra);
  const ib_extra = ctrlSetup(fields.ib_extra);
  const sms_extra = ctrlSetup(fields.sms_extra);
  const saving_extra = fields.saving_extra
    ? ctrlSetup(fields.saving_extra)
    : {};
  const nume_card = actions.getField(135);
  const livrare_card = actions.getField(fields.delivery);
  nume_card.error = false;
  if (!contval.ctrl.listData.find((e) => e[0] === "")) {
    contval.ctrl.listData.push(["", "Nu vreau cont in valuta"]);
  }
  return (
    <>
      {!readonly && (
        <div className="pachet-select-reset">
          <Button
            color="primary"
            className="link-btn"
            endIcon={<ArchiveIcon fontSize="large" />}
            onClick={onReset}
          >
            Arata toate produsele
          </Button>
        </div>
      )}
      <div className="pachet-selected-wrapper">
        <div
          className={["pachet-selected", info.className].join(" ")}
          id={cod_produs}
        >
          <div className="pachet-oferta">
            <PachetContIcon
              prefix={info.prefix}
              caption={info.caption}
              estePachet={info.estePachet}
            />
            {info.promoText && (
              <>
                <div className="promo">{info.promoText}</div>
                <div className="promo-helper">{info.promoHelper}</div>
              </>
            )}
          </div>
          {info.estePachet ? (
            /*Display pentru pachete*/
            <div className="pachet-optiuni">
              <div className="pachet-pret">
                <span>{info.pret}</span>
                <span>Lei</span>
                <span>/luna</span>
              </div>
              <div className="pachet-helper">{info.helperText}<br></br>{info.helperText2}</div>
              <div className="pachet-optiune">
                <div className="card-header-title">Cont curent</div>
                <div className="pachet-optiune-info">
                  <PachetContOption
                    type="cont"
                    caption={cont.opt.captionSelected}
                    helperText={cont.opt.helperText}
                    icon={getIcon(cont.opt.included)}
                  />
                </div>
              </div>
              <div className="pachet-optiune">
                <div className="card-header-title">Card debit</div>
                <div className="pachet-control">
                  <Field.Field4
                    item={card.ctrl}
                    inline={true}
                    actions={actions}
                    withOutLabel={true}
                    /*            css={css}
                            isMobile={isMobile}
                            gridItem={gridItem}*/
                  />
                </div>
                <div className="pachet-control download-icons">
                  <Field.Field4
                    item={nume_card}
                    inline={true}
                    actions={actions}
                    withLabel={true}
                    /*            css={css}
                            isMobile={isMobile}
                            gridItem={gridItem}*/
                  />
                  {livrare_card && (
                    <Field.Field4
                      item={livrare_card}
                      inline={true}
                      actions={actions}
                      withLabel={true}
                      /*            css={css}
                            isMobile={isMobile}
                            gridItem={gridItem}*/
                    />
                  )}
                </div>
              </div>
              {contval.ctrl.isVisible && (
                <div className="pachet-optiune">
                  <div className="card-header-title">Cont in valuta</div>
                  <div className="pachet-control">
                    <Field.Field4
                      item={contval.ctrl}
                      inline={true}
                      actions={actions}
                      withOutLabel={true}
                      /*            css={css}
                              isMobile={isMobile}
                              gridItem={gridItem}*/
                    />
                  </div>
                </div>
              )}
              <div className="pachet-optiune">
                <div className="card-header-title">
                  Internet & Mobile Banking
                </div>
                <div className="pachet-control">
                  {ib.ctrl.isReadonly ? (
                    <PachetContOption
                      type="ib"
                      caption={ib.opt.captionSelected}
                      helperText={ib.opt.helperText}
                      icon={getIcon(ib.opt.included)}
                    />
                  ) : (
                    <Field.Field5
                      item={ib.ctrl}
                      inline={true}
                      actions={actions}
                      /*            css={css}
                              isMobile={isMobile}
                              gridItem={gridItem}*/
                    />
                  )}
                </div>
              </div>
              {sms.ctrl.isVisible && (
                <div className="pachet-optiune">
                  <div className="card-header-title">SMS Alert</div>
                  <div className="pachet-control">
                    {sms.ctrl.isReadonly ? (
                      <PachetContOption
                        type="sms"
                        caption={sms.opt.captionSelected}
                        helperText={sms.opt.helperText}
                        icon={getIcon(sms.opt.included)}
                      />
                    ) : (
                      <Field.Field5
                        item={sms.ctrl}
                        inline={true}
                        actions={actions}
                        helperText={sms.opt.helperText}
                        /*            css={css}
                                isMobile={isMobile}
                                gridItem={gridItem}*/
                      />
                    )}
                  </div>
                </div>
              )}
              {pachet.depozit?.show && (
                <div className="pachet-optiune">
                  <div className="card-header-title">Depozit bancar</div>
                  <div className="pachet-control">
                    <PachetContOption
                      type="depo"
                      caption="Depozitul bancar se poate configura in pasul urmator"
                      helperText=""
                      icon={getIcon(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            /*Display pentru cont curent*/
            <div className="pachet-optiuni">
              <div className="pachet-extra pachet-extra-cont">
                <div className="pachet-extra-column">
                  <div className="card-header-title">
                    Alege conturile curente
                  </div>
                  <div className="pachet-control">
                    <Field.Field4
                      item={cont_extra.ctrl}
                      inline={true}
                      actions={actions}
                      withOutLabel={true}
                      /*            css={css}
                              isMobile={isMobile}
                              gridItem={gridItem}*/
                    />
                  </div>
                </div>
              </div>
              {pachet.depozit?.show && (
                <div className="pachet-optiune">
                  <div className="card-header-title">Depozit bancar</div>
                  <div className="pachet-control">
                    <PachetContOption
                      type="depo"
                      caption="Depozitul bancar se poate configura in pasul urmator"
                      helperText=""
                      icon={getIcon(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {info.estePachet ? (
        <>
          <div className="pachet-extra-title">Extraoptiuni</div>
          <div className="pachet-extra-subtitle">
            La pachetul ales {pachet.denumire} poti adauga extraoptiuni ce
            presupun costuri suplimentare
          </div>
        </>
      ) : (
        <>
          <div className="pachet-extra-title">Selecteaza si alte produse</div>
          <div className="pachet-extra-subtitle">
            Poti adauga extraoptiuni ce presupun costuri suplimentare
          </div>
        </>
      )}
      {saving_extra.ctrl?.isVisible && (
        <div className="pachet-extra pachet-extra-saving">
          <div className="pachet-extra-column pachet-control">
            <div className="card-header-title">Cont economii RON</div>
            <Field.Field5
              item={saving_extra.ctrl}
              inline={true}
              actions={actions}
              /*            css={css}
                      isMobile={isMobile}
                      gridItem={gridItem}*/
            />
          </div>
        </div>
      )}
      {card_extra.ctrl?.isVisible && (
        <div className="pachet-extra pachet-extra-card">
          <div className="pachet-extra-column">
            <div className="card-header-title">
              Adauga un card de debit suplimentar
              {info.estePachet && !!fields.csTooltip && (
                <span>
                  <TooltipIcon tooltip={fields.csTooltip} />
                </span>
              )}
            </div>
            <div className="pachet-control">
              <Field.Field4
                item={card_extra.ctrl}
                inline={true}
                actions={actions}
                withOutLabel={true}
                /*            css={css}
                      isMobile={isMobile}
                      gridItem={gridItem}*/
              />
            </div>
            {!info.estePachet && (
              <div className="pachet-control inline">
                <Field.Field4
                  item={nume_card}
                  inline={true}
                  actions={actions}
                  withLabel={true}
                  /*            css={css}
                        isMobile={isMobile}
                        gridItem={gridItem}*/
                />
                {livrare_card && (
                  <Field.Field4
                    item={livrare_card}
                    inline={true}
                    actions={actions}
                    withLabel={true}
                    /*            css={css}
                        isMobile={isMobile}
                        gridItem={gridItem}*/
                  />
                )}
              </div>
            )}
            <div className="">
              {info.estePachet && !!fields.csHelperText && fields.csHelperText}
            </div>
          </div>
        </div>
      )}
      {info.estePachet && (
        <div className="pachet-extra pachet-extra-cont">
          <div className="pachet-extra-column">
            <div className="card-header-title">
              Adauga cont curent in valuta
            </div>
            <div className="pachet-control">
              <Field.Field4
                item={cont_extra.ctrl}
                inline={true}
                actions={actions}
                withOutLabel={true}
                /*            css={css}
                        isMobile={isMobile}
                        gridItem={gridItem}*/
              />
            </div>
          </div>
        </div>
      )}
      {ib_extra.ctrl.isVisible && (
        <div className="pachet-extra pachet-extra-ib">
          <div className="pachet-extra-column pachet-control">
            <div className="card-header-title">
              Adauga Internet &amp; mobile banking
            </div>
            <Field.Field5
              item={ib_extra.ctrl}
              inline={true}
              actions={actions}
              /*            css={css}
                      isMobile={isMobile}
                      gridItem={gridItem}*/
            />
          </div>
          <div className="pachet-extra-column">
            Activeaza serviciul de Internet & Mobile Banking pentru o gestiune
            mai rapida a tranzactiilor tale bancare oricand si de oriunde!
          </div>
        </div>
      )}
      {sms_extra.ctrl.isVisible && (
        <div className="pachet-extra pachet-extra-sms">
          <div className="pachet-extra-column">
            <div className="card-header-title">
              Foloseste SMS Alert pentru cardurile
            </div>
            <Field.Field4
              item={sms_extra.ctrl}
              inline={true}
              actions={actions}
              withOutLabel={true}
              /*            css={css}
                      isMobile={isMobile}
                      gridItem={gridItem}*/
            />
          </div>
        </div>
      )}
      <div className="pachet-cont-chooser">
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight fontSize="large" />}
          onClick={() => {
            actions.updateStore({ goPrev: null });
            onSelect();
          }}
        >
          {!readonly ? "Confirma alegerea" : "Continua"}
        </Button>
      </div>
      {/*
      <div className="valori">
        <dt>Cont</dt><dd>{cont.ctrl.Valoare}</dd>
        <dt>Card</dt><dd>{card.ctrl.Valoare}</dd>
        <dt>IB</dt><dd>{ib.ctrl.Valoare}</dd>
        <dt>SMS</dt><dd>{sms.ctrl.Valoare}</dd>
        <dt>Cont Valuta</dt><dd>{contval.ctrl.Valoare}</dd>
        <dt>Cont Extra</dt><dd>{cont_extra.ctrl.Valoare}</dd>
        <dt>Card Extra</dt><dd>{card_extra.ctrl.Valoare}</dd>
        <dt>IB Extra</dt><dd>{ib_extra.ctrl.Valoare}</dd>
        <dt>SMS Extra</dt><dd>{sms_extra.ctrl.Valoare}</dd>
        <dt>OPT</dt><dd>{JSON.stringify(opt)}</dd>
      </div>
      */}
    </>
  );
};
export default observer(PachetSelectat);
