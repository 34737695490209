/* eslint-disable eqeqeq */

import { action, extendObservable, set } from "mobx";
//import { renameObjectKeys, runEvent, getValueOfList, getValueKey } from "Utils";
//import moment from "moment";
//import { Buffer } from "buffer";

//import { Api } from "Common/src";

const modelPreview = {
  isOpen: false,
  name: "",
  image: null,
  content: null,
};
const modelDialog = {
  isOpen: false,
  message: "Asteptati!",
  header: "Salvare document",
  url: null,
};
const modelEditDialog = {
  isOpen: false,
  header: "Modificare",
  child: null,
  save: null,
  showSave: true,
  showClose: true,
  fullScreen: false,
  error: null,
  validate: null,
  className: null,
  id: "",
};

const modelShape = {
  creditType: null,
  produse: null,
  interval_scadenta: [],
  credit: {
    suma_minima: 1000,
    suma_maxima: 80000,
    perioada_minima: 12,
    perioada_maxima: 60,
    asigurare: true,
    dobanda_fixa: true,
    valuta: "RON",
    dobanda: 7.49,
  },
  simulare: {},
  scadentar: [],
  comisioane: [],
  dialog: modelDialog,
  preview: modelPreview,
  errors: {},
  editDialog: modelEditDialog,
  // culoare: "#000000",
};

class CreditStore {
  constructor(props) {
    this.rootStore = props;
    //this.rootStore.api = new Api({ logout: props.mainStore.logout});
    extendObservable(this, modelShape);
  }

  @action
  doActivate = async (params, creditType) => {
    this.rootStore.mainStore.isWaiting = true;

    //daca este autentificat atunci se face logout
    this.creditType = creditType;
    this.rootStore.mainStore.stepId = "S0";
    if (this.rootStore.mainStore.isAuthenticated) {
      var url = null;
      await this.rootStore.mainStore.logout({ url: url });
    }
    this.rootStore.mainStore.isWaiting = true;
    this.rootStore.mainStore.updateValue({ flow: creditType });
    var result = await this.rootStore.mainStore.getFlow(creditType);
    if (result && result.noMatch) {
      this.rootStore.mainStore.isWaiting = false;
      return;
    }
    //await this.getCommissions();
    await this.rootStore.mainStore.checkVoucher(params?.voucher);
    await this.getDueDate();
    await this.getProducts(params);
    //this.rootStore.mainStore.hideAppBar = false;
    this.rootStore.mainStore.isWaiting = false;
  };

  @action
  getProducts = async (params) => {
    //this.rootStore.mainStore.isWaiting = true;

    // de adus nomenclatoare
    var results = await this.rootStore.api.fetch(
      "/api/common/produse-creditare",
      {
        type: this.creditType,
        voucher: params.voucher,
      }
    );
    if (results && !results.error && results != "401") {
      this.produse = results;
    }

    //this.rootStore.mainStore.isWaiting = false;
  };
  @action
  getDueDate = async () => {
    //this.rootStore.mainStore.isWaiting = true;

    // de adus nomenclatoare
    var results = await this.rootStore.api.fetch(
      "/api/common/credit-interval-scadenta"
    );
    if (results && !results.error && results != "401") {
      this.interval_scadenta = results;
    }

    //this.rootStore.mainStore.isWaiting = false;
  };

  @action
  getCommissions = async (id) => {
    this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.fetch(
      "/api/common/comisioane-produse-creditare",
      {
        id: id ? id : -1000,
      }
    );
    if (results && !results.error && results != "401") {
      this.comisioane = results;
    }

    this.rootStore.mainStore.isWaiting = false;
  };
  @action
  getSimulare = async (id) => {
    if (!id) {
      return;
    }
    //this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.fetch(
      "/api/common/simulare-creditare-get",
      {
        id: id,
      }
    );
    if (results && !results.error && results != "401") {
      this.simulare = results;
    }

    //this.rootStore.mainStore.isWaiting = false;
  };
  @action
  doDeactivate = async () => {
    await this.clear();
  };

  @action
  updateStore = (value, key) => {
    if (key) {
      set(this[key], value);
      return;
    }
    set(this, value);
  };
  @action
  addSimulation = async (params) => {
    this.rootStore.mainStore.isWaiting = true;
    var apiPath = `/api/common/simulare-creditare`;
    var results = await this.rootStore.api.fetch(apiPath, params);
    // sa vedem ce facem aici
    var errors = [];
    if (results.error) {
      errors.push(results.error);
      this.rootStore.mainStore.isWaiting = false;
      this.rootStore.mainStore.errors.open(errors);
    } else {
      set(this, results);
      var stelute = "**";
      this.comisioane
        .filter((x) => x.nota)
        .forEach((c) => {
          stelute = stelute + "*";
          c.nota = `${stelute} ${c.nota}`;
          c.denumire_comision = `${c.denumire_comision} ${stelute}`;
        });
    }
    this.rootStore.mainStore.isWaiting = false;
    return results;
  };

  clear = () => {
    this.rootStore.mainStore.info = null;
    set(this, modelShape);
  };
}

export default CreditStore;
