import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Edit, Delete, CloudDownload } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

import EditFields from "./field6_edit";
import { Checkbox } from "TemplateCerere";
import { formatCell } from "Utils";
import Field6EditField from "./field6_edit_field";
import "../index.css";

function Field6_Table(props) {
  const {
    /*item, inline, */ actions,
    eventProps,
    item,
    tableRowCss,
    onSelectRow,
  } = props;
  const { data, Jprop } = item;
  const TableCss = styled(Table)`
    ${eventProps.style}
  `;

  const handlerEdit = (row) => {
    if (!item.edit) {
      return;
    }
    actions.openEditTable({
      table: item,
      row: row,
      child: (
        <EditFields
          data={data}
          updateRow={actions.updateRow}
          tableId={item.Id}
          actions={actions}
        />
      ),
    });
  };
  const handleClick = (event, index, row) => {
    item.selectedRow = index;
    if (onSelectRow) {
      onSelectRow(row);
    }
  };

  const visibleHeaders = () => {
    var cols = item.data.columns.filter(
      (x) => x.isVisible && (!x.isDetail || (x.isDetail && x.keepinGrid))
    );
    var h = [];
    cols.forEach((c) => {
      var hIndex = h.length - 1;
      var hInfo = c.header || { text: "", expanded: true, inline: false };
      if (
        hIndex > -1 &&
        hInfo.text.toLowerCase() === h[hIndex].text.toLowerCase()
      ) {
        //sunt pe acelasi header, adaug coloana la header
        h[hIndex].columns.push(c);
      } //creez un header nou
      else {
        h.push({ ...hInfo, columns: [c] });
      }
    });
    return h;
  };
  var headers = visibleHeaders();

  const isSelected = (index) => {
    return item.selectedRow === index;
  };
  const updateRow = (row, tip, key, value, id) => {
    actions.updateRow(row, tip, key, value, id);
    actions.addToChangedTables(item);
  };
  return (
    <TableCss id={`tbl${item.Id}`} aria-label="spanning table" size="small">
      <TableHead id={`tbl${item.Id}h`}>
        {!(headers.length === 1 && headers[0].text === "") && (
          <TableRow id={`tbl${item.Id}r`}>
            {headers.map((header, index) => {
              var c = header.columns;
              if (c.length > 0) {
                return (
                  <TableCell
                    id={`tbl${item.Id}-c${index}`}
                    colSpan={c.length}
                    key={index}
                    align="center"
                    border={1}
                    bordercolor="grey.500"
                    style={{ ...Jprop?.headers?.style }}
                  >
                    {header.text}
                  </TableCell>
                );
              } else {
                return null;
              }
            })}
          </TableRow>
        )}
        <TableRow>
          {data.columns
            .filter(
              (x) =>
                x.isVisible && (!x.isDetail || (x.isDetail && x.keepinGrid))
            )
            .map((col) => {
              return (
                <TableCell key={`dt${col.key}`} {...col.formatProp}>
                  {col.Caption}
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
      <TableBody id={`tbl${item.Id}b`}>
        {data.rows.map((row, index) => {
          const isItemSelected = isSelected(index);
          return (
            item.isVisibleRow(row) && (
              <TableRow
                id={`tbl${item.Id}br${index}`}
                key={`tr-${index}`}
                hover
                onClick={(event) => handleClick(event, index, row)}
                onDoubleClick={() => {
                  !item.editInPlace && handlerEdit(row);
                }}
                selected={isItemSelected}
                className={tableRowCss}
              >
                {data.columns.length !== 0 &&
                  data.columns
                    .filter(
                      (x) =>
                        x.isVisible &&
                        (!x.isDetail || (x.isDetail && x.keepinGrid)) &&
                        !x.isMasterCol
                    )
                    .map((col) => {
                      const colStyle = Jprop?.cols?.[col.ColumnName]?.style;
                      return (
                        <TableCell
                          id={`tbl${item.Id}-c${col.key}-${index}`}
                          key={`${col.key}-${index}`}
                          {...col.formatProp}
                          style={{ ...colStyle }}
                        >
                          {col.tip === "c" &&
                          (!item.editInPlace || col.isReadonly) ? (
                            <Checkbox
                              checked={
                                row
                                  ? row[col.key] === "1" ||
                                    row[col.key] === true
                                  : false
                              }
                              color="primary"
                              variant="outlined"
                            />
                          ) : item.editInPlace && !col.isReadonly ? (
                            <Field6EditField
                              tableId={item.Id}
                              row={row}
                              col={col}
                              updateRow={updateRow}
                              actions={actions}
                            />
                          ) : (
                            formatCell(row, col, item.onFormatCell)
                          )}
                        </TableCell>
                      );
                    })}
                {item.edit && !item.editInPlace && (
                  <TableCell
                    id={`tbl${item.Id}-${index}e`}
                    style={{ align: "center" }}
                    key={`${item.Id}-${index}-e`}
                    onClick={() => handlerEdit(row)}
                    width={10}
                  >
                    <Tooltip title="Modifica">
                      <Edit color="primary" />
                    </Tooltip>
                  </TableCell>
                )}
                {item.delete && (
                  <TableCell
                    id={`tbl${item.Id}-${index}d`}
                    key={`${index}-d`}
                    onClick={() => actions.deleteTableRow(item, row._rowid)}
                    style={{ align: "center" }}
                    width={10}
                  >
                    <Tooltip title="Sterge">
                      <Delete color="secondary" />
                    </Tooltip>
                  </TableCell>
                )}
                {item.download && (
                  <TableCell
                    id={`tbl${item.Id}-${index}e`}
                    style={{ align: "center" }}
                    key={`${item.Id}-${index}-e`}
                    onClick={() =>
                      actions.downloadCloud({ table: item, row: row })
                    }
                    width={10}
                  >
                    <Tooltip title="Descarca">
                      <CloudDownload color="primary" />
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            )
          );
        })}
      </TableBody>
    </TableCss>
  );
}
export default observer(Field6_Table);
