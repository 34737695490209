import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import { ArrowRight, Refresh } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import OtpInput from "react-otp-input";
import FormHelperText from "@mui/material/FormHelperText";
import moment from "moment";

function ConfirmPhone(props) {
  const { item, actions } = props;
  const { errors } = actions.store.rootStore.mainStore;
  const lifeTime = item.Jprop?.lifeTime ?? "03";
  const [smsCode, setSmsCode] = useState(item.Valoare);
  const [isDisabled, setDisabled] = useState(false);
  const [endTime, setEndTime] = useState(moment().add(lifeTime, "minute"));
  const [timeLeft, setTimeLeft] = useState(null);
  const [isConfirmed, setConfirmed] = useState(!!item.Valoare);

  useEffect(() => {
    if (endTime && !isConfirmed && lifeTime !== "0") {
      const interval = setInterval(() => {
        var diffTime = endTime.diff(moment());
        if (diffTime <= 0) {
          setTimeLeft("00:00");
          setEndTime(null);
          errors.open([
            "A expirat timpul de introducere a codului.",
            'Apasa butonul "Retrimite" pentru a primi un nou cod valabil',
          ]);
          return;
        }
        setTimeLeft(moment(diffTime).format("mm:ss"));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [endTime]);
  const handlerRequest = () => {
    actions.queryData(
      item.Jprop?.queryRequest,
      {}, // sa vedem daca e nevoie de parametrii
      (msg) => {
        actions.updateMainStore({ isWaiting: false });
        console.log("msg", msg);
        if (msg?.r === 0) {
          setEndTime(moment().add(lifeTime, "minute"));
          if (msg.o?.valueOf("Table").Table && msg.o.Table[0].cod) {
            setSmsCode(msg.o.Table[0].cod);
          }
          alert("Codul a fost reinnoit!");
          setDisabled(false);
        } else {
          errors.open(msg?.err);
        }
      }
    );
  };

  const handlerConfirm = () => {
    actions.queryData(
      item.Jprop?.queryConfirm,
      { cod: smsCode }, // sa vedem daca e nevoie si de alti parametrii
      (msg) => {
        actions.updateMainStore({ isWaiting: false });
        console.log("msg", msg);
        if (
          msg?.r === 0 &&
          msg.o?.valueOf("Table").Table &&
          msg.o?.Table[0].description == "Cod valid"
        ) {
          actions.updateValue(item, smsCode);
          setConfirmed(true);
        } else {
          errors.open(msg?.err ?? "Cod Incorect!");
        }
      }
    );
  };

  return (
    <React.Fragment>
      {/*<Typography variant="subtitle2">{label}</Typography>*/}
      <OtpInput
        value={smsCode ? smsCode : ""}
        onChange={setSmsCode}
        numInputs={item.Jprop?.numInputs ?? 6}
        renderInput={(props) => (
          <input {...props} disabled={isDisabled || isConfirmed} />
        )}
        inputStyle="MuiInputBase-root MuiInputBase-input MuiOutlinedInput-input otp-input"
        focusStyle="otp-focus"
        disabledStyle="MuiInputBase-root Mui-disabled"
        inputType={item.Jprop?.inputType ?? "number"}
        containerStyle="otp-app"
      />
      <FormHelperText error={item.error}>
        {!!item.error ? item.error : smsCode && item.Jprop.helperText}{" "}
      </FormHelperText>
      {!isConfirmed && (
        <>
          {lifeTime !== "0" && (
            <div className="h-list">
              <Typography variant="caption">
                Nu ai primit cod? Asteapta <b>{timeLeft} sec.</b>
              </Typography>
              <Button
                color="primary"
                /*className="link-btn"*/
                endIcon={<Refresh />}
                onClick={handlerRequest}
                disabled={!!endTime}
              >
                Retrimite
              </Button>
            </div>
          )}
          {item.Jprop?.queryConfirm && (
            <Button
              variant="contained"
              color="primary"
              /*className="login-btn"*/
              onClick={handlerConfirm}
              endIcon={<ArrowRight fontSize="large" />}
              disabled={!smsCode}
            >
              Confirma
            </Button>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default observer(ConfirmPhone);
