import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import Container from "@mui/material/Container";

import LoginUserOtp from "./loginUserOtp";
import Copyright from "../copyright";

function LoginUser(props) {
  const { pwdDetails, checkUser } = props.store;
  const { username, smsCode, requestOtp, errors } = props.store;
  const { updateValue, resetPwd } = props.store;
  const { isPasswordSet } = pwdDetails;
  let query = new URLSearchParams(props.location.search);
  const url = query.get("ref");

  useEffect(() => {
    if (isPasswordSet === null) {
      checkUser({ history: props.history, url: url });
    }
  }, [isPasswordSet, url, props.history]);

  const handlerPassword = () => {
    var errorMsgs = [];
    if (!smsCode) {
      errorMsgs.push("[LU001] Introduceti codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    resetPwd(props.history, url);
  };

  return (
    <div className="login-card">
      <div component="main">
        <CssBaseline />
        <div className="login">
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            className="login-title"
            align="center"
          >
            Resetare Parola
          </Typography>
          <div className="form">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              label="Utilizator"
              name="Utilizator"
              value={username ? username : ""}
              //onChange= {(prop) => updateValue({ username: prop.target.value })}
              //autoFocus
              className="login-txt disabled"
              disabled={true}
              //autoComplete="Utilizator"
            />

            <LoginUserOtp
              smsCode={smsCode}
              updateValue={updateValue}
              requestOtp={requestOtp}
              //validare={validare}
              errors={errors}
            />
            <Button
              //type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              onClick={handlerPassword}
            >
              Reseteaza Parola
            </Button>
          </div>
        </div>

        <Copyright />
      </div>
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(LoginUser))
);
