import { action, observable, set, extendObservable } from "mobx";
import moment from "moment";

import { persist } from "mobx-persist";
import { userTypes } from "Common/src";
//import Api from "Common/src/Api";
import { ErrorsModel } from "TemplateCerere";
import { PrintParametersModel } from "TemplateCerere";

const model = {
  phone: null,
  smsCode: null,
  isAuthenticated: false,
  user: { username: "", userType: null },
  loginView: null,
  cnp: null,
  acordA: null,
  acordB: null,
  acordC: null,
  firstDigits: null,
  lastDigits: null,
  username: "",
  password: "",
  childs: null,
  title: "Patria Bank",
  version: "v2-2024.05.18-01",
  info: null,
  timeLeft: "00:00",
  codeExpired: false,
  logoutTime: null,
  lastCall: null,
  phoneX: { value: null },
  msgHtml: null,
  noMatch: "",
  urlFlow: null,
  preventGoBack: false,
  //appType: null,
  flowType: null,
  fluxuri: null,
  flux: null,
  autoLogOut: null,
};
const modelPwd = {
  oldPassword: null,
  newPassword: null,
  confirmPassword: null,
  isPasswordSet: null,
  otpRequired: true,
};

class MainStore {
  @persist @observable loginName = null;

  @persist @observable username = "";
  @persist @observable url = "";

  @persist @observable isAuthenticated = false;
  @persist("object") @observable user = { username: "" };

  @persist @observable loginView = null;
  @persist @observable registerView = null;

  //@persist Oare de ce era marcat persist?
  @observable flowLogin = null;

  @persist @observable flow = null;
  @persist @observable flowName = null;
  @persist @observable urlFlow = null;
  @persist @observable appType = null;

  @observable initializat = false;
  @observable tryLogin = true;
  autoLogOut = false;
  @observable errors = new ErrorsModel();
  @observable printParameters = new PrintParametersModel();
  @observable smsCode = null;
  @observable cnp = null;
  @observable name = null;
  @observable acordA = null;
  @observable acordB = null;
  @observable acordC = null;
  @observable firstDigits = null;
  @observable lastDigits = null;
  @observable idCerere = "";
  @observable phone = null;
  @observable email = null;
  @observable password = "";
  @observable isWaiting = false;
  @observable forceWaiting = false;
  @observable codeRequest = true;

  @observable title = model.title;
  @observable version = model.version;
  @observable info = model.info;
  @observable pwdDetails = modelPwd;

  @observable usrTypes = userTypes;
  @observable history = null;
  @observable jprop = null;
  endTime = null;
  awaitCode = false;
  loginAwait = null;
  @observable noMatch = null;
  @observable environment = "production";
  @observable isMobile = false;
  @observable hideAppBar = false;
  @observable stepId = null;
  @observable themeSelected = false;
  @observable msgHtml = null;
  @observable preventGoBack = false;
  @observable preventGoBackMessage =
    "Utilizand aceasta metoda este posibil sa pierdeti informatiile, doriti sa continuati?";
  @observable idSimulare = null;
  @observable theme = null;
  @observable position = { latitude: "", longitude: "" };
  @observable fluxuri = null;
  @observable voucher = { value: null, err: 0, msg: "", url: null };

  constructor(props) {
    this.rootStore = props;
    //this.rootStore.api = new Api({ logout: this.logout});
    extendObservable(this.pwdDetails, modelPwd);
    this.timerLogout();
  }

  getErrorMessages = async (history) => {
    this.history = history;
    var results = await this.rootStore.api.fetch("/api/common/messages");
    if (results && !results.error && results != "401") {
      this.errors.errorMessages = results;
      /*      console.log("this.getErrorMessages - results", results);
      console.log(
        "this.getErrorMessages - lista errorMessages",
        this.errors.errorMessages
      );*/
    }
  };
  @action
  checkLoginUser = async (history, url, appType, params) => {
    const isUser =
      this.user.userType === this.usrTypes.user ||
      this.user.userType === this.usrTypes.internal;
    //this.isUser = isUser;
    //this.url = url;
    var oldApptype = this.appType ?? "neselectat";
    set(this, {
      isUser: isUser,
      url: url,
      themeSelected: true,
      appType: appType,
      fluxuri: oldApptype != this.appType ? null : this.fluxuri,
      flux: oldApptype != this.appType ? null : this.flux,
    });
    if (this.isAuthenticated && isUser && oldApptype == appType) {
      this.tryLogin = false;

      if (this.fluxuri) {
        if (params?.type) {
          var flux = this.fluxuri.find((x) => x.Flow === params?.type);
          if (flux && flux.Flow != this.flow) {
            await this.changeFlux(flux, history);
          }
        }
      } else {
        // daca nu sunt fluxuri le initializez
        await this.initFlux(params, history);
      }

      return;
    }

    if (
      (!isUser && this.isAuthenticated) ||
      (this.isAuthenticated && oldApptype != appType && !this.tryLogin)
    ) {
      await this.logout({ url: url });
    }
    this.updateValue({ preventGoBack: false });
    this.tryLogin = true;
    const loginType = appType === "desk" ? "desk" : "app";
    history.push(`/${loginType}login?ref=${url}`);
    return;
  };

  @action
  checkLogin = async (history, url, params) => {
    const isUser =
      this.user.userType === this.usrTypes.user ||
      this.user.userType === this.usrTypes.internal;
    this.isUser = isUser;
    //this.url = url;
    //params = params||{};
    //daca sunt autentificat, nu este user si sunt pe acelasi flux inseamna ca e ok
    if (
      (this.isAuthenticated && !isUser && params.type === this.flow) ||
      (params?.auto == "true" && !this.autoLogOut)
    ) {
      this.tryLogin = false;
      return;
    }

    //    this.url = url;

    //daca sunt autentificat si utilizatorul este user sau difera fluxul atunci trebuie sa ma reautentific
    if (this.isAuthenticated && (isUser || params.type !== this.flow)) {
      await this.logout({ url: url });
    }
    set(this, {
      flow: params.type,
      idCerere: params.id,
      idSimulare: params.sim,
    });
    this.tryLogin = true;
    this.updateValue({ preventGoBack: false });
    history.push(`/login?ref=${url}`);
  };

  @action
  navigate = (history, url) => {
    history.push(url);
    return null;
  };
  @action
  //loginSelect = async (idCerere) => {
  loginSelect = async (url) => {
    if (!url) {
      return;
    }
    var [, type, flow, id, voucher] = url.split("/");
    const idCerere = type === "request" ? id : null;
    var idSimulare = type === "new" ? id : null;
    if (flow !== "retail-lending" && type === "new") {
      idSimulare = null;
      voucher = id;
    }
    const properties = {
      flow: flow,
      idCerere: idCerere,
      type: type,
      idSimulare: idSimulare,
      voucher: { value: voucher, err: 0, msg: "" },
    };
    set(this, properties);

    console.log("loginSelect ", properties);
    if (!this.isAuthenticated) {
      this.isWaiting = true;
      await this.clear();

      var results = await this.getFlow(flow);
      console.log("results for getflow", results);
      if (results && !results.error && results != "401") {
        const flowLogin = idCerere ? results.loginView : results.registerView;
        console.log("flowLogin", flowLogin, idCerere);
        set(this, {
          flowLogin: flowLogin,
        });
      }
      if (results && results.error) {
        this.isWaiting = false;
        this.errors.open(results.error);
      }
    }
    this.isWaiting = false;
  };
  getFlow = async (flow) => {
    var results = await this.rootStore.api.fetch("/api/request/flow", {
      id: flow,
    });
    //results.noMatch = "test";
    if (results && !results.error && results != "401") {
      const jprop = results.jprop ?? {};
      var theme = ["retail"].find((e) => e === jprop.theme) || "default";

      set(this, {
        flow: flow,
        flowName: results.denumire,
        loginView: results.loginView,
        registerView: results.registerView,
        jprop: jprop,
        noMatch: results.noMatch,
        urlFlow: results.url,
        // marchez tema ca incarcata
        themeSelected: true, // marchez tema ca incarcata,  Daniel - cred ca trebuie marcata tot timpul true
        theme: theme,
        flowType: results.tip,
      });

      this.errors.theme = theme;
      this.errors.flowName = flow;
    }
    return results;
  };
  @action
  logout = async (props) => {
    const { url, isOut } = props ? props : {};
    this.isWaiting = true;
    const refurl = url ? url : window.location.pathname;
    await this.clear();
    this.autoLogOut = true;
    await this.rootStore.api.post("/logout");
    this.isWaiting = false;
    //facem clear la mainstore?

    if (this.history && !isOut) {
      this.updateValue({ preventGoBack: false });
      this.history.push(`/navigate?ref=${refurl}`);
    }
  };

  @action
  updateValue = (value, key) => {
    if (key) {
      set(this[key], value);
      return;
    }

    set(this, value);
  };

  @action
  downloadAcord = async (numar) => {
    this.isWaiting = true;
    var response = await this.rootStore.api.downloadFile(
      "/api/request/download-acord",
      {
        flux: this.flow,
        numar: numar,
      }
    );
    this.isWaiting = false;
    if (response && response.error) {
      this.errors.open(response.error);
    }
  };

  @action
  downloadDocument = async (param) => {
    this.isWaiting = true;
    var response = await this.rootStore.api.downloadFile(
      "/api/common/download-print-file",
      param
    );
    this.isWaiting = false;
    if (response && response.error) {
      this.errors.open(response.error);
    }
  };

  @action
  checkUser = async ({ history, url }) => {
    if (!this.username) {
      this.errors.open("[S0002] Completati utilizatorul!");
      return;
    }
    this.isWaiting = true;
    var results = await this.rootStore.api.post(`/check-user`, {
      userName: this.username,
      appType: this.appType,
    });
    this.isWaiting = false;
    if (results && !results.error && results != "401") {
      var isPasswordSet = results.isPasswordSet;
      var validUser = results.validUser;
      var otpRequired = results.otpRequired;

      if (!validUser) {
        this.errors.open("[S0001]Utilizator incorect!");
        return;
      }
      set(this.pwdDetails, {
        isPasswordSet: isPasswordSet,
        otpRequired: otpRequired,
      });

      ///daca utilizatorul nu are parola setata trebuie sa il fortam sa defineasca parola noua
      // trebuei sa il redirectam pe setarea parolei
      if (!isPasswordSet) {
        history.push(`/changePwd?ref=${url}`);
        return;
      }
      //daca are parola atunci il redirectam catre introducerea parolei
      history.push(`/pwd?ref=${url}`);
    } else {
      if (results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };
  @action
  changePwd = async (history, url) => {
    this.isWaiting = true;
    var results = await this.rootStore.api.post("/change-pwd", {
      userName: this.username,
      currentPassword: this.pwdDetails.oldPassword,
      newPassword: this.pwdDetails.newPassword,
      confirmPassword: this.pwdDetails.confirmPassword,
      smsCode: this.smsCode,
    });
    this.isWaiting = false;
    if (results && !results.error && results != "401") {
      set(this.pwdDetails, modelPwd);
      this.smsCode = null;
      this.checkUser({ history, url });
      history.push(`/pwd?ref=${url}`);
    } else {
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };
  @action
  resetPwd = async (history, url) => {
    this.isWaiting = true;
    var results = await this.rootStore.api.post("/reset-pwd", {
      userName: this.username,
      smsCode: this.smsCode,
    });
    this.isWaiting = false;
    if (results && !results.error && results != "401") {
      this.smsCode = null;
      this.pwdDetails.isPasswordSet = false;
      history.push(`/changePwd?ref=${url}`);
    } else {
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };
  @action
  requestOtp = async () => {
    this.isWaiting = true;
    var results = await this.rootStore.api.post("/otp-request", {
      username: this.username,
    });
    this.isWaiting = false;
    if (results && !results.error && results != "401") {
      this.verificationToken = results.resend_token;
      this.smsCode = results.verify_token;
      this.codeRequest = false;
    } else {
      this.codeRequest = true;
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };

  @action
  sendPhone = async (par) => {
    par = par || {};
    par.flow = this.flow;
    par.action = this.idCerere ? "login" : "register";
    par.idCerere = this.idCerere;
    this.isWaiting = true;
    var results = await this.rootStore.api.post("/verify_phone_number", {
      PhoneNumber: this.phone,
      Params: par,
    });
    this.isWaiting = false;
    if (results && !results.error && results != "401") {
      this.timer();
      this.verificationToken = results.resend_token;
      this.smsCode = results.verify_token;
      this.codeRequest = false;
      const time = results.life_time;
      this.codeExpired = false;
      this.awaitCode = true;
      this.endTime = moment().add(time, "minute");
    } else {
      this.codeRequest = true;
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };

  @action
  timer = () => {
    this.loginAwait = setInterval(() => {
      if (/*this.startTime && */ this.endTime) {
        //this.startTime.add(1, "second");
        var diffTime = this.endTime.diff(moment());
        if (diffTime <= 0) {
          //this.startTime = null;
          this.endTime = null;
          this.timeLeft = "00:00";
          this.codeExpired = true;
          if (this.awaitCode) {
            this.errors.open([
              "[TM001] A expirat timpul de introducere a codului.",
              '[TM002] Apasa butonul "Retrimite SMS" pentru a primi un nou cod valabil',
            ]);
          }
          return;
        }
        this.timeLeft = moment(diffTime).format("mm:ss");
      }
    }, 1000);
  };
  @action
  timerLogout = () => {
    setInterval(() => {
      if (this.logoutTime) {
        var diffTime = moment().diff(this.logoutTime, "minutes");
        //console.log('--------- timerLogout --------- ', diffTime, moment());
        if (diffTime >= 3) {
          this.lastCall = moment();
          this.rootStore.api.fetch("/api/common/refresh-time");
          this.logoutTime = null;
          //console.log('--------- refresh-time --------- ', this.lastCall, moment());
        }
      }
    }, 10000); //10 secunde
  };

  setLogoutTime() {
    this.logoutTime = this.logoutTime ? this.logoutTime : this.lastCall;
  }
  /*
  @action
  timerAlert = () => {
    setInterval(() => {
      if (this.user.expiresAt) {
        var diffTime = moment(this.user.expiresAt).diff(moment(), "seconds");
        this.sessionRemainingTime = diffTime;
        console.log('--------- timerAlert --------- ', diffTime, '-------------alertInterval --------',   this.user.alertInterval, moment());
        if (diffTime <= this.user.alertInterval) {
          console.log('--------- OPEN ALERT --------- ', diffTime , moment());
          //diffTime = moment(this.user.expiresAt).diff(moment(), "seconds");
        }
          
      }
    }, 1000); //1 secunda
  };
  */
  @action
  confirmPhone = async (par, history) => {
    par = par || {};
    par.idCerere = this.idCerere;
    par.latitude = `${this.position.latitude}`;
    par.longitude = `${this.position.longitude}`;

    //return;
    this.isWaiting = true;
    var results = await this.rootStore.api.post("/confirm_phone_number", {
      phone_number: this.phone,
      //verification_token: this.verificationToken,
      sms_code: this.smsCode,
      flow: this.flow,
      param: par,
    });
    this.isWaiting = false;
    //daca se verifica telefonul inseamna ca sunt logat si ar trebui sa ma duc catre cerere
    if (results && !results.error && results != "401") {
      this.user =
        results; /*userId: GUID, userType: sms TODO oare ne intereseaza???*/
      this.user.username = this.phone;
      if (this.user.userType === this.usrTypes.external) {
        //dupa ce m-am logat sa nu mai ruleze timer-ul
        clearInterval(this.loginAwait);
        this.isAuthenticated = true;
        this.codeExpired = false;
        this.timeLeft = "00:00";
        this.awaitCode = false;
        var url = results.redirectURL;
        if (this.idCerere) {
          url = url ? url : `/request/${this.flow}/${this.idCerere}`;
        } else {
          url = url ? url : `/new/${this.flow}`;
        }
        if (results.o) {
          const { reload } = results.o;
          if (reload) {
            this.rootStore.mainStore.errors.open(
              "Am identificat o alta cerere introdusa de tine anterior iar pentru finalizarea acesteia, te vom redirectiona catre aceasta"
            );
          }
        }
        if (url && history) {
          this.updateValue({ preventGoBack: false });
          history.push(url);
          //window.location.href = results.redirectURL;
        }
      }
    } else {
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };

  @action
  authenticate = async (history, url) => {
    this.loginView = "username";
    this.user.userType = this.usrTypes.user;
    this.isWaiting = true;
    var results = await this.rootStore.api.post("/login_user", {
      username: this.username,
      password: this.password,
      //verification_token: this.verificationToken,
      smsCode: this.smsCode,
      appType: this.appType,
    });
    this.isWaiting = false;
    //se verifica daca  sunt logat si ar trebui sa ma duc la meniu
    if (results && !results.error && results != "401") {
      set(this.user, results);
      /*userId: GUID, userType: sms TODO oare ne intereseaza???*/
      if (
        this.user.userType === this.usrTypes.user ||
        this.user.userType === this.usrTypes.internal
      ) {
        this.isAuthenticated = true;
        this.user.username = this.username;
        url = results.redirectURL ? results.redirectURL : url;
        if (url && history) {
          this.updateValue({ preventGoBack: false });
          history.push(url);
        }
      } else {
        this.errors.open(
          "Utilizatorul nu are drepturi pentru aceasta operatie!"
        );
      }
    } else {
      // parola incorecta
      //this.errors.open(results.message);
      this.errors.open(results.error);
    }
  };

  /*Trimite un cod de autentificare pe email catre client*/
  @action
  sendEmailCode = async (par) => {
    par = par || {};
    //par.flow = this.flow;
    par.action = this.idCerere ? "login" : "register";
    par.idCerere = this.idCerere;
    this.isWaiting = true;
    var results = await this.rootStore.api.post("/api/common/email-code-send", {
      flow: this.flow,
      param: par,
    });
    this.isWaiting = false;
    if (results && !results.error && results != "401") {
      this.timer();
      this.verificationToken = results.resend_token;
      //this.smsCode = results.verify_token;
      this.codeRequest = false;
      const time = results.life_time;
      this.codeExpired = false;
      this.awaitCode = true;
      this.endTime = moment().add(time, "minute");
    } else {
      this.codeRequest = true;
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };

  @action
  confirmEmailCode = async (userid, code, par, history) => {
    par = par || {};
    par.idCerere = this.idCerere;
    //par.latitude = `${this.position.latitude}`;
    //par.longitude = `${this.position.longitude}`;

    //return;
    this.isWaiting = true;
    var results = await this.rootStore.api.post("/confirm_email_code", {
      phone_number: userid,
      verification_token: this.verificationToken,
      sms_code: code,
      flow: this.flow,
      param: par,
    });
    this.isWaiting = false;
    //daca se verifica telefonul inseamna ca sunt logat si ar trebui sa ma duc catre cerere
    if (results && !results.error && results != "401") {
      this.user =
        results; /*userId: GUID, userType: sms TODO oare ne intereseaza???*/
      this.user.username = this.userid;
      if (this.user.userType === this.usrTypes.external) {
        //dupa ce m-am logat sa nu mai ruleze timer-ul
        clearInterval(this.loginAwait);
        this.isAuthenticated = true;
        this.codeExpired = false;
        this.timeLeft = "00:00";
        this.awaitCode = false;
        var url = results.redirectURL;
        if (this.idCerere) {
          url = url ? url : `/request/${this.flow}/${this.idCerere}`;
        } else {
          url = url ? url : `/new/${this.flow}`;
        }
        if (results.o) {
          const { reload } = results.o;
          if (reload) {
            this.rootStore.mainStore.errors.open(
              "Am identificat o alta cerere introdusa de tine anterior iar pentru finalizarea acesteia, te vom redirectiona catre aceasta"
            );
          }
        }
        if (url && history) {
          this.updateValue({ preventGoBack: false });
          history.push(url);
          //window.location.href = results.redirectURL;
        }
      }
    } else {
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };

  /*Genereaza email de confirmare care duce catre cerere prin link*/
  @action
  sendEmailConfirmation = async (par, history) => {
    par = par || {};
    //par.flow = this.flow;
    par.action = this.idCerere ? "login" : "register";
    par.idCerere = this.idCerere;
    //    par.latitude = `${this.position.latitude}`;
    //    par.longitude = `${this.position.longitude}`;
    //return;
    this.isWaiting = true;
    var results = await this.rootStore.api.post(
      "/api/common/send-confirm-email",
      {
        flow: this.flow,
        param: par,
      }
    );
    this.isWaiting = false;
    if (results && !results.error && results != "401") {
      var r = results[0];
      if (r.redirectURL) {
        var url = r.redirectURL;
        if (this.idCerere) {
          url = url ? url : `/request/${this.flow}/${this.idCerere}`;
        } else {
          url = url ? url : `/new/${this.flow}`;
        }
        if (url && history) {
          this.updateValue({ preventGoBack: false });
          if (url.startsWith("https://")) {
            window.location.replace(url);
          } else {
            history.push(url);
          }

          //window.location.href = results.redirectURL;
        }
      } else {
        this.errors.type = r.type ?? "info";
        this.errors.header = r.header;
        this.errors.className = r.className ?? "retail-dialog-info";
        this.isWaiting = false;
        this.errors.open(r.message ?? "OK", false, () => {
          this.updateValue({
            preventGoBack: false,
          });
          var url = r.url ?? "/goodbye";
          if (url.startsWith("https://")) {
            window.location.replace(url);
          } else {
            history.push(url);
          }
          this.history.push();
        });
      }
    } else {
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };

  @action
  clear = async () => {
    this.rootStore?.cerereStore?.clear();
    this.rootStore?.cerereWebStore?.clear();
    this.rootStore?.creditStore?.clear();
    set(this, model);
  };
  /*
  @action
  resize = () => {
    this.isMobile = false; ///iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };
*/
  @action
  getStepsInfo = (stepId) => {
    if (!this.jprop) return {};

    var steps = this.jprop ? this.jprop.steps : [];
    steps = steps || [];
    if (steps.length === 0) {
      return {};
    }
    var masterStep = {},
      countMaster = steps.length,
      countSteps = 0,
      indexMaster = 0,
      indexStep = 0,
      statusPercent = 0,
      nextStep = {};
    var id = stepId ?? this.stepId;
    if (!id) {
      if (steps.length > 0 && steps[0].steps) {
        id = steps[0].steps[0].id;
      }
    }
    if (id) {
      masterStep = steps.find((s) => {
        //daca nu are children returnez pasul curent
        if (s.id == id) {
          return true;
        }
        var step = s.steps && s.steps.find((p) => p.id === id) ? s : null;
        return step && step.id == s.id;
      });
      indexMaster = masterStep
        ? steps.findIndex((s) => s.id === masterStep.id) + 1
        : indexMaster;
      if (masterStep?.steps) {
        indexStep = masterStep.steps.findIndex((p) => p.id === id) + 1;
        countSteps = masterStep.steps.length;
      } else {
        //daca nu are children atunci este unul singur
        indexStep = 1;
        countSteps = 1;
      }
      statusPercent =
        ((indexMaster - 1) / countMaster) * 100 +
        ((1 / countMaster) * 100 * indexStep) / countSteps;

      nextStep =
        indexStep !== countSteps
          ? masterStep.steps[indexStep]
          : indexMaster !== countMaster && steps[indexMaster].steps
          ? steps[indexMaster].steps[0]
          : indexMaster !== countMaster
          ? steps[indexMaster]
          : {};
    }
    return {
      masterStep: masterStep?.name,
      countMaster: countMaster,
      indexMaster: indexMaster,
      indexStep: indexStep,
      countSteps: countSteps,
      statusPercent: statusPercent,
      nextStep: nextStep.name
        ? `Urmatorul pas: ${nextStep.name}`
        : "Felicitari!",
    };
  };
  @action
  setStepId = (stepId) => {
    this.stepId = stepId;
  };
  @action
  getGoodbyeInfo = async (idCerere) => {
    this.isWaiting = true;
    await this.logout({ isOut: true });
    await this.updateValue({ hideAppBar: true, themeSelected: true });

    if (idCerere) {
      var results = await this.rootStore.api.fetch("/api/request/goodbye", {
        id: idCerere,
      });
      if (results && results.msgHtml && results != "401") {
        this.msgHtml = results.msgHtml;
      }
    }

    this.isWaiting = false;
  };

  @action
  getNotification = async (parameters) => {
    this.isWaiting = true;
    await this.logout({ isOut: true });
    await this.updateValue({ hideAppBar: true, themeSelected: true });

    var results = await this.rootStore.api.fetch(
      "/api/common/notification",
      parameters
    );
    if (results && results != "401") {
      this.msgHtml = results;
    }

    this.isWaiting = false;
  };
  checkVoucher = async (voucherCode) => {
    var code = voucherCode ?? this.voucher?.value;
    var results = await this.rootStore.api.fetch(
      `/api/common/voucher/${this.flow}/${code}`
    );
    const s = {
      voucher: {
        value: code,
        err: results?.error ? 2 : results[0].err,
        msg: results?.error ?? results[0].msg,
        url: results?.error ?? results[0].url,
      },
      isWaiting: false,
    };
    set(this, s);
    //console.log("Voucher results", s, results);
    if (s.voucher.url) {
      this.history.push(s.voucher.url);
    }
  };

  @action
  getFeedback = async (hash, navigate) => {
    this.isWaiting = true;
    //await this.updateValue({ hideAppBar: true, themeSelected: true });
    await this.updateValue({
      jprop: { theme: "free" },
    });
    var feedback = await this.rootStore.api.fetch(
      `/api/common/confirm/${hash}`
    );
    if (feedback && feedback != "401") {
      feedback.jprop = JSON.parse(feedback.jprop);
      if (feedback?.result_type == "redirect") {
        navigate.push(feedback.info);
      }
      if (feedback?.result_type == "download") {
        this.downloadDocument({ type: "feedback", index: feedback.info });
      }
      if (feedback?.result_type == "page") {
        //this.feedback = feedback;
      }
    }
    this.isWaiting = false;
    return feedback;
  };
  /*
  @action
  doDeactivate = () => {
    //daca este autentificat se face logout
    if (this.isAuthenticated && this.user.userType === this.usrTypes.user) {
      //&& this.user.userType === this.usrTypes.user
      this.logout({ isOut: true });
    }
  };
  */
  initFlux = async (params, history) => {
    console.log("initFlux");
    var results = await this.rootStore.api.fetch("/api/request/user-flows");
    if (results && !results.error && results != "401") {
      var fluxuri = results.Fluxuri;
      this.fluxuri = fluxuri;
      var flux = params?.type
        ? this.fluxuri.find((x) => x.Flow === params?.type)
        : fluxuri[0];
      if (flux.Flow && params?.id) {
        this.flux = flux;
        return;
      }
      if (flux.Flow) {
        // && flux.Flow != this.flow) {
        this.changeFlux(flux, history);
      }
    } else {
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };
  @action
  changeFlux = (flux, history) => {
    console.log("changeFlux", flux, history);
    this.filtre = {};
    this.filtreAplicate = {};
    this.flux = flux;
    const url = `/${this.appType}/${flux.Flow}`;
    this.updateValue({
      preventGoBack: false,
      flow: flux.Flow,
      flowName: flux.Denumire,
      registerView: flux.registerView,
      jprop: null,
      noMatch: flux.noMatch,
      urlFlow: url,
      url: url,
      // marchez tema ca incarcata
      themeSelected: true, // marchez tema ca incarcata,  Daniel - cred ca trebuie marcata tot timpul true
      theme: null,
      flowType: flux.flowType,
    });
    history?.push(`/navigate?ref=${url}`);
  };
}

export default MainStore;
