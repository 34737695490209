import { Suspense, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import "./index.css";
import { Loading } from "TemplateCerere";
import { ThemeSelector } from "TemplateCerere";
import MainContent from "./main";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Index = (props) => {
  console.log("restarting main view - props", props);
  const {
    getErrorMessages,
    initializat,
    isWaiting,
    forceWaiting,
    updateValue,
  } = props.store;
  var { jprop } = props.store;
  var renderCount = 0;
  const checkGeoLocation = ({ updateValue }) => {
    //console.log("geolocatie =----xxx");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          updateValue({
            position: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              enabled: true,
            },
          });
          //console.log("----- Latitude is :", position.coords.latitude);
          //console.log("----- Longitude is :", position.coords.longitude);
        },
        function (params) {
          console.log(" Eroare Locatie ------------- ", params);
          updateValue({
            position: {
              latitude: "",
              longitude: "",
              enabled: true,
            },
          });
        }, 
        { enableHighAccuracy: true }
      );
    }
  };

  /*Constructor*/

  useEffect(() => {
    try {
      getErrorMessages(props.history);
    } catch (e) {
      console.log("error on componentDidMount main", e);
    }
    checkGeoLocation({ updateValue });
  }, []);
  const customTheme = createTheme({
    palette: {
      tertiary: {
        light: "#ba68c8",
        main: "#9c27b0",
        dark: "#7b1fa2",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff4081",
        main: "#f50057",
        dark: "#c51162",
        contrastText: "#fff",
      },
    },
  });

  jprop = jprop || {};
  if (!initializat) return null;
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Suspense fallback={<Loading isOpen={true} suspense={true} />}>
          {jprop.theme && (
            <ThemeSelector theme={jprop.theme} updateValue={updateValue} />
          )}
          <Loading isOpen={isWaiting || forceWaiting} suspense={false} waiting={isWaiting} force={forceWaiting}/>
          <MainContent
            renderCount={renderCount}
            checkGeoLocation={checkGeoLocation}
          />
        </Suspense>
      </ThemeProvider>
    </>
  );
};
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(Index))
);
