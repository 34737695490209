import React from "react";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import { Save, Close } from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";

//import "./index.css";

function ToolBar(props) {
  const { title, close, save, isUser } = props;
  return (
    <div className="main-toolbar">
      <Toolbar variant="dense" className="toolbar-filter">
        <div className="toolbar-filter-name">{title}</div>
        <div className="toolbar-filter-btns toolbar-filter-btns-right">
          {isUser && (
            <Button
              className="toolbar-btn"
              size="small"
              variant="contained"
              onClick={() => close()}
              color="primary"
              startIcon={<Close />}
            >
              Renunta
            </Button>
          )}
          <Button
            className="toolbar-btn"
            size="small"
            variant="contained"
            onClick={() => save(close)}
            color="secondary"
            startIcon={<Save />}
          >
            Salveaza
          </Button>
        </div>
      </Toolbar>
    </div>
  );
}
export default observer(ToolBar);
