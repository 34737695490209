import React from "react";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const LoginUserOtp = (props) => {
  const { smsCode, updateValue, requestOtp, validare, errors } = props;
  const handlerRequestOtp = () => {
    var errorMsgs = [];
    if (validare) {
      errorMsgs = validare();
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    requestOtp();
  };
  return (
    <div className="login-user-otp">
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className="submit"
        onClick={handlerRequestOtp}
        //disabled={!phone}
      >
        Trimite Cod
      </Button>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="code"
        name="Cod"
        label="Cod primit prin SMS"
        value={smsCode ? smsCode : ""}
        onChange={(prop) => updateValue({ smsCode: prop.target.value })}
        //autoFocus
        //type="password"
        //disabled={codeRequest}
        className="login-txt"
      />
    </div>
  );
};
export default observer(LoginUserOtp);
