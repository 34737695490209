import React from "react";
import { observer, inject } from "mobx-react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    const msg = `${error.toString()} ${errorInfo.componentStack.toString()}`;
    this.props.api.log('error', msg);
    return { hasError: true };
  }

  render() {
    //daca se doreste returnarea unui mesaj generic utilizatorului
   /* if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>A aparut o erroare!</h1>
          <h2>Reincercati. Daca eroarea persista contactati departamentul de suport!</h2>
        </div>
      );

    }
    */
    return this.props.children; 
  }
}
export default  inject((stores) => ({ api: stores.api }))(observer(ErrorBoundary));
