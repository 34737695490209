import React, { useState } from "react";
import { observer } from "mobx-react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ArrowRight } from "@mui/icons-material";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import { IconCheck, IconInfo } from "TemplateCerere";
import { IconsSvg } from "TemplateCerere";
import { EditDialog } from "TemplateCerere";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const AcordLine = ({ children, tooltip }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <span className="normal-text">
      <SvgIcon
        component={IconCheck}
        className="initial-check"
        viewBox="0 0 16 16.001"
      />

      {children}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          arrow
          title={
            <Typography component="span" variant="body2">
              {tooltip}
            </Typography>
          }
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
        >
          <SvgIcon
            component={IconInfo}
            className="initial-info"
            viewBox="0 0 16 16.001"
            onClick={handleTooltipOpen}
            onMouseEnter={handleTooltipOpen}
          />
        </Tooltip>
      </ClickAwayListener>
    </span>
  );
};

const InitialPage = (props) => {
  const { handlerChangePage, className, partnerId } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IconsSvg icon="IllustrationHello" />

      <p className="page-title">Sa trecem la treaba,</p>
      <p className="subtitle-text">
        Pentru a continua cu solicitarea de credit, asigura-te ca:
      </p>

      <div className="acord-group">
        <AcordLine tooltip="Daca ai cont la noi, te invitam in cea mai apropiata unitate Patria Bank pentru a solicita creditul dorit">
          Inca<b> nu ai cont </b>la noi
        </AcordLine>

        <AcordLine tooltip="Serviciul este disponibil doar pentru persoanele fizice care au intre 21 si 63 ani impliniti, avand in vedere faptul ca varsta maxima  la sfarsitul perioadei de creditare este 65 ani, iar perioada minima de creditarea este 12 luni.">
          Ai intre<b> 21 si 63 ani</b> impliniti
        </AcordLine>
        <AcordLine tooltip="Serviciul este disponibil doar pentru persoanele fizice care au cetatenie si rezidenta romana si locul nasterii este in Romania.">
          Esti nascut, rezident fiscal si cu<b> cetatenie in Romania</b>
        </AcordLine>

        <AcordLine
          tooltip={
            <div>
              Persoanele expuse public (PEP) sunt persoanele fizice, romane sau
              straine, care exercita sau au exercitat functii publice
              importante, membrii familiilor acestora, precum si persoanele
              cunoscute public ca asociati apropiati ai persoanelor fizice care
              exercita functii publice importante (sefi de stat, sefi de
              guverne, membrii parlamentelor, comisarii europeni, membrii
              guvernelor, consilierii prezidentiali/stat, secretarii de stat);
              <ul>
                <li>
                  membrii curtilor constitutionale, membrii curtilor supreme sau
                  ai altor inalte instante judecatoresti ale caror hotarari nu
                  pot fi atacate decat prin intermediul unor cai extraordinare
                  de atac;
                </li>
                <li>
                  membrii curtilor de conturi sau asimilate acestora, membrii
                  consiliilor de administratie ale bancilor centrale;
                </li>
                <li>
                  ambasadorii, insarcinatii cu afaceri, ofiterii de rang inalt
                  din cadrul fortelor armate;
                </li>
                <li>conducatorii institutiilor si autoritatilor publice;</li>
                <li>
                  membrii consiliilor de administratie si ai consiliilor de
                  supraveghere si persoanele care detin functii de conducere ale
                  regiilor autonome, ale societatilor comerciale cu capital
                  majoritar de stat si ale companiilor nationale.
                </li>
              </ul>
            </div>
          }
        >
          Nu esti persoana expusa public <b>(PEP)</b>
        </AcordLine>

        <AcordLine
          tooltip="Pentru persoanele din SUA, Banca va trebui sa respecte un
                    set de masuri legislative aprobate de Statele Unite ale
                    Americii cu scopul de a preveni evaziunea fiscala generata de
                    activitatea transfrontaliera a contribuabililor americani."
        >
          Nu esti persoana din SUA, in conformitate cu prevederile referitoare
          <b> FATCA</b>
        </AcordLine>
        <AcordLine
          tooltip="Grup de persoane fizice cu influenta semnificativa asupra unei/unor entitati din cadrul grupului Bancii – orice grup format din membrii, persoane fizice afiliate bancii/grupului Patria (actionar cu detineri calificate, membru de conducere, persoana cu functie cheie sau asimilata acesteia sau membru apropiat al familiei acestuia: partenerul de viaţă,potrivit legii, şi copiii persoanei; copiii partenerului de viaţă al persoanei; dependenţi si parinti ai persoanei sau ai partenerului de viaţă al acesteia).
Din Grupul Patria, alături de Patria Bank S.A, mai fac parte și: Patria Credit IFN si  Patria Asset Management
"
        >
          Nu faci parte dintr-un grup de persoane fizice cu
          <b> influenta semnificativa</b>
        </AcordLine>
        <AcordLine
          tooltip="In calitate de beneficiar real al fondurilor, ma oblig sa
                    folosesc contul online numai in interes personal si nu voi
                    tranzita fonduri apartinand altor persoane."
        >
          Esti<b> beneficiarul real </b>al fondurilor (deschizi contul pentru
          tine , nu pentru altcineva)
        </AcordLine>

        <AcordLine
          tooltip={
            <div>
              Doua sau mai multe persoane fizice si/sau juridice care se afla
              intr-una din urmatoarele situatii:
              <ul>
                <li>
                  a) constituie, daca nu se dovedeste altfel, un singur risc,
                  deoarece una dintre ele detine direct sau indirect, controlul
                  asupra celeilalte/celorlalte;
                </li>
                <li>
                  b) intre care nu exista o relatie de control in sensul
                  exprimat la lit .a), dar care trebuie sa fie considerate ca
                  reprezentand un singur risc, deoarece sunt interconectate in
                  asemenea masura incat, in cazul in care una dintre acestea
                  s-ar confrunta cu probleme financiare, in special dificultati
                  de finantare ori de rambursare, cealalta sau toate celelalte
                  ar intampina probabil dificultati de finantare ori de
                  rambursare.
                </li>
              </ul>
            </div>
          }
        >
          Nu faci parte dintr-un grup de
          <b> clienti aflati in legatura cu banca </b>
        </AcordLine>
        <AcordLine tooltip="Contul de plati cu servicii de baza iti permite sa executi un numar nelimitat de operatiuni specifice utilizand anumite servicii mentionate conform prevederilor Legii 258/2017">
          Nu doresti sa deschizi un <b>Cont de plati cu servicii de baza</b>
        </AcordLine>

        <AcordLine tooltip="Serviciul este disponibil doar pentru persoanele fizice care obtin venituri din salarii care indeplinesc conditiile de eligibilitate ale bancii si ale caror venituri din salarii sunt raportate la ANAF">
          Obtii venituri <b>din salarii</b> raportate la <b>ANAF</b>
        </AcordLine>

        {partnerId === 1 && (
          <AcordLine tooltip="Contractul incheiat cu EON trebuie sa fie un contract in vigoare la momentul acordarii creditului">
            Ai semnat cu E.ON un contract specific privind achizitia solutiei de
            panouri fotovoltaice pentru producerea de energie electrica
          </AcordLine>
        )}
      </div>
      <p className="normal-text">
        Daca te intrebi de ce trebuie sa stim aceste lucruri despre tine, este
        pentru ca ele fac parte din reglementarile in vigoare si avem nevoie de
        ele pentru ca tu sa-ti poti accesa produsul de creditare solicitat
        online!
      </p>
      <div className="login-btn-group">
        <Button
          variant="contained"
          color="secondary"
          className="login-btn"
          onClick={() => {
            setIsOpen(true);
          }}
          /*endIcon={<ArrowRight fontSize="large" />}*/
        >
          Nu indeplinesc toate criteriile
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={handlerChangePage}
          endIcon={<ArrowRight fontSize="large" />}
        >
          Indeplinesc toate criteriile
        </Button>
      </div>
      <EditDialog
        className={"retail-dialog-error " + (className || "")}
        isOpen={isOpen}
        header="Nu indeplinesti conditiile necesare pentru a accesa produsul de creditare solicitat online?"
        showSave={true}
        saveCaption="Afla mai multe"
        showClose={true}
        closeCaption="Inchide"
        maxWidth="sm"
        save={() => {
          window.location.href = "https://www.patriabank.ro/persoane-fizice/";
        }}
        close={() => {
          setIsOpen(false);
        }}
      >
        {partnerId === 1 ? (
          <>
            <div className="normal-text">
              Nicio problema. Te asteptam in orice unitate teritoriala Patria
              Bank pentru a-ti prezenta alte solutii de creditare care sa iti
              satisfaca nevoile.
            </div>
            <div className="normal-text">
              Pana atunci, gaseste varianta potrivita pentru tine, afland mai
              multe despre ofertele, produsele si serviciile disponibile!
            </div>
          </>
        ) : (
          <>
            <div className="normal-text">
              Nici o problema. Te asteptam in orice unitate teritoriala
              PatriaBank.
            </div>
            <div className="normal-text">
              Pana atunci, gaseste varianta potrivita pentru tine, afland mai
              multe despre ofertele, produsele si serviciile disponibile!
            </div>
          </>
        )}
      </EditDialog>
    </>
  );
};
export default observer(InitialPage);
