import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import PachetCont from "../components/pachetCont";
import PachetHeader from "../components/pachetHeader";
import PachetSelectat from "../components/pachetSelectat";
import PachetCarousel from "../components/pachetCarousel";
import { Acord } from "TemplateCerere";
import "./OnboardingPachetPF.scss";

const OnboardingPachetPF = (props) => {
  const { doc, actions, nextStep, isMobile } = props;
  var jprop = doc.Jprop || {};
  var readonly = !doc.Incarcare;
  const tmp = jprop.template;
  const fld = tmp.fields;
  const pachet = actions.getField(fld.pachet);
  const acordInrolareRespins = actions.getField(fld.acord_inrolare_respins);
  const [hasGoPrev, setHasGoPrev] = useState(true);
  /*daca am pachet inseamna ca e selectat si cand merg inapoi il deselectez*/
  /*cand e readonly nu sterg pachetul si nu merg in pasul de alegere pachet*/
  const setGoPrev = () => {
    if (!pachet.isReadonly) {
      let goPrev = () => {
        actions.updateStore({ goPrev: null });
        actions.updateValue(pachet, null);
        setShouldUpdate(true);
        setPachetSelectat(undefined);
        return true;
      };
      actions.updateStore({ goPrev: goPrev });
    }
  };
  if (hasGoPrev && pachet.Valoare) {
    setGoPrev();
  }

  const listDataJson = (listData, fields) => {
    var fld = fields.map((f) => f.toLowerCase());
    return listData.map((e) => {
      var j = {};
      fld.forEach((f, index) => {
        var s = e[index];
        if (
          typeof s ==
          "string" /*&& s.trim().startsWith("{") && s.trim().endsWith("}")*/
        ) {
          try {
            s = JSON.parse(s) || {};
          } catch {}
        }
        j[f] = s;
      });
      return j;
    });
  };

  const pachete = listDataJson(pachet.listData, pachet.fields).filter((l) => {
    return l.info;
  });
  /*Setez informatiile despre produse disponibile*/
  pachete.forEach((p) => {
    Object.keys(tmp.fields).forEach((f) => {
      var c = p.opt.find((o) => o.id === tmp.fields[f]);
      if (c) {
        c.included = c.isVisible;
        p[f] = c;
        c.captionSup =
          c.captionSup || (!!p.info?.estePachet || c.included ? "" : "3");
        if (c.caption) {
          var cap = c.caption.split("|");
          c.caption = cap[0];
          c.captionSelected = cap[1] || c.caption;
          c.captionMobile = cap[2] || c.captionSelected;
          if (!c.captionSup) {
            var x = c.caption.toLowerCase();
            if (x.includes("disponibil")) {
              c.captionSup = "2";
            }
            if (x.includes("optional")) {
              c.captionSup = "2";
            }
            if (x.includes("nu este inclus")) {
              c.captionSup = "3";
            }
          }
        }
      }
    });
    /*Adaug si informatii aditionale despre depozit*/
    p.depozit = { ...p.depozit, ...p.info?.depozit };
    p.saving = { ...p.saving, ...p.info?.saving };
  });

  const getPachetSelectat = (px) => {
    return pachete.find((p) => p.cod_produs === px);
  };

  const setPachetProperties = () => {
    if (!doc.Incarcare || !pachetSelectat) return;
    if (shouldUpdate) {
      actions.updateValue(pachet, pachetSelectat.cod_produs);
    }
  };

  const [pachetSelectat, setPachetSelectat] = useState(
    getPachetSelectat(pachet.Valoare)
  );

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const handleSelect = (p) => {
    if (hasGoPrev) {
      setGoPrev();
      setHasGoPrev(false);
    }
    setShouldUpdate(true);
    setPachetSelectat(getPachetSelectat(p.cod_produs));
  };

  useEffect(() => {
    setPachetProperties(pachetSelectat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pachetSelectat]);
  return (
    <div className="pachet" id={`dco${doc.Id}`} key={doc.Id}>
      {!pachetSelectat && (
        <>
          <div className="e-card-title">{jprop.title}</div>
          <div className="e-card-subtitle">{jprop.subtitle}</div>
          <div className="e-card-content-document">
            {isMobile ? (
              <div className="carousel-wrapper">
                <PachetCarousel className="pachet-list">
                  {pachete.map((p, index) => (
                    <PachetCont
                      key={index}
                      onSelect={() => {
                        setHasGoPrev(true);
                        handleSelect(p);
                      }}
                      {...p.info}
                      readonly={readonly}
                      cont={{
                        included: p.cont.included,
                        caption: p.cont.captionMobile,
                        helperText: p.cont.captionHelper,
                      }}
                      card={{
                        included: p.card.included,
                        caption: p.card.captionMobile,
                        helperText: p.card.captionHelper,
                      }}
                      ib={{
                        included: p.ib.included,
                        caption:
                          "Internet & Mobile Banking " +
                          p.ib.captionMobile?.toLowerCase(),
                        helperText: p.ib.captionHelper,
                      }}
                      sms={{
                        included: p.sms.included,
                        caption:
                          "SMS Alert " + p.sms.captionMobile?.toLowerCase(),
                        helperText: p.sms.captionHelper,
                      }}
                      contval={{
                        show: p.contval?.show ?? true,
                        included: p.contval.included,
                        caption:
                          "Cont curent in valuta " +
                          p.contval.captionMobile?.toLowerCase(),
                        helperText: p.contval.captionHelper,
                      }}
                      depo={{
                        show: p.depozit?.show ?? true,
                        included: p.depozit?.included ?? true,
                        caption:
                          p.depozit?.captionMobile ??
                          "Depozit bancar disponibil", //, helperText: "(Configurare in pasii urmatori)",
                      }}
                      eco={{
                        show: p.saving?.show ?? true,
                        included: p.saving?.included ?? true,
                        caption:
                          p.saving?.captionMobile ?? "Cont economii disponibil", //, helperText: "(Configurare in pasii urmatori)",
                      }}
                      helperFirst={true}
                    />
                  ))}
                </PachetCarousel>
              </div>
            ) : (
              <>
                <PachetHeader
                  isMobile={isMobile}
                  depo={pachete.some((p) => p.depozit?.show ?? true)}
                  eco={pachete.some((p) => p.saving?.show ?? true)}
                  contval={true}
                >
                  <div className="pachet-list-wrapper">
                    <div className="pachet-list">
                      {pachete.map((p, index) => (
                        <PachetCont
                          key={index}
                          onSelect={() => {
                            setHasGoPrev(true);
                            handleSelect(p);
                          }}
                          {...p.info}
                          readonly={readonly}
                          cont={{
                            included: p.cont.included,
                            caption: p.cont.caption,
                            helperText: p.cont.captionHelper,
                            supText: p.cont.captionSup,
                          }}
                          card={{
                            included: p.card.included,
                            caption: p.card.caption,
                            helperText: p.card.captionHelper,
                            supText: p.card.captionSup,
                          }}
                          ib={{
                            included: p.ib.included,
                            caption: p.ib.caption,
                            helperText: p.ib.captionHelper,
                            supText: p.ib.captionSup,
                          }}
                          sms={{
                            included: p.sms.included,
                            caption: p.sms.caption,
                            helperText: p.sms.captionHelper,
                            supText: p.sms.captionSup,
                          }}
                          contval={{
                            show: true,
                            included: p.contval.included,
                            caption: p.contval.caption,
                            helperText: p.contval.captionHelper,
                            supText: p.contval.captionSup,
                          }}
                          depo={{
                            show: p.depozit?.show ?? true,
                            included: p.depozit?.included ?? true,
                            caption: p.depozit?.caption ?? "Disponibil", //, helperText: "(Configurare in pasii urmatori)",
                            supText: "3",
                            /*helperText: "(Configurare in pasii urmatori)",*/
                          }}
                          eco={{
                            show: p.saving?.show ?? true,
                            included: p.saving?.included ?? true,
                            caption: p.saving?.caption ?? "Disponibil", //, helperText: "(Configurare in pasii urmatori)",
                            supText: "3",
                            /*helperText: "(Configurare in pasii urmatori)",*/
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </PachetHeader>
                <p>
                  <sup>1</sup> Renuntarea la cel putin unul dintre produsele sau
                  serviciile bancare obligatorii incluse in pachet determina
                  inchiderea automata a pachetului
                </p>
                <p>
                  <sup>2</sup> Configurare in pasii urmatori
                </p>
                <p>
                  <sup>3</sup> Extraoptiune, configurare in pasii urmatori
                </p>
              </>
            )}
            {acordInrolareRespins && (
              <Acord
                updateValue={actions.updateValue}
                acord={acordInrolareRespins}
              />
            )}
          </div>
        </>
      )}
      {pachetSelectat && (
        <>
          <div className="e-card-title">
            {pachetSelectat.info.estePachet
              ? `Configureaza ${pachetSelectat.denumire}`
              : "Alege produsele pe care le doresti"}
          </div>
          <div className="e-card-content-document pachet-detaliu">
            <PachetSelectat
              pachet={pachetSelectat}
              readonly={readonly}
              onReset={() => handleSelect("")}
              onSelect={() => {
                setHasGoPrev(false);
                nextStep();
              }}
              doc={doc}
              fields={fld}
              actions={actions}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default observer(OnboardingPachetPF);
