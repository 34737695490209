import React, { useState } from "react";
import { observer } from "mobx-react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";

import "../index.css";

function PasswordField(props) {
  const {
    id,
    label,
    value,
    error,
    helperText,
    className,
    variant,
    type,
    ...other
  } = props;


  const [showPassword, setShowPassword] = useState(false);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const InputAdornmentCustom = () => {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      );
    };

    return (
      <InputMask
        id={id}
        label={label}
        value={value}
        error={error}
        helperText={helperText}
        className={className}
        variant={variant}
        {...other}
      >
        {() => (
          <TextField
            id={id}
            label={label}
            value={value}
            error={error}
            helperText={helperText}
            className={className}
            variant={variant}
            //{..}
            type={showPassword ? type || "text" : "password"}
            InputProps={{
              endAdornment: <InputAdornmentCustom />,
            }}
          />
        )}
      </InputMask>
    );
  }
export default observer(PasswordField);
