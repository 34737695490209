import React from "react";
import { observer } from "mobx-react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Documents } from "TemplateCerere";
//import "./index.css";

function Tab(props) {
  const { doc, sectiuni, actions, isMobile } = props;
  return (
    <div>
      {sectiuni.length !== 0 &&
        sectiuni.map((item) => (
          <Accordion
            key={item.Id}
            expanded={item.Collapsable ? item.Expanded : true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`pc${item.Id}`}
              id={item.Id}
              onClick={() => {
                item.Expanded = !item.Expanded;
              }}
            >
              <Typography variant="subtitle2">{item.Denumire}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px" }}>
              <Documents
                key={item.Id}
                documents={doc.filter(
                  (doc) => doc.sec === item.Id && doc.Vizualizare
                )}
                actions={actions}
                isMobile={isMobile}
              />
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
export default observer(Tab);
