import React from "react";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
//import styled from "styled-components";
//import Collapse from "@mui/material/Collapse";

import Filter from "./filter/filter";

import "./index.css";

function Filters(props) {
    const { items, filtre, updateFilter, filterReset } = props;

    return (
      <div className="card-header">
        <div className="e-card-content-document">
          {items.map((item, index) => (
            <div key={`fl${index}`} className="doc-table-row">
              <div className="doc-table-label">{item.Descriere}</div>

              {item.Filtru.toLowerCase() === "interval" ? (
                <div className="hlist vcenter">
                  <Filter
                    tip={item.Tip}
                    cimp={`${item.Cimp}_start`}
                    valoare={filtre[`${item.Cimp}_start`]}
                    updateFilter={updateFilter}
                    end={`${item.Cimp}_end`}
                  />
                  <div className="doc-table-bettwen">Pana la</div>
                  <Filter
                    tip={item.Tip}
                    cimp={`${item.Cimp}_end`}
                    valoare={filtre[`${item.Cimp}_end`]}
                    updateFilter={updateFilter}
                  />
                </div>
              ) : (
                <Filter
                  tip={item.Tip}
                  cimp={`${item.Cimp}`}
                  valoare={filtre[`${item.Cimp}`]}
                  listData={item.Lista && JSON.parse(item.Lista)}
                  updateFilter={updateFilter}
                />
              )}
            </div>
          ))}
        </div>
        <div className="dialog-buttons">
          <Button
            className="btn-reset-filter"
            variant="contained"
            color="primary"
            onClick={filterReset}
          >
            Sterge Filtru
          </Button>
        </div>
      </div>
    );
  }
export default observer(Filters);
