import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

export function IBIcon(props) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(0 -2)">
        <path d="M15,4H1A1,1,0,0,0,0,5V15a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V5A1,1,0,0,0,15,4ZM2.75,6.5h3A.25.25,0,0,1,6,6.75v2A.25.25,0,0,1,5.75,9h-3a.25.25,0,0,1-.25-.25v-2A.25.25,0,0,1,2.75,6.5Zm5.5,7H2.75a.25.25,0,0,1-.25-.25v-.5a.25.25,0,0,1,.25-.25h5.5a.25.25,0,0,1,.25.25v.5A.25.25,0,0,1,8.25,13.5ZM9,8.5A.5.5,0,1,1,9.5,8,.5.5,0,0,1,9,8.5Zm1.227-1.182.884-.491a1.968,1.968,0,0,1,0,2.347l-.885-.491A.99.99,0,0,0,10.227,7.318ZM13.25,13.5h-2.5a.25.25,0,0,1-.25-.25v-.5a.25.25,0,0,1,.25-.25h2.5a.25.25,0,0,1,.25.25v.5A.25.25,0,0,1,13.25,13.5Zm-.382-3.35-.873-.486a2.99,2.99,0,0,0,0-3.327l.873-.487a3.969,3.969,0,0,1,0,4.3Z" />
      </g>
    </SvgIcon>
  );
}

export function CardIcon(props) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(0 -2)">
        <path d="M16,7.5V5a1,1,0,0,0-1-1H1A1,1,0,0,0,0,5V7.5Z" />
        <path
          d="M0,15v5.5a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V15Zm6.5,4H2V18H6.5ZM14,19H12V18h2Z"
          transform="translate(0 -5.5)"
        />
      </g>
    </SvgIcon>
  );
}

export function ContIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15,2H1A.945.945,0,0,0,0,3V13a.945.945,0,0,0,1,1H15a.945.945,0,0,0,1-1V3A.945.945,0,0,0,15,2ZM11.1,12H4.9A4.23,4.23,0,0,0,2,9.1V6.9A4.23,4.23,0,0,0,4.9,4h6.3a4.23,4.23,0,0,0,2.9,2.9V9.2A3.9,3.9,0,0,0,11.1,12Z"
        transform="translate(0 -2)"
      />
      <circle cx="2" cy="2" r="2" transform="translate(6 4)" />
    </SvgIcon>
  );
}

export function ContValutaIcon(props) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(0 0)">
        <circle cx="4" cy="4" r="4" />
        <circle cx="4" cy="4" r="4" transform="translate(8 8)" />
        <path
          d="M19,5h2.5V8h1V4.5A.5.5,0,0,0,22,4H19V2L16,4.5,19,7Z"
          transform="translate(-7 -1)"
        />
        <path
          d="M6.5,21H4V18H3v3.5a.5.5,0,0,0,.5.5h3v2l3-2.5L6.5,19Z"
          transform="translate(-1.5 -9)"
        />
      </g>
    </SvgIcon>
  );
}

export function SMSAlertIcon(props) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(0 2)">
        <path
          d="M10.667,9.333A.667.667,0,0,1,10,8.667V1.333A1.333,1.333,0,0,1,11.333,0H18a1.333,1.333,0,0,1,1.333,1.333V6A1.333,1.333,0,0,1,18,7.333H13.555L11.067,9.2a.667.667,0,0,1-.4.133Z"
          transform="translate(-4.666)"
        />
        <path
          d="M11.333,9.333V14a.667.667,0,0,1-.667.667H4A.667.667,0,0,1,3.333,14V4.667A.667.667,0,0,1,4,4H6V2H4A2,2,0,0,0,2,4V14.667a2,2,0,0,0,2,2h6.667a2,2,0,0,0,2-2V9.333Z"
          transform="translate(-2 -0.668)"
        />
      </g>
    </SvgIcon>
  );
}

export function ChipIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="m28.138,27.045l-24.273,0a3.87,3.87 0 0 1 -3.865,-3.865l0,-19.314a3.87,3.87 0 0 1 3.865,-3.866l24.273,0a3.868,3.868 0 0 1 3.862,3.866l0,19.314a3.868,3.868 0 0 1 -3.862,3.865zm-26.206,-18.836a0.484,0.484 0 0 0 -0.482,0.491l0,14.48a2.418,2.418 0 0 0 2.415,2.42l24.273,0a2.416,2.416 0 0 0 2.412,-2.42l0,-19.314a2.416,2.416 0 0 0 -2.412,-2.416l-24.273,0a2.418,2.418 0 0 0 -2.415,2.416l0,2.9a0.482,0.482 0 1 0 0.964,0l0,-2.9a1.452,1.452 0 0 1 1.45,-1.45l7.308,0l0,2.057a2.465,2.465 0 0 0 1.042,1.987a0.951,0.951 0 0 1 0.4,0.819a0.992,0.992 0 0 1 -1,0.93l-9.682,0zm26.206,16.422l-7.311,0l0,-2.059a2.464,2.464 0 0 0 -1.043,-1.987a0.965,0.965 0 0 1 -0.4,-0.819a1,1 0 0 1 1,-0.933l9.206,0l0,4.347a1.452,1.452 0 0 1 -1.452,1.451zm-8.275,0l-7.726,0l0,-2.059a1.491,1.491 0 0 1 0.639,-1.2a1.921,1.921 0 0 0 0.806,-1.643a1.952,1.952 0 0 0 -1.481,-1.8l0,-8.814a1.951,1.951 0 0 0 1.481,-1.8a1.921,1.921 0 0 0 -0.806,-1.643a1.489,1.489 0 0 1 -0.639,-1.2l0,-2.057l7.726,0l0,2.058a1.491,1.491 0 0 1 -0.639,1.2a1.924,1.924 0 0 0 -0.808,1.643a1.953,1.953 0 0 0 1.482,1.8l0,8.816a1.953,1.953 0 0 0 -1.482,1.8a1.924,1.924 0 0 0 0.808,1.643a1.493,1.493 0 0 1 0.639,1.2l0,2.055l0,0.001zm-8.691,0l-7.307,0a1.452,1.452 0 0 1 -1.45,-1.451l0,-4.347l9.2,0a1,1 0 0 1 1,0.933a0.951,0.951 0 0 1 -0.4,0.819a2.465,2.465 0 0 0 -1.041,1.987l0,2.058l-0.002,0.001zm18.413,-6.763l-8.722,0l0,-3.861l8.723,0l0,3.86l-0.001,0.001zm-18.448,0l-8.723,0l0,-3.861l8.723,0l0,3.86l0,0.001zm18.448,-4.829l-8.722,0l0,-3.862l8.723,0l0,3.861l-0.001,0.001zm-18.448,0l-8.723,0l0,-3.862l8.723,0l0,3.861l0,0.001zm18.448,-4.83l-9.205,0a1,1 0 0 1 -1,-0.93a0.965,0.965 0 0 1 0.4,-0.819a2.464,2.464 0 0 0 1.043,-1.987l0,-2.058l7.311,0a1.451,1.451 0 0 1 1.447,1.45l0,4.343l0.004,0.001z"
        id="svg_7"
      />
    </SvgIcon>
  );
}

export function PackageIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.435,13v4.522a.348.348,0,0,1-.348.348H6.913a.348.348,0,0,1-.348-.348V13H1V24.13a.7.7,0,0,0,.7.7H16.3a.7.7,0,0,0,.7-.7V13Z"
        transform="translate(-1 -8.824)"
      />
      <path
        d="M27.864,4.478h5.462l-4.077-3.4A.349.349,0,0,0,29.026,1H26.373Z"
        transform="translate(-17.547 -1)"
      />
      <path
        d="M7.1,4.478,8.591,1H5.938a.349.349,0,0,0-.223.08l-4.077,3.4Z"
        transform="translate(-1.416 -1)"
        id="package"
      />
    </SvgIcon>
  );
}
