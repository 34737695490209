import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import { onEnter } from "Utils";

import LoginUserOtp from "./loginUserOtp";
import Copyright from "../copyright";

function LoginUser(props) {
  const { username, password, pwdDetails, smsCode, appType } = props.store;
  const { requestOtp, updateValue, checkUser, authenticate } = props.store;
  const { otpRequired, isPasswordSet } = pwdDetails;
  let query = new URLSearchParams(props.location.search);
  const url = query.get("ref");

  useEffect(() => {
    if (isPasswordSet === null) {
      //let query = new URLSearchParams(props.location.search);
      //const url = query.get("ref");
      checkUser({ history: props.history, url: url });
    }
  }, [isPasswordSet, url, props.history]);

  const handlerInainte = () => {
    authenticate(props.history, url);
  };
  const handlerChangePwd = () => {
    updateValue({ preventGoBack: false });
    props.history.push(`/changePwd?ref=${url}`);
  };
  const handlerResetPwd = () => {
    updateValue({ smsCode: null });
    updateValue({ preventGoBack: false });
    props.history.push(`/resetPwd?ref=${url}`);
  };

  return (
    <div className="login-card">
      <div component="main">
        <CssBaseline />
        <div className="login">
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            className="login-title"
            align="center"
          >
            Autentificare
          </Typography>
          <div className="form">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              label="Utilizator"
              name="Utilizator"
              value={username ? username : ""}
              //onChange= {(prop) => updateValue({ username: prop.target.value })}
              //autoFocus
              className="login-txt disabled"
              disabled={true}
              //autoComplete="Utilizator"
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="Parola"
              label="Parola"
              value={password ? password : ""}
              onChange={(prop) => updateValue({ password: prop.target.value })}
              autoFocus
              type="password"
              className="login-txt"
              onKeyPress={(event) => onEnter(event, handlerInainte)}
            />
            {otpRequired && (
              <LoginUserOtp
                smsCode={smsCode}
                updateValue={updateValue}
                requestOtp={requestOtp}
              />
            )}
            <Button
              //type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              onClick={handlerInainte}
            >
              Inainte
            </Button>
            {appType === "app" && (
              <div>
                <Link
                  variant="body2"
                  onClick={handlerChangePwd}
                  className="termenii"
                >
                  Schimbare parola
                </Link>
                <Link
                  variant="body2"
                  onClick={handlerResetPwd}
                  className="termenii"
                >
                  Am uitat parola
                </Link>
              </div>
            )}
          </div>
        </div>
        <Copyright />
      </div>
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(LoginUser))
);
