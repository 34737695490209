import React from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { TextFieldMask, TooltipLine } from "TemplateCerere";
import { PasswordField, Checkbox } from "TemplateCerere";
import { ArrowRight, Refresh } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import OtpInput from "react-otp-input";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";

function PhonePage(props) {
  const {
    updateAcord,
    updateValue,
    codeRequest,
    timeLeft,
    sendPhone,
    confirmPhone,
    state,
    updateState,
    downloadAcord,
  } = props;

  const {
    phone,
    smsCode,
    cnp,
    cetatean,
    amCard,
    firstDigits,
    lastDigits,
    acord,
  } = state;

  const {
    tc, //termeni si conditii
    ni, //nota de informare
    tc_err,
    ni_err,
    /*      proc_err,
      com_err,
      dp_err,*/
  } = acord;

  return (
    <React.Fragment>
      <p className="page-title">Buna,</p>
      <p className="normal-text">
        Ne bucuram sa te intalnim, chiar si virtual.
      </p>
      <p>
        Pentru a incepe procesul de Actualizare a datelor tale personale este
        important sa te Autentifici, completand campurile de mai jos:
      </p>
      <div className="form switch-group">
        <div className="label">Esti cetatean roman?</div>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              width="100px"
              checked={cetatean}
              //disabled={state.arataProduse}
              onChange={(prop) => {
                var s = { ...state };
                s.cetatean = prop.target.checked;
                updateState(s);
              }}
            />
          }
          label={cetatean ? "Da" : "Nu"}
          labelPlacement="top" //{dobandaFixa ? "start" : "end"}
        />
      </div>
      <TooltipLine
        className="form"
        tooltip="In cazul in care nu esti cetatean roman si nu ai un CNP, este necesar sa te autentifici cu Numarul de identificare fiscala (NIF) ."
      >
        <PasswordField
          autoFocus
          id="cnp"
          label={cetatean ? "CNP *" : "NIF/Serie si nr. act de identitate *"}
          defaultValue={cnp?.value ?? ""}
          className="login-pwd"
          variant="outlined"
          onBlur={(prop) =>
            updateValue("cnp", prop.target.value?.toString().toUpperCase())
          }
          mask={cetatean ? "9999999999999" : ""}
          maskChar=""
          error={!!cnp.error}
          helperText={cnp.error}
          type="tel"
        />
      </TooltipLine>
      <div className="form switch-group">
        <div className="label">Detin card de debit la Patria Bank:</div>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              width="100px"
              checked={amCard}
              //disabled={state.arataProduse}
              onChange={(prop) => {
                var s = { ...state };
                s.amCard = prop.target.checked;
                updateState(s);
              }}
            />
          }
          label={amCard ? "Da" : "Nu"}
          labelPlacement="top" //{dobandaFixa ? "start" : "end"}
        />
      </div>
      {amCard && (
        <div className="form">
          <Typography className="label">Numarul Cardului</Typography>
          <div className="hlist">
            <TextFieldMask
              variant="outlined"
              margin="normal"
              required
              //fullWidth
              //maxWidth="100px"
              id="firstDigits"
              label="Primele 4 Cifre"
              name="firstDigits"
              defaultValue={firstDigits?.value ?? ""}
              onBlur={(prop) => updateValue("firstDigits", prop.target.value)}
              mask="9999"
              maskChar=""
              className="card"
            />
            <TextField
              variant="outlined"
              margin="normal"
              placeholder="* * * *"
              className="card disabled center"
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              placeholder="* * * *"
              className="card disabled center"
              disabled
            />

            <TextFieldMask
              variant="outlined"
              margin="normal"
              required
              id="lastDigits"
              label="Ultimele 4 Cifre"
              name="lastDigits"
              className="card"
              defaultValue={lastDigits?.value ?? ""}
              onBlur={(prop) => updateValue("lastDigits", prop.target.value)}
              mask="9999"
              maskChar=""
            />
          </div>
        </div>
      )}
      <div className="acord">
        <div className="acord">
          <Checkbox
            name="tc"
            checked={tc}
            onChange={updateAcord}
            color="primary"
          />
          <div className="acord-txt">
            Accept
            <Link onClick={() => downloadAcord(1)} className="termenii">
              Termenii si Conditiile Generale
            </Link>
            de utilizare a Platformei Online *
            <FormHelperText error={!!tc_err}>{tc_err}</FormHelperText>
          </div>
        </div>
      </div>
      <div className="acord">
        <Checkbox
          name="ni"
          checked={ni}
          onChange={updateAcord}
          color="primary"
        />
        <div className="acord-txt">
          Am citit si am inteles prevederile
          <Link onClick={() => downloadAcord(2)} className="termenii">
            Notei de informare
          </Link>
          vizand prelucrarea datelor cu caracter personal pentru scopul
          desfasurarii sesiunii pe Platforma Online *
          <FormHelperText error={!!ni_err}>{ni_err}</FormHelperText>
        </div>
      </div>
      <div className="form">
        <TextFieldMask
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Nr Telefon"
          defaultValue={phone?.value ?? (cetatean ? "07" : "00")}
          onBlur={(prop) => updateValue("phone", prop.target.value)}
          className="phone"
          mask={cetatean ? "0799999999" : ""}
          maskChar=""
          error={!!phone.error}
          helperText={phone.error}
          type="tel"
        />
      </div>
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={sendPhone}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Trimite Cod
      </Button>
      <Typography variant="subtitle2">
        Introdu codul primit prin SMS:
      </Typography>
      <OtpInput
        value={smsCode ? smsCode : ""}
        onChange={(value) => updateValue("smsCode", value)}
        numInputs={6}
        renderInput={(props) => <input {...props} disabled={!!codeRequest} />}
        inputStyle="MuiInputBase-root MuiInputBase-input MuiOutlinedInput-input otp-input"
        focusStyle="otp-focus"
        disabledStyle="MuiInputBase-root Mui-disabled"
        inputType="number"
        containerStyle="otp"
      />
      <FormHelperText error={false}></FormHelperText>
      <div className="h-list">
        <Typography variant="caption">
          Nu ai primit cod? Asteapta <b>{timeLeft} sec.</b>
        </Typography>

        <Button
          color="primary"
          /*className="link-btn"*/
          endIcon={<Refresh />}
          onClick={sendPhone}
        >
          Retrimite SMS
        </Button>
      </div>
      <Button
        variant="contained"
        color="primary"
        /*className="login-btn"*/
        onClick={confirmPhone}
        endIcon={<ArrowRight fontSize="large" />}
        disabled={!smsCode}
      >
        Confirma telefon
      </Button>
    </React.Fragment>
  );
}
export default observer(PhonePage);
