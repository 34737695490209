import React from "react";
import { observer } from "mobx-react";
import { objectIsNull } from "Utils";

import FieldLabel from "./field4_label";
import FieldValue from "./field4_value";
import DivCss from "../components/divCss";
import "../index.css";

function Field4(props) {
  const { item, withOutLabel } = props;
  if (!item.listData) {
    return null;
  }
  var withLabel = objectIsNull(withOutLabel) || !withOutLabel;
  withLabel = objectIsNull(item.Jprop.withLabel)
    ? withLabel
    : item.Jprop.withLabel;

  return (
    item.isVisible && (
      <DivCss
        {...props}
        id={`f4-${item.Id}`}
        key={item.Id}
        className={item.Jprop?.className}
      >
        {withLabel && <FieldLabel {...props} />}
        <FieldValue {...props} withLabel={withLabel} />
      </DivCss>
    )
  );
}
export default observer(Field4);
