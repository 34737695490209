import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { CrediteSimulare } from "TemplateCerere";

class DigitalLendingSimulare extends React.Component {
  componentDidMount() {
    //console.log("DigitalLendingSimulare - componentDidMount");
    //this.props.actions.lendingActivate(this.props?.doc?.Jprop?.hideWidget);
    this.props.actions.lendingActivate();
  }
  /*
  componentDidUpdate() {
    const { simulare, actions } = this.props;
    //console.log("DigitalLendingSimulare - componentDidMount");
    if (simulare) {
      actions.updateStore({ simulare: null });
      //this.props.actions.lendingActivate();
    }
  }
*/
  componentWillUnmount() {
    const { template, actions } = this.props;
    if (template?.credit) {
      actions.updateStore({ credit: null }, "template");
    }
  }

  nextStepSave = async (uidSimulare) => {
    const { doc, actions, nextStep } = this.props;
    const jprop = doc.Jprop ?? {};
    const { fields } = jprop.template;
    const fldSimulare = actions.getField(fields.simulare);
    await actions.updateStore({ goPrev: null });
    if (fldSimulare&&uidSimulare){
      await actions.updateValue(fldSimulare, uidSimulare);
    }
    nextStep();
  };
  render() {
    const { doc, actions, errors, nextAction } = this.props;
    const jprop = doc.Jprop ?? {};
    const tmp = jprop.template;
    const fld = tmp.fields;
    const fields = {
      simulare: actions.getField(fld.simulare),
      stareSanatate: actions.getField(fld.stareSanatate),
      vechime: actions.getField(fld.vechime),
      incapacitate: actions.getField(fld.incapacitate),
      confirmAsigurare: actions.getField(fld.confirmAsigurare),
    };
    const {
      produse,
      simulare,
      scadentar,
      comisioane,
      interval_scadenta,
      credit,
      file,
    } = this.props.template;

    if (!produse || !interval_scadenta /*|| !credit*/) {
      return null;
    }
    return (
      <CrediteSimulare
        produse={produse}
        interval_scadenta={interval_scadenta}
        simulare={simulare}
        credit={credit}
        scadentar={scadentar}
        comisioane={comisioane}
        jprop={jprop}
        navigate={this.props.history.push}
        actions={actions}
        isRequest={true}
        title={jprop.title}
        subtitle={jprop.subtitle}
        title2={jprop.title2}
        subtitle2={jprop.subtitle2}
        nextStepSave={this.nextStepSave}
        nextAction={nextAction}
        fields={fields}
        errors={errors}
        doc={doc}
        file={file}
      />
    );
  }
}
export default withRouter(observer(DigitalLendingSimulare));
