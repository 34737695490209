import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import moment from "moment";
import { Edit, Add, FilterList } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import TableSortLabel from "@mui/material/TableSortLabel";

import { EditDialog } from "TemplateCerere";
import { Filters } from "TemplateCerere";
import "./index.css";

function CereriWeb(props) {
  const {
    flux,
    cimpuri,
    cereri,
    paginare,
    changePage,
    addRequest,
    sort,
    filter,
    cimpuriFiltrare,
    filtre,
    updateFilter,
    filterDialog,
    closeFilterDialog,
    saveFilterDialog,
    filterReset,
    doActivate,
  } = props.store;
  const { isAuthenticated } = props.main;
  useEffect(() => {
    props.main.checkLoginUser(
      props.history,
      props.match.url,
      props.appType,
      props.match.params
    );
  }, []);
  useEffect(() => {
    doActivate(props.match.params, props.history);
  }, [flux]);
  /* componentDidMount() {
    props.store.doActivate(props.match.params, props.history);
  }
  componentWillUnmount() {
    props.store.doDeactivate();
  }*/
  /*componentWillUnmount (){
        props.store.doDeactivate();
    }*/

  if (flux && flux.noMatch) {
    return (
      <div
        className="nomatch"
        /*fullWidth*/ dangerouslySetInnerHTML={{ __html: flux.noMatch }}
      />
    );
  }

  if (cimpuri.length === 0) return null;
  const handlerAdd = () => {
    addRequest(props.history);
  };
  const handlerEdit = async (uid) => {
    await props.main.updateValue({ preventGoBack: false });
    console.log(
      "handlerEdit",
      `/${props.main.appType}/request/${props.main.flux.Flow}/${uid}`
    );
    props.history.push(
      `/${props.main.appType}/request/${props.main.flux.Flow}/${uid}`
    );
  };
  const handlerFilter = () => {
    filter(
      <Filters
        items={cimpuriFiltrare}
        filtre={filtre}
        updateFilter={updateFilter}
        filterReset={filterReset}
      />
    );
  };
  const createSortHandler = (property) => (event) => {
    sort(property);
  };

  return (
    <div className="e-card e-card-border e-card-table">
      <div className="e-card-content-document">
        <EditDialog
          close={closeFilterDialog}
          save={saveFilterDialog}
          {...filterDialog}
        />

        <Tooltip title="Adauga cerere">
          <Fab
            className="btn1"
            size="small"
            onClick={handlerAdd}
            color="primary"
          >
            <Add className="btn-add" />
          </Fab>
        </Tooltip>
        <Tooltip title="Filtrare">
          <Fab
            className="btn2"
            size="small"
            onClick={handlerFilter}
            color="primary"
          >
            <FilterList className="btn-filter" />
          </Fab>
        </Tooltip>
        <TableContainer component={Paper} className="list">
          <Table stickyHeader aria-label="spanning table" size="small">
            <TableHead>
              <TableRow>
                {cimpuri.length !== 0 &&
                  cimpuri.map((col) => {
                    var prop = JSON.parse(col.Style);
                    return (
                      <TableCell key={col.Id} {...prop}>
                        <TableSortLabel
                          active={paginare.orderBy === col.Id}
                          direction={
                            paginare.orderBy === col.Id ? paginare.order : "asc"
                          }
                          onClick={createSortHandler(col.Id)}
                        >
                          {col.Descriere}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}

                <TableCell key={`h`} width={20} align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cereri.map((item, index) => {
                var prop = JSON.parse(item.Style);
                return (
                  <TableRow key={`tr-${index}`}>
                    {cimpuri.length !== 0 &&
                      cimpuri.map((col) => {
                        var value = item[col.Cimp];
                        value =
                          value && col.Tip === "data"
                            ? moment(value).format("DD/MM/YYYY")
                            : value;

                        return (
                          <TableCell key={`${col.Id}-${index}`} style={prop}>
                            {value}
                          </TableCell>
                        );
                      })}

                    <TableCell
                      key={`e-${index}`}
                      width={20}
                      align="center"
                      style={{ padding: 0 }}
                    >
                      <Tooltip title="Editeza">
                        <IconButton
                          onClick={(prop) => {
                            handlerEdit(item.Uid_Cerere);
                          }}
                        >
                          <Edit style={{ color: "dodgerblue", fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        component="div"
        count={paginare.nrInregistrari ? paginare.nrInregistrari : 0}
        rowsPerPage={paginare.marimePagina ? paginare.marimePagina : 50}
        page={paginare.numarPagina ? paginare.numarPagina : 0}
        onPageChange={(event, newPage) => changePage({ numarPagina: newPage })}
        onRowsPerPageChange={(event) =>
          changePage({ marimePagina: +event.target.value, numarPagina: 0 })
        }
        labelRowsPerPage="Inregistrari pe Pagina"
        /****  de modificat traducerea
          backIconButtonProps={{ Tooltip: "Pagina Precedenta" }}
          nextIconButtonProps={{ Tooltip: "Pagina Urmatoare" }}
          */
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} din ${count !== -1 ? count : `mai mult decat ${to}`}`
        }
      />
    </div>
  );
}
export default withRouter(
  inject((stores) => {
    return { store: stores.cereriStore, main: stores.mainStore };
  })(observer(CereriWeb))
);
