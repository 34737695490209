import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import { checkEmail } from "Utils";

import { RetailPrescoring } from "TemplateCerere";
import Button from "@mui/material/Button";
import { ArrowLeft } from "@mui/icons-material";
import { ProgressBar } from "TemplateCerere";
import { Prompt } from "react-router-dom";

function RegisterPrescoring(props) {
  const { updateValue,
    codeRequest,
    sendEmailCode,
    confirmEmailCode,
    errors,
    setStepId,
    checkVoucher,
    voucher,
    flowLogin,
    timeLeft
  } = props.store;
  const {
    isMobile,
    getStepsInfo,
    preventGoBack,
    preventGoBackMessage,
    stepId,
    downloadAcord,
  } = props.store;
  let { jprop } = props.store;
  jprop = jprop || {};

  const [statePage, setStatePage] = useState({
    page: 1,
    acord: {
      tc: false, //termeni si conditii
      ni: false, //nota de informare
    },
    name: { value: null, error: null },
    email: { value: null, error: null },
    email2: { value: null, error: null },
    smsCode: null
  });
  const [pages, setPages] = useState({
    acord: 1,
    email: 2
  });

  const [prevPage, setPrevPage] = useState({
    2: "Acorduri (inapoi)",
  });

  useEffect(() => {
    async function internalCheckVoucher() {
      await checkVoucher();
    }
    //updateValue("codeRequest", true);
    setStepId("O1");
    console.log("checkVoucher at start", voucher.value, flowLogin);
    
    internalCheckVoucher();

    window.addEventListener("load", (event) => {
      event.preventDefault();
    });
  }, []);

  const handlerUpdateValue = (name, value) => {
    var s = { ...statePage };
    s[name] = name === "smsCode" ? value : { value: value, error: null };
    setStatePage(s);
  };
  const handlerUpdateError = (name, error) => {
    var errorMsgs = [];
    errorMsgs.push({
      msg: error,
      check: name,
    });
    errors.open(errorMsgs);
  };

  const handlerUpdateAcord = (event) => {
    var s = { ...statePage };
    s.acord[event.target.name] = event.target.checked;
    s.acord[event.target.name + "_err"] = false;
    setStatePage(s);
  };

  const validare = () => {
    const { email, email2, page, acord } = statePage;

    var errorMsgs = [];

    if (page === pages.acord) {
      if (!acord.tc) {
        errorMsgs.push({
          msg: "[PDPF01]Trebuie sa accepti „Termenii si Conditiile de utilizare ale Platformei” pentru a putea continua!",
          check: "tc",
        });
      }
      if (!acord.ni) {
        errorMsgs.push({
          msg: "[PDPF02]Trebuie sa accepti „Nota de Informare prelucrare date” pentru a putea continua!",
          check: "ni",
        });
      }
    }
    if (page === pages.email) {
      if (!checkEmail(email.value)) {
        errorMsgs.push({
          msg: "[PDPF05]Adresa de e-mail nu are un format valid!",
          ctrl: "email",
        });
      }
      if (!checkEmail(email2.value)) {
        errorMsgs.push({
          msg: "[PDPF05]Adresa de e-mail nu are un format valid!",
          ctrl: "email2",
        });
      }
      if (
        email.value &&
        email2.value &&
        email.value.toUpperCase() !== email2.value.toUpperCase()
      ) {
        errorMsgs.push({
          msg: "[PDPF04]Adresele de e-mail nu corespund!",
          ctrl: "email2",
        });
      }
    }
    var s = { ...statePage };
    errorMsgs.forEach((e) => {
      if (e.ctrl) {
        s[e.ctrl] = {
          value: statePage[e.ctrl].value,
          error: errors.processMsg(e.msg),
        };
        e.ctrl = null;
      }
      if (e.check) {
        s.acord[e.check + "_err"] = errors.processMsg(e.msg);
      }
    });
    setStatePage(s);
    return errorMsgs;
  };

  const handlerConfirm = () => {
    const { email, acord, smsCode } = statePage;
    confirmEmailCode(
      email.value,
      smsCode, 
      {
        acord_tc: acord.tc ? "1" : "0", //termeni si conditii
        acord_ni: acord.ni ? "1" : "0", //nota de informare
        email: email.value,
        voucher: voucher.value
      },
      props.history
    );
  };
  const handlerChangePage = (page) => {
    var s = { ...statePage };

    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs, false);
      return;
    }

    if (s.page === pages.email) {
      handlerConfirm();
    } else {
      s.page++;
      setStatePage(s);
      setStepId("O" + s.page);
    }
  };

  const handlerSendEmail = async () => {
    const { email, acord } = statePage;
    var s = { ...statePage };
    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs, false);
      return;
    }
    await sendEmailCode(
      {
        acord_tc: acord.tc ? "1" : "0", //termeni si conditii
        acord_ni: acord.ni ? "1" : "0", //nota de informare
        email: email.value,
        voucher: voucher.value
      }
    );
    s.smsCode = props.store.smsCode;
    setStatePage(s);
  };

  const handlerPrevPage = () => {
    var s = { ...statePage };
    s.page--;
    setStatePage(s);
    setStepId("O" + s.page);
  };

  const Page = (className) => {
    switch (statePage.page) {
      case pages.acord:
        return (
          <RetailPrescoring.AcordPage
            {...statePage.acord}
            handleChange={handlerUpdateAcord}
            downloadAcord={downloadAcord}
            handlerChangePage={handlerChangePage}
            updateValue={handlerUpdateValue}
            name={statePage.name}
            className={className}
          />
        );
      case pages.email:
        return (
          <RetailPrescoring.EmailPage
            updateValue={handlerUpdateValue}
            name={statePage.name}
            email={statePage.email}
            email2={statePage.email2}
            smsCode={statePage.smsCode}
            sendEmailCode={handlerSendEmail}
            handlerChangePage={handlerChangePage}
            updateError={handlerUpdateError}
            className={className}
            codeRequest={codeRequest}
            timeLeft={timeLeft}
          />
        );
      default:
        return <RetailPrescoring.AcordPage />;
    }
  };

  var toolbarCss = "paper-toolbar";
  toolbarCss =
    isMobile && !prevPage[statePage.page] ? toolbarCss + " first" : toolbarCss;

  return (
    <div className={toolbarCss}>
      <Prompt when={preventGoBack} message={preventGoBackMessage} />
      {isMobile && jprop.theme === "retail" && (
        <div className="nav-bar">
          <ProgressBar
            goBack={handlerPrevPage}
            prevStep={!!prevPage[statePage.page]}
            stepId={stepId}
            getStepsInfo={getStepsInfo}
          />
        </div>
      )}

      <CssBaseline />
      <div className="login-content">
        {!isMobile && prevPage[statePage.page] && (
          <div className="doc-nav">
            <Button
              color="primary"
              className="link-btn"
              startIcon={<ArrowLeft />}
              onClick={handlerPrevPage}
            >
              {prevPage[statePage.page]}
            </Button>
          </div>
        )}
        {(voucher.err) ?
          <div>{voucher.msg} {voucher.value}</div>
          :
          Page(jprop.className || "")}
      </div>
    </div>
  );
}

export default withRouter(
  inject((stores) => ({
    store: stores.mainStore
  }))(
    observer(RegisterPrescoring)
  )
);
