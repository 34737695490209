import React from "react";
import { observer } from "mobx-react";
import EditFields from "./field6_edit";
import Button from "@mui/material/Button";
//import { Edit, Delete, CloudDownload } from "@mui/icons-material";
//import Tooltip from "@mui/material/Tooltip";
import "../index.css";

function Field6_table_div(props) {
  const { actions, item } = props;
  const { data } = item;
  return (
    <div id={`tbl${item.Id}`} className="doc-div-table">
      {data.rows.map((row, index) => (
        <div className="doc-div-table-row" key={`tbl${index}`}>
          <EditFields
            id={`tbl${item.Id}eft${index}`}
            key={`tbl${item.Id}eft${index}`}
            data={data}
            updateRow={actions.updateRow}
            tableId={item.Id}
            actions={actions}
            row={row}
            isReadonly={item.isReadonly}
            tableCss="doc-div-table-row-element"
            className="doc-div-table-row-data"
          />
          <div className="doc-div-table-row-actions">
            {item.delete && (
              <Button
                variant="contained"
                color="secondary"
                className="btn"
                onClick={() => actions.deleteTableRow(item, row._rowid)}
              >
                Sterge
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
export default observer(Field6_table_div);
