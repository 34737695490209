import React from "react";
import { observer } from "mobx-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import { Check, Close, NavigateNext } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
//import StepList from "./stepList";

//import "./index.css";
import { Card } from "@mui/material";
import { ScrollTop } from "TemplateCerere";

function Toolbar(props) {
  const {
    taburi,
    currentTab,
    changeTab,
    close,
    save,
    title,
    nextStep,
    hasChanges,
    canPass,
    color,
  } = props;
  const _hasChanges = hasChanges();
  return (
    <Card className="flux-tabs">
      <div className="toolbar-filter-name" style={{ color: color }}>
        {title}
      </div>
      <div className="request-tabs">
        {taburi.length !== 0 && (
          <Tabs
            //orientation="vertical"
            variant="scrollable"
            value={currentTab ? currentTab.Id : taburi[0].Id}
          >
            {taburi.map((item) => {
              return (
                <Tab
                  key={item.Id}
                  label={item.Denumire}
                  value={item.Id}
                  selected={currentTab && currentTab.Id === item.Id}
                  onClick={() => changeTab(item)}
                />
              );
            })}
          </Tabs>
        )}
      </div>
      <div className="request-btns">
        {canPass && (
          <ScrollTop target="app-back-to-top-anchor">
            <Tooltip title="Avansare la pasul urmator">
              <Fab
                size="small"
                onClick={nextStep}
                style={{ backgroundColor: "green", color: "white" }}
                className="request-btn"
              >
                <NavigateNext />
              </Fab>
            </Tooltip>
          </ScrollTop>
        )}
        {_hasChanges ? (
          <Tooltip title="Salveaza">
            <Fab
              size="small"
              onClick={save}
              color="primary"
              className="request-btn"
            >
              <Check />
            </Fab>
          </Tooltip>
        ) : (
          <Fab
            size="small"
            color="primary"
            className="request-btn"
            disabled={true}
          >
            <Check />
          </Fab>
        )}
        <Tooltip title="Renunta">
          <Fab
            size="small"
            onClick={close}
            color="secondary"
            className="request-btn"
          >
            <Close />
          </Fab>
        </Tooltip>
      </div>
    </Card>
  );
}
export default observer(Toolbar);
