import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import Container from "@mui/material/Container";

import { onEnter } from "Utils";
import Copyright from "../copyright";

function LoginUser(props) {
  const { username, appType } = props.store;
  const { updateValue, checkUser } = props.store;
  const { history } = props;
  let query = new URLSearchParams(props.location.search);
  const url = query.get("ref");

  useEffect(() => {
    updateValue({ themeSelected: true });
    updateValue({ appType: props?.appType?? appType ?? "app" });
  }, []);

  return (
    <div className="login-card">
      <div component="main">
        <CssBaseline />
        <div className="login">
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            className="login-title"
            align="center"
          >
            Autentificare {appType}
          </Typography>
          <div className="form">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              label="Utilizator"
              name="Utilizator"
              value={username ? username : ""}
              onChange={(prop) => updateValue({ username: prop.target.value })}
              //defaultValue={username ? username : ""}
              //onBlur={(prop)=> updateValue({ username: prop.target.value })}
              //autoFocus
              className="login-txt"
              //autoComplete="Utilizator"
              onKeyPress={(event) =>
                onEnter(event, checkUser, {
                  history: history,
                  url: url,
                })
              }
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              onClick={() =>
                checkUser({ history: history, url: url })
              } /*onClick={() => authenticate(this.props.history)}*/
            >
              Inainte
            </Button>
          </div>
        </div>

        <Copyright />
      </div>
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(LoginUser))
);
