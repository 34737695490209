import { extendObservable, set } from "mobx";

const model = {
  isOpen: false,
  message: [],
  className: "retail-dialog-error",
  type: null,
  header: null,
};

/*const errorMessages = {
    "V0003":"Numarul de telefon introdus nu are un format corect!", 
    "V0004":"Nu aveti dreptul sa accesati aceasta cerere!", 
    "S0003":"Codul introdus a expirat sau nu este corect!", 
}*/
export default class Errors {
  errorMessages = {};
  theme = "default";

  constructor() {
    extendObservable(this, model);
  }

  processMsg = (err) => {
    //console.log("Lista errorMessages:", this.errorMessages);
    err = err?.trim()??"";
    if (err.indexOf("[") === 0) {
      var iEnd = err.indexOf("]");
      if (iEnd > 0) {
        var errCode = err.substring(1, iEnd);
        err = err.substring(iEnd + 1).trim();
        err = this.errorMessages[errCode] || err||errCode;
        //err = `#${errCode} ${err}`;
        if (this.theme === "default") {
          err = `#${errCode} ${err}`;
        }
      }
    }
    //console.log("Lista err:", err);
    return err;
  };
  open = (errors, openDialog, closeHandler) => {
    if (openDialog === undefined) {
      openDialog = false;
    }
    if (typeof errors == "object" && errors[0]) {
      errors.forEach((error) => {
        var errMsg = error;
        var errCtrl = null;
        if (typeof error == "object") {
          errMsg = error.msg;
          errCtrl = error.ctrl; //id-ul controlului
        }
        errMsg.split("~ |").forEach((err) => {
          err = this.processMsg(err);
          if (this.theme === "default") {
            this.message.push(err);
            if (errCtrl) {
              errCtrl.error = err;
              //openDialog = true;
            }
            openDialog = true;
          } else {
            if (!err.startsWith("Nu se poate salva cererea")) {
              if (errCtrl) {
                errCtrl.error = err;
              } else {
                this.message.push(err);
                openDialog = true;
              }
            }
          }
        });
      });
    } else {
      if (errors?.length !== 0) {
        //console.log("Lista errors (dublate!?):", errors);
        openDialog = true;
        errors?.split("~ |").forEach((err) => {
          if (
            !err.startsWith("Nu se poate salva cererea") ||
            this.theme === "default"
          ) {
            this.message.push(this.processMsg(err));
          }
        });
      }
    }
    //eliminare mesaje de erroare duplicate
    this.message = Array.from(new Set(this.message));
    this.closeHandler=closeHandler;
    if (openDialog && this.message.length !== 0) {
      this.isOpen = true;
    } else {
      this.close();
    }

    //set(this, {message: error, isOpen : true});
  };
  close = () => {
    if (this.closeHandler){
      this.closeHandler.call();
      this.closeHandler=null;
    }
    set(this, model);
  };
}
