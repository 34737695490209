import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

function Navbar(props) {
  const { fluxuri, flux, changeFlux } = props.store;
  return (
    <div className="navbar">
      {fluxuri && flux?.uid_flux && (
        <Tabs
          value={flux.uid_flux}
          variant="scrollable" /*scrollButtons="auto"  wrapped="false"*/
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#f1f8fa",
            },
          }}
        >
          {fluxuri.map((item) => {
            return (
              <Tab
                key={item.uid_flux}
                label={item.Denumire}
                value={item.uid_flux}
                selected={flux.uid_flux && flux.uid_flux === item.uid_flux}
                onClick={() => changeFlux(item, props.history)}
                sx={[
                  {
                    color: "#fff",
                  },
                ]}
              />
            );
          })}
        </Tabs>
      )}
    </div>
  );
}

export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(Navbar))
);
