import React from "react";
import { observer } from "mobx-react";
import PachetContOption from "./pachetContOption";
import PachetContIcon from "./pachetContIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import * as Field from "../field";
import {
  IconCards,
  IconCurrentAccount,
  IconInternetBanking,
  IconSms,
} from "TemplateCerere";
import ArchiveIcon from "@mui/icons-material/Archive";
import Button from "@mui/material/Button";
//import "./../templates/DigitalLendingPachetPF.scss";

const PachetImplicit = (props) => {
  const getIcon = (e) => {
    return e ? (
      <CheckCircleIcon style={{ color: "#009479" }} />
    ) : (
      <RemoveCircleOutlineIcon style={{ fill: "#C22821" }} />
    );
  };
  const {
    pachet,
    readonly,
    doc,
    fields,
    onReset,
    actions,
    afisareExtraOptiuni,
    gridItem,
  } = props;
  const { cod_produs, info, opt } = pachet;

  const ctrlSetup = (c) => {
    var ctrl = actions.getField(c);
    ctrl.error = false;
    var r = { ctrl: ctrl, opt: opt.find((o) => o.id === c) || {} };
    r.ctrl.Caption = r.opt.captionSelected ?? r.ctrl.Caption;
    return r;
  };

  const cont = ctrlSetup(fields.cont);
  const card = ctrlSetup(fields.card);
  const ib = ctrlSetup(fields.ib);
  const sms = ctrlSetup(fields.sms);
  const contval = ctrlSetup(fields.contval);
  const cont_extra = ctrlSetup(fields.cont_extra);
  const card_extra = ctrlSetup(fields.card_extra);
  const ib_extra = ctrlSetup(fields.ib_extra);
  const sms_extra = ctrlSetup(fields.sms_extra);
  const nume_card = actions.getField(135);
  const livrare_card = actions.getField(fields.delivery);
  nume_card.error = false;
  if (!contval.ctrl.listData.find((e) => e[0] === "")) {
    contval.ctrl.listData.push(["", "Nu vreau cont in valuta"]);
  }

  return (
    <>
      {!readonly && (
        <div className="pachet-select-reset">
          <Button
            color="primary"
            className="link-btn"
            endIcon={<ArchiveIcon fontSize="large" />}
            onClick={onReset}
          >
            Arata toate produsele
          </Button>
        </div>
      )}
      <div className="pachet-selected-wrapper">
        <div
          className={["pachet-selected", info.className].join(" ")}
          id={cod_produs}
        >
          <div className="pachet-oferta">
            <PachetContIcon
              prefix={info.prefix}
              caption={info.caption}
              estePachet={info.estePachet}
            />
            {/*info.promoText && (
              <>
                <div className="promo">{info.promoText}</div>
                <div className="promo-helper">{info.promoHelper}</div>
              </>
            )*/}
          </div>
          <div className="pachet-optiuni">
            <div className="pachet-pret">
              {pachet.info.estePachet ? "Cost pachet: " : "Cost: "}
              <span>{info.pret}</span>
              <span>Lei</span>
              <span>/luna</span>
            </div>
            <div className="pachet-helper">{info.helperText}<br></br>{info.helperText2}</div>
          </div>
        </div>
        <div className="pachet-optiuni-title">Produse si servicii incluse:</div>
        <div className="pachet-optiuni">
          <div className="pachet-optiune-simplu">
            <div className="card-header-title pachet-label-simplu">
              <IconCurrentAccount style={{ marginRight: "10px" }} />
              Cont curent in RON
            </div>
            <div className="pachet-optiune-info-simplu pachet-cont-option-group">
              <PachetContOption
                type="cont"
                caption={cont.opt.captionSelected}
                helperText={cont.opt.helperText}
                icon={getIcon(cont.opt.included)}
              />
            </div>
          </div>
          {info.estePachet && (
            <>
              <div className="pachet-optiune-simplu">
                <div className="card-header-title pachet-label-simplu">
                  <IconCards style={{ marginRight: "10px" }} />
                  Card de debit
                </div>
                <div className="pachet-cont-option-group">
                  <PachetContOption
                    type="card"
                    caption={card.opt.captionSelected}
                    helperText={card.opt.helperText}
                    icon={getIcon(card.opt.included)}
                  />
                  <div className="pachet-cont-option">
                    <Field.Field4
                      item={card.ctrl}
                      inline={true}
                      actions={actions}
                      withOutLabel={true}
                      gridItem={gridItem}
                      /*            css={css}
                              isMobile={isMobile}
                              gridItem={gridItem}*/
                    />
                  </div>
                  <div className="pachet-cont-option download-icons">
                    <Field.Field4
                      item={nume_card}
                      inline={true}
                      actions={actions}
                      withLabel={true}
                      gridItem={gridItem}
                      /*            css={css}
                              isMobile={isMobile}
                              gridItem={gridItem}*/
                    />
                    <Field.Field4
                      item={livrare_card}
                      inline={true}
                      actions={actions}
                      withLabel={true}
                      gridItem={gridItem}
                      /*            css={css}
                              isMobile={isMobile}
                              gridItem={gridItem}*/
                    />
                  </div>
                </div>
                {contval.ctrl.isVisible && (
                  <div className="pachet-optiune-simplu">
                    <div className="card-header-title pachet-label-simplu">
                      Cont in valuta
                    </div>
                    <div className="pachet-control pachet-cont-option-group">
                      <Field.Field4
                        item={contval.ctrl}
                        inline={true}
                        actions={actions}
                        withOutLabel={true}
                        gridItem={gridItem}
                        /*            css={css}
                                isMobile={isMobile}
                                gridItem={gridItem}*/
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="pachet-optiune-simplu">
                <div className="card-header-title pachet-label-simplu">
                  <IconInternetBanking style={{ marginRight: "10px" }} />
                  Internet & Mobile Banking
                </div>
                <div className="pachet-control pachet-cont-option-group">
                  {ib.ctrl.isReadonly ? (
                    <PachetContOption
                      type="ib"
                      caption={ib.opt.captionSelected}
                      helperText={ib.opt.helperText}
                      icon={getIcon(ib.opt.included)}
                    />
                  ) : (
                    <Field.Field5
                      item={ib.ctrl}
                      inline={true}
                      actions={actions}
                      gridItem={gridItem}
                      /*            css={css}
                                isMobile={isMobile}
                                gridItem={gridItem}*/
                    />
                  )}
                </div>
              </div>
              {sms.ctrl.isVisible && (
                <div className="pachet-optiune-simplu">
                  <div className="card-header-title pachet-label-simplu">
                    <IconSms style={{ marginRight: "10px" }} />
                    SMS Alert
                  </div>
                  <div className="pachet-control pachet-cont-option-group">
                    {sms.ctrl.isReadonly ? (
                      <PachetContOption
                        type="sms"
                        caption={sms.opt.captionSelected}
                        helperText={sms.opt.helperText}
                        icon={getIcon(sms.opt.included)}
                      />
                    ) : (
                      <Field.Field5
                        item={sms.ctrl}
                        inline={true}
                        actions={actions}
                        helperText={sms.opt.helperText}
                        gridItem={gridItem}
                        /*            css={css}
                                  isMobile={isMobile}
                                  gridItem={gridItem}*/
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {afisareExtraOptiuni && (
        <>
          {info.estePachet ? (
            <>
              <div className="pachet-extra-title">Extraoptiuni</div>
              <div className="pachet-extra-subtitle">
                La pachetul ales {pachet.denumire} poti adauga extraoptiuni ce
                presupun costuri suplimentare
              </div>
            </>
          ) : (
            <>
              <div className="pachet-extra-title">
                Selecteaza si alte produse
              </div>
              <div className="pachet-extra-subtitle">
                Poti adauga extraoptiuni ce presupun costuri suplimentare
              </div>
            </>
          )}
          {card_extra && (
            <div className="pachet-extra pachet-extra-card">
              <div className="pachet-extra-column">
                <div className="card-header-title pachet-label-simplu">
                  Adauga un card de debit suplimentar
                </div>
                <div className="pachet-control">
                  <Field.Field4
                    item={card_extra.ctrl}
                    inline={true}
                    actions={actions}
                    withOutLabel={true}
                    gridItem={gridItem}
                  />
                </div>
                {!info.estePachet && (
                  <div className="pachet-control">
                    <Field.Field4
                      item={nume_card}
                      inline={true}
                      actions={actions}
                      withLabel={true}
                      gridItem={gridItem}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {info.estePachet && cont_extra && (
            <div className="pachet-extra pachet-extra-cont">
              <div className="pachet-extra-column">
                <div className="card-header-title pachet-label-simplu">
                  Adauga cont curent in valuta
                </div>
                <div className="pachet-control">
                  <Field.Field4
                    item={cont_extra.ctrl}
                    inline={true}
                    actions={actions}
                    withOutLabel={true}
                    gridItem={gridItem}
                  />
                </div>
              </div>
            </div>
          )}
          {ib_extra?.ctrl.isVisible && (
            <div className="pachet-extra pachet-extra-ib">
              <div className="pachet-extra-column pachet-control">
                <div className="card-header-title pachet-label-simplu">
                  Adauga Internet &amp; mobile banking
                </div>
                <Field.Field5
                  item={ib_extra.ctrl}
                  inline={true}
                  actions={actions}
                  gridItem={gridItem}
                />
              </div>
              <div className="pachet-extra-column">
                Activeaza serviciul de Internet & Mobile Banking pentru o
                gestiune mai rapida a tranzactiilor tale bancare oricand si de
                oriunde!
              </div>
            </div>
          )}
          {sms_extra?.ctrl.isVisible && (
            <div className="pachet-extra pachet-extra-sms">
              <div className="pachet-extra-column">
                <div className="card-header-title pachet-label-simplu">
                  Foloseste SMS Alert pentru cardurile
                </div>
                <Field.Field4
                  item={sms_extra.ctrl}
                  inline={true}
                  actions={actions}
                  withOutLabel={true}
                  gridItem={gridItem}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default observer(PachetImplicit);
