import React from "react";
import { observer } from "mobx-react";

import styled from "styled-components";
import { Checkbox } from "TemplateCerere";

import "../index.css";

function Field6_Edit_C(props) {
  const { row, updateRow, col, tableId, isReadonly } = props;
  const CheckboxCss = styled(Checkbox)`
    ${col && col.eventProps ? col.eventProps.style : null}
  `;
  return (
    <CheckboxCss
      id={`ecb-${col.key}`}
      checked={row ? row[col.key] === "1" || row[col.key] === true : false}
      onChange={(prop) =>
        updateRow(row, col.tip, col.key, prop.target.checked, tableId)
      }
      color="primary"
      disabled={col.isReadonly || isReadonly}
      className={`doc-table-value${
        col.isReadonly || isReadonly ? " disabled" : ""
      }`}
      variant="outlined"
      label={col.inline && col.Caption}
    />
  );
}
export default observer(Field6_Edit_C);
