import React from "react";
import { observer } from "mobx-react";

import * as Document from './document'


import "./index.css";


function PrintDocuments(props) {
        const {item, openPrintParameters} =props;
    return (
        <div className="e-card e-card-border" >
            {/*<div className="e-card-title">Documente</div>*/}
            <div className="e-card-content-document">
                <div className="download-icons">
                    {
                        item.length!==0 && 
                        item.map(doc =>  
                            <Document.Document1 key={doc.Id_doc} doc={doc} openPrintParameters={openPrintParameters} />                    
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default observer(PrintDocuments);