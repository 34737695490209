import React from "react";
import { observer } from "mobx-react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import "./index.css";
import ListItemText from "@mui/material/ListItemText";

function StepList(props) {
    const { steps, save } = props;
    return (
      <List>
        {steps.map((step) => (
          <ListItem
            button
            onClick={() => save(null, true, step.id)}
            key={step.id}
          >
            <ListItemText primary={step.denumire} />
          </ListItem>
        ))}
      </List>
    );
  }
export default observer(StepList);
