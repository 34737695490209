import React from "react";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import { TextFieldMask } from "TemplateCerere";
import { PasswordField } from "TemplateCerere";
//import { onEnter } from "Utils";
import { ArrowRight, Refresh } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import OtpInput from "react-otp-input";
import FormHelperText from "@mui/material/FormHelperText";

function PhonePage(props) {
  const {
    updateValue,
    codeRequest,
    phone,
    smsCode,
    timeLeft,
    sendPhone,
    confirmPhone,
    name,
    cnp,
    //      handlerChangePage,
  } = props;

  return (
    <>
      <p className="page-title">{name.value}, ne poti spune CNP-ul tau?</p>
      <p className="normal-text">
        Avem nevoie de CNP-ul tau pentru a verifica daca esti clientul nostru!
        <br />
        Numarul de telefon este necesar pentru inregistrarea cererii.
      </p>

      <div className="form">
        <PasswordField
          autoFocus
          id="cnp"
          label="CNP *"
          defaultValue={cnp && cnp.value ? cnp.value : ""}
          className="login-pwd"
          variant="outlined"
          onBlur={(prop) => updateValue("cnp", prop.target.value)}
          mask="9999999999999"
          maskChar=""
          error={!!cnp.error}
          helperText={cnp.error}
          type="tel"
        />
        <TextFieldMask
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Nr Telefon"
          defaultValue={phone && phone.value ? phone.value : "07"}
          onBlur={(prop) => updateValue("phone", prop.target.value)}
          className="phone"
          mask="0799999999"
          maskChar=""
          error={!!phone.error}
          helperText={phone.error}
          type="tel"
        />
      </div>
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={sendPhone}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Trimite Cod
      </Button>
      <Typography variant="subtitle2">
        Introdu codul primit prin SMS:
      </Typography>

      <OtpInput
        value={smsCode ? smsCode : ""}
        onChange={(value) => updateValue("smsCode", value)}
        numInputs={6}
        renderInput={(props) => <input {...props} disabled={!!codeRequest} />}
        inputStyle="MuiInputBase-root MuiInputBase-input MuiOutlinedInput-input otp-input"
        focusStyle="otp-focus"
        disabledStyle="MuiInputBase-root Mui-disabled"
        inputType="number"
        containerStyle="otp"
      />
      <FormHelperText error={false}></FormHelperText>
      <div className="h-list">
        <Typography variant="caption">
          Nu ai primit cod? Asteapta <b>{timeLeft} sec.</b>
        </Typography>

        <Button
          color="primary"
          /*className="link-btn"*/
          endIcon={<Refresh />}
          onClick={sendPhone}
        >
          Retrimite SMS
        </Button>
      </div>
      <Button
        variant="contained"
        color="primary"
        /*className="login-btn"*/
        onClick={confirmPhone}
        endIcon={<ArrowRight fontSize="large" />}
        disabled={!smsCode}
      >
        Confirma telefon
      </Button>
    </>
  );
}
export default observer(PhonePage);
