export const getValueKey = (value, key) => {
  if (value == null) {
    return null;
  }
  if (Object.keys(value).length <= key) {
    //key = 0;
    return null;
  }
  var _value = value[Object.keys(value)[key]];
  return _value != null && _value != undefined ? _value : "";
};

export const getValueOfList = (list, value) => {
  if (value === undefined || !list /*|| list.length === 0 --daca nu este nici o valoare in lista sa fie afisata valoarea din camp */) 
    return { 0: "", 1: "" };
  // eslint-disable-next-line eqeqeq
  var selected = list.find((x) => getValueKey(x, 0) == value);
  selected = selected ? selected : { 0: value, 1: value };
  return selected;
};

