import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ToggleButton from "@mui/material//ToggleButton";
import ToggleButtonGroup from "@mui/material//ToggleButtonGroup";
import { Tab, Tabs, FormLabel } from "@mui/material";
import Slider from "@mui/material/Slider";

import { Checkbox } from "TemplateCerere";
import { getValueKey, getValueOfList, getStyle } from "Utils";
import IconButton from "@mui/material/IconButton";
import { Update } from "@mui/icons-material";
import { TooltipIcon } from "TemplateCerere";
import "../index.css";

/*
Lungime 10 --> dropdown - o singura valoare interogare db 				--> item.coldef.dt
Lungime 11 --> selectie multipla (lista de checknox-uri) - interogare db 		--> item.coldef.dt
Lungime 12 --> combobox - o singura valoare (inclusiv alte valori) - interogare db	--> item.coldef.dt
Lungime 20 --> dropdown - o singura valoare - lista valori 				--> item.coldef.list
Lungime 30 --> dropdown - o singura valoare - lista globala 				--> liste[item.SursaLista.toUpperCase()]
*/
function getChecked(selected, item) {
  if (selected) {
    return selected.split(",").some((x) => x === item[0]);
  }
  return;
}

function getSliderMarks(item) {
  if (item.visibleList) {
    return item.visibleList().map((value, index) => {
      return {
        value: index,
        label: getValueKey(value, 1),
        key: getValueKey(value, 0),
      };
    });
  }
  return null;
}

function Field4_Value(props) {
  const { item, inline, actions, isMobile, withLabel, gridItem } = props;
  if (!item.listData) {
    return null;
  }
  const marks = getSliderMarks(item);

  const setChecked = (value) => {
    var v = item.Valoare ? item.Valoare.split(",") : [];
    var p = v.indexOf(value);
    if (p === -1) {
      //value nu exista in lista, deci il adaug
      item.Checked = { action: "check", value: value };
      v.push(value);
    } //am gasit valoarea in lista, deci o elimin
    else {
      item.Checked = { action: "uncheck", value: value };
      v.splice(p, 1);
    }
    /*
      in varianta scurta valorile se adauga pe masura ce sunt bifate, dar nu respecta ordinea in care apar in item.listData
      pozitionam valorile in ordinea din listData
      */
    v.sort(function (a, b) {
      var i = item.listData.findIndex((x) => x[0] === a);
      var j = item.listData.findIndex((x) => x[0] === b);
      return i - j;
    });
    var listchecks = v.join(",");
    actions.updateValue(item, listchecks);
  };

  const getWidth = () => {
    return gridItem ? "100%" : 350;
  };
  const defaultValue = getValueOfList(item.listData, item.Valoare);
  const label = {
    label: inline && withLabel ? item.Caption : item.Jprop?.placeholder,
  };

  const styleProps = getStyle(item.eventProps);
  const ListCss = styled(List)`
    ${styleProps.input}
  `;
  const AutocompleteCss = styled(Autocomplete)`
    ${styleProps.input}
  `;
  const FormControlCss = styled(FormControl)`
    ${styleProps.input}
  `;
  /*
      verific ce fel de select e
      poate fi select - combobox, radio - butoane radio, button - grup de butoane
      daca nu e unul dintre ele, atunci devine select
    */
  var controlType =
    ["select", "radio-v", "radio-h", "button", "tab", "slider"].find(
      (e) => e === item.Jprop.control
    ) || "select";
  //console.log("render " + item.Id, withLabel);
  const isError = item.error ? true : false;
  const orientation = isMobile ? "vertical" : "horizontal";

  const TextControl = () => {
    if (item.Lungime % 10 === 0) {
      // este cimp de tip select
      if (controlType === "select") {
        return (
          <div className="refresh-autocomplete">
            <AutocompleteCss
              id={`f4f-${item.Id}`}
              //id={"cb" + item.Id}
              controled="true"
              options={item.visibleList()}
              getOptionLabel={(option) => getValueKey(option, 1)}
              clearOnEscape
              value={defaultValue}
              style={{ width: getWidth() }}
              size="small"
              disabled={item.isReadonly || item.docReadOnly}
              onChange={(event, newValue) => {
                actions.updateValue(
                  item,
                  /*newValue &&*/ getValueKey(newValue, 0)
                );
              }}
              autoComplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...label}
                  variant="outlined"
                  //error = {item.error}
                  error={isError}
                  helperText={isError ? item.error : item.Jprop.helperText}
                  required={item.Obligatoriu}
                />
              )}
              required={item.Obligatoriu}
            />
            {item.hasButton && !(item.isReadonly || item.docReadOnly) && (
              <IconButton
                aria-label="toggle "
                onClick={() => {
                  actions.runEvent(actions.store, item, false, true);
                }}
                size="small"
              >
                <Update />
              </IconButton>
            )}
            {inline && item.Jprop?.infoLabel && (
              <TooltipIcon tooltip={item.Jprop?.infoLabel} />
            )}
          </div>
        );
      }
      if (controlType === "radio-v" || controlType === "radio-h") {
        return (
          <FormControlCss
            component="fieldset"
            error={isError}
            id={`f4fc-${item.Id}`}
          >
            {inline && (
              <FormLabel id={`f4fl-${item.Id}`} component="legend">
                {label.label}
                {item.Jprop?.infoLabel && (
                  <TooltipIcon tooltip={item.Jprop?.infoLabel} />
                )}
              </FormLabel>
            )}
            <RadioGroup
              id={`f4f-${item.Id}`}
              name={`f4f-${item.Id}`}
              value={item.Valoare}
              row={controlType === "radio-h"}
              onChange={(event) => {
                var newValue = event.target.value;
                actions.updateValue(item, newValue);
              }}
            >
              {item.visibleList().map((value) => {
                var kv = getValueKey(value, 0);
                return (
                  <FormControlLabel
                    value={kv + ""}
                    control={<Radio color="primary" />}
                    label={getValueKey(value, 1)}
                    key={`f4f-${item.Id}-${kv}`}
                    id={`f4f-${item.Id}-${kv + ""}`}
                    disabled={item.isReadonly || item.docReadOnly}
                  />
                );
              })}
            </RadioGroup>
            <FormHelperText>
              {isError ? item.error : item.Jprop.helperText}
            </FormHelperText>
          </FormControlCss>
        );
      }

      if (controlType === "button") {
        return (
          <FormControlCss component="fieldset" error={isError}>
            {inline && (
              <FormLabel component="legend" id={`f4fl-${item.Id}`}>
                {label.label}
                {item.Jprop?.infoLabel && (
                  <TooltipIcon tooltip={item.Jprop?.infoLabel} />
                )}
              </FormLabel>
            )}
            <ToggleButtonGroup
              size="medium"
              value={item.Valoare}
              exclusive
              onChange={(event, newValue) => {
                actions.updateValue(item, newValue);
              }}
              disabled={item.isReadonly || item.docReadOnly}
            >
              {item.visibleList().map((value) => {
                var kv = getValueKey(value, 0);
                return (
                  <ToggleButton
                    key={`f4f-${item.Id}-${kv}`}
                    value={kv + ""}
                    classes={item.Jprop.classes}
                    id={`f4f-${item.Id}-${kv + ""}`}
                    disabled={item.isReadonly || item.docReadOnly}
                  >
                    {getValueKey(value, 1)}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
            <FormHelperText>
              {isError ? item.error : item.Jprop.helperText}
            </FormHelperText>
          </FormControlCss>
        );
      }
      if (controlType === "tab") {
        return (
          <FormControlCss component="fieldset" error={isError}>
            {
              //inline && (<FormLabel component="legend">{item.Caption}</FormLabel>)
            }
            <Tabs
              value={item.Valoare}
              onChange={(event, newValue) => {
                actions.updateValue(item, newValue);
              }}
              variant="scrollable"
              scrollButtons="on"
              indicatorcolor="secondary"
              textColor="primary"
              orientation={orientation}
            >
              {item.visibleList().map((value) => {
                var kv = getValueKey(value, 0);
                return (
                  <Tab
                    key={`f4f-${item.Id}-${kv}`}
                    value={kv + ""}
                    label={getValueKey(value, 1)}
                    id={`f4f-${item.Id}-${kv + ""}`}
                    disabled={item.isReadonly || item.docReadOnly}
                  />
                );
              })}
            </Tabs>
            <FormHelperText>
              {isError ? item.error : item.Jprop.helperText}
            </FormHelperText>
          </FormControlCss>
        );
      }

      if (controlType === "slider") {
        return (
          <FormControlCss component="fieldset" error={isError}>
            {inline && (
              <FormLabel component="legend" id={`f4fl-${item.Id}`}>
                {item.Caption}
                {item.Jprop?.infoLabel && (
                  <TooltipIcon tooltip={item.Jprop?.infoLabel} />
                )}
              </FormLabel>
            )}
            {item.isReadonly || item.docReadOnly ? (
              <div className="field-value">{getValueKey(defaultValue, 1)}</div>
            ) : (
              <Slider
                value={marks.findIndex((m) => m.key === item.Valoare)}
                onChange={(event, newValue) => {
                  actions.updateValue(item, marks[newValue].key);
                }}
                marks={marks}
                step={null}
                max={marks.length - 1}
                disabled={item.isReadonly || item.docReadOnly}
              />
            )}
            <FormHelperText>
              {isError ? item.error : item.Jprop.helperText}
            </FormHelperText>
          </FormControlCss>
        );
      }
    }

    if (item.Lungime % 10 === 1) {
      return (
        <FormControlCss
          id={`f4c-${item.Id}`}
          required={item.Obligatoriu}
          error={!!item.error}
        >
          <FormGroup id={`f4g-${item.Id}`}>
            <ListCss
              id={`f4f-${item.Id}`}
              className="checkList"
              required={item.Obligatoriu}
            >
              {item.visibleList().map((value) => {
                const v = getValueKey(value, 0);
                return (
                  <ListItem
                    id={`f4f-${item.Id}-${v}`}
                    key={`f4f-${item.Id}-${v}`}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    onClick={() => setChecked(v)}
                    disabled={item.isReadonly || item.docReadOnly}
                  >
                    <ListItemIcon>
                      <Checkbox
                        id={`f4f-${item.Id}-${v}cb`}
                        checked={getChecked(item.Valoare, value)}
                        color="primary"
                        disabled={item.isReadonly || item.docReadOnly}
                        className={
                          item.isReadonly || item.docReadOnly
                            ? "disabled"
                            : null
                        }
                        style={{ padding: 0 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={`f4f-${item.Id}-${v}txt`}
                      primary={getValueKey(value, 1)}
                      secondary={getValueKey(value, 2)}
                    />
                  </ListItem>
                );
              })}
            </ListCss>
          </FormGroup>
          <FormHelperText>{item.error || item.Jprop.helperText}</FormHelperText>
        </FormControlCss>
      );
    }

    if (item.Lungime % 10 === 2) {
      return (
        <div className="refresh-autocomplete">
          <AutocompleteCss
            id={`f4f-${item.Id}`}
            controled="true"
            freeSolo
            options={item.visibleList()}
            getOptionLabel={(option) => getValueKey(option, 1)}
            value={defaultValue}
            style={{ width: getWidth() }}
            disabled={item.isReadonly || item.docReadOnly}
            onChange={(event, newValue) => {
              actions.updateValue(item, newValue && getValueKey(newValue, 0));
            }}
            renderInput={(params) => (
              <TextField
                id={`f4f-${item.Id}-txt`}
                {...params}
                {...label}
                className={
                  item.isReadonly || item.docReadOnly ? "disabled" : null
                }
                variant="outlined"
                onBlur={(prop) => {
                  actions.updateValue(item, prop.target.value);
                }}
                error={isError}
                helperText={item.error || item.Jprop.helperText}
                required={item.Obligatoriu}
              />
            )}
            size="small"
            required={item.Obligatoriu}
          />
          {item.hasButton && !(item.isReadonly || item.docReadOnly) && (
            <IconButton
              aria-label="toggle "
              onClick={() => {
                actions.runEvent(actions.store, item, false, true);
              }}
              size="small"
            >
              <Update />
            </IconButton>
          )}
        </div>
      );
    }
  };
  if (item.isVisible) {
    return <TextControl />;
  }
  return null;
}
export default observer(Field4_Value);
