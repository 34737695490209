import { getValueOfList, getValueKey } from "Utils";

export const formatCell = (row, col, onFormatCell) => {
      var value = row[col.key];
      var { lista, tip } = col;
      var sValue = "";
      if (onFormatCell) {
        var frm = onFormatCell(row, col);
        if (frm !== false && frm !== undefined) {
          return frm;
        }
      }
      switch (tip) {
        case "d": //date
          sValue = value;
          break;
        case "n": //number
          sValue =
            value !== undefined && !isNaN(value) && value != null
              ? value.toLocaleString("ro-RO")
              : null;
          break;
        case "s": // selected list
          sValue =
            value !== undefined
              ? getValueKey(getValueOfList(lista, value), 1)
              : null;
          break;
        case "l": //list
          sValue =
            value !== undefined
              ? getValueKey(getValueOfList(lista, value), 1)
              : null;
          break;
        default:
          sValue = value !== undefined && value != null ? value : "";
      }
      return sValue;
    };