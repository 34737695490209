import React from "react";
import { observer } from "mobx-react";
/*import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";*/
import { IFrame } from "TemplateCerere/src/views/iframe";

//import "./index.css";

function VideoCall(props) {
  const { videoCall } = props;
  //const matches = useMediaQuery('(min-width:700px)');
  return videoCall.isOpen ? (
    <IFrame
      title="Videocall Iframe"
      name="i-video"
      allow="geolocation; camera; microphone"
      border="0"
      src={videoCall.src}
      className="video-call"
    >
      <h3>Asteptati initierea video call</h3>
    </IFrame>
  ) : null;
  //    }
}
export default observer(VideoCall);
