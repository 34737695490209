import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./AcceptanceAgreement.scss";
import Fields from "../fields";
import PdfAllPages from "../components/pdfViewAllPages";

function AcceptanceAgreement(props) {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    var jprop = doc.Jprop || {};
    const tmp = jprop.template || {};
    const fields = tmp.fields || {};
    const documents = tmp.documents || {};
    actions.getPrintFile({
      id: documents.acord,
    });
  }, []);

  const { doc, actions, isMobile, css } = props;
  const { file } = props.template;
  const headers = actions.getHeaders(doc.Id);
  return (
    <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
      <div className="e-card-title">
        {doc.Jprop.template.header || doc.Descriere}
      </div>
      {doc.Jprop.subtitle && (
        <div className="e-card-subtitle">{doc.Jprop.subtitle}</div>
      )}
      <div className="e-card-content-document">
        {file && (
          <>
            <PdfAllPages
              file={file}
              onLoadSuccess={() => {
                setLoading(false);
                actions.updateMainStore({ forceWaiting: false });
              }}
            />
            <div key={`dc${doc.Id}`} className="data-doc">
              {!isLoading &&
                headers.length !== 0 &&
                headers.map((head, index) => {
                  var fld = actions.getFields(head);
                  return (
                    fld.filter((e) => e.isVisible).length !== 0 && (
                      <div key={`hd${doc.Id}_${index}`} className="card-header">
                        <div className="card-header-title">{head.Caption}</div>
                        <div className="e-card-content-document">
                          <Fields
                            data={fld}
                            inline={head.Inline}
                            css={css}
                            actions={actions}
                            isMobile={isMobile}
                            jprop={doc.Jprop || {}} //este intotdeauna setat
                          />
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
          </>
        )}
      </div>
      <div className="e-card-footer">
        {doc.Jprop && doc.Jprop.footer ? doc.Jprop.footer : null}
      </div>
    </div>
  );
}
export default observer(AcceptanceAgreement);
