import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { getStyle } from "Utils";
import Grid from "@mui/material/Grid";

const DivCss = (props) => {
  const { item, inline, css, gridItem } = props;
  var offset = null;
  //console.log("DivCss", props);
  const jprop = item.Jprop || {};
  jprop.col = jprop.col || {};
  if (typeof jprop.col == "number") {
    jprop.col = { md: jprop.col };
  }

  jprop.col.xs = jprop.col.xs ?? 12;
  jprop.col.sm = jprop.col.sm ?? 12;
  jprop.col.md = jprop.col.md ?? 3;
  jprop.col.lg = jprop.col.lg ?? jprop.col.md;
  jprop.col.xl = jprop.col.xl ?? jprop.col.lg;

  if (jprop.offset) {
    offset = jprop.offset;
    if (typeof offset == "number") {
      offset = { md: offset };
    }

    offset.xs = offset.xs ?? 12;
    offset.sm = offset.sm ?? 12;
    offset.md = offset.md ?? 3;
    offset.lg = offset.lg ?? offset.md;
    offset.xl = offset.xl ?? offset.lg;
  }

  const styleProps = getStyle(item.eventProps);
  var className = jprop.className ? jprop.className : "";
  var cssLabel =
    css && css.field && css.field.label ? css.field.label : "inline-label";
  cssLabel = `doc-field ${!inline ? cssLabel : ""} ${className}`;

  const CompDivCss = gridItem
    ? styled(Grid)`
        ${styleProps.field}
      `
    : styled.div`
        ${styleProps.field}
      `;

  //const CompDivCss = gridItem? Grid:<div/>;

  //console.log(item.Caption, {...props});
  return (
    <>
      {gridItem && jprop.col.new && <div className="row-break"></div>}
      {offset && (
        <Grid
          item
          xs={offset.xs}
          sm={offset.sm}
          md={offset.md}
          lg={offset.lg}
          xl={offset.xl}
        />
      )}
      <CompDivCss
        children={props.children}
        item
        xs={jprop.col.xs}
        sm={jprop.col.sm}
        md={jprop.col.md}
        lg={jprop.col.lg}
        xl={jprop.col.xl}
        className={cssLabel}
      />
    </>
  );
};
export default observer(DivCss);
