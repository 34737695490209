import React from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { TextFieldMask } from "TemplateCerere";
import { PasswordField } from "TemplateCerere";
import { onEnter } from "Utils";
//import "../../index.css";

function PhonePage(props) {
    const {
      updateValue,
      codeRequest,
      phone,
      smsCode,
      timeLeft,
      sendPhone,
      confirmPhone,
      name,
      cnp,
    } = props;

    return (
      <div className="form">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nume si prenume"
          value={name && name.value ? name.value : ""}
          onChange={(prop) =>
            updateValue("name", prop.target.value.toUpperCase())
          }
          className="login-txt"
          error={!!name.error}
          helperText={
            name.error || "Verificati numele si prenumele dumneavoastra!"
          }
        />
        <PasswordField
          autoFocus
          id="cnp"
          label="CNP *"
          defaultValue={cnp && cnp.value ? cnp.value : ""}
          className="login-pwd"
          variant="outlined"
          onBlur={(prop) => updateValue("cnp", prop.target.value)}
          mask="9999999999999"
          maskChar=""
          error={!!cnp.error}
          helperText={cnp.error}
          type="number"
        />
        <TextFieldMask
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Nr Telefon *"
          defaultValue={phone && phone.value ? phone.value : "07"}
          onBlur={(prop) => updateValue("phone", prop.target.value)}
          className="phone"
          mask="0799999999"
          maskChar=""
          error={!!phone.error}
          helperText={phone.error}
          type="tel"
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className="submit"
          onClick={sendPhone}
          //disabled={!phone}
        >
          {!codeRequest && smsCode ? "Retrimite cod" : "Trimite cod"}
        </Button>
        <div className="hlist">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            name="Cod"
            label="Cod"
            value={smsCode ? smsCode : ""}
            onChange={(prop) => updateValue("smsCode", prop.target.value)}
            autoFocus
            className="login-code"
            disabled={codeRequest}
            onKeyPress={(event) => onEnter(event, confirmPhone)}
            type="number"
          />
          <TextField
            variant="outlined"
            margin="normal"
            id="valabilitate"
            label=""
            name="Time"
            value={timeLeft}
            className="login-time disabled"
            disabled
          />
        </div>
      </div>
    );
  }
export default observer(PhonePage);
