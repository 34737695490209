import React from "react";
import { observer } from "mobx-react";
import Tooltip from "@mui/material/Tooltip";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  DoubleArrow,
  Save,
} from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useMediaQuery from "@mui/material/useMediaQuery";

//import "./index.css";

function StepBar(props) {
  const { countSteps, currentStep, sectiuni, nextPhase } = props;
  const { save, goStep, stepName, endJourney, canSave, canPass } = props;
  const matches = useMediaQuery("(min-width:700px)");

  return (
    <div className="main-toolbar">
      <div className="toolbar-filter-btns">
        <div className="stepper">
          {/*<div className="stepper-details" >{`${stepName(sectiuni[currentStep].Denumire)}`}</div>*/}
          <div className="stepps">
            <Tooltip title={currentStep === 0 ? "" : "Navigheaza inapoi"}>
              <Fab
                size="small"
                onClick={() => {
                  currentStep !== 0 && goStep(currentStep - 1);
                }}
                disabled={currentStep === 0}
                className={"step " + (countSteps === 0 ? "" : "nav")}
              >
                <KeyboardArrowLeft />
              </Fab>
            </Tooltip>
            {sectiuni.length > 40 || !matches ? (
              sectiuni.map((sectiune, index) => (
                <Tooltip title={stepName(sectiune.Denumire)} key={`st${index}`}>
                  <Fab
                    size="small"
                    /*onClick={()=>goStep(index)}*/ className={
                      index === currentStep ? "step active" : "step"
                    }
                  >
                    {index + 1}
                  </Fab>
                </Tooltip>
              ))
            ) : (
              <Stepper activeStep={currentStep} alternativeLabel>
                {sectiuni.map((sectiune, index) => (
                  <Step key={sectiune.Id}>
                    <StepLabel>{stepName(sectiune.Denumire)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            {(countSteps !== currentStep + 1 || !(canPass || canSave)) && ( //se afiseaza daca nu suntem la sfaRsitul listei de sectiuni sau pe tabul implicit
              <Tooltip title={"Navigheaza Inainte"}>
                <Fab
                  size="small"
                  onClick={() => goStep(currentStep + 1)}
                  className={
                    "step " + (countSteps === currentStep + 1 ? "" : "nav")
                  }
                  disabled={countSteps === currentStep + 1}
                >
                  <KeyboardArrowRight />
                </Fab>
              </Tooltip>
            )}

            {countSteps === currentStep + 1 && //se afiseaza daca suntem pe tabul implicit care este editabil
              (canPass || canSave) && //si pot sa trec la un pas urmator sau pot sa salvez
              (endJourney || !nextPhase.Denumire ? (
                <Tooltip title="Salveaza">
                  <Fab
                    size="small"
                    onClick={() => save(currentStep, true, true)}
                    className="step finish"
                  >
                    <Save />
                  </Fab>
                </Tooltip>
              ) : (
                <Tooltip title={nextPhase.Denumire}>
                  <Fab
                    size="small"
                    onClick={() => save(currentStep, true, false)}
                    className="step nav"
                    variant="extended"
                  >
                    <DoubleArrow />
                    {nextPhase.Denumire}
                  </Fab>
                </Tooltip>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
  //    }
}
export default observer(StepBar);
