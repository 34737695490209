/* eslint-disable eqeqeq */
import { action, extendObservable, set, remove } from "mobx";
import {
  dataType,
  getValueOfList,
  getValueKey,
  setupEvent,
  escapeXML,
} from "Utils";
import moment from "moment";

const modelCommon = {
  liste: [],
  allFields: {},
  changedFields: {},
  goPrev: null,
  warning: { text: null, title: null, severity: null },
  tab: [],
  sec: [],
  doc: [],
  head: [],
  fld: [],
};

class CommonCerereStore {
  constructor(props) {
    this.rootStore = props;
    //this.allFields= {};
    //this.rootStore.api = new Api({ logout: props.mainStore.logout });
    extendObservable(this, modelCommon);
  }

  setupFields = (_setupFields, props) => {
    _setupFields.forEach((cimp) => {
      this.setupField(cimp, props);
    });
  };
  /*setarea initiala a proprietatilor cimpului*/
  setupField(item, cerere) {
    //item.defaultValue = item.Valoare ? item.Valoare : "";
    /*if (item.Obligatoriu && cerere.inline) {
      item.Caption += " *";
    }*/
    item.Jprop = item.Jprop || {};
    item.error = null;
    item.isVisible = true;
    item.isReadonly = item.Incarcare == 0 || item.docReadOnly;
    item.eventProps = {};
    item.Caption = item.Jprop.caption || item.Caption;
    item.displayText = function () {
      return item.Valoare;
    };
    cerere.allFields = this.allFields; //De ce apare chestia asta?
    cerere.changedFields = this.changedFields; //De ce apare chestia asta?

    if (item.Tip === 4) {
      item.visibleList = function () {
        return item.listData || [];
      };
      //lista de selectie
      switch (Math.floor(item.Lungime / 10)) {
        case 1:
          /*set(item, {
            listData: item.coldef?.dt?.Rows,
            fields: item.coldef?.dt.Columns.map((c) =>
              c.ColumnName.toUpperCase()
            ),
          });*/

          item.listData = item.coldef?.dt?.Rows;
          item.fields = item.coldef?.dt.Columns.map((c) =>
            c.ColumnName.toUpperCase()
          );
          break;
        case 2:
          item.listData = item.coldef.list;
          item.fields = ["COD", "DENUMIRE"];
          break;
        case 3:
          var l = this.getLista(item.SursaLista);
          if (l) {
            item.listData = l.rows;
            item.fields = l.cols;
          }
          break;
        default:
          break;
      }

      /*verific daca are relatie de masterId*/
      if (item.coldef && item.coldef.Prop.masterlistid) {
        item.masterId = item.coldef.Prop.masterlistid;
        item.masterFields = item.coldef.Prop.masterlistfields
          .toUpperCase()
          .split(";");
        item.detailFields = item.coldef.Prop.detaillistfields
          .toUpperCase()
          .split(";");

        var m = this.allFields[item.masterId];
        if (m) {
          m.detailIds = m.detailIds || [];
          if (m.detailIds.indexOf(item.Id) === -1) {
            m.detailIds.push(item.Id);
          }

          item.visibleList = function () {
            var mv = m.Valoare;
            if (!m.listData) {
              var df = item.fields.indexOf(item.detailFields[0]);
              if (df < 0) {
                return [];
              } else {
                return item.listData.filter((f) => mv == f[df]);
              }
            }
            var mkey = m.listData.find((i) => i[0] == mv);
            if (!mkey) {
              //master nu are selectata nici o valoare
              return [];
            }
            var mf = item.masterFields.map((a) => {
              var i = m.fields.indexOf(a);
              return mkey[i];
            });
            return item.listData.filter((f) => {
              var df = item.detailFields.map((a) => item.fields.indexOf(a));
              return mf.every((v, index) => v == f[df[index]]);
            });
          };
        }
      }

      item.displayText = function (column) {
        column = column ?? 1;
        var v = getValueOfList(item.listData || [], item.Valoare);
        if (v) {
          if (v.fields) {
            return v[v.fields[column]];
          }
          if (v.length > column) {
            return v[column];
          }
        }
        return item.Valoare;
      };
    }

    if (item.Tip === 6) {
      //grid
      this.getDataTable(
        item
      ); /* TO DO ce functie folosesc, se poate generaliza?! */
    }

    /*Colectare evenimente*/
    if (item.Eveniment) {
      this.collectEvents(
        item,
        cerere
      ); /* TO DO ce se trimite la this, sa trimit doarce e necesar */
    }
    if (item.Tip === 6) {
      item.data.columns
        .filter((c) => c.Eveniment)
        .forEach((c) => {
          this.collectEvents(item, cerere, c);
        });
    }
  }

  mergeDocs(oldDoc, newDoc, cerere) {
    var _setupFields = [];
    /*Adaug documentele noi*/
    if (!oldDoc && newDoc) {
      console.log("Doc add", newDoc);
      //newDoc se adauga in lista de documente
      this.doc.push(newDoc);
      //adaug headerele noi
      if (cerere?.data?.head) {
        this.head.push(
          ...cerere.data.head.filter((head) => head.doc == newDoc.Id)
        );
      }
      /*Pun cimpurile noi in lista de cimpuri ce trebuie procesate*/
      cerere?.data?.fld.forEach((fld) => {
        _setupFields.push(fld);
      });
    } else if (oldDoc && !newDoc) {
      /*Sterg documentele care nu mai exista*/
      console.log("Doc delete", oldDoc);
      //oldDoc se sterge
      var docId = oldDoc.Id;
      this.doc.remove(oldDoc);
      /*sterg headerele care au oldDoc*/
      this.head
        .filter((head) => head.doc == docId)
        .forEach((head) => this.head.remove(head));
      /*sterg campurile cu oldDoc*/
      this.fld
        .filter((fld) => fld.doc == docId)
        .forEach((fld) => {
          delete this.allFields[fld.Id];
          this.fld.remove(fld);
        });
    } else if (oldDoc && newDoc) {
      /*Modific documentele care existau deja*/
      console.log("Doc change", oldDoc, newDoc);
      /*Schimb vechiul document cu unul nou in lista this.doc*/
      var i = this.doc.findIndex((f) => f.Id === oldDoc.Id);
      this.doc[i] = newDoc;
      /*sterg headerele de pe documentul afectat*/
      this.head
        .filter((head) => head.doc === oldDoc.Id)
        .forEach((head) => this.head.remove(head));
      /*si adaug headerele venite din request*/
      if (cerere?.data?.head) {
        this.head.push(
          ...cerere.data.head.filter((head) => head.doc == newDoc.Id)
        );
      }
      var newFld = cerere?.data?.fld || [];
      /*Campurile care nu exista le sterg*/
      this.fld
        .filter(
          (fld) =>
            fld.doc === docId && newFld.findIndex((n) => n.Id === fld.Id) === -1
        )
        .forEach((fld) => {
          delete this.allFields[fld.Id];
          this.fld.remove(fld);
        });
      /*Adaug sau modific cimpurile primite*/
      _setupFields.push(...newFld.filter((fld) => fld.doc == newDoc.Id));
    }
    _setupFields.forEach((fld) => {
      fld.docReadOnly =
        this.doc.find((doc) => doc.Id === fld.doc)?.Incarcare === 0;
      var i = this.fld.findIndex((f) => f.Id === fld.Id);
      if (i === -1) {
        i = this.fld.push(fld); //adaug cimpul si aflu noua dimensiune
        i--;
      }
      this.fld[i] = fld;
      /*Pregatesc cimpurile venite din request*/
      this.setupField(this.fld[i], cerere);
      /*Le inlocuiesc in allFields*/
      this.allFields[fld.Id] = this.fld[i];
      /*Le schimb si in lista de cimpuri*/
    });

    /*Refac relatii de master/detail la griduri : TODO E putin excesiv, dar nu ma incurca*/
    this.fld
      .filter((d) => d.masterId) //este detail pentru un alt grid
      .forEach((d) => {
        //marchez in master care sunt gridurile detail
        var m = this.getField(d.masterId);
        d.master = m;
        m.detailIds = m.detailIds || [];
        if (m.detailIds.indexOf(d.Id) == -1) {
          m.detailIds.push(d.Id);
        }
      });
  }

  @action
  getField = (id) => {
    //var cimp = this.allFields.find(x => x.id == id);
    //return this.allFields[id];
    //console.log(this);
    return this.fld.find((x) => x.Id == id);
  };

  getHeaders = (doc) => {
    return this.head.filter((x) => x.doc == doc);
  };

  getFields = (head) => {
    return this.fld.filter((x) => x.head == head.Id && head.doc == x.doc);
  };

  getDataTable = (item) => {
    var columns = [];
    var rows = [];
    var _allFields = this.allFields;

    item.selectedRow = item.selectedRow || -1;
    item.height = -1;

    //safe
    item.grid.Definition.GRID = item.grid.Definition.GRID || {};
    item.grid.Definition.GRID.Prop = item.grid.Definition.GRID.Prop || {};

    var props = item.grid.Definition.GRID.Prop;

    if (props.masterid > 0) {
      item.masterId = props.masterid;
      item.masterFields = props.masterfields?.toUpperCase().split(";") || [];
      item.detailFields = props.detailfields?.toUpperCase().split(";") || [];
    }
    //determina daca este inregistrare e vizibila. Pentru MasterDetail se uita la valorile din gridul master
    if (!item.isVisibleRow) {
      item.isVisibleRow = function (row) {
        if (item.masterId) {
          console.log(
            "isVisibleRow",
            "master",
            item.masterId,
            "detail",
            item.Id
          );
          var m = _allFields[item.masterId],
            mr;
          if (m.Tip == 6) {
            //master e un tabel
            var ix = m.selectedRow;
            if (ix == -1) {
              //nu e nici o linie selectata in master

              if (m.data.rows.length > 0) {
                ix = 0; //iau date din prima linie
              } else {
                //nu exista inregistrari in master, nu afisez nimic
                return false;
              }
            }
            mr = m.data.rows[ix];
          } else {
            //e combobox
            var mv = m.Valoare;
            mr = m.listData.find((i) => i[0] == mv);
          }
          if (!mr) {
            return false;
          }
          return item.masterFields.every((mField, index) => {
            var mf = mField;
            if (m.Tip == 4) {
              mf = m.fields.indexOf(mField);
            }
            // fiecare cimp din master e egal cu cimpul corespondent din detail
            return (
              mr[mf].toString().toUpperCase() ==
              row[item.detailFields[index]].toString().toUpperCase()
            );
          });
        } else {
          return true;
        }
      };
    }
    item.isReadonly = item.isReadonly || props.readonly == 1;
    item.append =
      !item.isReadonly && (props.append == 1 || props.append == null);
    item.delete =
      !item.isReadonly && (props.delete == 1 || props.delete == null);
    item.edit = !item.isReadonly && (props.edit == 1 || props.edit == null);
    item.height = (props.height || item.height) * 1;
    item.download = props.download == 1;
    item.editInPlace = item.editInPlace || props.editinplace == 1;
    /*****Neimplementate
     * wide
     * PropTable
     * ReadOnlyFor
     * SumFields
     * AvgFields
     * CountFields
     * Width
     * Height
     * MasterCaption
     */

    item.limit = props.limit || 0;

    if (item.grid.dt.Columns) {
      var _detailFields = (props.isdetailfields || "").toUpperCase().split(",");
      var _keepinGridFields = (props.keepingrid || "").toUpperCase().split(",");
      var _readonlyFields = (props.readonlyfields || "")
        .toUpperCase()
        .split(",");

      item.grid.dt.Columns.forEach((col) => {
        var _colName = col.ColumnName.toUpperCase();
        var colDef = item.grid.Definition[_colName];
        col.key = _colName;
        col.isVisible = _colName !== "ID_CERERE";
        col.isVisibleEdit = col.isVisible; //coloana e vizibila in macheta de editare?
        col.isReadonly = _readonlyFields.indexOf(_colName) > -1;
        col.isDetail = _detailFields.indexOf(_colName) > -1;
        col.keepinGrid = _keepinGridFields.indexOf(_colName) > -1;
        col.Caption = col.ColumnName;
        // se preia proprietatea de vizibilitate pentru coloanele care o au
        if (colDef) {
          /**********Ramas de implementat - pe masura ce avem nevoie de ele
           * Save
           * ReadOnlyFor
           * Total
           * Align
           * DisplayFormat
           * Info
           * SingleValue
           * Autofill
           * AllowText
           * Width
           * Lines
           * IsDetailTable
           * ReadOnlyFor
           * Default Values => 'Operator', 'NumeOperator', 'Sucursala', 'NumeSucursala'
           */

          // se preia denumirea coloanei asa cu se doreste sa apara pe ecran (daca exista)
          colDef.Prop = colDef.Prop || {};
          col.Caption = colDef.Prop.title || col.ColumnName;
          col.isReadonly = colDef.Prop.readonly == 1 || col.isReadonly;
          col.inline = colDef.Prop.inline == 1;
          col.width = colDef.Prop.width;
          col.isVisible =
            colDef.Prop.visible != undefined
              ? colDef.Prop.visible == 1 || colDef.Prop.visible == 3
              : col.isVisible;
          col.isVisibleEdit =
            colDef.Prop.visible == 3
              ? false
              : colDef.Prop.visible == 2
              ? true
              : col.isVisible;
          col.isSelected =
            colDef.Prop.select ||
            colDef.Prop.list ||
            colDef.Prop.headerselector;
          col.save = colDef.Prop.save == 0 ? false : true;
          col.displayFormat = colDef.Prop.displayformat;
          col.list = colDef.Prop.list;
          col.isSelectList = colDef.Prop.headerselector || colDef.Prop.select;
          col.lista = col.isSelectList && colDef.dt ? colDef.dt.Rows : null;
          col.fields =
            col.isSelectList && colDef.dt
              ? colDef.dt.Columns.map((c) => c.ColumnName.toUpperCase())
              : null;
          col.isDetail = colDef.Prop.isdetail || col.isDetail;
          col.keepinGrid = colDef.Prop.keepingrid || col.keepinGrid;
          col.default = colDef.Prop.default;
          col.autoinc = colDef.Prop.autoinc;
          col.headerSelector = colDef.Prop.headerselector;
          if (colDef.Prop.helpertext) {
            col.helperText = colDef.Prop.helpertext;
          }
          if (colDef.Prop.masterlistid) {
            col.masterId = colDef.Prop.masterlistid.toUpperCase();
            col.masterFields = colDef.Prop.masterlistfields
              .toUpperCase()
              .split(";");
            col.detailFields = colDef.Prop.detaillistfields
              .toUpperCase()
              .split(";");
            /*            console.log('Column master ID', col.key);
                        console.log(col.masterListId);
                        console.log(col.masterFields);
                        console.log(col.detailFields);
            */
          }
          col.Eveniment = colDef.Eveniment;
        }
        //pentru coloanele care nu au titlu, textul se tranforma in Sent case si se inlocuieste "_" cu " "
        col.Caption =
          col.Caption === col.ColumnName
            ? col.Caption.substr(0, 1) +
              col.Caption.substr(1).split("_").join(" ").toLowerCase()
            : col.Caption;

        //se extrage denumirea coloanei parent si a coloanelor child
        col.index = item.grid.dt.Columns.indexOf(col);

        //se contruieste setul de date "columns" care cuprinde definitia coloanelor
        var column = {};
        column.save = col.save;
        column.isVisible = col.isVisible;
        column.isVisibleEdit = col.isVisibleEdit;
        column.isReadonly = col.isReadonly;
        column.key = col.ColumnName.toUpperCase();
        column.ColumnName = col.ColumnName;
        column.Caption = col.Caption;
        column.list = col.list;
        column.isSelectList = col.isSelectList;
        column.lista = col.lista;
        column.fields = col.fields;
        column.formatProp = dataType(col.DataType, col.displayFormat);
        column.span = {};

        column.isDetail = col.isDetail;
        column.keepinGrid = col.keepinGrid;
        column.defaultValue = col.default;
        column.autoinc = col.autoinc;
        column.headerSelector = col.headerSelector;
        column.inline = col.inline;
        column.helperText = col.helperText;

        var width = col.width ? col.width : 200;
        width = width < 150 ? 150 : width;
        column.width = width;
        if (colDef && colDef.Prop.editmask) {
          column.editMask = colDef.Prop.editmask;
        }
        if (colDef && colDef.Prop.mask) {
          column.mask = colDef.Prop.mask;
        }
        if (colDef && colDef.Prop.textTransform) {
          column.textTransform = colDef.Prop.textTransform;
        }
        column.tip = "t";
        if (column.list) {
          column.tip = "l";
        }
        if (column.formatProp.type === "date") {
          column.tip = "d";
        }
        if (column.formatProp.format === "B") {
          column.tip = "c";
        }
        if (column.formatProp.format === "N2") {
          column.tip = "n";
        }
        if (column.isSelectList) {
          column.tip = "s";
        }
        if (column.list /* && column.isVisible*/) {
          var l = this.getLista(column.list.toUpperCase());
          column.lista = l.rows;
          column.fields = l.cols;
        }

        if (col.masterId) {
          column.masterId = col.masterId;
          column.masterFields = col.masterFields;
          column.detailFields = col.detailFields;
        }

        column.visibleList = function () {
          return this.lista || [];
        };
        columns.push(column);

        var hExpanded = true,
          hInline = false,
          hText = "";
        if (column.Caption.includes("|")) {
          //verific daca am nevoie sa fac un nou header
          var hCaption = column.Caption;
          hExpanded = hCaption.startsWith("[-]");
          hCaption = hCaption.replace("[-]", "");
          hInline = hCaption.startsWith("~");
          hCaption = hCaption.replace("~", "");
          hText = hCaption.split("|")[0];
          column.Caption = hCaption.split("|")[1];
          column.header = { text: hText, expanded: hExpanded, inline: hInline };
        }
        column.Eveniment = col.Eveniment;
      });
    }

    /*prelucrare tabele master-detail*/
    columns
      .filter((d) => d.masterId)
      .forEach((d) => {
        //marchez in master care sunt controalele detail
        var m;
        var id = d.Id;
        if (isNaN(d.masterId)) {
          m = columns.find((c) => c.key === d.masterId);
        } else {
          m = _allFields[d.masterId];
          id = item.id + "." + d.Id;
        }
        m.detailIds = m.detailIds || [];
        if (m.detailIds.indexOf(id) === -1) {
          m.detailIds.push(id);
        }

        d.visibleList = function (row) {
          var mv = isNaN(m.Id) ? row[m.key] : m.Valoare;
          var l = isNaN(m.Id) ? m.lista : m.listData;
          if (!l) {
            var df = d.fields.indexOf(d.detailFields[0]);
            if (df < 0) {
              return [];
            } else {
              return d.lista.filter((f) => mv == f[df]);
            }
          }
          var mkey = l.find((i) => i[0] == mv);
          if (!mkey) {
            //master nu are selectata nici o valoare
            return [];
          }
          var mf = d.masterFields.map((a) => {
            var i = m.fields.indexOf(a);
            return mkey[i];
          });
          return d.lista.filter((f) => {
            var df = d.detailFields.map((a) => d.fields.indexOf(a));
            return mf.every((v, index) => v == f[df[index]]);
          });
        };
      });

    // se construieste setul de date "rows" prin maparea indexului aferent coloanei fiecarui rand cu indexul aferent coloanelor definite in "columns"
    item.grid.dt.Rows.forEach((row, index) => {
      var newRow = { _rowid: index };
      item.grid.dt.Columns.forEach((col) => {
        var newValue = row[col.index];
        newValue =
          col.DataType === "DateTime" && newValue
            ? moment(newValue)
                .format("DD/MM/YYYY HH:mm:ss")
                .replace(" 00:00:00", "")
            : newValue;
        newRow[col.ColumnName.toUpperCase()] = newValue; // !== null && newValue !== undefined ? newValue : "";
      });
      rows.push(newRow);
    });
    item._rowid = rows.length;
    var row = {};
    var hasDetails = false;
    //    var columnsDetails = [];
    if (columns.some((c) => c.isDetail)) {
      //tabel cu un singur rand in care coloanele sunt transpuse ca si randuri
      //inaltimea tabelului e 0 si exista cimpuri cu isDetail=true
      //se preia primul rand din tabel
      row = rows[0]; //asta e incorect

      // preiau informatiile pentru coloanele necesare din definitia tabelului
      //      columnsDetails = columns.filter(c => c.isDetail);

      //se marcheaza ca are si coloane de tip details
      hasDetails = true;
    }

    //se marcheaza ca procesat astefl incat sa nu se mai apeleze
    item.isProcess = true;

    //se adauga obiectul cu datele necesare aferente tabelului
    item.data = {
      rows: rows,
      columns: columns,
      row: row,
      hasDetails: hasDetails,
      selectedHeader:
        columns.findIndex((c) => c.headerSelector) > -1 ? "" : null, //daca am cimp cu header selector
    };
  };

  @action
  getLista = (lista) => {
    /*
    var rows = this.liste[lista.toUpperCase()];
    return { rows: rows.map((r) => Object.values(r)), cols: Object.keys(rows[0]) };
    */
    return this.liste[lista.toUpperCase()];
  };

  @action
  filterSursaLista = async (item, filter) => {
    var result = await this.rootStore.api.post("/api/request/filtrare-lista", {
      //type: this.type,
      uidCerere: this.allFields["uidCerere"],
      id: item.Id,
      idClient: this.idClient,
      filter: filter,
    });

    //item.filter = filter;
    item.listData = result;
    /*Daca valoarea controlului nu e in noua lista valoarea devine ""*/
    if (
      !item.isReadonly &&
      item.Valoare &&
      !item.listData.find((x) => x[item.fields[0]] == item.Valoare)
    ) {
      item.Valoare = "";
      item.isModified = true;
      this.changedFields[item.Id] = "";
    }
    /*
    if (Math.floor(item.Lungime / 10) === 1) item.coldef.dt.Rows = result;
    if (Math.floor(item.Lungime / 10) === 2) item.coldef.list = result;
    if (Math.floor(item.Lungime / 10) === 3)
      this.getLista(item.SursaLista.toUpperCase()).rows = result;
    */
  };

  queryData = async (reason, par, callback, fallback) => {
    var store = this.rootStore.cerereStore.type
      ? this.rootStore.cerereStore
      : this.rootStore.cerereWebStore;
    var DT = {
      type: store.type,
      uidCerere: store.uidCerere,
      reason: reason,
      param: par,
    };
    var result = await this.rootStore.api.post("/api/request/querydata", DT);
    //console.log(result);
    if (result) {
      if (result.error || !!result.r) {
        this.rootStore.mainStore.errors.open(
          result.msg ?? result.error,
          false,
          fallback
        );
        this.rootStore.mainStore.isWaiting = false;
        //result = { err: result.msg ?? result.error };
        return;
      } else {
        //alert("My result: " + JSON.stringify(result));
        //return result;
      }
    } else {
      this.rootStore.mainStore.errors.open("Eroare necunoscuta la queryData");
      //result = { err: "Eroare necunoscuta la queryData" };
      this.rootStore.mainStore.isWaiting = false;
      return;
    }
    if (typeof callback == "function") {
      callback(result);
    }
    return result;
  };

  collectEvents = function (item, ctx, col) {
    const _ctx = ctx;
    const _this = this;
    const queryData = this.queryData;
    const eveniment = (col ?? item).Eveniment;
    function getColumn(key) {
      return item.data.columns.find((x) => x.key == key);
    }
    function getCell(key) {
      return item.data.row[key] || "";
    }

    function getCellDisplay(key, column) {
      column = column ?? 1;
      if (item.Tip == 6) {
        //tabel
        return (
          getValueKey(
            getValueOfList(getColumn(key).lista, item.data.row[key]),
            column
          ) || ""
        );
      } else {
        var k = _ctx.allFields[key];
        return getValueKey(getValueOfList(k.listData, k.Valoare), column) || "";
      }
    }
    /*  
      function updateCell(key, valoare) {
        set(item.data.row, { [key]: valoare });
      }
    */
    const showMessage = (m) => {
      //_ctx.rootStore.mainStore.errors.open(m);
      alert(m);
    };

    const displayWarning = (mx, add) => {
      var w = {};
      var t = _this.warning?.text;
      [w.text, w.title, w.severity] = mx.split("|");
      if (add) {
        w.text = t + "\n" + w.text;
      }
      set(_this.warning, w);
    };

    function eventParse(obj) {
      //let AsyncFunction = Object.getPrototypeOf(async function(){}).constructor
      var f;

      const itemHandler = {
        get(target, key) {
          if (key == "isProxy") return true;
          if (key == "__target__") {
            return target;
          }
          const prop = target[key];
          // return if property not found
          if (typeof prop == "undefined") return;
          // set value as proxy if object
          if (typeof target[key] === "object" && target[key] !== null) {
            return new Proxy(target[key], itemHandler);
          } else {
            return target[key];
          }
        },
        set: function (obj, prop, value) {
          if (prop == "Valoare") {
            /*nu marchez ca modificate cimpurile de tip memo*/
            if (obj.Tip !== 8) {
              obj.isModified = true;
              obj.error = null;
              _ctx.changedFields[obj.Id] = value;
            }
          }
          if (prop == "isReadonly") {
            obj.Incarcare = !value;
          }
          if (prop == "Incarcare") {
            obj.isReadonly = !value;
          }
          obj[prop] = value;
          return true;
        },
      };

      var objProxy = new Proxy(obj, itemHandler);
      var allFieldsProxy = new Proxy(_ctx.allFields, itemHandler);
      try {
        // eslint-disable-next-line no-new-func
        f = new /*Async*/ Function(
          "item",
          "ctrlCol",
          "ctrlRow",
          "ctrlDisplay",
          "ctrl",
          "queryData",
          "showMessage",
          "displayWarning",
          '"use strict";return ( ' + eveniment + ")"
        )(
          objProxy,
          getColumn,
          getCell,
          getCellDisplay,
          allFieldsProxy,
          queryData,
          showMessage,
          displayWarning
        );
      } catch (error) {
        console.log(item.Id, col ? col.key : null);
        console.log(eveniment);
        console.log(error);
      }
      return f;
    }

    if (!eveniment) {
      return;
    }
    try {
      var eventDef = eventParse;
      var result = eventDef(item); //rulez sa vedem ce iese, am functie sau am array
      if (typeof result === "object" && !Array.isArray(result)) {
        Object.keys(result).forEach((key) => {
          setupEvent(getColumn(key), result[key]);
        });
      } else {
        setupEvent(col ?? item, result, eventDef);
      }
    } catch (e) {
      console.log("collect event", [eveniment, col ?? item, e]);
    }
  };

  saveDataTable = (changedTabels) => {
    //daca sunt tabele modificate construiesc xml-ul cu liniile acestora
    changedTabels.forEach((id) => {
      var body = "";
      var item = this.getField(id);
      item.data.rows.forEach((row) => {
        var line = "";
        item.data.columns
          .filter((c) => c.save)
          .forEach((x) => {
            var v = row[x.key];
            if (v != undefined) {
              if (x.tip == "d" && v) {
                v =
                  v == "01/01/1900"
                    ? ""
                    : moment(v, "DD/MM/YYYY HH:mm:ss").format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
              }

              v = escapeXML(v);

              line = `${line} ${x.ColumnName}="${v}" `;
            }
          });

        body = `${body}<R ${line}></R>`;
      });
      body = `<D>${body}</D>`;
      this.changedFields[item.Id] = body;
    });
  };

  setPrintParameters = (headers, cerere) => {
    const docId = "printParameters";
    //toate  campurile trebuie sa fie atasate de un document, pentru parametrii de printare inserez un document dummy
    var doc = this.doc.find((d) => d.Id == docId);
    if (!doc) {
      this.doc.push({ Id: docId });
    }

    //sterg parametrii toti parametrii de print existenti
    this.fld
      .filter((f) => f.doc == docId)
      .forEach((d) => {
        this.fld.remove(d);
        delete this.allFields[d.Id];
      });

    // transform campurile legate de header in campuri legate de documentul dummy definit mai sus
    headers.forEach((header) => {
      header.Cimpuri.forEach((cimp) => {
        cimp.doc = docId;
        cimp.head = "P" + cimp.head;
        this.setupField(cimp, {
          type: cerere.type,
          uidCerere: cerere.uidCerere,
        });
        if (!this.allFields[cimp.Id]) {
          this.fld.push(cimp);
        }
        this.allFields[cimp.Id] = cimp;
      });
      header.doc = docId;
      header.Id = "P" + header.Id;
      var head = this.head.find((h) => h.Id == header.Id && h.doc == docId);

      if (!head) {
        this.head.push(header);
      }
    });
  };
  clear = () => {
    set(this, modelCommon);
    //this.allFields= {};
  };
}
export default CommonCerereStore;
