import React from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
import { ArrowRight } from "@mui/icons-material";
import Button from "@mui/material/Button";

function EmailPage(props) {
  const { updateValue, email, email2, name, handlerChangePage } = props;

  return (
    <>
      <p className="page-title">{name.value}, care este adresa ta de e-mail?</p>
      <p className="normal-text">
        Pe adresa de e-mail vei primi informatii legate de logare si detalii
        despre produsele noastre.
      </p>

      <div className="form">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="Email"
          value={email && email.value ? email.value : ""}
          onChange={(prop) => updateValue("email", prop.target.value)}
          helperText={email.error || "Adresa trebuie sa fie scrisa corect"}
          error={!!email.error}
          className="login-txt"
          type="email"
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email2"
          label="Confirmare Email"
          name="Email2"
          defaultValue={email2 && email2.value ? email2.value : ""}
          onChange={(prop) => updateValue("email2", prop.target.value)}
          className="login-txt"
          helperText={
            email2.error ||
            "Confirmarea adresei de email folosita pentru corespondenta"
          }
          error={!!email2.error}
          type="email"
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </div>
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={handlerChangePage}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Confirma e-mail
      </Button>
    </>
  );
}

export default observer(EmailPage);
