import React from "react";
import { observer } from "mobx-react";
import { ArrowRight } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextFieldMask from "../components/textFieldMask";
import { NumericFormat } from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { IconsSvg, TooltipLine } from "TemplateCerere";
import "./CrediteSimulare.scss";

const CrediteSimulareProduse = (props) => {
  const {
    state,
    comisioane,
    simulare,
    interval_scadenta,
    isRequest,
    title,
    subtitle,
    fields,
    jprop,
  } = props;
  const {
    setPerioada,
    setSuma,
    updateState,
    arataGrafic,
    updateValue,
    viewAcord,
  } = props;
  const { docAsigurare } = jprop.documents || {};
  const isReadonly =
    fields && (fields.simulare.isReadonly || fields.simulare.docReadOnly);
  //const asigurare = state.arataAsigurare === null ? false : jprop.asigurare ?? true;
  const autocalc = jprop.autocalc ?? true;
  const btnNextName =
    jprop.btnNextName ?? "Arata grafic de rambursare estimativ";

  return (
    <>
      <IconsSvg icon="IllustrationHello" />
      <p className="page-title">{title}</p>
      <p className="subtitle-text">{subtitle}</p>
      {state.credit && (
        <>
          <div className="simulare">
            <div className="simulare-doc">
              <div className="doc-table-simulare">
                <div className="label-title">
                  {`De ce suma ai nevoie (${state.credit.valuta}):`}
                </div>
                <div className="slider-group">
                  <div className="value-slider">
                    <Slider
                      value={state.suma}
                      step={1000}
                      onChange={(event, value) => {
                        updateState({ suma: value });
                      }}
                      onChangeCommitted={(event, value) => {
                        setSuma(value);
                      }}
                      valueLabelDisplay="auto"
                      //valueLabelDisplay="on"
                      valueLabelFormat={(value) => (
                        <NumericFormat
                          value={value}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          renderText={(value) => (
                            <div className="percent">{value}</div>
                          )}
                        />
                      )}
                      marks={state.intervalSuma}
                      min={state.credit.suma_minima}
                      max={state.credit.suma_maxima}
                      disabled={isReadonly}
                      className={isReadonly ? "slider-disabled" : null}
                    />
                  </div>

                  <div className="value-suma">
                    <TextFieldMask
                      id="suma"
                      className={`doc-table-value${
                        isReadonly ? " disabled" : ""
                      }`}
                      variant="outlined"
                      value={state.suma}
                      onBlur={(prop) => {
                        var value = prop.target.value
                          ? prop.target.value
                              .replace(/[.]/g, "")
                              .replace(",", ".")
                          : 0;
                        value = parseInt(value);
                        updateState({ suma: value });
                        setSuma(value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "." || event.key === ",")
                          event.preventDefault();
                      }}
                      error={!!state.error.suma}
                      helperText={state.error.suma}
                      type="decimal"
                      disabled={isReadonly}
                      isDecimal={true}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {state.credit.valuta}
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                    />
                  </div>
                </div>
              </div>

              <div className="doc-table-simulare">
                <div className="label-title">Perioada (luni)</div>
                <div className="slider-group">
                  <div className="value-slider">
                    <Slider
                      value={state.perioada}
                      step={1}
                      onChange={(event, value) =>
                        updateState({ perioada: value })
                      }
                      onChangeCommitted={(event, value) => setPerioada(value)}
                      valueLabelDisplay="auto"
                      marks={state.intervalPerioada}
                      min={state.credit.perioada_minima}
                      max={simulare.perioada_maxima}
                      disabled={isReadonly}
                      className={isReadonly ? "slider-disabled" : null}
                    />
                  </div>

                  <div className="value-suma">
                    <TextFieldMask
                      id="suma"
                      className={`doc-table-value${
                        isReadonly ? " disabled" : ""
                      }`}
                      variant="outlined"
                      value={state.perioada}
                      onBlur={(prop) => {
                        var value = prop.target.value
                          ? prop.target.value
                              .replace(/[.]/g, "")
                              .replace(",", ".")
                          : 0;
                        value = parseInt(value);
                        updateState({ perioada: value });
                        setPerioada(value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "." || event.key === ",")
                          event.preventDefault();
                      }}
                      error={!!state.error.perioada}
                      helperText={state.error.perioada}
                      type="decimal"
                      disabled={isReadonly}
                      isDecimal={true}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Luni</InputAdornment>
                        ),
                      }}
                      size="small"
                    />
                  </div>
                </div>
              </div>
              <div className="v-list">
                <div className="group-switch radio-align">
                  <div className="label-title">Tip dobanda</div>

                  <RadioGroup
                    value={state.dobanda_fixa}
                    //row//={controlType === "radio-h"}
                    row
                    onChange={(event) => {
                      updateState(
                        { dobanda_fixa: event.target.value == "true" },
                        true
                      );
                    }}
                    disabled={isReadonly}
                  >
                    {state.credit.dobanda && (
                      <TooltipLine
                        tooltip="Dobanda este calculata dupa urmatoarea formula: indice de referinta IRCC + Marja fixa de dobanda"
                        className="right"
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio color="primary" />}
                          label="variabila"
                          style={{ marginRight: 0 }}
                          disabled={isReadonly || !state.credit.dobanda}
                          //className="right"
                        />
                      </TooltipLine>
                    )}
                    {state.credit.dobanda_fixa && (
                      <TooltipLine
                        tooltip="Rata dobanzii va avea  aceeasi valoare pe toata perioada contractului"
                        className="right"
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="primary" />}
                          label="fixa"
                          //style={{ marginRight: 0 }}
                          disabled={isReadonly || !state.credit.dobanda_fixa}
                          //className="right"
                        />
                      </TooltipLine>
                    )}
                  </RadioGroup>
                </div>
                <div className="group-switch radio-align">
                  <div className="label-title">Tip rate lunare</div>

                  <RadioGroup
                    value={state.rate_egale}
                    //row//={controlType === "radio-h"}
                    row
                    onChange={(event) => {
                      updateState(
                        { rate_egale: event.target.value == "true" },
                        true
                      );
                    }}
                    disabled={isReadonly}
                  >
                    <TooltipLine
                      tooltip="Modalitate de rambursare a creditului in care valoarea ratei lunare este aceeasi in fiecare luna"
                      className="right"
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label="egale (anuitati)"
                        style={{ marginRight: 0 }}
                        disabled={isReadonly}
                      />
                    </TooltipLine>
                    <TooltipLine
                      tooltip="Modalitate de rambursare a creditului in care sumele de plata sunt in cuantum mai mare in prima perioada de derulare a creditului si au o evolutie descendenta pană la finalul perioadei de rambursare"
                      className="right"
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="descrescatoare"
                        style={{ marginRight: 0 }}
                        disabled={isReadonly}
                      />
                    </TooltipLine>
                  </RadioGroup>
                </div>

                <div className="label-title">
                  Doresc sa am o dobanda mai mica prin:
                </div>

                {state.arataAsigurare && (
                  <>
                    <div className="group-switch h-list-max">
                      <TooltipLine
                        tooltip={state.credit.info_asigurare}
                        className="label-title left full"
                      >
                        &#8226; Alegerea unui produs cu asigurare
                      </TooltipLine>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            width="100px"
                            checked={state.asigurare}
                            disabled={state.arataProduse}
                            onChange={(prop) => {
                              updateState(
                                { asigurare: prop.target.checked },
                                true
                              );
                              if (!prop.target.checked && isRequest) {
                                updateValue(fields.confirmAsigurare, "0");
                              }
                            }}
                          />
                        }
                        label={state.asigurare ? "Da" : "Nu"}
                        labelPlacement="top" //{dobandaFixa ? "start" : "end"}
                      />
                    </div>

                    {isRequest && state.asigurare && (
                      <>
                        <div className="group-switch h-list-max">
                          <TooltipLine
                            tooltip={
                              <div>
                                Prin stare de sanatate buna, in acceptiunea
                                ofertei si a conditiilor de asigurare, se
                                intelege ca un client: nu se afla in
                                incapacitate temporara de munca si nu sufera de
                                vreo boala care necesita supraveghere sau
                                tratament medical constant; de asemenea, oferta
                                de asigurare este valabila doar daca Asiguratul
                                declara pe propria raspundere urmatoarele:
                                <ul>
                                  <li>
                                    In ultimii 5 ani nu a fost spitalizat, nu a
                                    avut nicio interventie chirurgicala ca
                                    urmare a unei afectiuni cardiace sau
                                    coronariene, unei boli pulmonare (cu
                                    exceptia pneumoniei), unei boli renale (cu
                                    exceptia litiazei renale) sau unui
                                    transplant de organe si nu urmeaza sa
                                    suporte o spitalizare sau o interventie
                                    chirurgicala.
                                  </li>
                                  <li>
                                    Este intr-o stare de sanatate buna, nu are
                                    niciun handicap si in ultimii 10 ani nu a
                                    avut simptome, nu a fost diagnosticat si nu
                                    a fost tratat sau urmarit medical din cauza
                                    niciuneia dintre urmatoarele afectiuni:
                                    cancer, tumori cerebrale, limfom, leucemie,
                                    accident coronarian acut, boala ischemica
                                    coronariana, accident vascular cerebral,
                                    atac ischemic tranzitoriu, anevrism
                                    cerebral, hemoragie cerebrala, diabet
                                    zaharat de tip I, lupus eritematos, hepatita
                                    B sau C, ciroza hepatica, infectie HIV,
                                    insuficienta renala, dializa renala,
                                    paralizie, scleroza multipla, boli mentale,
                                    dependenta de alcool sau de substante
                                    psihoactive, pentru orice alta afectiune sau
                                    vatamare care a necesitat spitalizare pentru
                                    mai mult de 30 zile in decurs de 1 an
                                  </li>
                                </ul>
                              </div>
                            }
                            tooltipClass="tooltip-max"
                            className="label-title left-double full italic"
                          >
                            1. Starea mea de sanatate este buna
                          </TooltipLine>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  //Switch
                                  color="primary"
                                  width="100px"
                                  style={{ marginLeft: "15px" }}
                                  checked={fields.stareSanatate.Valoare === "1"}
                                  disabled={
                                    state.arataProduse &&
                                    fields.stareSanatate.isReadonly
                                  }
                                  onChange={(prop) => {
                                    updateValue(
                                      fields.stareSanatate,
                                      prop.target.checked ? "1" : "0"
                                    );
                                  }}
                                />
                              }
                              //label={state.stareSanatate ? "Da" : "Nu"}
                              //labelPlacement="top" //{dobandaFixa ? "start" : "end"}
                            />
                          </div>
                        </div>
                        <div className="group-switch h-list-max">
                          <div className="label-title left-double full italic">
                            2. Am minim 6 luni vechime la actualul loc de munca
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  //Switch
                                  color="primary"
                                  width="100px"
                                  style={{ marginLeft: "15px" }}
                                  checked={fields.vechime.Valoare === "1"}
                                  disabled={
                                    state.arataProduse &&
                                    fields.vechime.isReadonly
                                  }
                                  onChange={(prop) => {
                                    updateValue(
                                      fields.vechime,
                                      prop.target.checked ? "1" : "0"
                                    );
                                  }}
                                />
                              }
                              //label={state.vechime ? "Da" : "Nu"}
                              //labelPlacement="top" //{dobandaFixa ? "start" : "end"}
                            />
                          </div>
                        </div>
                        <div className="group-switch h-list-max">
                          <TooltipLine
                            tooltip="In ultimul an NU am fost in  incapacitate de munca mai mult de 30 de zile	consecinţă a unui accident sau a unei boli, care este dovedită prin documente eliberate de o unitate medicală competentă, în conformitate cu legislaţia privind drepturile de asigurări sociale în vigoare, care îl împiedică pe Asigurat, pe o perioadă limitată de timp, să desfăşoare activitatea de bază remunerată pe care acesta o desfăşura la momentul accidentului sau diagnosticării."
                            className="label-title left-double full italic"
                          >
                            3. In ultimul an NU am fost in incapacitate de munca
                            mai mult de 30 de zile
                          </TooltipLine>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  //Switch
                                  color="primary"
                                  width="100px"
                                  style={{ marginLeft: "15px" }}
                                  checked={fields.incapacitate.Valoare === "1"}
                                  disabled={
                                    state.arataProduse &&
                                    fields.incapacitate.isReadonly
                                  }
                                  onChange={(prop) => {
                                    updateValue(
                                      fields.incapacitate,
                                      prop.target.checked ? "1" : "0"
                                    );
                                  }}
                                />
                              }
                              //label={state.incapacitate ? "Da" : "Nu"}
                              //labelPlacement="top" //{dobandaFixa ? "start" : "end"}
                            />
                          </div>
                        </div>
                        <div className="group-switch h-list-max">
                          <div className="label-title full">
                            Te rugam citeste „Documentele de pre-ofertare
                            asigurare de viata Allianz-Tiriac Asigurari, atasata
                            creditului solicitat” si confirma ca le-ai inteles
                            si esti de acord cu acestea
                          </div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                width="100px"
                                style={{ marginLeft: "15px" }}
                                checked={
                                  fields.confirmAsigurare.Valoare === "1"
                                }
                                //disabled={state.arataProduse}
                                disabled={true}
                                onChange={(prop) => {
                                  updateValue(
                                    fields.confirmAsigurare,
                                    prop.target.checked ? "1" : "0"
                                  );
                                }}
                              />
                            }
                          />
                        </div>
                        <div className="group-switch h-list-max">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              viewAcord(docAsigurare, fields.confirmAsigurare)
                            }
                            disabled={fields.confirmAsigurare.isReadonly}
                          >
                            Deschide „Documentele de pre-ofertare asigurare de
                            viata Allianz-Tiriac Asigurari, atasata creditului
                            solicitat”
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="group-switch h-list-max">
                  <TooltipLine
                    tooltip="Daca virezi lunar salariul net luat in considerare la aprobarea solicitarii de credit in contul curent ce va fi deschis dupa aprobarea creditului  vei beneficia de o dobanda mai mica.
                    Nu este obligatoriu ca angajatorul sa iti transfere salariul in acest cont - o poti face chiar tu prin transfer din Internet sau Mobile Banking, depunere de numerar la casieriile Patria Bank/MFM
                    "
                    className="label-title left full"
                  >
                    &#8226; Incasarea venitului la Patria Bank
                  </TooltipLine>

                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          width="100px"
                          checked={state.incasare_venit}
                          onChange={(prop) => {
                            updateState(
                              { incasare_venit: prop.target.checked },
                              true
                            );
                          }}
                        />
                      }
                      label={state.incasare_venit ? "Da" : "Nu"}
                      labelPlacement="top" //{dobandaFixa ? "start" : "end"}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
              </div>

              {state.credit.arata_zi_default && (
                <div className="slider-group  h-list-max">
                  <div className="label-title">
                    <TooltipLine
                      tooltip={
                        state.scadenta.dataScadenta &&
                        (simulare.partnerId !== 1
                          ? `Prima scadenta va fi in data de ${moment(
                              state.scadenta.dataScadenta
                            ).format("DD/MM/YYYY")}. 
                      `
                          : "") +
                          `Pentru datele de scadenta din zilele nelucratoare, data de rambursare este prima zi lucratoare care ii urmeaza.`
                      }
                      className="label-title full"
                    >
                      Ziua scadenta rata
                    </TooltipLine>
                  </div>

                  <div className="value-scadenta">
                    <Autocomplete
                      className="doc-table-value"
                      controled="true"
                      options={interval_scadenta}
                      getOptionLabel={(option) => option.zi.toString()}
                      value={state.scadenta}
                      size="small"
                      disableClearable
                      onChange={(event, newValue) => {
                        updateState({ scadenta: newValue }, true);
                      }}
                      autoComplete
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" required />
                      )}
                      required
                      disabled={isReadonly}
                    />
                  </div>
                </div>
              )}
            </div>

            {autocalc && (
              <div className="simulare-doc">
                {!state.dobanda_fixa && (
                  <div className="doc-table-simulare h-list-max">
                    <div className="label-title">Marja fixa de dobanda</div>
                    <div className="value-suma">
                      <NumericFormat
                        value={simulare.marja}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix="%"
                        decimalScale={2}
                        fixedDecimalScale
                        renderText={(value) => (
                          <div className="percent">{value}</div>
                        )}
                      />
                    </div>
                  </div>
                )}
                <div className="doc-table-simulare h-list-max">
                  <div className="label-title">Procent dobanda *</div>
                  <div className="value-suma">
                    <NumericFormat
                      value={simulare.procent_dobanda}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix="%"
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={(value) => (
                        <div className="percent">{value}</div>
                      )}
                    />
                  </div>
                </div>
                {state.asigurare && (
                  <div className="doc-table-simulare h-list-max">
                    <div className="label-title">Prima unica de asigurare</div>
                    <div className="value-suma">
                      <NumericFormat
                        value={simulare.prima_asigurare}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        //suffix="%"
                        suffix={` ${state.credit.valuta}`}
                        decimalScale={2}
                        fixedDecimalScale
                        renderText={(value) => (
                          <div className="percent">{value}</div>
                        )}
                      />
                    </div>
                  </div>
                )}
                <div className="doc-table-simulare h-list-max">
                  <div className="label-title">Prima rata</div>
                  <div className="value-suma">
                    <NumericFormat
                      value={simulare.prima_rata}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={` ${state.credit.valuta}`}
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={(value) => (
                        <div className="percent">{value}</div>
                      )}
                    />
                  </div>
                </div>

                <div className="doc-table-simulare h-list-max">
                  <div className="label-title">
                    Valoare totala de rambursare
                  </div>
                  <div className="value-suma">
                    <NumericFormat
                      value={simulare.total_rambursare}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={` ${state.credit.valuta}`}
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={(value) => (
                        <div className="percent">{value}</div>
                      )}
                    />
                  </div>
                </div>

                <div className="doc-table-simulare h-list-max">
                  <div className="label-title">DAE **</div>
                  <div className="value-suma">
                    <NumericFormat
                      value={simulare.dae}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix="%"
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={(value) => (
                        <div className="percent">{value}</div>
                      )}
                    />
                  </div>
                </div>

                <div className="label-title">Taxe si comisioane:</div>

                {comisioane &&
                  comisioane.map((comision) => (
                    <div
                      key={comision.denumire_comision}
                      className="doc-table-simulare h-list-max"
                    >
                      <div className="label-title left full">
                        &#8226; {comision.denumire_comision}
                      </div>
                      <div className="value-suma">
                        <NumericFormat
                          value={comision.valoare_comision}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          suffix={
                            comision.comision_procentual
                              ? "%"
                              : ` ${state.credit.valuta}`
                          }
                          decimalScale={2}
                          fixedDecimalScale
                          renderText={(value) => (
                            <div className="interest">{value}</div>
                          )}
                        />
                      </div>
                    </div>
                  ))}

                <div>{state.credit.nota && state.credit.nota}</div>

                <div>
                  ** DAE este Dobanda Anuala Efectiva si include taxele si
                  comisioanele aferente creditului, percepute de banca.
                </div>
                {comisioane &&
                  comisioane
                    .filter((x) => x.nota)
                    .map((comision) => (
                      <div key={comision.denumire_comision}>
                        {comision.nota}
                      </div>
                    ))}
              </div>
            )}
            {isRequest && (
              <>
                <div className="footer">
                  {jprop.footer ??
                    `In cazul in care creditul tau va fi aprobat, suma iti va fi
                virata in contul curent pe care Banca ti-l poate oferi in cadrul
                unui pachet de cont curent pentru care poti opta intr-un pas
                ulterior, impreuna cu multe alte beneficii!`}
                </div>
                {jprop.footerAsig && <div>{jprop.footerAsig}</div>}
              </>
            )}
          </div>

          {
            <div className="login-btn-group">
              <Button
                variant="contained"
                color="primary"
                className="login-btn"
                onClick={arataGrafic}
                endIcon={<ArrowRight fontSize="large" />}
              >
                {btnNextName}
              </Button>
            </div>
          }
        </>
      )}
    </>
  );
};

export default observer(CrediteSimulareProduse);
