import React, { useState } from "react";
import { observer } from "mobx-react";

import Typography from "@mui/material/Typography";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Checkbox } from "TemplateCerere";
import Button from "@mui/material/Button";
import { ThumbUp, ArrowRight } from "@mui/icons-material";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { EditDialog, IconDocuments, IconDownload } from "TemplateCerere";
import {
  PackageIcon,
  SMSAlertIcon,
  CardIcon,
  ContIcon,
  IBIcon,
} from "../components/icons";

import "../index.css";
import "./OnboardingPachetPF.scss";
import { NumericFormat } from "react-number-format";

const OnboardingConfirmarePF = (props) => {
  const getPachet = (c) => {
    var p = {};
    if (c?.grid?.dt?.Rows?.length > 0) {
      var r = c.grid.dt.Rows[0];
      p.cod_produs = r[0];
      p.denumire = r[1];
      p.opt = JSON.parse(r[2]) || [];
      p.extra = JSON.parse(r[3]) || [];
      p.depo = JSON.parse(r[4]) || [];
      p.card = { dst: r[6], adresa: r[7], ext: r[8] };
    }
    return p;
  };
  const [isOpen, setIsOpen] = useState(false);

  const { doc, actions, nextStep, canPass } = props;
  const fld = doc.Jprop.template.fields ?? {};
  const pachet = getPachet(actions.getField(fld.pachet));
  var client = null;
  if (fld.client) {
    client = actions.getField(fld.client)?.grid?.dt;
    if (client.Rows?.length == 0) {
      client = null;
    }
  }
  var risc = null;
  if (fld.risc) {
    risc = actions.getField(fld.risc)?.grid?.dt;
    if (risc.Rows?.length == 0) {
      risc = null;
    }
  }
  const docs = actions.getField(fld.docs);
  const acord15 = actions.getField(fld.acord15);
  const acordDoc = actions.getField(fld.acordDoc);
  const eco = actions.getField(fld.eco);
  const ecoDobanzi = actions.getField(fld.ecoDobanzi);

  const actionNameNext = doc.Jprop.template?.actionNameNext; //?? "Incepe validarea ID";
  const docTitle = doc.Jprop.template?.docTitle ?? "Documentele tale";
  const docSubtitle = doc.Jprop.template?.docSubtitle;
  const clientTitle = doc.Jprop.template?.clientTitle ?? "Client";
  const riscTitle = doc.Jprop.template?.riscTitle ?? "Grad de risc";
  const decl = doc.Jprop.template?.decl ?? [];
  if (decl.length === 0) {
    decl.push(
      "Declar pe proprie raspundere ca toate informatiile inscrise in aceasta cerere precum si toate documentele prezentate sunt complete, reale si valide, luand la cunostinta despre consecintele legale rezultate in urma furnizarii de date false."
    );
    decl.push(
      "Subsemnatul confirm ca, inainte de a semna am verificat toate declaratiile si optiunile selectate in prezentul document si ca acestea reflecta in mod corect vointa mea."
    );
    decl.push(
      "Banca isi rezerva dreptul de a accepta sau nu intrarea in relatii cu clientul, inclusiv in cazul in care Clientul refuza sa furnizeze informatii marcate ca fiind obligatorii. In situatia in care, la orice moment in timp, Banca constata ca oricare din datele si/sau documentele furnizate Bancii au fost sau sunt nereale, incomplete sau neactuale sub orice aspect, aceasta isi rezerva dreptul de a inceta imediat relatia cu Clientul, fara a fi necesara indeplinirea oricaror notificari prealabile, conditii, formalitati sau interventia unei instante de judecata."
    );
  }
  var econ = (pachet.depo?.length??0)+(eco?.Valoare*1);
  //    const isReadonly = docs.isReadonly || docs.docReadOnly;

  const visibleDocs = () => {
    return docs?.data.rows;
  };

  const getIcon = (cod) => {
    if (!cod) {
      return null;
    }
    if (cod.startsWith("P001")) {
      return cod.length === 8 ? (
        <ContIcon viewBox="0 0 16 12" />
      ) : (
        <CardIcon viewBox="0 0 16 16" />
      );
    }
    return cod.startsWith("P006_IB") ? (
      <IBIcon viewBox="0 0 16 16" />
    ) : (
      <SMSAlertIcon viewBox="0 0 16 20" />
    );
  };
  //console.log(client);
  return (
    <>
      <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
        <div className="e-card-title">{doc.Jprop.title || doc.Descriere}</div>
        <div className="e-card-subtitle">{doc.Jprop.subtitle}</div>
        <div className="e-card-content-document">
          <div key={`dc${doc.Id}`}>
            {pachet.cod_produs && (
              <div className="pachet-extra-confirm">
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    lg={pachet.extra?.length ? 6 : 12}
                    className="pachet-extra-column"
                  >
                    <div className="card-header-title">Pachet</div>
                    <div className="pachet-control">
                      <div className={"produs " + pachet.cod_produs}>
                        <span>
                          <PackageIcon viewBox="0 0 16 16"></PackageIcon>
                        </span>
                        {pachet.denumire}
                      </div>

                      <ul className="optiuni">
                        {pachet.opt &&
                          pachet.opt.map((o) => (
                            <li
                              className={
                                "opt " +
                                o.cod_produs +
                                " " +
                                o.tip_produs.toLowerCase()
                              }
                              key={o.cod_produs}
                            >
                              {getIcon(o.cod_produs)}
                              <span>{o.denumire}</span> <span>{o.nume}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Grid>
                  {pachet.extra && pachet.extra.length > 0 && (
                    <Grid item xs={12} lg={6} className="pachet-extra-column">
                      <div className="card-header-title">Extraoptiuni</div>
                      <div className="pachet-control">
                        <ul className="extra">
                          {pachet.extra.map((o) => (
                            <li
                              className={
                                "extra " +
                                o.cod_produs +
                                " " +
                                o.tip_produs.toLowerCase()
                              }
                              key={o.cod_produs}
                            >
                              {getIcon(o.cod_produs)}
                              <span>{o.denumire}</span> <span>{o.nume}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Grid>
                  )}
                </Grid>
                {pachet.card?.dst && (
                  <Grid container spacing={0}>
                    <Grid item xs={12} className="pachet-extra-helper">
                      {`Cardul tau va fi livrat la ${pachet.card?.dst} (${pachet.card?.adresa}). ${pachet.card?.ext}`}
                    </Grid>
                  </Grid>
                )}
              </div>
            )}
            {econ>0 && 
              <div className="sumar-depozit depozit-title">
                {econ===1?"Produs de economisire ales":"Produse de economisire alese"}
              </div>
            }
            {pachet.depo && pachet.depo.length > 0 && (
              <div className="sumar-depozit">
                <div className="depozit-titleX">Depozit ales:</div>
                <div className="depozite-grid">
                  {pachet.depo.map((row, index) => (
                    <div className="document-line" key={index}>
                      <div className="item">
                        <div className="header">Tip depozit:</div>
                        <div>{row.tip_produs}</div>
                      </div>
                      <div className="item">
                        <div className="header">Moneda depozit:</div>
                        <div>{row.valuta}</div>
                      </div>

                      <div className="item right">
                        <div className="header right">Suma depusa:</div>
                        <NumericFormat
                          displayType="text"
                          value={row.suma}
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale
                          valueIsNumericString
                          decimalScale={2}
                        />
                        {` ${row.valuta}`}
                      </div>
                      <div className="item">
                        <div className="header right">Perioada:</div>
                        <div>{`${row.perioada} ${row.perioada === 1 ? "Luna" : "Luni"
                          }`}</div>
                      </div>
                      <div className="item">
                        <div className="header right">Procent dobanda:</div>
                        <div>
                          <NumericFormat
                            displayType="text"
                            value={row.procent}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                            valueIsNumericString
                            decimalScale={2}
                          />
                          %
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {eco?.Valoare == 1 && (
              <div className="sumar-depozit">
                <div className="depozit-titleX">Contul de Economii ales:</div>
                <div className="depozite-grid">
                  <div className="document-line">
                    <div className="item">
                      <div className="header">Tip:</div>
                      <div>Contul de Economii Patria</div>
                    </div>
                    <div className="item">
                      <div className="header">Moneda:</div>
                      <div>RON</div>
                    </div>

                    <div className="item right">
                      <div className="header right">Suma depusa:</div>
                      <div>-</div>
                    </div>
                    <div className="item">
                      <div className="header right">Perioada:</div>
                      <div>Nedeterminata</div>
                    </div>
                    <div className="item">
                      <div className="header right">Procent dobanda*:</div>
                      <div>
                        {ecoDobanzi?.data?.rows.map((row, index) => {
                          return (
                            <div
                              id={`ecoDobanzi${ecoDobanzi.Id}br${index}`}
                              key={`ecoDobanzi-${index}`}
                            >
                              {row.PROCENT_DOBANDA}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="item-full">*rata de dobândă aplicabilă depinde de soldul disponibil în contul de economii</div>
                  </div>
                </div>
              </div>
            )}
            {client && (
              <div className="pachet-client-confirm">
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    lg={risc ? 6 : 12}
                    className="pachet-extra-column"
                  >
                    <div className="card-header-title">{clientTitle}</div>
                    <div className="pachet-control">
                      <ul className="optiuni">
                        {client.Columns.map(
                          (col, index) =>
                            client.Rows[0][index]?.toString() != "" && (
                              <li className="opt" key={index}>
                                <span>{col.Caption}</span>{" "}
                                <span>{client.Rows[0][index]}</span>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </Grid>
                  {risc && (
                    <Grid item xs={12} lg={6} className="pachet-extra-column">
                      <div className="card-header-title">
                        {riscTitle}{" "}
                        <span
                          className={`client-risc client-risc-${risc.Rows[0][2].toLowerCase()}`}
                        >
                          {risc.Rows[0][2]}
                        </span>
                      </div>
                      <div className="pachet-control">
                        <ul className="extra">
                          {risc.Rows.slice(1).map((row, index) => (
                            <li className="risc" key={index}>
                              <span>{row[1]}</span>{" "}
                              <span
                                className={`client-risc-${row[2].toLowerCase()}`}
                              >
                                {row[2]}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
            {docs && (
              <>
                <div className="depozit-title">{docTitle}</div>
                {docSubtitle && <div>{docSubtitle}</div>}
                <div className="documents-download">
                  {visibleDocs().map((e) => (
                    <span key={e["COD_DOC"]} className="document-line">
                      <SvgIcon
                        component={IconDocuments}
                        className="document-icon"
                        viewBox="0 0 14 16"
                      />
                      <div>{e["DENUMIRE_DOCUMENT"]}</div>
                      <div
                        className="group"
                        onClick={() => actions.downloadAcord(e["ACORD"])}
                      >
                        <div className="save-text">Salveaza</div>
                        <Tooltip
                          arrow
                          title={
                            <Typography variant="body2">{`Salveaza document`}</Typography>
                          }
                        >
                          <SvgIcon
                            component={IconDownload}
                            className="save-icon"
                            viewBox="0 0 16 16"
                          />
                        </Tooltip>
                      </div>
                    </span>
                  ))}
                </div>
              </>
            )}
            {acord15 && (
              <FormControl
                id={`f5c-${acord15.Id}`}
                required={acord15.Obligatoriu}
                error={!!acord15.error}
              >
                <FormGroup id={`f5g-${acord15.Id}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`f5f-${acord15.Id}`}
                        checked={acord15.Valoare === "1"}
                        onChange={(prop) =>
                          actions.updateValue(
                            acord15,
                            prop.target.checked ? "1" : "0"
                          )
                        }
                        color="primary"
                        disabled={acord15.isReadonly || acord15.docReadOnly}
                        className={
                          acord15.isReadonly || acord15.docReadOnly
                            ? "disabled"
                            : null
                        }
                        variant="outlined"
                        required={acord15.Obligatoriu}
                      />
                    }
                    label={acord15.Caption}
                  />
                </FormGroup>
                <FormHelperText>
                  {acord15.error || acord15.Jprop.helperText}
                </FormHelperText>
              </FormControl>
            )}
            {acordDoc && (
              <div className="check-group">
                <FormControl
                  id={`f5c-${acordDoc.Id}`}
                  required={acordDoc.Obligatoriu}
                  error={!!acordDoc.error}
                >
                  <FormGroup id={`f5g-${acordDoc.Id}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={`f5f-${acordDoc.Id}`}
                          checked={acordDoc.Valoare === "1"}
                          onChange={(prop) =>
                            actions.updateValue(
                              acordDoc,
                              prop.target.checked ? "1" : "0"
                            )
                          }
                          color="primary"
                          disabled={acordDoc.isReadonly || acordDoc.docReadOnly}
                          className={
                            acordDoc.isReadonly || acordDoc.docReadOnly
                              ? "disabled"
                              : null
                          }
                          variant="outlined"
                          required={acordDoc.Obligatoriu}
                        />
                      }
                      label={acordDoc.Caption}
                    />
                  </FormGroup>
                  <FormHelperText>
                    {acordDoc.error || acordDoc.Jprop.helperText}
                  </FormHelperText>
                </FormControl>
              </div>
            )}
          </div>
        </div>
      </div>
      {canPass && actionNameNext && (
        <div className="doc-nav">
          {doc.Incarcare ? (
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRight fontSize="large" />}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {actionNameNext}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRight fontSize="large" />}
              onClick={() => nextStep()}
            >
              {actionNameNext}
            </Button>
          )}
        </div>
      )}
      <EditDialog
        className="retail-dialog-info"
        isOpen={isOpen}
        header="Important!"
        showSave={true}
        saveCaption="Confirm"
        showClose={true}
        closeCaption="Renunta"
        maxWidth="md"
        save={() => {
          setIsOpen(false);
          nextStep();
        }}
        close={() => {
          setIsOpen(false);
        }}
      >
        {decl.map((d, i) => (
          <Typography
            variant="body2"
            gutterBottom
            className="text-confirm"
            key={i}
          >
            <ThumbUp />
            {d}
          </Typography>
        ))}
      </EditDialog>
    </>
  );
};
export default observer(OnboardingConfirmarePF);
