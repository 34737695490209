import React from "react";
import PropTypes from "prop-types";

export default function ScrollTop(props) {
  const { children, target } = props;
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      target ? `#${target}` : "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView();
    }
  };

  return <div onClick={handleClick}>{children}</div>;
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};