import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import moment from "moment";
import CssBaseline from "@mui/material/CssBaseline";
import { checkFullName, checkCNP, getValueKey, getValueOfList } from "Utils";

import Button from "@mui/material/Button";
import { PictureAsPdf } from "@mui/icons-material";
import { TextFieldMask } from "TemplateCerere";
import Autocomplete from "@mui/material/Autocomplete";

import { ArrowRight } from "@mui/icons-material";
import { IconsSvg } from "TemplateCerere";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const RegisterBoardPF = (props) => {
  const { cimpuri, save, updateValue } = props.store;
  const { downloadAcord } = props.main;
  const [cnp, setCNP] = useState({ value: null, error: null });
  const [tipId, setTipId] = useState({ value: null, error: null });
  const [idList, setIdList] = useState([]);
  const [idRom, setIdRom] = useState(true);

  const [tara, setTara] = useState({ value: null, error: null });
  const [tariList, setTariList] = useState([]);
  const [nume, setNume] = useState({ value: null, error: null });
  const [dataN, setDataN] = useState({ value: null, error: null });
  const [sex, setSex] = useState({ value: null, error: null });
  const [sexList, setSexList] = useState([]);

  const [rol, setRol] = useState({ value: null, error: null });
  const [rolList, setRolList] = useState([]);

  const [acordDownloaded, setAcordDownloaded] = useState(true);

  const [unitate, setUnitate] = useState("");

  const navigate = props.history.push;
  useEffect(() => {
    //if (idList.length>0){      return;    }
    var id = props.common.getField(3);
    var vl = id?.visibleList() ?? [];
    setIdList(vl);

    var tara = props.common.getField(11);
    var vlt = tara?.visibleList() ?? [];
    console.log("lista tari", vlt);
    setTariList(vlt.slice(1));

    var sx = props.common.getField(13);
    var vls = sx?.visibleList() ?? [];
    setSexList(vls);

    var rl = props.common.getField(298);
    var vlr = rl?.visibleList() ?? [];
    setRolList(vlr);

    setUnitate(props.common.getField(-99)?.Valoare);
  }, [cimpuri]);

  useEffect(() => {
    /*La modificare  resetez eroarea de la CNP*/
    setCNP({ value: cnp.value, error: null });
  }, [idRom]);

  useEffect(() => {
    if (idRom) {
      return;
    }
    var xcnp = "";
    if (tara.value) {
      xcnp += getValueKey(getValueOfList(tariList, tara.value), 2);
    }
    if (nume.value) {
      var [n1, n2, n3] = nume.value.split(" ");
      if (n1.length < 3 && n3) {
        n1 = n2;
        n2 = n3;
      }
      xcnp += n1?.substring(0, 3) ?? "";
      xcnp += n2?.substring(0, 3) ?? "";
    }
    if (dataN.value && dataN.value.isValid()) {
      xcnp += dataN.value.format("DDMMYY");
    }
    if (sex.value) {
      xcnp += sex.value;
    }
    console.log("xcnp", xcnp);
    setCNP({ value: xcnp, error: null });
  }, [tara, nume, dataN, sex, sex]);

  var toolbarCss = "paper-toolbar";

  const validare = () => {
    const { errors } = props.main;
    var errorMsgs = [];
    var msg = "";
    if (idRom) {
      if (!checkCNP(cnp?.value)) {
        msg = "[OPF2]Campul CNP este incorect!";
        errorMsgs.push(msg);
        setCNP({ value: cnp.value, error: errors.processMsg(msg) });
      }
    } else {
      if (!tara?.value || tara.value == 150) {
        msg = "Trebuie sa completati cu o tara diferita de Romania";
        errorMsgs.push(msg);
        setTara({ value: tara.value, error: errors.processMsg(msg) });
      }
      if (!checkFullName(nume?.value)) {
        msg = "Numele clientului nu este completat corect";
        errorMsgs.push(msg);
        setNume({ value: nume.value, error: errors.processMsg(msg) });
      }
      if (
        !dataN.value ||
        !dataN.value.isValid() ||
        moment().diff(dataN.value, "years") < 18
      ) {
        msg = "Nu ati completat corect data nasterii";
        errorMsgs.push(msg);
        setDataN({ value: dataN.value, error: errors.processMsg(msg) });
      }
      if (!sex?.value) {
        msg = "Trebuie sa alegeti o optiune";
        errorMsgs.push(msg);
        setSex({ value: sex.value, error: errors.processMsg(msg) });
      }
      if (!cnp.value || cnp.value.length < 7) {
        msg = "[OPF2]Campul CNP este incorect!";
        errorMsgs.push(msg);
        setCNP({ value: cnp.value, error: errors.processMsg(msg) });
      }
    }
    if (!tipId?.value) {
      msg = "Completati tipul actului de identitate";
      errorMsgs.push(msg);
      setTipId({ value: tipId.value, error: errors.processMsg(msg) });
    }
    if (!rol?.value) {
      msg = "Trebuie sa alegeti o optiune";
      errorMsgs.push(msg);
      setRol({ value: rol.value, error: errors.processMsg(msg) });
    }
    return errorMsgs;
  };
  const handleNew = () => {
    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      return;
    }
    updateValue(props.common.getField(2), cnp.value);
    updateValue(props.common.getField(3), tipId.value);
    updateValue(props.common.getField(298), rol.value);
    if (!idRom) {
      updateValue(props.common.getField(1), nume.value);
      updateValue(props.common.getField(2), cnp.value);
      updateValue(props.common.getField(12), dataN.value);
      updateValue(props.common.getField(13), sex.value);
      updateValue(props.common.getField(11), tara.value);
    }
    save(navigate, { idRom: idRom });
  };

  return (
    <div className={toolbarCss}>
      <CssBaseline />
      <IconsSvg icon="IllustrationHello" />
      <p className="page-title">Buna,</p>
      <p className="normal-text">
        Pentru a incepe procesul de initiere a relatiei de afaceri cu Banca
        (Persoana Fizica) este important sa completezi campurile de mai jos in
        baza declaratiilor furnizate de client:
      </p>
      <div className="acord">
        <div className="acord">
          <div className="acord-txt">
            Inainte de a continua cu cererea de initiere relatie de afaceri cu
            Banca, prezinta clientului informatii
            <Link
              onClick={() => {
                downloadAcord(103);
                setAcordDownloaded(true);
              }}
              className="termenii em"
            >
              ACORD GDPR <PictureAsPdf></PictureAsPdf>
            </Link>
            !
          </div>
        </div>
      </div>
      <div className="form">
        <TextField
          variant="outlined"
          margin="normal"
          disabled
          id="unitate"
          label="Unitatea teritoriala"
          value={unitate ?? ""}
          className="login-txt"
          size="small"
        />
        <TextField
          variant="outlined"
          margin="normal"
          disabled
          fullWidth
          id="actiune"
          label="Tip actiune"
          value="Initiere relatie client"
          className="login-txt"
          size="small"
        />
        <TextField
          variant="outlined"
          margin="normal"
          disabled
          fullWidth
          id="client"
          label="Tipul de client"
          value="Persoana fizica"
          className="login-txt"
          size="small"
        />
        <Autocomplete
          id="rid"
          //controled="true"
          options={rolList}
          getOptionLabel={(option) => getValueKey(option, 1) ?? ""}
          value={getValueOfList(rolList, rol.value)}
          onChange={(event, newValue) => {
            setRol({ value: getValueKey(newValue, 0), error: null });
          }}
          disableClearable
          autoComplete
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              id="tid"
              variant="outlined"
              label="Rolul clientului"
              error={!!rol.error}
              helperText={rol.error}
            />
          )}
          required
          className="login-pwd"
        />
        <div className="form switch-group">
          <div className="label">
            Persoana are un act de identitate emis in Romania
          </div>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                width="100px"
                checked={idRom}
                //disabled={state.arataProduse}
                onChange={(prop) => {
                  setIdRom(prop.target.checked);
                }}
              />
            }
            label={idRom ? "Da" : "Nu"}
            labelPlacement="top" //{dobandaFixa ? "start" : "end"}
          />
        </div>
        {idRom && (
          <>
            <TextFieldMask
              autoFocus
              id="cnp"
              label="CNP *"
              defaultValue={cnp?.value ?? ""}
              className="login-pwd"
              variant="outlined"
              onBlur={(prop) =>
                setCNP({ value: prop.target.value, error: null })
              }
              mask="9999999999999"
              maskChar=""
              error={!!cnp.error}
              helperText={cnp.error}
              type="tel"
            />
          </>
        )}
        {!idRom && (
          <>
            <Autocomplete
              id="tid"
              //controled="true"
              options={tariList}
              getOptionLabel={(option) => getValueKey(option, 1) ?? ""}
              value={getValueOfList(tariList, tara.value)}
              onChange={(event, newValue) => {
                setTara({ value: getValueKey(newValue, 0), error: null });
              }}
              disableClearable
              autoComplete
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="tid"
                  variant="outlined"
                  label="Tara emitenta act de identitate"
                  error={!!tara.error}
                  helperText={tara.error}
                />
              )}
              required
              className="login-pwd"
            />
            <TextField
              autoFocus
              id="nid"
              label="Nume si prenume client"
              value={nume?.value ?? ""}
              onChange={(event) => {
                setNume({
                  value: event.target.value?.toUpperCase(),
                  error: null,
                });
              }}
              className="login-pwd"
              variant="outlined"
              error={!!nume.error}
              helperText={nume.error}
              required
            />

            <DatePicker
              id="did"
              value={dataN?.value}
              label="Data nasterii"
              onChange={(prop) => {
                setDataN({ value: prop, error: null });
              }}
              format="DD/MM/YYYY"
              clearable
              inputVariant="outlined"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              size="small"
              sx={{
                width: "-webkit-fill-available",
                input: { padding: "8.5px 14px" },
              }}
              required
              error={!!dataN.error}
              helperText={dataN.error}
              className="login-pwd"
            />

            <Autocomplete
              id="sid"
              //controled="true"
              options={sexList}
              getOptionLabel={(option) => getValueKey(option, 1) ?? ""}
              value={getValueOfList(sexList, sex.value)}
              onChange={(event, newValue) => {
                setSex({ value: getValueKey(newValue, 0), error: null });
              }}
              disableClearable
              autoComplete
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="sid"
                  variant="outlined"
                  label="Sex"
                  error={!!sex.error}
                  helperText={sex.error}
                />
              )}
              required
              className="login-pwd"
            />
            <TextField
              autoFocus
              id="dcnp"
              label="CNP *"
              value={cnp?.value ?? ""}
              onChange={(event) => {
                setCNP({
                  value: event.target.value?.toUpperCase(),
                  error: null,
                });
              }}
              className="login-pwd"
              variant="outlined"
              error={!!cnp.error}
              helperText={cnp.error}
              disabled
            />
          </>
        )}
        <Autocomplete
          id="lid"
          //controled="true"
          options={idList}
          getOptionLabel={(option) => getValueKey(option, 1) ?? ""}
          value={getValueOfList(idList, tipId.value)}
          onChange={(event, newValue) => {
            setTipId({ value: getValueKey(newValue, 0), error: null });
          }}
          disableClearable
          autoComplete
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              id="tid"
              variant="outlined"
              label="Tipul actului de identitate"
              error={!!tipId.error}
              helperText={tipId.error}
            />
          )}
          required
        />
      </div>
      <p></p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={handleNew}
        endIcon={<ArrowRight fontSize="large" />}
        disabled={!acordDownloaded}
      >
        Continua
      </Button>
    </div>
  );
};
export default withRouter(
  inject((stores) => ({
    store: stores.cerereNouaStore,
    main: stores.mainStore,
    common: stores.commonStore,
  }))(observer(RegisterBoardPF))
);
