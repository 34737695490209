import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
//import Collapse from "@mui/material/Collapse";

import Field6EditField from "./field6_edit_field";
import "../index.css";

function Field6_Edit(props) {
  const { data, tableId, updateRow, actions, tableCss, row, isReadonly } =
    props;
  const DivCss = styled.div`
    ${data.eventProps ? data.eventProps.style : null}
  `;
  const visibleHeaders = () => {
    var cols = data.columns.filter((x) => x.isVisibleEdit);
    var h = [];
    cols.forEach((c) => {
      var hIndex = h.length - 1;
      var hInfo = c.header || { text: "", expanded: true, inline: false };
      if (
        data.selectedHeader === null ||
        hInfo.text === "" ||
        data.selectedHeader.toLowerCase() === hInfo.text.toLowerCase()
      ) {
        if (
          hIndex > -1 &&
          hInfo.text.toLowerCase() === h[hIndex].text.toLowerCase()
        ) {
          //sunt pe acelasi header, adaug coloana la header
          h[hIndex].columns.push(c);
        } //creez un header nou
        else {
          h.push({ ...hInfo, columns: [c] });
        }
      }
    });
    return h;
  };
  var headers = visibleHeaders();
  return (
    <DivCss id="edf6">
      {headers.map((header, index) => (
        <div key={`hdx_${index}`} className="card-header">
          <div className="card-header-title">{header.text}</div>
          <div className={`e-card-content-document ${tableCss}`}>
            {header.columns.map((col) => {
              return (
                <div key={`ed${col.key}`} className="doc-table-row">
                  {!col.inline && (
                    <div className="doc-table-label">{col.Caption}</div>
                  )}

                  <Field6EditField
                    tableId={tableId}
                    row={row ?? data.row}
                    col={col}
                    updateRow={updateRow}
                    actions={actions}
                    isReadonly={isReadonly}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </DivCss>
  );
}
export default observer(Field6_Edit);
