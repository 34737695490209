import React from "react";
import { observer } from "mobx-react";

import styled from "styled-components";
import Button from "@mui/material/Button";
import { Restore } from "@mui/icons-material";

import "../index.css";

function Field00(props) {
  const { item, actions } = props;
  const ButtonCss = styled(Button)`
    ${item.eventProps && item.eventProps.style}
  `;

  return (
    !!item.isVisible && (
      <ButtonCss
        id={`f0-${item.Id}`}
        variant="contained"
        color="primary"
        className="doc-button"
        endIcon={<Restore />}
        onClick={() => actions.runEvent(actions.store, item, false, true)}
        disabled={item.isReadonly || item.docReadOnly}
        {...(item.eventProps && item.eventProps.props)}
        {...item?.Jprop?.ctrlProps}
      >
        {item.Caption}
      </ButtonCss>
    )
  );
}
export default observer(Field00);
