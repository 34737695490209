export function jsonParse(jsonString) {
  var f;
  try {
    // eslint-disable-next-line no-new-func
    f = new Function('"use strict";return ( ' + jsonString + ")");
  } catch (error) {
    console.log(jsonString);
    console.log(error);
  } finally {
    return typeof f == "function" ? f() : null;
  }
}
