import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import CircularProgressBar from "./circularProgressBar";
import { ArrowLeft } from "@mui/icons-material";
import ScrollTop from "./scrollTop";

const ProgressBar = (props) => {
  const { goBack, prevStep, stepId, getStepsInfo } = props;
  const [stepsInfo, setStepsInfo] = useState(null);

  useEffect(() => {
    if (getStepsInfo) {
      const stepsInfo = getStepsInfo(stepId);
      setStepsInfo(stepsInfo);
    }
  }, [stepId]);

  if (!stepsInfo) return null;
  return (
    <div className="stepper-bar">
      <div className="back-step">
        {prevStep && (
          <ScrollTop target="app-back-to-top-anchor">
            <ArrowLeft onClick={goBack} />
          </ScrollTop>
        )}
      </div>

      <div className="progress-bar">
        <CircularProgressBar
          value={stepsInfo.statusPercent}
          caption={`${stepsInfo.indexMaster} / ${stepsInfo.countMaster}`}
          count={stepsInfo.countMaster}
        />
      </div>
      <div>
        <div className="current-step">{stepsInfo.masterStep}</div>
        <div className="next-step">{stepsInfo.nextStep}</div>
      </div>
    </div>
  );
};
export default observer(ProgressBar);
