import React from "react";
import { observer } from "mobx-react";
//import styled from "styled-components";
import TextField from "@mui/material/TextField";
import "../index.css";

function Filter_T(props) {
  const { cimp, valoare, updateFilter, end, style } = props;
  return (
    <TextField
      value={valoare ? valoare : ""}
      onChange={(prop) => updateFilter(cimp, prop.target.value, end)}
      variant="outlined"
      className="doc-table-value"
      size="small"
      {...style}
    />
  );
}
export default observer(Filter_T);
