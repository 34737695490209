import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import { DoneOutline } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import { formatCell } from "Utils";
import "../index.css";

function Field6_Table_Transpose(props) {
  const { eventProps, item, tableRowCss, onSelectRow, btns } = props;
  const { data, Jprop } = item;
  const TableCss = styled(Table)`
    ${eventProps.style}
  `;

  const handlerSelect = (index, row) => {
    if (item.isReadonly) return;
    item.selectedRow = index;
    if (onSelectRow) {
      onSelectRow(row);
    }
  };
  return (
    <TableCss id={`tbl${item.Id}`} aria-label="spanning table" size="small">
      <TableHead id={`tbl${item.Id}h`}>
        {data.columns
          .filter((x) => x.isVisible && x.ColumnName === Jprop?.headers?.col)
          .map((col, index) => {
            return (
              <TableRow
                id={`tbl${item.Id}br${index}c`}
                key={`tr-${index}c`}
                className={tableRowCss}
              >
                <TableCell
                  id={`tbl${item.Id}-c${col.key}-${index}c`}
                  key={`${col.key}-${index}c`}
                  align="left"
                  // minWidth={50}
                  style={{ ...Jprop?.headers?.style }}
                >
                  {col.Caption}
                </TableCell>
                {data.rows.map((row, indexRow) => {
                  var cell = formatCell(row, col, item.onFormatCell);
                  return (
                    <TableCell
                      id={`tbl${item.Id}-c${col.key}-${indexRow}`}
                      key={`${col.key}-${indexRow}`}
                      {...col.formatProp}
                      align={"center"}
                      //style={index==0?{fontWeight:"bold"}:{...Jprop?.cols?.["firstCol"]?.style}}
                      style={{ ...Jprop?.headers?.style }}
                      className={
                        item.selectedRow === indexRow ||
                          (item.selectedRow === -1 && indexRow == 0)
                          ? "table-selected-row"
                          : ""
                      }
                      onDoubleClick={() => handlerSelect(indexRow, row)}
                    >
                      {col.tip === "c" ? (
                        (
                          row
                            ? row[col.key] === "1" || row[col.key] === true
                            : false
                        ) ? (
                          <CheckBoxSharpIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankSharpIcon color="primary" />
                        )
                      ) : (
                        cell
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableHead>
      <TableBody id={`tbl${item.Id}b`}>
        {data.columns
          .filter((x) => x.isVisible && x.ColumnName !== Jprop?.headers?.col)
          .map((col, index) => {
            return (
              <TableRow
                id={`tbl${item.Id}br${index}c`}
                key={`tr-${index}c`}
                hover
                className={tableRowCss}
              >
                <TableCell
                  id={`tbl${item.Id}-c${col.key}-${index}c`}
                  key={`${col.key}-${index}c`}
                  align="left"
                  //width={50}
                  //{...col.formatProp}
                  style={{
                    ...Jprop?.cols?.[col.ColumnName]?.style,
                    ...Jprop?.cols?.["firstCol"]?.style,
                  }}
                >
                  {col.Caption}
                </TableCell>
                {data.rows.map((row, indexRow) => {
                  var cell = formatCell(row, col, item.onFormatCell);
                  return (
                    <TableCell
                      id={`tbl${item.Id}-c${col.key}-${indexRow}`}
                      key={`${col.key}-${indexRow}`}
                      {...col.formatProp}
                      align={"center"}
                      style={{ ...Jprop?.cols?.[col.ColumnName]?.style }}
                      className={
                        item.selectedRow === indexRow ||
                          (item.selectedRow === -1 && indexRow == 0)
                          ? "table-selected-row"
                          : ""
                      }
                      onDoubleClick={() => handlerSelect(indexRow, row)}
                    >
                      {col.tip === "c" ? (
                        (
                          row
                            ? row[col.key] === "1" || row[col.key] === true
                            : false
                        ) ? (
                          <CheckBoxSharpIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankSharpIcon color="primary" />
                        )
                      ) : cell.className ? (
                        <div className={cell.className}>{cell.text}</div>
                      ) : (
                        cell
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        <TableRow
          id={`tbl${item.Id}actionr`}
          key={`tr-actionr`}
          hover
          className={tableRowCss}
        >
          <TableCell
            id={`tbl${item.Id}-actionc`}
            key={`action-actionc`}
            align="left"
            style={{
              ...Jprop?.cols?.["action"]?.style,
              ...Jprop?.cols?.["firstCol"]?.style,
            }}
          ></TableCell>
          {item.edit &&
            data.rows.map((row, indexRow) => {
              return (
                <TableCell
                  id={`tbl${item.Id}-${indexRow}c`}
                  style={{ ...Jprop?.cols?.["action"]?.style }}
                  align="center"
                  key={`${item.Id}-${indexRow}-c`}
                  width={10}
                  className={
                    item.selectedRow === indexRow ||
                      (item.selectedRow === -1 && indexRow == 0)
                      ? "table-selected-row"
                      : ""
                  }
                >
                  {item.selectedRow === indexRow ||
                    (item.selectedRow === -1 && indexRow == 0) ? (
                    <Tooltip title="Selectat">
                      <DoneOutline color="primary" />
                    </Tooltip>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handlerSelect(indexRow, row)}
                    >
                      Selecteaza
                    </Button>
                  )}
                </TableCell>
              );
            })}
        </TableRow>
        {btns &&
          btns.map((btn, indexBtn) => {
            return (
              <TableRow
                id={`tbl${item.Id}btnrow${indexBtn}`}
                key={indexBtn}
                hover
                className={tableRowCss}
              >
                <TableCell
                  id={`tbl${item.Id}btn${indexBtn}`}
                  key={indexBtn}
                  align="left"
                  style={{
                    ...Jprop?.cols?.["action"]?.style,
                    ...Jprop?.cols?.["firstCol"]?.style,
                  }}
                ></TableCell>
                {data.rows.map((row, indexRow) => {
                  return (
                    <TableCell
                      id={`tbl${item.Id}-${indexBtn}-${indexRow}c`}
                      style={{ ...Jprop?.cols?.["action"]?.style }}
                      align="center"
                      key={`${item.Id}-${indexBtn}-${indexRow}-c`}
                      width={10}
                      className={
                        item.selectedRow === indexRow ||
                          (item.selectedRow === -1 && indexRow == 0)
                          ? "table-selected-row"
                          : ""
                      }
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => btn.action(indexRow, row)}
                      >
                        {btn.label}
                      </Button>
                    </TableCell>
                  );
                })}
              </TableRow>);
          })
        }
      </TableBody>
    </TableCss>
  );
}
export default observer(Field6_Table_Transpose);
