import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import Button from "@mui/material/Button";

import { TooltipIcon, EditDialog } from "TemplateCerere";
import Typography from "@mui/material/Typography";
import PdfAllPages from "../components/pdfViewAllPages";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";

import { NumericFormat } from "react-number-format";
import * as Field from "../field";
import "../index.css";
import "./DigitalLendingSelectieOferta.scss";
import "./CrediteSimulare.scss";

import imgSuma from "../img/widget-suma.png";
import imgRata from "../img/widget-rata.png";
import imgDob from "../img/widget-dob.png";
import imgPer from "../img/widget-per.png";

import TableContainer from "@mui/material/TableContainer";
import Table from "../field/field6_table";
import TableTranspose from "../field/field6_table_transpose";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { getStyle } from "Utils";
import Slider from "@mui/material/Slider";
import { ThumbUp, ArrowRight } from "@mui/icons-material";

const DigitalLendingSelectieOferta = (props) => {
  const {
    doc,
    actions,
    isMobile,
    css,
    inline,
    template,
    errors,
    nextStep,
    save,
    simulare,
  } = props;
  const jprop = doc.Jprop || {};
  const fld = jprop.template.fields;
  var { docCredit, addFilesCredit } = jprop.template.documents || {};
  const oferte = actions.getField(fld.oferte);
  const asigurare = actions.getField(fld.asigurare);
  const acord = actions.getField(fld.acord);
  const suma = actions.getField(fld.suma);
  if (suma.Valoare) {
    suma.Valoare *= 1;
  }
  const { file } = template;

  const [isOpen, setIsOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [simulareOferta, setSimulareOferta] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true);
  var viewElement = null;

  const footerAsig = jprop.template?.footerAsig;

  const selectieOferta = (oferta) => {
    actions.updateMainStore({ isWaiting: true });
    actions.queryData(
      "DigitalLending.OfertaCredit.Set",
      { id: oferta.ID },
      (msg) => {
        actions.updateMainStore({ isWaiting: false });
        console.log("msg", msg);
        if (msg?.r === 0) {
          if (msg.o?.Table?.length > 0) {
            Object.assign(oferta, msg.o.Table[0]);
          }
          setSimulareOferta(oferta);
          if (oferta.SUMA_ERR == 0) {
            actions.updateValue(suma, oferta.SUMA_CREDIT);
          }
          actions.updateValue(acord, "0");
        } else {
          //            console.log(result);
          errors.open(msg?.err);
        }
      }
    );
  };

  useEffect(() => {
    if (simulare) {
      actions.updateStore({ simulare: null });
    }
  }, [simulare]);
  useEffect(() => {
    oferte.selectedRow = oferte.data.rows.findIndex((r) => r.IS_SELECTED === 1);
    //actions.updateStore({ simulare: null });
    if (oferte) {
      var oferta =
        oferte.selectedRow === -1
          ? oferte.data.rows[0]
          : oferte.data.rows[oferte.selectedRow];
      if (oferta) {
        setSimulareOferta(oferta);
        //actions.updateValue(suma, oferta.SUMA_CREDIT);
        actions.updateValue(acord, "0");
      }
    }
  }, [suma]);

  const updateSuma = (item, value) => {
    value += "";
    if (!value || value == "") {
      actions.updateValue(item, "");
      errors.open([
        {
          msg: "Trebuie sa completezi suma solicitata pentru oferta aleasa!",
          ctrl: item,
        },
      ]);

      return;
    }

    value = value.replace(/[.]/g, "").replace(",", ".");
    value = value === "" ? null : parseFloat(value);

    if (simulareOferta && value > simulareOferta.VALOARE_MAXIMA) {
      errors.open([
        {
          msg: `Nu te incadrezi la suma solicitata, ci la maxim ${simulareOferta.VALOARE_MAXIMA.toLocaleString(
            "ro-RO"
          )} Lei!`,
          ctrl: item,
        },
      ]);
    } else {
      if (simulareOferta && value < simulareOferta.VALOARE_MINIMA) {
        errors.open([
          {
            msg: `Suma minima pentru oferta selectata este ${simulareOferta.VALOARE_MINIMA.toLocaleString(
              "ro-RO"
            )} Lei`,
            ctrl: item,
          },
        ]);
      } else {
        actions.updateValue(item, value);
        actions.updateValue(acord, "0");
        actions.updateMainStore({ isWaiting: true });
        actions.queryData(
          "DigitalLending.OfertaCredit.SetSuma",
          { id: simulareOferta.ID, suma: value },
          (result) => {
            actions.updateMainStore({ isWaiting: false });
            if (result?.r === 0) {
              if (result.o?.Table?.length > 0) {
                const oferta = oferte.data.rows.find(
                  (r) => r.ID === simulareOferta.ID
                );
                Object.assign(oferta, result.o.Table[0]);

                if (oferta.SUMA_ERR == 1) {
                  actions.updateValue(suma, "");
                }
              }
            } else {
              errors.open(result?.err);
            }
          }
        );
      }
    }
  };

  const intervalSuma = () => {
    if (simulareOferta) {
      var a = [simulareOferta?.VALOARE_MINIMA, simulareOferta?.VALOARE_MAXIMA];
      if (
        !a.find((x) => x == suma.Valoare) &&
        suma.Valoare > simulareOferta?.VALOARE_MINIMA &&
        suma.Valoare < simulareOferta?.VALOARE_MAXIMA
      ) {
        a.push(suma.Valoare);
      }

      //valori distincte
      return [...new Set(a)].map((v) => {
        return {
          value: v,
          label: (
            <NumericFormat
              value={v}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
            />
          ),
        };
      });
    } else {
      return [];
    }
  };

  let setRef = (el) => {
    viewElement = el;
  };

  const handleAcceptAcord = () => {
    actions.updateValue(acord, "1");
    setIsOpen(false);
    setBtnDisabled(true);
  };
  const handleCancelAcord = () => {
    actions.updateValue(acord, "0");
    setIsOpen(false);
    setBtnDisabled(true);
    errors.open(
      `[DPF62]Pentru a finaliza solicitarea de credit, trebuie sa accepti documentul “Informatii Standard la Nivel European privind creditul pentru consumatori & Documentele specifice asigurarii (daca produsul solicitat este cu asigurare)”`
    );
    //errors.open(`Pentru a finaliza solicitarea de credit, trebuie sa accepti documentul “Informatii Standard la Nivel European privind creditul pentru consumatori${asigurare?.Valoare==1 ?"  & Documentele specifice asigurarii“":"“"}`);
  };
  const handleScroll = (e) => {
    //get how much pixels left to scrolling our ReactElement
    const x = e.target.getBoundingClientRect();
    const limit = x.height + x.top;
    const r = viewElement.getBoundingClientRect();
    const top = r.top;
    setBtnDisabled(top > limit);
  };

  const handleGenereazaIse = () => {
    if (!suma?.Valoare || suma?.Valoare == 0) {
      errors.open(
        "Trebuie sa completezi suma solicitata pentru oferta aleasa!"
      );
      return;
    }
    actions.updateMainStore({ isWaiting: true });
    if (asigurare?.Valoare == 1) {
      actions.queryData("DigitalLending.SimulareAllianz", {}, printFile);
    } else {
      printFile();
    }
  };

  const printFile = (prop) => {
    if (prop?.err) {
      errors.open(prop.err);
      return;
    }
    actions
      .getPrintFile({ id: docCredit, addFiles: addFilesCredit })
      .then(() => {
        actions.updateMainStore({ isWaiting: false });
        setIsOpen(true);
      });
  };
  const handleValideazaIdentitate = () => {
    if (doc.docReadOnly) {
      nextStep();
    } else {
      if (!suma?.Valoare || suma?.Valoare == 0) {
        errors.open(
          "Trebuie sa completezi suma solicitata pentru oferta aleasa!"
        );
        return;
      }
      if (acord?.Valoare != 1) {
        errors.open(
          `[DPF62]Pentru a finaliza solicitarea de credit, trebuie sa accepti documentul “Informatii Standard la Nivel European privind creditul pentru consumatori & Documentele specifice asigurarii (daca produsul solicitat este cu asigurare)”`
        );
        //errors.open(`Pentru a finaliza solicitarea de credit, trebuie sa accepti documentul “Informatii Standard la Nivel European privind creditul pentru consumatori${asigurare?.Valoare==1 ?"  & Documentele specifice asigurarii“":"“"}`);
        return;
      }
      setIsDialogOpen(true);
    }
  };
  //const headers = doc.Headers;
  const styleProps = getStyle(oferte.eventProps);
  const TableContainerCss = styled(TableContainer)`
    ${styleProps.input}
  `;
  return (
    <>
      <Dialog open={!!file && isOpen} maxWidth="lg" className="retail-lending">
        <DialogTitle>{jprop.template.header}</DialogTitle>
        <DialogContent dividers={true} onScroll={handleScroll}>
          <div className="acceptance-dialog">
            <div className="body">
              <PdfAllPages
                file={file}
                onLoadSuccess={() => {
                  actions.updateMainStore({ forceWaiting: false });
                }}
                downloadDisabled={false}
              />
              <div className="bottom" ref={setRef}></div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            className="btn"
            onClick={handleCancelAcord}
            disabled={btnDisabled}
          >
            Renunta
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="btn"
            onClick={handleAcceptAcord}
            disabled={btnDisabled}
          >
            De acord
          </Button>
        </DialogActions>
      </Dialog>

      <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
        <div className="e-card-title">{jprop.title || doc.Descriere}</div>
        <div className="e-card-subtitle">{jprop.subtitle}</div>
        <div className="e-card-content-document">
          <div key={`dc${doc.Id}`} className="data-doc">
            {oferte.height !== 0 && (
              <div className="offer-table">
                <TableContainerCss
                  component={Paper}
                  //style={{ minHeight: 100, maxHeight: 300 }}
                >
                  {oferte?.Jprop?.isTransposed ? (
                    <TableTranspose
                      item={oferte}
                      eventProps={oferte.eventProps}
                      actions={actions}
                      tableRowCss="table-row-default"
                      onSelectRow={selectieOferta}
                    />
                  ) : (
                    <Table
                      item={oferte}
                      eventProps={oferte.eventProps}
                      actions={actions}
                      tableRowCss="table-row-default"
                      onSelectRow={selectieOferta}
                    />
                  )}
                </TableContainerCss>
              </div>
            )}
            {/*<div className="offer-group offer-note">Inteleg si sunt de acord valoarea aferenta comisionului de analiza a dosarului, perceputa de catre Banca numai in cazul aprobarii cererii de creditare, sa imi fie retinuta din valoarea creditului.</div>*/}
            {/*
            <TooltipLine
              tooltip="Pentru oferta aleasa poti solicita maxim suma mentionata in coloana „Valoare maxima credit (Lei)”. Completeaza suma creditului pe care o doresti!"
              className="offer-value"
>*/}
            <div className="offer-value">
              <Field.Field3
                key={suma.Id}
                item={suma}
                inline={inline}
                actions={{ updateValue: updateSuma }}
                //actions={actions}
                css={css}
                isMobile={isMobile}
                gridItem={isMobile}
              />
            </div>
            {doc.Incarcare && (
              <div className="offer-slider">
                <Slider
                  value={parseFloat(suma.Valoare)}
                  //value={suma.Valoare}
                  step={1000}
                  onChange={(event, value) => {
                    actions.updateValue(suma, value);
                  }}
                  onChangeCommitted={(event, value) => {
                    console.log("suma finala", value);
                    updateSuma(suma, value);
                  }}
                  valueLabelDisplay="off"
                  valueLabelFormat={(value) => (
                    <NumericFormat
                      value={value}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      renderText={(value) => (
                        <div className="percent">{value}</div>
                      )}
                    />
                  )}
                  marks={intervalSuma()}
                  //marks
                  min={simulareOferta?.VALOARE_MINIMA}
                  max={simulareOferta?.VALOARE_MAXIMA}
                  disabled={suma.isReadonly}
                  className={suma.isReadonly ? "slider-disabled" : null}
                />
              </div>
            )}

            {/*</TooltipLine>*/}

            {/*<div className="offer-group offer-note">Oferta selectata</div>
            <div className="offer-group">
              <div className="offer-selected">
                <div className="offer-selected-btn">
                  <div className="offer-btn">{`Genereaza documentul Informatii Standard la Nivel European privind creditul pentru consumatori (ISE)${asigurare ? " & Documentele specifice asigurarii" :""}.`}</div>
                  */}
            {doc.Incarcare && (
              <div className="offer-label">
                {`Genereaza documentul Informatii Standard la Nivel European privind creditul pentru consumatori (ISE)${
                  asigurare?.Valoare == 1
                    ? " & Documentele specifice asigurarii"
                    : ""
                }.`}
                <TooltipIcon tooltip="Generati si cititi documentele pentru a putea continua." />
              </div>
            )}
            <div className="offer-hlist">
              {doc.Incarcare && (
                <div className="offer-btn">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenereazaIse}
                  >
                    {`Genereaza ISE${
                      asigurare?.Valoare == 1
                        ? " & Documente specifice asigurarii"
                        : ""
                    }`}
                  </Button>
                </div>
              )}
              {/*</div>
                <div className="offer-selected-details">*/}
              {simulareOferta && (
                <div className="widget-oferta">
                  <div className="widget-credit-oferta">
                    <div className="widget-header">Creditul ales</div>
                    <div className="widget-title">{simulareOferta.OFERTA}</div>
                    <div className="widget-line">
                      <img src={imgSuma} alt=""></img>
                      <NumericFormat
                        value={simulareOferta?.SUMA_CREDIT}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={` ${simulareOferta.VALUTA}`}
                        prefix="Suma creditului: "
                        //decimalScale={2}
                        fixedDecimalScale
                        renderText={(value) => (
                          <div className="widget-detail">{value}</div>
                        )}
                      />
                    </div>
                    <div className="widget-line">
                      <img src={imgRata} alt=""></img>
                      <NumericFormat
                        value={simulareOferta.RATA_LUNARA}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={` ${simulareOferta.VALUTA}`}
                        prefix={"Rata lunara: "}
                        decimalScale={2}
                        fixedDecimalScale
                        renderText={(value) => (
                          <div className="widget-detail">{value}</div>
                        )}
                      />
                    </div>
                    <div className="widget-line">
                      <img src={imgDob} alt=""></img>
                      <NumericFormat
                        value={simulareOferta.DOBANDA}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix=" %"
                        prefix={
                          simulareOferta.DOBANDA_FIXA
                            ? "Dobanda fixa: "
                            : "Dobanda variabila: "
                        }
                        decimalScale={2}
                        fixedDecimalScale
                        renderText={(value) => (
                          <div className="widget-detail">{value}</div>
                        )}
                      />
                    </div>
                    <div className="widget-line">
                      <img src={imgPer} alt=""></img>
                      <NumericFormat
                        value={simulareOferta.PERIOADA}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix=" Luni"
                        prefix="Perioada: "
                        //decimalScale={2}
                        fixedDecimalScale
                        renderText={(value) => (
                          <div className="widget-detail">{value}</div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {(asigurare?.Valoare == 1) && 
                <div>{footerAsig}</div>
              }
          </div>
        </div>
      </div>

      {doc.Incarcare && (
        <div className="doc-nav">
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight fontSize="large" />}
            onClick={handleValideazaIdentitate}
          >
            {jprop.actionNameNext ?? "Incepe validarea ID"}
          </Button>
        </div>
      )}
      <EditDialog
        className="retail-dialog-info"
        isOpen={isDialogOpen}
        header="Important!"
        showSave={true}
        saveCaption="Confirm"
        showClose={true}
        closeCaption="Renunta"
        maxWidth="md"
        save={() => {
          setIsDialogOpen(false);
          //nextStep();
          save(0, true, false); // nu ma intereseaza pasul urmator
        }}
        close={() => {
          setIsDialogOpen(false);
        }}
      >
        <Typography variant="body2" gutterBottom className="text-confirm">
          <ThumbUp />
          {`Am citit si sunt de acord cu documentul Informatii Standard la Nivel European privind creditul pentru consumatori${
            asigurare?.Valoare == 1
              ? " si cu Documentele specifice asigurarii"
              : ""
          }.`}
        </Typography>
        <Typography variant="body2" gutterBottom className="text-confirm">
          <ThumbUp />
          Inteleg si sunt de acord ca valoarea aferenta comisionului de analiza
          a dosarului, perceputa de catre Banca numai in cazul aprobarii cererii
          de creditare, sa imi fie retinuta din valoarea creditului.
        </Typography>
        {asigurare?.Valoare == 1 && (
          <Typography variant="body2" gutterBottom className="text-confirm">
            <div className="offer-group">
              <div className="offer-note">
                <ThumbUp />
                In temeiul documentelor specifice asigurarii, declar/confirm
                urmatoarele:
              </div>
              <div className="offer-note2">
                a. Datele de identificare sunt reale;
              </div>
              <div className="offer-note2">
                b. Am parcurs si am inteles formularul de analiza a cerintelor
                si necesitatilor clientilor (DNT);
              </div>
              <div className="offer-note2">
                c. Am fost informat cu privire la produsul de asigurare adecvat
                DNT si mi-au fost prezentate documentele de informare si
                conditiile de asigurare privind asigurarea de deces din orice
                cauza si asigurarea de somaj care contin informatii-cheie despre
                produsul de asigurare solicitat;
              </div>
              <div className="offer-note2">
                d. Am beneficiat de consultanta in alegerea produsului de
                asigurare anterior alegerii acestuia.
              </div>
            </div>
          </Typography>
        )}
      </EditDialog>
    </>
  );
};
export default observer(DigitalLendingSelectieOferta);
