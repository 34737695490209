import React from 'react';
import View from "./views";

const  Main = () => {
  return (
       	<View />
    );
}

export default Main;
