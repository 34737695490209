import React from "react";
import { observer } from "mobx-react";
import { NumericFormat } from "react-number-format";
import moment from "moment";

import CrediteSimulareProduse from "./CrediteSimulareProduse";
import CrediteSimulareGrafic from "./CrediteSimulareGrafic";
import PdfAllPages from "../components/pdfViewAllPages";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import "./CrediteSimulare.scss";
import { DialogActions } from "@mui/material";

class CrediteSimulare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credit: null,
      suma: null,
      perioada: null,
      asigurare: null,
      dobanda_fixa: null,
      incasare_venit: false,
      rate_egale: true,
      scadenta: null,
      rata_lunara: 0,
      valoare_totala: 0,
      dae: null,
      taxa_rnpm: 0,
      intervalPerioada: null,
      intervalSuma: null,
      arataProduse: false,
      //simulareId: null,
      error: {},
      hasGoPrev: false,
      isOpen: false,
      cimpFld: null,
      perioada_maxima: null,
      dataMax: null,
      //id: null,
      btnDisabled: true,
      arataAsigurare: null,
//      age_limit: '65 ani'
    };
  }
  componentDidMount() {
    const { produse, interval_scadenta } = this.props;
    var { isRequest } = this.props;
    isRequest = !!isRequest;
    if (!isRequest) {
      const produs = produse && produse[0];
      //const id = produs ? produs.id : null;
      var zi_scadenta = produs && produs.zi_default;
      var scadenta =
        zi_scadenta && interval_scadenta?.find((x) => x.zi === zi_scadenta);
      scadenta = scadenta || (interval_scadenta ? interval_scadenta[0] : {});
      this.setState({ scadenta: scadenta }, () => this.setCredit());
    } else {
      this.creditInit();
    }
  }
  creditInit = () => {
    const { isRequest, produse, interval_scadenta, jprop } = this.props;
    const creditDefault = produse[0]; //.find((p) => p.id === credit.id);
    const credit = this.props.credit ?? {
      suma: creditDefault.suma_default,
      perioada: creditDefault.perioada_default,
      asigurare: creditDefault.asigurare_default,
      dobanda_fixa: creditDefault.dobanda_fixa_default,
      incasare_venit: creditDefault.incasare_venit_default,
      perioada_maxima: creditDefault.perioada_maxima,
      perioada_minima: creditDefault.perioada_minima,
      suma_maxima: creditDefault.suma_maxima,
      suma_minima: creditDefault.suma_minima,
      scadenta: null,
      valuta: creditDefault.valuta,
      rate_egale: true,
    };

    credit.perioada_maxima = credit.perioada_maxima??creditDefault.perioada_maxima;

    var scadenta = {};
    if (creditDefault.zi_default && !credit.scadenta) {
      credit.scadenta = moment()
        .endOf("month")
        .add(creditDefault.zi_default, "day")
        .valueOf();
    }
    scadenta = interval_scadenta.find(
      (x) => x.zi === moment(credit.scadenta).date()
    );
    scadenta = scadenta || {
      data: credit.scadenta,
      zi: moment(credit.scadenta).date(),
      dataScadenta: credit.scadenta,
    };

    if (isRequest) {
      this.setState(
        {
          credit: creditDefault,
          suma: credit.suma,
          perioada: credit.perioada,
          asigurare: credit.asigurare,
          dobanda_fixa: credit.dobanda_fixa,
          incasare_venit: credit.incasare_venit,
          rate_egale: credit.rate_egale,
          scadenta: scadenta,
          intervalPerioada: this.intervalPerioadaDefault(
            creditDefault,
            credit.perioada_maxima
          ),
          intervalSuma: this.intervalSumaDefault(creditDefault),
          perioada_maxima: credit.perioada_maxima,
          arataAsigurare:
            jprop.asigurare == false
              ? false
              : creditDefault.asigurare_default ?? false,
          //voucher: voucher,
        },
        () => {
          this.simulareCredit();
        }
      );
    }
  };

  intervalPerioadaDefault = (credit, perioada_maxima) => {
    if (perioada_maxima === 0)
      return [
        {
          value: perioada_maxima,
          label: perioada_maxima,
        },
      ];
    return [
      {
        value: credit.perioada_minima,
        label: credit.perioada_minima,
      },
      {
        value: perioada_maxima,
        label: perioada_maxima,
      },
    ];
  };
  intervalSumaDefault = (credit) => {
    return [
      {
        value: credit.suma_minima,
        label: (
          <NumericFormat
            value={credit.suma_minima}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        value: credit.suma_maxima,
        label: (
          <NumericFormat
            value={credit.suma_maxima}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
    ];
  };
  setPerioada = (value) => {
    var error = this.validatePerioada(value);
    var err = this.state.error;
    err.perioada = null;
    if (error) {
      err.perioada = error;
      this.setState({ error: err });
      return;
    }
    let intervalPerioada = this.intervalPerioadaDefault(
      this.state.credit,
      this.state.perioada_maxima
    );
    const isNotMarkPerioadaInterval = !intervalPerioada.find(
      (x) => x.value === value
    );
    if (isNotMarkPerioadaInterval) {
      intervalPerioada.push({
        value: value,
        label: value,
      });
    }
    this.setState({ intervalPerioada: intervalPerioada, error: err }, () =>
      this.simulareCredit()
    );
  };
  validateSuma = (value) => {
    const { actions } = this.props;
    var err = "";
    if (
      !value ||
      value < this.state.credit.suma_minima ||
      value > this.state.credit.suma_maxima
    ) {
      err = `Suma solicitata poate fi minim ${this.state.credit.suma_minima?.toLocaleString(
        "ro-RO"
      )} ${
        this.state.credit.valuta
      } - maxim  ${this.state.credit.suma_maxima?.toLocaleString("ro-RO")} ${
        this.state.credit.valuta
      }`;
    }
    return actions.processMsg(err);
  };
  validatePerioada = (value) => {
    const { actions } = this.props;
    var err = "";
    var perioada_maxima = this.state.perioada_maxima;
    if (!!value && value > perioada_maxima) {
      err =`Perioada trebuie sa fie cuprinsa intre  ${this.state.credit.perioada_minima} luni - ${this.state.perioada_maxima} luni!`;
      /*
        this.state.credit.perioada_maxima != this.state.perioada_maxima
          ? `Varsta maxima la sfarsitul perioadei de creditare este depasita! Puteti solicita credit pe maxim ${this.state.perioada_maxima} luni`
          : `Perioada poate fi minim ${this.state.credit.perioada_minima} luni - ${this.state.perioada_maxima} luni!`;
      */
      return actions.processMsg(err);
    }

    if (
      !value ||
      value < this.state.credit.perioada_minima ||
      value > this.state.perioada_maxima
    ) {
      err = `Perioada poate fi minim ${this.state.credit.perioada_minima} luni - maxim ${this.state.credit.perioada_maxima} luni`;
      return actions.processMsg(err);
    }
  };
  setSuma = (value) => {
    //value = value ? value : this.state.suma;
    var error = this.validateSuma(value);
    var err = this.state.error;
    err.suma = null;
    if (error) {
      err.suma = error;
      this.setState({ error: err });
      return;
    }
    let intervalSuma = this.intervalSumaDefault(this.state.credit);
    const isNotMarkSumaInterval = !intervalSuma.find((x) => x.value === value);
    if (isNotMarkSumaInterval) {
      intervalSuma.push({
        value: value,
        label: (
          <NumericFormat
            value={value}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            //decimalScale={2}
            //fixedDecimalScale
            /*renderText={(value) => (
  <div className="deposit-interest">{value}</div>
)}*/
          />
        ),
      });
    }
    this.setState({ intervalSuma: intervalSuma, error: err }, () =>
      this.simulareCredit()
    );
    //this.simulareCredit();
  };
  setCredit = () => {
    const { produse, jprop } = this.props;
    const credit = produse[0]; //.find((p) => p.id === id);
    if (credit) {
      const intervalPerioada = this.intervalPerioadaDefault(
        credit,
        credit.perioada_maxima
      );
      const intervalSuma = this.intervalSumaDefault(credit);
      this.setState(
        {
          credit: credit,
          suma: credit.suma_default,
          perioada: credit.perioada_default,
          asigurare: credit.asigurare_default,
          dobanda_fixa: credit.dobanda_fixa_default,
          incasare_venit: credit.incasare_venit_default,
          intervalPerioada: intervalPerioada,
          intervalSuma: intervalSuma,
          perioada_maxima: credit.perioada_maxima,
          arataAsigurare:
            jprop.asigurare == false
              ? false
              : credit.asigurare_default ?? false,
          //voucher: props.voucher
        },
        () => {
          this.setSuma(this.state.suma);
        }
      );
    }
  };
  updateState = (state, callback) => {
    this.setState(state, () => {
      if (callback) {
        var errorMsgs = [];
        var error = this.validatePerioada(this.state.perioada);
        if (error) {
          errorMsgs.push(error);
        }
        error = this.validateSuma(this.state.suma);
        if (error) {
          errorMsgs.push(error);
        }
        if (errorMsgs.length !== 0) {
          return false;
        }
        this.simulareCredit();
      }
    });
  };
  simulareCredit = async (persistent) => {
    const { actions, isRequest, voucher, jprop } = this.props;
    const autocalc = jprop?.template?.autocalc ?? true;
    if (isRequest && persistent) {
      this.setState({ hasGoPrev: true });
    }
    //if (autocalc) {
    const sim = await actions.addSimulation({
      //idProdus: this.state.credit.id,
      voucher: voucher,
      suma: this.state.suma,
      perioada: this.state.perioada,
      dataScadenta: this.state.scadenta.data, // data_scadenta.format("YYYY/MM/DD"),
      dobandaFixa: this.state.dobanda_fixa,
      rateEgale: this.state.rate_egale,
      incasareVenit: this.state.incasare_venit,
      asigurare: this.state.asigurare ?? false,
      persistent: autocalc ? (persistent ? 1 : 0) : 99,
    });
    //};

    //if (isRequest) 
    //{
      //determin perioada maxima
      var perioada_maxima =  sim.simulare.perioada_maxima; //this.state.credit.perioada_maxima;
      this.setState(
        {
          perioada_maxima: perioada_maxima,
//          age_limit: sim.simulare.age_limit
        }
      )
      var intervalPerioada = this.intervalPerioadaDefault(
        this.state.credit,
        perioada_maxima
      );
      this.setState(
        {
          intervalPerioada: intervalPerioada,
        },
        // validez perioada maxima
        () => {
          var error = this.validatePerioada(this.state.perioada);
          var err = this.state.error;
          err.perioada = null;
          if (error) {
            err.perioada = error;
            this.setState({ error: err });
            return;
          }
        }
      );
    //}
  };
  creditNew = () => {
    const { isRequest, nextStepSave, simulare, navigate } = this.props;
    var { voucher } = this.props;
    if (voucher) {
      voucher = "/" + voucher;
    } else {
      voucher = "";
    }
    if (isRequest) {
      nextStepSave(simulare.uid);
    } else {
      navigate(`/new/retail-lending/${simulare.uid}${voucher}`);
    }
  };
  downloadDocument = () => {
    const { simulare } = this.props;
    this.props.actions.downloadDocument({
      type: "simulare",
      index: 1,
      parameters: {
        ID: simulare.uid,
      },
    });
  };

  goPrev = () => {
    const { actions, isRequest, simulare } = this.props;
    const { uid, ...sim } = simulare;
    actions.updateStore({ goPrev: null });
    if (isRequest)
      actions.updateStore({ simulare: { uid: null, ...sim } }, "template");
    else actions.updateStore({ uid: null }, "simulare");

    return true;
  };

  arataGrafic = (prop) => {
    const { fields, errors, isRequest, nextStepSave, simulare, jprop } =
      this.props;
    const autocalc = jprop?.template?.autocalc ?? true;
    var errorMsgs = [];
    if (this.state.asigurare && isRequest) {
      if (fields.stareSanatate.Valoare !== "1") {
        errorMsgs.push(
          "[DPF26]Nu putem sa iti oferim un credit cu Asigurare Allianz atasata deoarece starea ta de sanatate nu este una buna."
        );
      }
      if (fields.vechime.Valoare !== "1") {
        errorMsgs.push(
          "[DPF27]Nu putem sa iti oferim un credit cu Asigurare Allianz atasata deoarece, nu ai minim 6 luni vechime la actualul loc de munca. Te rugam sa modifici optiunea Asigurare Allianz."
        );
      }
      if (fields.incapacitate.Valoare !== "1") {
        errorMsgs.push(
          "[DPF28]Nu putem sa iti oferim un credit cu Asigurare Allianz atasata deoarece ai fost in incapacitate de munca mai mult de 30 zile in ultimul an."
        );
      }
      if (fields.confirmAsigurare.Valoare !== "1") {
        errorMsgs.push(
          "[DPF29] Pentru a aplica la un credit cu asigurare trebuie sa acceptati continutul documentelor de pre-ofertare Allianz. Daca nu sunteti de acord cu continutul acestor documente va rugam sa modificati optiunea de Asigurare Atasata creditului."
        );
      }
    }

    var error = this.validatePerioada(this.state.perioada);
    if (error) {
      errorMsgs.push(error);
    }
    error = this.validateSuma(this.state.suma);
    if (error) {
      errorMsgs.push(error);
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return false;
    }
    if (autocalc) {
      this.simulareCredit(prop);
    } else {
      nextStepSave(simulare.uid);
    }
  };
  handleViewAcord = (idDoc, cimpFld) => {
    const { actions } = this.props;
    actions.getPrintFile({ id: idDoc });
    this.setState({ isOpen: true, cimpFld: cimpFld, btnDisabled: true });
  };

  handleAcceptAcord = () => {
    const { actions } = this.props;
    actions.updateValue(this.state.cimpFld, "1");
    this.setState({ isOpen: false, cimpFld: null });
  };
  handleCancelAcord = () => {
    const { actions, errors } = this.props;
    actions.updateValue(this.state.cimpFld, "0");
    this.setState({ isOpen: false, cimpFld: null });
    errors.open(["[DPF29]"]);
  };
  handleScroll = (e) => {
    //get how much pixels left to scrolling our ReactElement
    const x = e.target.getBoundingClientRect();
    const limit = x.height + x.top;
    const r = this.viewElement.getBoundingClientRect();
    const top = r.top;
    this.setState({ btnDisabled: top > limit });
  };
  render() {
    const {
      isReadonly,
      //produse,
      simulare,
      scadentar,
      comisioane,
      interval_scadenta,
      actions,
      isRequest,
      title,
      subtitle,
      fields,
      title2,
      subtitle2,
      jprop,
      doc,
      file,
    } = this.props;

    let setRef = (el) => {
      this.viewElement = el;
    };

    return (
      <div className="e-card" /*id={`dco${doc.Id}`} key={doc.Id}*/>
        {isRequest && this.state.asigurare && (
          <Dialog
            open={!!file && this.state.isOpen}
            maxWidth="lg"
            className="retail-lending"
          >
            <DialogTitle>{jprop.template.header}</DialogTitle>
            <DialogContent dividers={true} onScroll={this.handleScroll}>
              <div className="acceptance-dialog">
                <div className="body">
                  <PdfAllPages
                    file={file}
                    onLoadSuccess={() => {
                      this.setState({ isLoading: false });
                      actions.updateMainStore({ forceWaiting: false });
                    }}
                  />
                  <div className="bottom" ref={setRef}></div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                className="btn"
                onClick={this.handleCancelAcord}
                disabled={this.state.btnDisabled}
              >
                Renunta
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="btn"
                onClick={this.handleAcceptAcord}
                disabled={this.state.btnDisabled}
              >
                De acord
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <div className="e-card-content-document">
          {simulare && simulare.uid && this.state.credit ? (
            <CrediteSimulareGrafic
              state={this.state}
              simulare={simulare}
              scadentar={scadentar}
              comisioane={comisioane}
              //updateStore={actions.updateStore}
              creditNew={this.creditNew}
              downloadDocument={this.downloadDocument}
              isRequest={isRequest}
              title={title2}
              subtitle={subtitle2}
              goPrev={this.goPrev}
            />
          ) : (
            <CrediteSimulareProduse
              state={this.state}
              isReadonly={isReadonly}
              //produse={produse}
              comisioane={comisioane}
              simulare={simulare}
              setPerioada={this.setPerioada}
              setSuma={this.setSuma}
              updateState={this.updateState}
              simulareCredit={this.simulareCredit}
              interval_scadenta={interval_scadenta}
              isRequest={isRequest}
              title={title}
              subtitle={subtitle}
              updateValue={actions.updateValue}
              fields={fields}
              arataGrafic={this.arataGrafic}
              viewAcord={this.handleViewAcord}
              jprop={jprop.template || {}}
              doc={doc}
            />
          )}
        </div>
      </div>
    );
  }
}
export default observer(CrediteSimulare);
