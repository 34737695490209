import React, { useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import styled from "styled-components";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

import "../index.css";

function Field6_Edit_D(props) {
  const { row, updateRow, col, tableId, isReadonly } = props;
  const DatePickerCss = styled(DatePicker)`
    ${col && col.eventProps ? col.eventProps.style : null}
  `;
  return (
    <DatePickerCss
      id={`edt-${col.key}`}
      value={
        row && row[col.key] && row[col.key] !== "01/01/1900"
          ? moment(row[col.key], "DD/MM/YYYY HH:mm:ss")
          : null
      }
      label={col.inline ? col.Caption : null}
      onAccept={(prop) => {
        if (prop && prop._isValid) {
          updateRow(row, col.tip, col.key, prop, tableId);
        }
        if (!prop) {
          //am sters valoarea
          updateRow(row, col.tip, col.key, null, tableId);
        }
      }}
      format="DD/MM/YYYY"
      //clearable
      disabled={col.isReadonly || isReadonly}
      className={`doc-table-value${
        col.isReadonly || isReadonly ? " disabled" : ""
      }`}
      inputVariant="outlined"
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      //style={{width: 210}}
      size="small"
      sx={{
        width: "-webkit-fill-available",
        input: { padding: "8.5px 14px" },
      }}
      slots={{
        textField: (params) => (
          <TextField
            {...params}
            defaultValue={params.value}
            onBlur={(prop) => {
              var value = moment(prop.target.value, "DD/MM/YYYY");
              updateRow(
                row,
                col.tip,
                col.key,
                value.isValid() ? value : null,
                tableId
              );
            }}
          />
        ),
      }}
    />
  );
}

export default observer(Field6_Edit_D);
