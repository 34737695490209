import React from 'react';
import View from "./views";


const  CereriWeb = (props) => {
  return (
      	<View {...props}/>
  );
}

export default CereriWeb;
