import React from "react";
import { observer } from "mobx-react";
import {
  ErrorOutlineOutlined,
  WarningOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import EditDialog from "./editDialog";

import "./index.css";

function Errors(props) {
    const { close, isOpen, message, className, header, type, theme } =
      props;
    const Icon = () => {
      switch (type) {
        case "error":
          return <ErrorOutlineOutlined className="error-icon" />;
        case "info":
          return <InfoOutlined className="info-icon" />;
        case "warning":
          return <WarningOutlined className="warning-icon" />;
        default:
          return <ErrorOutlineOutlined className="error-icon" />;
      }
    };
    //    var css = "error-dialog";
    //    css = type === "info" ? "info-dialog" : css;
    //    css = type === "warning" ? "warning-dialog" : css;
    return (
      isOpen && (
        <EditDialog
          className={className}
          isOpen={isOpen}
          header={
            header
              ? header
              : (theme !== "retail" || type === "error") && "Eroare"
          }
          showSave={true}
          saveCaption="Inchide"
          showClose={false}
          maxWidth="md"
          save={close}
        >
          <div className="error-dialog">
            {message.length !== 0 && theme !== "retail" && <Icon />}
            <div className="errors">
              {message.map((error, index) => {
                return (
                  <div
                    key={`eror${index}`}
                    className={"error-message " + (theme || "")}
                  >
                    {error}
                  </div>
                );
              })}
            </div>
          </div>
        </EditDialog>
      )
    );
  }
export default observer(Errors);
