import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export function IFrame(props) {
  const { children, title, ...prop } = props;
  const [mountNode, setMountNode] = useState(null);
  const setContentRef = (contentRef) => {
    setMountNode(contentRef?.contentWindow?.document?.body);
  };
  return (
    <iframe title={title} {...prop} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
}
