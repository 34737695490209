import { create } from "mobx-persist";
import clientPersist from "client-persist";
import Cookies from "universal-cookie";
import Api from "Common/src/Api";
import MainStore from "Main/src/stores/index";
import CereriStore from "CereriWeb/src/stores/index";
//import CereriNoiStore from "CereriNoiWeb/src/stores/index";
import CerereWebStore from "CerereWeb/src/stores/index";

import CerereNouaStore from "CerereNouaWeb/src/stores/index";
import CerereStore from "Cerere/src/stores/index";
import CommonStore from "TemplateCerere/src/stores/index";
import CreditStore from "Credit/src/stores/index";

export default class RootStore {
  sendErrors = null;

  constructor() {
    this.mainStore = new MainStore(this);
    this.mainStore.initializat = false;
    this.api = new Api();

    // salvare pe sesiune ********************
    clientPersist.setDriver(clientPersist.SESSIONSTORAGE); // daca se doreste salvarea pe sesiune
    const hydrate = create({
      storage: clientPersist, // daca se doreste salvarea pe sesiune
      jsonify: true,
    });

    // salvare in local storage **********************
    /*
    const hydrate = create({
      storage:localStorage,
      jsonify: true,
    });
   */

    hydrate("MainStore", this.mainStore).then(
      () => (this.mainStore.initializat = true)
    );

    this.cereriStore = new CereriStore(this);
    this.cerereWebStore = new CerereWebStore(this);
    this.cerereNouaStore = new CerereNouaStore(this);
    this.cerereStore = new CerereStore(this);
    this.commonStore = new CommonStore(this);
    this.creditStore = new CreditStore(this);
    this.api.logout = this.mainStore.logout;
    this.api.addError = this.addError;
    this.api.sendError = this.sendError;

    this.retrySendError();
    this.getEnvironment();
  }

  addError = (error) => {
    const cookies = new Cookies();
    //daca exista cookie, se preiau erorile
    var errors = cookies.get("PbkErrors") ?? [];
    //se adauga erori noi
    errors.push(error);
    //se scriu errorile
    cookies.set("PbkErrors", errors);
    cookies.samesite = "Lax";

    //daca nu am timer il pornesc
    if (!this.sendErrors) {
      this.retrySendError();
    }
  };

  sendError = async () => {
    const cookies = new Cookies();
    cookies.samesite = "Lax";
    var errors = cookies.get("PbkErrors");
    if (errors) {
      var isSucces = await this.api.logs(errors);

      //daca logul este trimis cu succes se sterge cookie
      if (isSucces) {
        cookies.remove("PbkErrors");
      }
    } else {
      //dupa trimiterea tuturor erprilor anulez timer-ul
      clearInterval(this.sendErrors);
    }
  };

  retrySendError = () => {
    this.sendErrors = setInterval(() => {
      this.sendError();
    }, 10000);
  };
  getEnvironment = async () => {
    var results = await this.api.fetch("/environment");
    if (results && !results.error && results != "401") {
      this.mainStore.environment = results;
    }
  };
}
