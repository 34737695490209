import React from "react";
import { observer } from "mobx-react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Radial separators
import RadialSeparators from "./radialSeparators";

const circularProgressBar=(props)=> {
  const { value, count, caption } = props;
  return (
    <CircularProgressbarWithChildren
    value={value}
    text={caption}
    strokeWidth={10}
    styles={buildStyles({
      strokeLinecap: "butt"
    })}
  >
    <RadialSeparators
      count={count}
      style={{
        background: "#001c85",
        width: "5px",
        // This needs to be equal to props.strokeWidth
        height: `${14}%`,
      }}
      
    />
  </CircularProgressbarWithChildren>
  );
}
export default observer(circularProgressBar);
