import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NumericFormat } from "react-number-format";

import { Checkbox } from "TemplateCerere";
import { getValueKey, getValueOfList } from "Utils";
import "../index.css";

function NumericFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      //onBlur={(prop) => actions.updateValue(item, prop.target.value)}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale
      valueIsNumericString
      style={{ textAlign: "right" }}
    />
  );
}
function visibleHeaders (item) {
  var cols = item.data.columns.filter((x) => x.isDetail && x.isVisible);
  var h = [];
  cols.forEach((c) => {
    var hIndex = h.length - 1;
    var hInfo = c.header || { text: "", expanded: true, inline: false };
    if (
      hIndex > -1 &&
      hInfo.text.toLowerCase() === h[hIndex].text.toLowerCase()
    ) {
      //sunt pe acelasi header, adaug coloana la header
      h[hIndex].columns.push(c);
    } //creez un header nou
    else {
      h.push({ ...hInfo, columns: [c] });
    }
  });
  return h;
};

function Field6_fields(props) {
  const { item, data } = props;
  var rows = data.rows.filter((r) => item.isVisibleRow(r)),
    row;
  row = item.selectedRow === -1 ? rows[0] : rows[item.selectedRow];

  console.debug("render", item.Id);

  /* const TableContainerCss = styled(TableContainer)`${eventProps.style}`;
         const DivCss = styled.div`${eventProps.style}`;*/

  
  
  var headers = visibleHeaders(item);
  return (
    <div>
      {headers.map((header, index) => (
        <div key={`hdx_${index}`} className="card-header">
          <div className="card-header-title">{header.text}</div>
          <div className="e-card-content-document">
            {header.columns.map((col) => {
              return (
                <div key={col.key} className="doc-table-row">
                  <div className="doc-table-label">{col.Caption}</div>

                  <div className="doc-table-value">
                    {col.tip === "c" && (
                      <Checkbox
                        checked={row ? row[col.key] : false}
                        //onChange={(prop)=>actions.updateValue(item, prop.target.checked)}
                        color="primary"
                        disabled={true}
                        className="doc-table-value disabled"
                        variant="outlined"
                      />
                    )}
                    {col.tip === "l" && (
                      <Autocomplete
                        controled="true"
                        options={col.lista ? col.lista : []}
                        getOptionLabel={(option) => getValueKey(option, 1)}
                        //clearOnEscape
                        //value={data.row ? data.row[col.key] :null }
                        value={
                          row && row[col.key] != null
                            ? getValueOfList(col.lista, row[col.key].toString())
                            : null
                        }
                        //style={{ width: 350}}
                        size="small"
                        disabled={true}
                        //onChange={(event, newValue) => { actions.updateValue(item, newValue && getValueKey(newValue, 0))}}
                        //autoComplete
                        className="disabled"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //label={col.headerText}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                    {col.tip === "s" && (
                      <Autocomplete
                        controled="true"
                        options={col.lista ? col.lista : []}
                        getOptionLabel={(option) => getValueKey(option, 1)}
                        //clearOnEscape
                        //value={data.row ? data.row[col.key] :null }
                        value={
                          row && row[col.key] != null
                            ? getValueOfList(col.lista, row[col.key].toString())
                            : null
                        }
                        //style={{ width: 350}}
                        size="small"
                        disabled={true}
                        //onChange={(event, newValue) => { actions.updateValue(item, newValue && getValueKey(newValue, 0))}}
                        //autoComplete
                        className="disabled"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //label={col.headerText}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                    {col.tip === "d" && (
                      <DatePicker
                        value={
                          row && row[col.key] && row[col.key] !== "01/01/1900"
                            ? moment(row[col.key], "DD/MM/YYYY")
                            : null
                        }
                        //label={inline ? item.Caption : null}
                        //onChange={(prop)=> { if(prop._isValid) actions.updateValue(item, prop)}}
                        format="DD/MM/YYYY"
                        disabled={true}
                        //clearable
                        className="doc-table-value disabled"
                        inputVariant="outlined"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        //style={{width: 210}}
                        size="small"
                        sx={{
                          width: "-webkit-fill-available",
                          input: { padding: "8.5px 14px" },
                        }}
                      />
                    )}
                    {col.tip === "n" && (
                      <TextField
                        value={row ? row[col.key] : null}
                        disabled={true}
                        className={"doc-table-value disabled"}
                        variant="outlined"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                          inputProps: { decimalScale: 2 },
                        }}
                        //style={eventProps.style}
                        size="small"
                      />
                    )}
                    {col.tip === "t" && (
                      <TextField
                        value={
                          row && row[col.key] ? row[col.key].toString() : ""
                        }
                        //label={inline ? item.Caption : null}
                        readOnly={true}
                        disabled={true}
                        variant="outlined"
                        className="doc-table-value disabled"
                        //style={{  width: width(), maxWidth: "calc(100% - 40px)"}}
                        size="small"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}

export default observer(Field6_fields);
