import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/ro";

import "react-app-polyfill/stable";
import Main from "./Main/src";
import "./App.css";
import "moment/locale/ro";

import { Provider } from "mobx-react";
import RootStore from "./rootStore";
import ErrorBoundary from "./errorBoundary ";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider {...new RootStore()}>
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ro">
        <BrowserRouter basename={"/"}>
          <Main />
        </BrowserRouter>
      </LocalizationProvider>
    </ErrorBoundary>
  </Provider>
);

/*
ReactDOM.render(
   <BrowserRouter>
      <Main />
  </BrowserRouter>,
  document.getElementById("root")
);
*/
