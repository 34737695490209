//import React from 'react';
import {
  action,
  extendObservable,
  set,
  toJS,
  /*makeObservable,
  computed,
  observable,
  override,*/
} from "mobx";
import { renameObjectKeys } from "Utils";
//import { Api } from "Common/src";

const modelFilterDialog = {
  isOpen: false,
  header: "Modificare",
  child: null,
  save: null,
  showSave: true,
  showClose: true,
};
const model = {
  cereri: [],
  paginare: {
    nrInregistrari: null,
    numarPagina: 0,
    marimePagina: 50,
    order: "desc",
    orderBy: "INDICE_CERERE",
  },

  cimpuri: [],
  cimpuriFiltrare: [],
  filtre: {},
  showFluxuri: false,
  filterDialog: modelFilterDialog,
};

class CereriStore {
  //api = null;
  filtreAplicate = {};
  /*cereri = [];
  paginare = {
    nrInregistrari: null,
    numarPagina: 0,
    marimePagina: 50,
    order: "desc",
    orderBy: "INDICE_CERERE",
  };

  cimpuri = [];
  cimpuriFiltrare = [];
  filtre = {};
  showFluxuri = false;
  filterDialog = modelFilterDialog;
*/
  constructor(props) {
    this.rootStore = props;
    extendObservable(this, model);
    extendObservable(this.filterDialog, modelFilterDialog);

    //this.rootStore.api = new Api({ logout: props.mainStore.logout });

    /* makeObservable(this, {
      doActivate: action,
      fetchData: action,
      addRequest: action,
      newRequest: action,
      changePage: action,
      doDeactivate: action,
      updateFilter: action,
      filterApply: action,
      filterReset: action,
      filter: action,
      saveFilterDialog: action,
      closeFilterDialog: action,
      sort: action,
    });*/
  }

  @action
  doActivate = async (params, history) => {
    if (this.rootStore.mainStore.tryLogin) {
      return;
    }
    set(this.paginare, model.paginare);
    if (this.rootStore?.mainStore?.flux?.noMatch) {
      return;
    }
    this.fetchData();
  };

  @action
  fetchData = async () => {
    this.rootStore.mainStore.isWaiting = true;
    var apiPath = `/api/request/user-requests`;

    var results =
      this.rootStore.mainStore.flux &&
      (await this.rootStore.api.post(apiPath, {
        flux: this.rootStore.mainStore.flux.Flow,
        numarPagina: this.paginare.numarPagina,
        marimePagina: this.paginare.marimePagina,
        filtre: this.filtreAplicate,
        order: this.paginare.order,
        orderBy: this.paginare.orderBy,
      }));
    this.filtre = this.filtreAplicate;
    if (results && !results.error && results != "401") {
      var resultsJson = await renameObjectKeys(results);
      var data = {};

      if (resultsJson.cimpuri) {
        /*TO DO comentat pt test*/
        data.cimpuri = resultsJson.cimpuri.filter((x) => x.Vizibil);
        //data.cimpuri = resultsJson.cimpuri;
        data.cimpuriFiltrare = resultsJson.cimpuri.filter((x) => x.Filtru);
        data.cereri = resultsJson.cereri;

        this.paginare.nrInregistrari =
          resultsJson.inregistrari[0].NrInregistrari;
        set(this, data);
      }
    }
    if (results && results.error) {
      this.rootStore.mainStore.errors.open(results.error);
      //return;
    }
    this.rootStore.mainStore.isWaiting = false;
  };

  @action
  addRequest = async (history) => {
    var url = `/${this.rootStore.mainStore.appType}/new/${this.rootStore.mainStore.flux.Flow}`;
    //daca e autocreate se creeaza fluxul
    if (this.rootStore.mainStore.flux.AutoCreate) {
      url = await this.newRequest();
    }
    if (history && url) {
      this.rootStore.mainStore.updateValue({ preventGoBack: false });
      history.push(url);
    }
  };

  @action
  newRequest = async () => {
    var apiPath = `/api/request/add`;
    var params = {
      name: this.rootStore.mainStore.flux
        .Flow ,
      par: {} /* trebuie initializati si parametrii*/,
    };
    var results = await this.rootStore.api.post(apiPath, params);
    var uidCerere = null;
    if (results.r === 0) {
      uidCerere = results.msg;
      var url = `/${this.rootStore.mainStore.appType}/request/${this.rootStore.mainStore.flux.Flow}/${uidCerere}`;
      return url;
    }
  };

  @action
  changePage = (value) => {
    set(this.paginare, value);
    this.fetchData();
  };

  @action
  doDeactivate = async () => {
    set(this, model);
  };

  @action
  updateFilter = (cimp, valoare, end) => {
    if (valoare) {
      this.filtre[cimp] = valoare;
      if (end && !this.filtre[end]) {
        this.filtre[end] = valoare;
      }
    } else {
      delete this.filtre[cimp];
    }
  };

  @action
  filterApply = () => {
    //aplicare filtre
    this.filtreAplicate = toJS(this.filtre);
    this.fetchData();
    this.closeFilterDialog();
  };
  @action
  filterReset = () => {
    Object.keys(this.filtre).forEach((x) => {
      this.filtre[x] = null;
      delete this.filtre[x];
    });
  };
  @action
  filter = (child) => {
    this.filterDialog.isOpen = true;
    this.filterDialog.header = "Filtrare Cereri";
    this.filterDialog.saveCaption = "Aplica Filtru";
    this.filterDialog.showSave = true;
    this.filterDialog.child = child;
    this.filterDialog.save = this.filterApply;
  };

  @action
  saveFilterDialog = () => {
    if (this.filterDialog.save) {
      this.filterDialog.save();
    }
  };

  //actiunea de inchidere a dialogului de editare
  @action
  closeFilterDialog = () => {
    set(this.filtre, this.filtreAplicate);
    set(this.filterDialog, modelFilterDialog);
  };

  @action
  sort = (property) => {
    const isAsc =
      this.paginare.orderBy === property && this.paginare.order === "asc";
    const order = isAsc ? "desc" : "asc";
    const orderBy = property;
    set(this.paginare, { order: order, orderBy: orderBy });
    this.fetchData();
  };
}
export default CereriStore;
