import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";

import { PasswordField, Checkbox } from "TemplateCerere";
import { onEnter, actionIsNumber } from "Utils";
import Copyright from "../copyright";
import LoginCode from "./loginCode";

function LoginPhoneCard(props) {
  //showCNP = false;
  const {
    cnp,
    phone,
    smsCode,
    firstDigits,
    lastDigits,
    acordA,
    acordB,
    flowName,
  } = props.store;
  const {
    downloadAcord,
    updateValue,
    confirmPhone,
    sendPhone,
    codeRequest,
    errors,
  } = props.store;
  const history = props.history;

  const validare = () => {
    var errorMsgs = [];
    if (!cnp || cnp.length !== 13 || isNaN(cnp)) {
      errorMsgs.push(
        "[LP004] CNP-ul introdus nu este valid, va rugam sa incercati din nou."
      );
    }
    if (!firstDigits || firstDigits.length !== 4 || isNaN(firstDigits)) {
      errorMsgs.push(
        "[LP005] Introduceti corect PRIMELE 4 cifre ale  numarului de card!"
      );
    }
    if (!lastDigits || lastDigits.length !== 4 || isNaN(lastDigits)) {
      errorMsgs.push(
        "[LP006] Introduceti corect ULTIMELE 4 cifre ale  numarului de card!"
      );
    }
    if (!phone || phone.length !== 10) {
      errorMsgs.push(
        "[LP007] Introduceti corect numarul de telefon (de forma: 07xxxxxxxx) !"
      );
    }

    if (!acordA) {
      errorMsgs.push(
        "[LP008] Trebuie sa acceptati Termenii si Conditiile privind prelucrarea datelor in cadrul accesarii online a unor servicii aferente produselor de carduri Persoane Fizice!"
      );
    }
    if (!acordB) {
      errorMsgs.push(
        "[LP009] Trebuie sa acceptati Conditiile Generale pentru utilizarea cardurilor de debit Persoane Fizice!"
      );
    }
    return errorMsgs;
  };

  const handlerSendPhone = () => {
    var errorMsgs = validare();

    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    sendPhone({
      cnp: cnp,
      firstDigits: firstDigits,
      lastDigits: lastDigits,
      acordA: acordA.toString(),
      acordB: acordB.toString(),
      clientType: "F",
    });
  };

  const handlerConfirmPhone = () => {
    var errorMsgs = validare();
    if (!smsCode || smsCode.length === 0) {
      errorMsgs.push("[LP002] Completati codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    confirmPhone(
      {
        cnp: cnp,
        firstDigits: firstDigits,
        lastDigits: lastDigits,
        acordA: acordA.toString(),
        acordB: acordB.toString(),
        clientType: "F",
      },
      history
    );
  };
  return (
    <div /*component="main"  maxWidth={false}*/>
      <CssBaseline />
      <div className="login">
        <Avatar className="avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          className="login-title"
          align="center"
        >
          {flowName}
        </Typography>
        <div className="form">
          <PasswordField
            id="cnp"
            label="CNP"
            //name="CNP"
            value={cnp ? cnp : ""}
            //error={error}
            //helperText={error}
            className="login-pwd"
            variant="outlined"
            onChange={(prop) => updateValue({ cnp: prop.target.value })}
            mask="9999999999999"
            maskChar=""
            //onChange={(prop) => actionIsNumber(prop.target.value, updateValue, "cnp") }
          />

          <Typography className="label">Numarul Cardului</Typography>

          <div className="hlist">
            <TextField
              variant="outlined"
              margin="normal"
              required
              //fullWidth
              //maxWidth="100px"
              id="firstDigits"
              label="Primele 4 Cifre"
              name="firstDigits"
              value={firstDigits ? firstDigits : ""}
              onChange={(prop) =>
                actionIsNumber(prop.target.value, updateValue, "firstDigits")
              }
              className="card"
            />
            <TextField
              variant="outlined"
              margin="normal"
              placeholder="* * * *"
              className="card disabled center"
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              placeholder="* * * *"
              className="card disabled center"
              disabled
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              id="lastDigits"
              label="Ultimele 4 Cifre"
              name="lastDigits"
              value={lastDigits ? lastDigits : ""}
              onChange={(prop) =>
                actionIsNumber(prop.target.value, updateValue, "lastDigits")
              }
              className="card"
            />
          </div>
          <div className="acord">
            <Checkbox
              checked={acordA}
              onChange={(prop) => updateValue({ acordA: prop.target.checked })}
              color="primary"
            />
            <div className="acord-txt">
              Am citit si sunt de acord cu
              <Link onClick={() => downloadAcord(1)} className="termenii">
                Termenii si Conditiile
              </Link>
              privind prelucrarea datelor in cadrul accesarii online a
              serviciilor de carduri
            </div>
          </div>
          <div className="acord">
            <Checkbox
              checked={acordB}
              onChange={(prop) => updateValue({ acordB: prop.target.checked })}
              color="primary"
            />
            <div className="acord-txt">
              Am citit si sunt de acord cu
              <Link onClick={() => downloadAcord(2)} className="termenii">
                Conditiile Generale
              </Link>
              pentru utilizarea cardurilor de debit Persoane Fizice
            </div>
          </div>
          <Tooltip title="Se va introduce numarul de telefon mobil declarat de dvs. la deschiderea relatiei de afaceri cu Banca sau cel pe care l-ati actualizat ulterior.">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Nr Telefon"
              name="Nr Telefon"
              value={phone ? phone : ""}
              onChange={(prop) => updateValue({ phone: prop.target.value })}
              className="phone"
              onKeyPress={(event) => onEnter(event, handlerSendPhone)}
              //helperText="Se va introduce numărul de telefon mobil declarat de dvs. la deschiderea relației de afaceri cu Banca sau cel pe care l-ați actualizat ulterior."
            />
          </Tooltip>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="submit"
            onClick={() => handlerSendPhone()}
            //disabled={!phone}
          >
            {!codeRequest && smsCode ? "Retrimite SMS" : "Trimite SMS"}
          </Button>

          <LoginCode handlerConfirmPhone={handlerConfirmPhone} />
        </div>
      </div>

      <Copyright />
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(LoginPhoneCard))
);
