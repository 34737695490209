import React from "react";
import { observer } from "mobx-react";
import Switch from "@mui/material/Switch";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

import { Checkbox, TooltipIcon, LinkLabel } from "TemplateCerere";
import "../index.css";
import { Typography } from "@mui/material";
import DivCss from "../components/divCss";

function Field5(props) {
  const { item, actions, css } = props;
  var { inline } = props;
  if (item.Jprop.inline !== undefined) {
    inline = item.Jprop.inline;
  }
  var cssLabel = (!inline ? "inline-label" : "check-box-label") + " doc-field";
  var controlType =
    ["checkbox", "switch", "radio-v", "radio-h"].find(
      (e) => e === item.Jprop.control
    ) || "checkbox";

  const valueTrue = item.Jprop.valueTrue ?? "1";
  const valueFalse = item.Jprop.valueFalse ?? "0";
  const { controlLabel, infoLabel, labelType, linkClick } = item.Jprop;
  return (
    !!item.isVisible && (
      <DivCss
        item={item}
        inline={inline}
        css={css}
        gridItem={props.gridItem}
        id={`f5-${item.Id}`}
        key={item.Id}
      >
        <FormControl
          id={`f5c-${item.Id}`}
          required={item.Obligatoriu}
          error={!!item.error}
        >
          <FormGroup id={`f5g-${item.Id}`} /*style={{ alignItems: "start" }}*/>
            <div
              id={`f5-${item.Id}`}
              key={item.Id}
              className={
                cssLabel
              } /*className={'doc-field' + (!inline ? ' inline-label' : ' check-box-label')}*/
            >
              <div
                id={`f5l-${item.Id}`}
                className={inline ? "doc-field-check" : "doc-field-label"}
              >
                {labelType === "link" ? (
                  <LinkLabel
                    linkClick={linkClick}
                    actions={actions}
                    label={item.Caption + (item.Obligatoriu ? " *" : "")}
                  />
                ) : (
                  item.Caption + (item.Obligatoriu ? " *" : "")
                )}
                {infoLabel && (
                  <TooltipIcon tooltip={infoLabel} className="right" />
                )}
              </div>
              <div>
                {(controlType === "checkbox" && (
                  <Checkbox
                    id={`f5f-${item.Id}`}
                    checked={item.Valoare === valueTrue}
                    onChange={(prop) =>
                      actions.updateValue(
                        item,
                        prop.target.checked ? valueTrue : valueFalse
                      )
                    }
                    color="primary"
                    disabled={item.isReadonly || item.docReadOnly}
                    className={
                      item.isReadonly || item.docReadOnly ? "disabled" : null
                    }
                    variant="outlined"
                    required={item.Obligatoriu}
                    indeterminate={item.Valoare == null}
                  />
                )) ||
                  (controlType === "switch" && (
                    <FormControlLabel
                      control={
                        <Switch
                          id={`f5f-${item.Id}`}
                          checked={item.Valoare === valueTrue}
                          onChange={(prop) =>
                            actions.updateValue(
                              item,
                              prop.target.checked ? valueTrue : valueFalse
                            )
                          }
                          color="primary"
                          disabled={item.isReadonly || item.docReadOnly}
                          className={
                            item.isReadonly || item.docReadOnly
                              ? "disabled"
                              : null
                          }
                          required={item.Obligatoriu}
                        />
                      }
                      label={
                        controlLabel
                          ? item.Valoare === valueTrue
                            ? "Da"
                            : "Nu"
                          : null
                      }
                      labelPlacement={controlLabel} //{dobandaFixa ? "start" : "end"}
                    />
                  )) ||
                  ((controlType === "radio-v" || controlType === "radio-h") && (
                    <RadioGroup
                      id={`f5f-${item.Id}`}
                      name={`f5f-${item.Id}`}
                      value={item.Valoare}
                      row={controlType === "radio-h"}
                      onChange={(event) => {
                        actions.updateValue(item, event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={valueTrue}
                        control={<Radio color="primary" />}
                        label="Da"
                        id={`f5f-${item.Id}-1`}
                      />
                      <FormControlLabel
                        value={valueFalse}
                        control={<Radio color="primary" />}
                        label="Nu"
                        id={`f5f-${item.Id}-0`}
                      />
                    </RadioGroup>
                  ))}
              </div>
            </div>
          </FormGroup>
          {item.Jprop.text &&
            item.Jprop.text.length > 0 &&
            item.Jprop.text.map((t, index) => (
              <Typography key={`t-${index}`} className="doc-field-text">
                {t}
              </Typography>
            ))}
          <FormHelperText style={{ paddingLeft: "10px" }}>
            {item.error || item.Jprop.helperText}{" "}
          </FormHelperText>
        </FormControl>
      </DivCss>
    )
  );
}
export default observer(Field5);
