import React from "react";

export default function PachetHeaderOption(props) {
  const { caption, type, className, icon } = props;
  return (
    <div className={["pachet-header-option", `pachet-header-option-${type}`, className].join(" ")}>
      <div className="pachet-header-option-icon">
        {icon}
      </div>
      <div>
        <div className="pachet-header-option-caption">
          {caption}
        </div>
      </div>
    </div>
  );
}
