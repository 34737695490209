export const dataType=(value, format)=>{
    
    switch (value) {
        case "Int16":
            return({format: 'N0', align:"right"});
            //return( "N0")
        case "Int32":
            return({align:"right"});
            //return( "N0")
        case "DateTime": 
            return( {type:'date', format:'dd.MM.yyyy', align:"center"})
        case "Decimal": 
            if (format==="#0,.0000")
                return {format: 'N4', align:"right"};
            if (format==="#0,.00")
                return {format: 'N2', align:"right"};
            return({format: 'N2', align:"right"});
        case "Boolean": 
            return({format: 'B', align:"center"});
            //return("B")
        default:
            return({format: 'S', align:"left"});
    }
}