import React from "react";
import { observer } from "mobx-react";
//import Checkbox from "@mui/material/Checkbox";
import { Checkbox } from "TemplateCerere";

import "../index.css";

function Filter_C(props) {
  const { cimp, valoare, updateFilter, end } = props;

  return (
    <Checkbox
      checked={valoare ? valoare === "1" : false}
      onChange={(prop) => {
        var value = prop.target.checked && "1";
        updateFilter(cimp, value, end);
      }}
      color="primary"
      //className="doc-table-value"
      variant="outlined"
    />
  );
}
export default observer(Filter_C);
