/* eslint-disable eqeqeq */
import React from "react";
import { action, extendObservable, set } from "mobx";
import { renameObjectKeys, runEvent, getValueOfList, getValueKey } from "Utils";
import moment from "moment";
import { Buffer } from "buffer";
import StepList from "../views/stepList";

//import { Api } from "Common/src";
const modelEditDialog = {
  isOpen: false,
  header: "Modificare",
  child: null,
  save: null,
  showSave: true,
  showClose: true,
  className: null,
  id: "",
};
const modelPreview = {
  isOpen: false,
  name: "",
  image: null,
  content: null,
};
const modelDialog = {
  isOpen: false,
  message: "Asteptati!",
  header: "Salvare document",
  url: null,
  isNextStep: false,
};

const modelEditTable = {
  table: {},
  newId: null,
};
const modelInit = {
  uidCerere: null,
  type: null,
  id: null,
  idFlux: null,
};
const modelShape = {
  error: null,
  //cimpuri: [],
  //taburi: [],
  currentTab: null,
  sectiuni: [],
  countSteps: 0,
  currentStep: 0,
  caption: null,
  canPass: null,
  canSave: null,
  //changedFields: {},
  param: {},
  dialog: modelDialog,
  preview: modelPreview,
  errors: {},
  nextSteps: [],
  denumire: null,
  docListareCerere: [],
  editDialog: modelEditDialog,
  editTable: modelEditTable,
  changedTabels: [],
  //allFields: {},
  autoSave: false, //se salveaza cererea la schimbarea tabului??, Daca il fac tru, trebuie sa aduc AFT la fiecare salvare
  culoare: "#000000",
};

class CerereStore {
  // api = null;
  //liste = [];

  constructor(props) {
    this.rootStore = props;
    //this.rootStore.api = new Api({ logout: props.mainStore.logout });
    extendObservable(this, modelShape);
    extendObservable(this, modelInit);
    extendObservable(this.preview, modelPreview);
    extendObservable(this.dialog, modelDialog);
    extendObservable(this.editDialog, modelEditDialog);
    extendObservable(this.editTable, modelEditTable);
  }

  @action
  doActivate = async (params) => {
    if (this.rootStore.mainStore.tryLogin) {
      return;
    }
    this.uidCerere = params.id;
    this.type = params.type;
    //preluare jprop flux
    var result = await this.rootStore.mainStore.getFlow(params.type);
    if (result && result.noMatch) {
      return;
    }
    this.getRequest();
  };

  @action
  getRequest = async (tab) => {
    this.rootStore.mainStore.isWaiting = true;
    this.rootStore.commonStore.changedFields = {};
    this.changedTabels = [];
    if (!tab) {
      //initializez proprietatile cu valoarea default
      Object.keys(modelShape).forEach((key) => {
        this[key] = modelShape[key];
      });
      this.rootStore.commonStore.clear();
    }
    var results = await this.rootStore.api.fetch("/api/request/show-new", {
      type: this.type,
      uidCerere: this.uidCerere,
      reload: false,
    });
    if (
      results &&
      results.o &&
      !results.error &&
      !results.r &&
      results !== "401"
    ) {
      if (!results.o) {
        console.log(results);
      }
      this.aft = results.o.aft;
      this.rootStore.mainStore.lastCall = moment();
      var resultsJson = await renameObjectKeys(results.o);
      var refreshAllTabs = resultsJson?.jprop?.refreshAllTabs;

      if (tab) {
        //Actualizez datele din tab-urile selectate
        resultsJson.tab
          .filter(
            //results.o.Taburi.filter(
            (t) => t.Id === tab.Id || refreshAllTabs
          )
          .forEach((rTab) => {
            //aici se face pentru sectiunile care exista
            //sa vedem cum facem daca se elimina o sectiune sau se adauga
            this.sectiuni
              .filter((s) => s.tab == rTab.Id)
              .forEach((sectiune) => {
                /*Pentru documentele aduse de request verific daca s-a modificat ceva*/
                resultsJson.doc
                  .filter((doc) => doc.tab == tab.Id && doc.sec == sectiune.Id) //iau doar documentele din sectiunea curenta
                  .forEach((newDoc) => {
                    /*Am gasit documentul, fac merge la el*/
                    var oldDoc = this.rootStore.commonStore.doc.find(
                      (x) => x.Id === newDoc.Id
                    );
                    /*mergeDocs - daca oldDoc nu exista atunci newDoc se adauga, altfel se face combinatie intre ele*/
                    this.rootStore.commonStore.mergeDocs(oldDoc, newDoc, {
                      type: this.type,
                      uidCerere: this.uidCerere,
                      data: resultsJson,
                    });
                  });
                /*Pentru toate documentele pe care le am in sectiune curenta verific daca s-a sters ceva
          Nu ar trebui sa mai apara in resultsJson.doc
          mergeDocs - daca newDoc nu exista atunci oldDoc se sterge
          */
                this.rootStore.commonStore.doc
                  .filter(
                    (x) =>
                      x.tab == tab.Id /* sa vedem daca trebuie conditia*/ &&
                      x.sec == sectiune.Id &&
                      resultsJson.doc.findIndex((n) => n.Id === x.Id) === -1
                  )
                  .forEach((oldDoc) => {
                    this.rootStore.commonStore.mergeDocs(oldDoc, null, {
                      type: this.type,
                      uidCerere: this.uidCerere,
                    });
                  });
              });
          });
        set(this.docListareCerere, resultsJson.docListareCerere);
      } else {
        //reset
        if (resultsJson.tab && resultsJson.tab.length !== 0) {
          const { tab, sec, head, doc, fld, liste, ...other } = resultsJson;

          //setez valorile pentru toate proprietatile, mai putin pentru cele pe care le setez manual
          Object.keys(other).forEach((key) => {
            this[key] = other[key];
          });

          this.rootStore.commonStore.liste = liste;
          this.sectiuni = sec.map((s) => {
            return { ...s, Editable: true };
          });
          this.rootStore.commonStore.allFields = {};

          this.rootStore.commonStore.allFields["uidCerere"] = this.uidCerere;
          this.rootStore.commonStore.allFields["idPas"] = this.idPas || 0;

          this.rootStore.commonStore.tab = [];
          this.rootStore.commonStore.sec = [];
          this.rootStore.commonStore.doc = [];
          this.rootStore.commonStore.head = [];
          this.rootStore.commonStore.fld = [];

          set(this.rootStore.commonStore.tab, tab);
          //set(this.rootStore.commonStore.sec, resultsJson.sec); -- sa vedem daca trecem pe "sec", acum e utilizat "sectiuni"
          set(this.rootStore.commonStore.sec, doc.sec);
          set(this.rootStore.commonStore.doc, doc);
          set(this.rootStore.commonStore.head, head);
          set(this.rootStore.commonStore.fld, fld);

          this.getAllFields(this.rootStore.commonStore.allFields);
          //rulez evenimentele
          //Object.values(this.rootStore.commonStore.allFields)
          this.rootStore.commonStore.fld
            .filter((item) => item.event)
            .forEach((item) =>
              runEvent(
                {
                  showErrors: this.rootStore.mainStore.errors,
                  allFields: this.rootStore.commonStore.allFields,
                  filterSursaLista: this.rootStore.commonStore.filterSursaLista,
                  refreshDocument: this.refreshDocument,
                },
                item,
                true,
                false
              )
            );
        }
      }

      if (this.rootStore.commonStore.tab.length !== 0)
        this.currentTab = tab ? tab : this.rootStore.commonStore.tab[0];
    } else {
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
      if (results && results.r) {
        this.rootStore.mainStore.errors.open(results.msg);
      }
    }
    this.rootStore.mainStore.isWaiting = false;
  };

  @action
  doDeactivate = async () => {
    this.rootStore.mainStore.jprop = null;
    await this.clear();
  };
  /*Colecteaza cimpurile si seteaza proprietatile tuturor cimpurilor*/
  @action
  getAllFields = (_allFields) => {
    var _setupFields = [];
    //Corectie Jprop
    this.rootStore.commonStore.doc.forEach((doc) => {
      doc.Jprop = doc.Jprop || {};
    });

    this.rootStore.commonStore.fld.forEach((fld) => {
      fld.docReadOnly =
        this.rootStore.commonStore.doc.find((doc) => doc.Id == fld.doc)
          ?.Incarcare == 0;
      _allFields[fld.Id] = fld;
      _setupFields.push(fld);
    });

    this.rootStore.commonStore.setupFields(_setupFields, {
      type: this.type,
      uidCerere: this.uidCerere,
    });
    /*refac sectiune.Editable*/
    this.sectiuni.forEach((sec) => {
      //verific daca are documente editabile
      sec.Editable =
        this.rootStore.commonStore.doc.findIndex(
          (doc) => doc.sec == sec.Id && doc.Tip === 2 && doc.Incarcare
        ) > -1;
      //daca nu am gasit in documente caut in cimpuri
      if (!sec.Editable) {
        sec.Editable =
          this.rootStore.commonStore.fld.findIndex(
            (fld) => fld.sec == sec.Id && !fld.isReadonly
          ) > -1;
      }
    });

    /*Setez relatii de master/detail la griduri*/
    //    Object.values(_allFields)
    this.rootStore.commonStore.fld
      .filter((d) => d.masterId) //este detail pentru un alt grid
      .forEach((d) => {
        //marchez in master care sunt gridurile detail
        var m = this.rootStore.commonStore.getField(d.masterId);
        d.master = m;
        m.detailIds = m.detailIds || [];
        if (m.detailIds.indexOf(d.Id) == -1) {
          m.detailIds.push(d.Id);
        }
      });
  };

  @action
  rowChecked = (row, item) => {
    row.data.isChecked = row.isChecked;
    var sursa = this.sursaLista(item);
    var value = sursa.data
      .filter((s) => s.isChecked)
      .map((x) => x[sursa.fields.id])
      .toString();
    this.updateValue(item, value);
  };
  @action
  updateStore = (value, key) => {
    if (key) {
      set(this[key], value);
      return;
    }
    set(this, value);
  };

  @action
  updateValue = (item, value) => {
    if (value === undefined) {
      value = null;
    }
    /*trebuie sa permit schimbarea daca value=""*/
    var sValue = "";
    switch (item.Tip) {
      case 0: //textbox
        sValue = value;
        if (item.Lungime > 1000) {
          sValue = sValue?.toUpperCase();
        }
        break;
      case 1: //memo
        sValue = value;
        break;
      case 2: //data
        sValue = value ? moment(value).format("DD/MM/YYYY") : "";
        //sValue =  value ? value : "";
        break;
      case 3: //numeric
        sValue = value.replace(/[.]/g, "").replace(",", ".");
        break;
      case 4: //listbox
        sValue = value;
        break;
      case 5: //checkbox
        //sValue = value ? "1" : "0";
        sValue = value ?? "0";
        break;
      case 6: //grid
        sValue = value;
        break;
      case 7: //richedit
        sValue = value;
        break;
      default:
        sValue = value;
    }
    if (item.Valoare != sValue) {
      this.rootStore.commonStore.changedFields[item.Id] = sValue;
      item.Valoare = sValue;
      this.rootStore.mainStore.setLogoutTime();
      runEvent(
        {
          allFields: this.rootStore.commonStore.allFields,
          filterSursaLista: this.rootStore.commonStore.filterSursaLista,
          refreshDocument: this.refreshDocument,
        },
        item
      );
    }
  };
  @action
  uploadFile = async (file, document) => {
    this.rootStore.mainStore.isWaiting = true;
    var idDoc = document.Id;
    var resultDocs = await this.rootStore.api.upload(
      "/api/request/upload-file",
      file,
      {
        type: this.type,
        uidCerere: this.uidCerere,
        idDoc: idDoc,
        fileInfo: "",
      }
    );
    //de verificat daca a fost cu succes
    if (document && document.uploadObj) {
      document.uploadObj.clearAll();
    }

    if (resultDocs.o.doc && resultDocs.o.doc.length !== 0) {
      //var sec = this.rootStore.commonStore.sectiuni[this.currentStep];
      resultDocs.o.doc.forEach((newDoc) => {
        var doc = this.rootStore.commonStore.doc.find(
          (x) => x.Id === newDoc.Id /*&& x.sec === sec.Id*/
        );
        if (doc) {
          this.rootStore.commonStore.mergeDocs(doc, newDoc, {
            type: this.type,
            uidCerere: this.uidCerere,
          });
          if (newDoc.Tip === 2) {
            set(doc, newDoc); //necesar pentru modulul de incarcare CI.
          }
        }
      });
    } else {
      var errors = [
        "A aparut o eroare la incarcare fisierului!",
        " Incercati din nou!",
      ];
      this.rootStore.mainStore.errors.open(errors);
    }
    this.rootStore.mainStore.isWaiting = false;
  };
  @action
  updateFile = async (item, document) => {
    this.rootStore.mainStore.isWaiting = true;
    var idUpload = item.Id;
    var fileInfo = item.Info;
    var idDoc = document.Id;
    /*var result =*/ await this.rootStore.api.post("/api/request/update-file", {
      type: this.type,
      uidCerere: this.uidCerere,
      idDoc: idDoc,
      idUpload: idUpload,
      fileInfo: fileInfo,
    });
    this.rootStore.mainStore.lastCall = moment();
    //de verificat daca a fost cu succes
    var resultDocs = await this.refreshDocument({ document: document });
    if (!resultDocs) {
      this.rootStore.mainStore.errors.open(
        "A aparut o eroare la actualizarea informatiilor despre fisier! Incercati din nou!"
      );
    }
    this.rootStore.mainStore.isWaiting = false;
  };
  @action
  deleteFile = async (item, document) => {
    this.rootStore.mainStore.isWaiting = true;
    var idUpload = item.Id;
    var idDoc = document.Id;
    var result = await this.rootStore.api.post("/api/request/delete-file", {
      type: this.type,
      uidCerere: this.uidCerere,
      idDoc: idDoc,
      idUpload: idUpload,
    });
    this.rootStore.mainStore.lastCall = moment();
    if (result && result.error) {
      this.rootStore.mainStore.errors.open(result.error);
    }
    /*var resultDocs =*/ await this.refreshDocument({ document: document });
    this.rootStore.mainStore.isWaiting = false;
  };

  @action
  downloadFile = async (doc) => {
    //var result = await this.rootStore.api.downloadFile("/api/request/download-file", {type: this.type, uidCerere: this.uidCerere, id: doc.Id}, doc.NumeFisier);
    await this.rootStore.api.downloadFile(
      "/api/request/download-file",
      { type: this.type, uidCerere: this.uidCerere, id: doc.Id },
      doc.NumeFisier
    );
    this.rootStore.mainStore.lastCall = moment();
  };

  @action
  openPrintParameters = async (doc) => {
    this.rootStore.mainStore.isWaiting = true;
    //prima data cer parametrii de generare raport
    var results = await this.rootStore.api.post(
      "/api/request/parameters-print-file",
      {
        type: this.type,
        uidCerere: this.uidCerere,
        id: doc.Id_doc,
      }
    );
    if (results && !results.error && results != "401") {
      this.rootStore.mainStore.lastCall = moment();

      var parameters = {
        type: this.type,
        uidCerere: this.uidCerere,
        id: results.Id || doc.Id_doc,
      };
      if (results.Headers.length == 0) {
        //nu exista parametri pentru acest raport
        this.downloadPrintFile(parameters);
      } else {
        this.rootStore.mainStore.isWaiting = false;
        parameters.param = {};

        this.rootStore.commonStore.setPrintParameters(results.Headers, {
          type: this.type,
          uidCerere: this.uidCerere,
        });

        /*
        results.Headers.forEach((header) =>
          header.Cimpuri.forEach((cimp) => {
            this.rootStore.commonStore.setupField(cimp, {
              type: this.type,
              uidCerere: this.uidCerere,
            });
          })
        );
*/
        this.rootStore.mainStore.printParameters.open({
          parametrii: parameters,
          headers: results.Headers,
          print: this.downloadPrintFile,
          showError: this.rootStore.mainStore.errors.open,
        });
      }
    } else {
      this.rootStore.mainStore.isWaiting = false;
      if (results && results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };

  @action
  downloadPrintFile = async (parameters) => {
    this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.downloadFile(
      "/api/request/download-print-file",
      parameters
    );
    //		await this.rootStore.api.downloadFile("/api/request/download-print-file", {type: this.type, uidCerere: this.uidCerere, id: doc.Id_doc});
    this.rootStore.mainStore.lastCall = moment();
    this.rootStore.mainStore.isWaiting = false;
    if (results && results.error) {
      this.rootStore.mainStore.errors.open(results.error);
    }
  };

  @action
  refreshDocument = async ({ document, sectiune }) => {
    //this.rootStore.mainStore.isWaiting = true;
    var idDoc = document
      ? typeof document === "object"
        ? document.Id
        : document
      : "";
    var idSectiune = sectiune ? sectiune.Id : -1;
    var results = await this.rootStore.api.fetch("/api/request/refresh-new", {
      type: this.type,
      uidCerere: this.uidCerere,
      idDoc: idDoc,
      idSectiune: idSectiune,
    });

    if (results && results !== "401") {
      if (results.error || results.r) {
        var error = results.error ?? results.msg;
        this.rootStore.mainStore.errors.open(error);
      } else {
        this.rootStore.mainStore.lastCall = moment();
        var resultsDoc = await renameObjectKeys(results.o);
        if (resultsDoc.doc?.length !== 0) {
          /*Pentru ducomentele aduse de request verific daca s-a modificat ceva*/
          resultsDoc.doc.forEach((newDoc) => {
            /*Am gasit documentul, fac merge la el*/
            var oldDoc = this.rootStore.commonStore.doc.find(
              (x) => x.Id === newDoc.Id
            );
            /*mergeDocs - daca oldDoc nu exista atunci newDoc se adauga, altfel se face combinatie intre ele*/
            this.rootStore.commonStore.mergeDocs(oldDoc, newDoc, {
              type: this.type,
              uidCerere: this.uidCerere,
              data: resultsDoc,
            });
          });
          /*Pentru toate documentele pe care le am in sectiune curenta verific daca s-a sters ceva
          Nu ar trebui sa mai apara in resultsDoc.doc
          mergeDocs - daca newDoc nu exista atunci oldDoc se sterge
          */
          this.rootStore.commonStore.doc
            .filter(
              (x) =>
                x.sec == idSectiune &&
                resultsDoc.doc.findIndex((n) => n.Id === x.Id) === -1
            )
            .forEach((oldDoc) => {
              this.rootStore.commonStore.mergeDocs(oldDoc, null, {
                type: this.type,
                uidCerere: this.uidCerere,
              });
            });
        }
      }
    }
  };
  @action
  previewFile = async (doc) => {
    this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.fetch(
      "/api/request/preview-file",
      { type: this.type, uidCerere: this.uidCerere, id: doc.Id },
      doc.NumeFisier
    );
    if (results.NumeFisier) {
      this.rootStore.mainStore.lastCall = moment();
      var file, content;
      var fileType = doc.NumeFisier.split(".").pop().toLowerCase();
      //if (results.NumeFisier.toLowerCase().endsWith(".xml")) {
      if (fileType == "xml") {
        content = Buffer.from(results.Content, "base64").toString("utf-8");
      } else {
        file = `data:*;base64,${results.Content}`;
        //file = `data:image/jpg;base64,${results.Content}`;
      }
      var preview = {
        name: doc.NumeFisier,
        file: file,
        fileType: fileType,
        content: content,
        isOpen: true,
      };
      this.rootStore.mainStore.isWaiting = false;
      set(this.preview, preview);
    }
  };
  @action
  showFile = async (doc) => {
    this.rootStore.mainStore.isWaiting = true;
    var preview = { name: doc.name, image: doc.img, isOpen: true };
    this.rootStore.mainStore.isWaiting = false;
    set(this.preview, preview);
  };
  @action
  closePreviewFile = async () => {
    set(this.preview, modelPreview);
  };

  @action
  changeTab = (tab) => {
    if (this.autoSave) {
      const withError = this.save(tab);
      if (withError) {
        return;
      }
    }
    //set(this.currentTab, tab);
    this.currentTab = tab;
  };

  @action
  nextStep = async () => {
    this.nextSteps = [];
    var ok = await this.save(this.currentTab);
    if (!ok) {
      return false;
    }
    //aici trebuie facuta partea de afisare pasi urmatori
    this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.fetch("/api/request/nextsteps", {
      type: this.type,
      uidCerere: this.uidCerere,
    });
    this.param = {};
    this.rootStore.mainStore.isWaiting = false;
    if (!results || (results && results.r !== 0)) {
      var errors = [];
      if (results.error) {
        errors.push(results.error);
      }
      if (results && results.msg) {
        errors.push(results.msg);
      }
      this.rootStore.mainStore.errors.open(errors);
      return false;
    }
    if (results && results.o) {
      this.rootStore.mainStore.lastCall = moment();
      set(this.nextSteps, results.o);
      if (results.o.length == 0) {
        this.rootStore.mainStore.errors.open("Nu exista pasi urmatori!");
        return false;
      } else {
        //deschid dialog cu pasii
        this.editDialog.isOpen = true;
        this.editDialog.header = "Alege pasul urmator";
        this.editDialog.showSave = false;
        this.editDialog.child = (
          <StepList steps={this.nextSteps} save={this.save} />
        );
      }
    }
    return true;
  };

  @action
  save = async (tab, isEnd, pasNou) => {
    this.closeEditDialog();
    tab = tab || this.currentTab; //daca nu l-am dat ca parametru raman pe tabul asta
    /*TODO daca this.changedFields == {} si this.changedTabels == [] nu mai fac salvare*/
    this.rootStore.mainStore.isWaiting = true;
    var idTab = this.currentTab.Id;
    var idNewTab = tab.Id;
    this.param["idTab"] = `${idTab}`;
    this.param["idNewTab"] = `${idNewTab}`;
    this.param.latitude = this.rootStore.mainStore.position.latitude;
    this.param.longitude = this.rootStore.mainStore.position.longitude;

    //daca nu sunt modificari pe caimpuri si nici pe tabele nu se face salvare - daca nu cumva sunt pe avansare
    if (
      !pasNou &&
      Object.keys(this.rootStore.commonStore.changedFields).length === 0 &&
      this.changedTabels.length === 0
    ) {
      if (isEnd) {
        this.dialog.url = `/${this.rootStore.mainStore.appType}/${this.type}`;
        this.dialog.message = "Documentul a fost salvat cu succes!";
        this.dialog.isOpen = true;
        this.dialog.isNextStep = false;
      } else {
        this.currentTab = tab;
      }
      this.rootStore.mainStore.isWaiting = false;
      return true;
    }
    //daca sunt tabele modificate construiesc xml-ul cu liniile acestora
    this.rootStore.commonStore.saveDataTable(this.changedTabels);
    var results = await this.rootStore.api.post(
      "/api/request/save",
      //      `/api/request/save?uidCerere=${this.uidCerere}`,
      {
        type: this.type,
        uidCerere: this.uidCerere,
        idPas: this.idPas,
        fld: this.rootStore.commonStore.changedFields,
        param: this.param,
        pasNou: pasNou,
        aft: this.aft,
      }
    );
    this.param = {};
    this.rootStore.mainStore.isWaiting = false;

    if (!results || (results && results.r !== 0)) {
      var errors = [];
      if (!results && results !== "401") {
        errors.push("Unknown network error");
      } else {
        if (results.msg) {
          errors.push(results.msg);
        }
        if (results.error) {
          errors.push(results.error);
        }
        if (results.o && results.o.o) {
          var _allFields = this.rootStore.commonStore.allFields;
          results.o.o.forEach((x) => {
            const tip = (x.tip || "").toLowerCase();
            var ctrl = tip == "fld" ? _allFields[x.id] : null;
            ctrl =
              tip == "doc"
                ? this.doc.find((d) => d.Id === x.id && d.Tip == 2)
                : ctrl;

            errors.push({ msg: x.eroare, ctrl: ctrl });
          });
        }
      }
      this.rootStore.mainStore.errors.open(errors);
      return false;
    }
    if (results && results.r === 0) {
      this.rootStore.mainStore.lastCall = moment();
      this.changedTabels = [];
      this.rootStore.commonStore.changedField = {};
      var message = results.msg;
      if (isEnd) {
        this.dialog.url = results.o.url;
        this.dialog.message = message
          ? message
          : `Documentul a fost ${
              pasNou > 0 ? "transferat" : "salvat"
            } cu succes!`;
        this.dialog.isOpen = true;
        this.dialog.isNextStep = pasNou > 0;
      } else {
        this.getRequest(tab);
      }
    }
    return true;
  };

  @action
  close = (history) => {
    this.rootStore.mainStore.updateValue({ preventGoBack: false });
    var url = `/${this.rootStore.mainStore.appType}/${this.type}`;
    set(this.dialog, modelDialog);
    this.rootStore.mainStore.logoutTime = null;
    history(url);
  };

  clear = () => {
    set(this, modelShape);
    set(this, modelInit);
    this.rootStore.commonStore.clear();
  };

  //actiunea de adaugare a unei linii in tabel
  @action
  addTableRow = (table) => {
    if (table.limit > 0) {
      var rc = table.masterId
        ? table.data.rows.filter((r) => table.isVisibleRow(r)).length
        : table.data.rows.length;
      if (table.limit <= rc) {
        this.rootStore.mainStore.errors.open(
          `Nu se poate adauga. Exista o limita de ${table.limit} inregistrari.`
        );
        return false;
      }
    }
    table.selectedRow = -1;
    var newRow = { _rowid: table._rowid };
    table._rowid++;
    var autoinc = table.data.columns.find((col) => col.autoinc == 1);
    var newId = 0;
    if (autoinc) {
      autoinc = autoinc.key;
      newId = Math.max(
        ...table.data.rows.map((r) => {
          return r[autoinc];
        })
      );
      if (newId === -Infinity) {
        newId = 0;
      }
      newId++;
      newRow[autoinc] = newId;
    }
    this.editTable.newId = newId;

    /*Valori default*/
    table.data.columns.forEach((col) => {
      if (col.defaultValue != null) {
        newRow[col.key] = col.defaultValue;
      }
    });
    if (table.masterId) {
      var m = this.rootStore.commonStore.allFields[table.masterId];
      var ix = m.selectedRow;
      if (ix == -1) {
        //nu e nici o linie selectata in master
        if (m.data.rows.length > 0) {
          ix = 0; //iau date din prima linie
        } else {
          //nu exista inregistrari in master, nu afisez nimic
          return false;
        }
      }
      var mr = m.data.rows[ix];
      var df = table.detailFields;
      table.masterFields.forEach((mField, index) => {
        // adaug valorile din master
        newRow[df[index]] = mr[mField];
      });
    }
    table.data.row = newRow;
    return true;
  };

  //actiune de update cand se modifica o celula a tabelului
  @action
  updateRow = (row, tip, key, value, tableId) => {
    if (value === undefined) {
      value = null;
    }
    var sValue = "";
    switch (tip) {
      case "t": //textbox
        sValue = value;
        break;
      case "d": //data
        sValue = value ? moment(value).format("DD/MM/YYYY") : "";
        break;
      case "n": //numeric
        sValue = value.replace(/[.]/g, "").replace(",", ".");
        break;
      case "l": //listbox
        sValue = value;
        break;
      case "s": //listbox
        sValue = value;
        break;
      case "c": //checkbox
        sValue = value ? "1" : "0";
        break;
      default:
        sValue = value;
    }
    if (row[key] !== sValue) {
      row[key] = sValue;
      var item = this.rootStore.commonStore.getField(tableId);
      var col = item.data.columns.find((col) => col.key === key);
      runEvent(
        {
          allFields: this.rootStore.commonStore.allFields,
          filterSursaLista: this.rootStore.commonStore.filterSursaLista,
        },
        col
      );
      if ((tip == "l" || tip == "s") && col.headerSelector >= 0) {
        item.data.selectedHeader = getValueKey(
          getValueOfList(col.lista, sValue),
          col.headerSelector
        );
      }
    }
  };

  addToChangedTables(tbl) {
    var _allFields = this.rootStore.commonStore.allFields;
    var changedTable = this.changedTabels.find((x) => x === tbl.Id);
    if (!changedTable) {
      this.changedTabels.push(tbl.Id);
      tbl.Caption = "*" + tbl.Caption;
      //adaug si tabelele de detail daca am modificat masterul
      if (tbl.detailIds) {
        tbl.detailIds.forEach((d) => this.addToChangedTables(_allFields[d]));
      }
    }
  }
  //actiune de salvare pentru o linie a tabelului
  @action
  saveRow = () => {
    //var changedTable = this.changedTabels.find(x => x.Id === this.editTable.table.Id);
    var editRow = this.editTable.table.data.row;
    var rows = this.editTable.table.data.rows;
    var row = rows.find((r) => r._rowid === editRow._rowid);
    if (!row) {
      this.editTable.table.data.rows.push(editRow);
    } else {
      set(row, editRow);
    }
    this.addToChangedTables(this.editTable.table);
    this.editTable.newId = null;
    this.closeEditDialog();
  };

  // actiune de stergere a unei linii dn tabel
  // eventual trebuie pus un pas intermediar de confirmare a stergerii liniei
  @action
  deleteTableRow = (table, rowid) => {
    var detailIds = table.detailIds || [];
    var index = table.data.rows.findIndex((r) => r._rowid === rowid);
    if (index === -1) {
      return;
    }
    var mr = table.data.rows[index];
    if (!mr) {
      return;
    }
    detailIds.forEach((id) => {
      var dTable = this.rootStore.commonStore.allFields[id];
      var dRow = dTable.data.rows.find((row) => {
        return dTable.masterFields.every((mField, index) => {
          // fiecare cimp din master e egal cu cimpul corespondent din detail
          return (
            mr[mField].toString().toUpperCase() ==
            row[dTable.detailFields[index]].toString().toUpperCase()
          );
        });
      });
      if (dRow) {
        this.deleteTableRow(dTable, dRow._rowid);
      }
    });
    table.data.rows.splice(index, 1);
    this.addToChangedTables(table);
  };

  //deschiderea formei de editare pentru o linie din tabel
  @action
  openEditTable = ({ table, child, row, saveCaption, header }) => {
    if (!row && table.data.hasDetails) {
      //caut cimpul filtrat. daca nu am nimic, adaug o linie noua;
      row = table.data.rows.find((r) => table.isVisibleRow(r));
      table.selectedRow = 0;
      if (!row) {
        if (!this.addTableRow(table)) {
          return;
        }
        table.selectedRow = -1;
      }
    }
    if (row) {
      table.data.row = JSON.parse(JSON.stringify(row)); //deep copy
    }
    this.editTable.table = table;
    this.editDialog.child = child;
    this.editDialog.isOpen = true;
    this.editDialog.saveCaption = saveCaption;
    this.editDialog.save = this.saveRow;
    this.editDialog.header = header ? header : this.editDialog.header;

    /*Initializare Selected Header*/
    var cHeader = table.data.columns.find((c) => c.headerSelector);
    if (cHeader) {
      table.data.selectedHeader = getValueKey(
        getValueOfList(cHeader.lista, row[cHeader.key]),
        cHeader.headerSelector
      );
    } else {
      table.data.selectedHeader = null;
    }
    //rulare evenimente la afisare macheta
    table.data.columns
      .filter((c) => c.event && c.isVisibleEdit && !c.isMasterCol)
      .forEach((col) => {
        runEvent(
          {
            allFields: this.rootStore.commonStore.allFields,
            filterSursaLista: this.rootStore.commonStore.filterSursaLista,
          },
          col,
          true
        );
      });
  };

  //actiunea de salvare la inchiderea dialogului de editare deschis (de exemplu editare tabel)
  //actiune poate fi folosita si pentru alte tipuri de editare in dialog
  @action
  saveEditDialog = () => {
    if (this.editDialog.save) {
      this.editDialog.save();
    }
  };

  //actiunea de inchidere a dialogului de editare
  @action
  closeEditDialog = () => {
    set(this.editDialog, modelEditDialog);
  };
  @action
  downloadCloud = async ({ table, row }) => {
    this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.downloadFile(
      "/api/request/download-cloud",
      {
        type: this.type,
        uidCerere: this.uidCerere,
        id: table.Id,
        file: row.ID,
      },
      row.FILENAME?.split("/").pop()
    );
    this.rootStore.mainStore.isWaiting = false;
    if (results && results.error) {
      this.rootStore.mainStore.errors.open("Fisierul nu poate fi descarcat!");
    }
  };
}

export default CerereStore;
