import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import moment from "moment";
import CssBaseline from "@mui/material/CssBaseline";
import { checkFullName, checkCNP, getValueKey, getValueOfList } from "Utils";

import Button from "@mui/material/Button";
import { PictureAsPdf } from "@mui/icons-material";
import { TextFieldMask } from "TemplateCerere";
import Autocomplete from "@mui/material/Autocomplete";

import { ArrowRight } from "@mui/icons-material";
import { IconsSvg } from "TemplateCerere";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const RegisterReevaluarePF = (props) => {
  const { cimpuri, save, updateValue } = props.store;
  const { getField } = props.common;
  const { downloadAcord } = props.main;
  const [cnp, setCNP] = useState({ value: null, error: null });
  //const [tipId, setTipId] = useState({ value: null, error: null });
  const [idList, setIdList] = useState([]);
  const [idRom, setIdRom] = useState(true);
  const [tipActiune, setTipActiune] = useState({ value: null, error: null });

  const [acordDownloaded, setAcordDownloaded] = useState(true);

  const [unitate, setUnitate] = useState("");

  const navigate = props.history.push;
  useEffect(() => {
    //if (idList.length>0){      return;    }
    var id = getField(3);
    var vl = id?.visibleList() ?? [];
    setIdList(vl);

    setUnitate(getField(-99)?.Valoare);
    setTipActiune(getField(-98)?.Valoare);
  }, [cimpuri]);

  useEffect(() => {
    /*La modificare  resetez eroarea de la CNP*/
    setCNP({ value: cnp.value, error: null });
  }, [idRom]);

  var toolbarCss = "paper-toolbar";

  const validare = () => {
    const { errors } = props.main;
    var errorMsgs = [];
    var msg = "";
    if (idRom) {
      if (!checkCNP(cnp?.value)) {
        msg = "[OPF2]Campul CNP este incorect!";
        errorMsgs.push(msg);
        setCNP({ value: cnp.value, error: errors.processMsg(msg) });
      }
    } else {
      if (!cnp.value || cnp.value.length < 7) {
        msg = "[OPF2]Campul CNP este incorect!";
        errorMsgs.push(msg);
        setCNP({ value: cnp.value, error: errors.processMsg(msg) });
      }
    }
    /*if (!tipId?.value) {
      msg = "Completati tipul actului de identitate";
      errorMsgs.push(msg);
      setTipId({ value: tipId.value, error: errors.processMsg(msg) });
    }*/
    return errorMsgs;
  };
  const handleNew = () => {
    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      return;
    }
    updateValue(getField(2), cnp.value);
    //updateValue(getField(3), tipId.value);
    updateValue(getField(11), idRom ? 150 : null);
    save(navigate);
  };

  return (
    <div className={toolbarCss}>
      <CssBaseline />
      <IconsSvg icon="IllustrationHello" />
      <p className="page-title">Buna,</p>
      <p className="normal-text">
        Pentru a incepe actiunea selectata este important sa completezi
        campurile de mai jos:
      </p>
      {/*<div className="acord">
        <div className="acord">
          <div className="acord-txt">
            Inainte de a continua cu actiunea selectata, prezinta clientului
            informatii ACORD GDPR (format pdf)!
            <Link
              onClick={() => {
                downloadAcord(103);
                setAcordDownloaded(true);
              }}
              className="termenii em"
            >
              ACORD GDPR <PictureAsPdf></PictureAsPdf>
            </Link>
            !
          </div>
        </div>
      </div>*/}
      <div className="form">
        <TextField
          variant="outlined"
          margin="normal"
          disabled
          id="unitate"
          label="Unitatea teritoriala"
          value={unitate ?? ""}
          className="login-txt"
          size="small"
        />
        <TextField
          variant="outlined"
          margin="normal"
          disabled
          fullWidth
          id="actiune"
          label="Tip actiune"
          value={tipActiune}
          className="login-txt"
          size="small"
        />
        <TextField
          variant="outlined"
          margin="normal"
          disabled
          fullWidth
          id="client"
          label="Tipul de client"
          value="Persoana fizica"
          className="login-txt"
          size="small"
        />

        <div className="form switch-group">
          <div className="label">
            Persoana are un act de identitate emis in Romania
          </div>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                width="100px"
                checked={idRom}
                //disabled={state.arataProduse}
                onChange={(prop) => {
                  setIdRom(prop.target.checked);
                }}
              />
            }
            label={idRom ? "Da" : "Nu"}
            labelPlacement="top" //{dobandaFixa ? "start" : "end"}
          />
        </div>
        {idRom && (
          <TextFieldMask
            autoFocus
            id="cnp"
            label="CNP *"
            defaultValue={cnp?.value ?? ""}
            className="login-pwd"
            variant="outlined"
            //onBlur={updateCNP}
            onBlur={(prop) => setCNP({ value: prop.target.value, error: null })}
            mask="9999999999999"
            maskChar=""
            error={!!cnp.error}
            helperText={cnp.error}
            type="tel"
          />
        )}
        {!idRom && (
          <TextField
            autoFocus
            id="dcnp"
            label="NIF/Serie si nr. act de identitate/Cod client *"
            value={cnp?.value ?? ""}
            onChange={(event) => {
              setCNP({
                value: event.target.value?.toUpperCase(),
                error: null,
              });
            }}
            className="login-pwd"
            variant="outlined"
            error={!!cnp.error}
            helperText={cnp.error}
          />
        )}
        {/*<Autocomplete
          id="lid"
          //controled="true"
          options={idList}
          getOptionLabel={(option) => getValueKey(option, 1) ?? ""}
          value={getValueOfList(idList, tipId.value)}
          onChange={(event, newValue) => {
            setTipId({ value: getValueKey(newValue, 0), error: null });
          }}
          disableClearable
          autoComplete
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              id="tid"
              variant="outlined"
              label="Tipul actului de identitate"
              error={!!tipId.error}
              helperText={tipId.error}
            />
          )}
          required
          />*/}
      </div>
      <p></p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={handleNew}
        endIcon={<ArrowRight fontSize="large" />}
        disabled={!acordDownloaded}
      >
        Continua
      </Button>
    </div>
  );
};
export default withRouter(
  inject((stores) => ({
    store: stores.cerereNouaStore,
    main: stores.mainStore,
    common: stores.commonStore,
  }))(observer(RegisterReevaluarePF))
);
