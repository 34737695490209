export const userTypes = {
  internal: "ghepard",
  external: "sms",
  user: "local",
};

export const actiuniDepozite = [
  {
    id: 1,
    value: "P",
    label: "Prelungire fara Capitalizare",
    text: "Prelungeste FARA Capitalizare",
    hide: false,
  },
  {
    id: 2,
    value: "PC",
    label: "Prelungire cu Capitalizare",
    text: "Prelungeste CU Capitalizare",
    hide: false,
  },
  { id: 3, value: "L", label: "Lichidare", text: "Lichideaza", hide: false },
];

export const creditTypes = {
  credit: "retail-lending",
  card: "retail-card",
  prescoring: "retail-prescoring",
};
