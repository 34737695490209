import React, { useState } from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { TextFieldMask } from "TemplateCerere";
import { Checkbox, LinkLabel } from "TemplateCerere";
import { ArrowRight, Refresh } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import OtpInput from "react-otp-input";
import FormHelperText from "@mui/material/FormHelperText";
import * as Icons from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { IconText } from "TemplateCerere";

const PhonePage = (props) => {
  const [show, setShow] = useState(false);
  const {
    updateAcord,
    updateValue,
    codeRequest,
    timeLeft,
    sendPhone,
    confirmPhone,
    state,
    downloadAcord,
    register,
  } = props;

  const { phone, smsCode, cnp, firstDigits, lastDigits, acord } = state;

  const {
    a1, //termeni si conditii
    a2, //nota de informare
    a3,
    a1_err,
    a2_err,
    a3_err,
  } = acord;
  let { agreement } = register;
  agreement = agreement || {};
  const actions = { downloadAcord };

  return (
    <React.Fragment>
      {register.title && <p className="page-title">{register.title}</p>}
      {register.subtitle && <p className="normal-text">{register.subtitle}</p>}
      {register.subtitle2 && (
        <p className="normal-text">{register.subtitle2}</p>
      )}
      <div className="form">
        {register.cnp && (
          <TextFieldMask
            id="cnp"
            label={register.cnp.label || "CNP/NIF"}
            variant="outlined"
            margin="normal"
            required
            defaultValue={cnp && cnp.value ? cnp.value : ""}
            className="login-pwd"
            onBlur={(prop) => updateValue("cnp", prop.target.value)}
            mask={register.cnp.mask}
            maskChar={register.cnp.maskChar}
            error={!!cnp.error}
            //helperText={cnp.error}
            type={
              register.cnp.type === "password" && !show ? "password" : "text"
            }
            helperText={register.cnp.helperText}
            InputProps={{
              endAdornment: register.cnp.type === "password" && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle "
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    {
                      register.cnp.type === "password" && (
                        <IconText
                          icon={show ? "VisibilityOff" : "Visibility"}
                        />
                      )
                      //<TextFieldIcon />
                    }
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>
      {register.amCard && (
        <div className="form">
          <Typography className="label">Numarul Cardului</Typography>
          <div className="hlist">
            <TextFieldMask
              variant="outlined"
              margin="normal"
              required
              //fullWidth
              //maxWidth="100px"
              id="firstDigits"
              label="Primele 4 Cifre"
              name="firstDigits"
              defaultValue={firstDigits ? firstDigits : ""}
              onBlur={(prop) => updateValue("firstDigits", prop.target.value)}
              mask="9999"
              maskChar=""
              className="card"
            />
            <TextField
              variant="outlined"
              margin="normal"
              placeholder="* * * *"
              className="card disabled center"
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              placeholder="* * * *"
              className="card disabled center"
              disabled
            />

            <TextFieldMask
              variant="outlined"
              margin="normal"
              required
              id="lastDigits"
              label="Ultimele 4 Cifre"
              name="lastDigits"
              className="card"
              defaultValue={lastDigits ? lastDigits : ""}
              onBlur={(prop) => updateValue("lastDigits", prop.target.value)}
              mask="9999"
              maskChar=""
            />
          </div>
        </div>
      )}
      {agreement.a1 && agreement.a1.label && (
        <div className="acord">
          <Checkbox
            name="a1"
            checked={a1}
            onChange={updateAcord}
            color="primary"
          />
          <div className="acord-txt">
            <LinkLabel
              id="a1"
              linkClick={agreement.a1.linkClick}
              actions={actions}
              label={agreement.a1.label}
            />
            <FormHelperText error={!!a1_err}>{a1_err}</FormHelperText>
          </div>
        </div>
      )}
      {agreement.a2 && agreement.a2.label && (
        <div className="acord">
          <Checkbox
            name="a2"
            checked={a2}
            onChange={updateAcord}
            color="primary"
          />
          <div className="acord-txt">
            <LinkLabel
              id="a2"
              linkClick={agreement.a2.linkClick}
              actions={actions}
              label={agreement.a2.label}
            />
            <FormHelperText error={!!a2_err}>{a2_err}</FormHelperText>
          </div>
        </div>
      )}
      {agreement.a3 && agreement.a3.label && (
        <div className="acord">
          <Checkbox
            name="a3"
            checked={a3}
            onChange={updateAcord}
            color="primary"
          />
          <div className="acord-txt">
            <LinkLabel
              id="a3"
              linkClick={agreement.a3.linkClick}
              actions={actions}
              label={agreement.a3.label}
            />
            <FormHelperText error={!!a3_err}>{a3_err}</FormHelperText>
          </div>
        </div>
      )}
      <div className="form">
        <TextFieldMask
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Nr Telefon"
          defaultValue={phone && phone.value ? phone.value : "07"}
          onBlur={(prop) => updateValue("phone", prop.target.value)}
          className="phone"
          mask="0799999999"
          maskChar=""
          error={!!phone.error}
          helperText={phone.error}
          type="tel"
        />
      </div>
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={sendPhone}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Trimite Cod
      </Button>
      <Typography variant="subtitle2">
        Introdu codul primit prin SMS:
      </Typography>
      <OtpInput
        value={smsCode ? smsCode : ""}
        onChange={(value) => updateValue("smsCode", value)}
        numInputs={6}
        renderInput={(props) => <input {...props} disabled={!!codeRequest} />}
        inputStyle="MuiInputBase-root MuiInputBase-input MuiOutlinedInput-input otp-input"
        focusStyle="otp-focus"
        disabledStyle="MuiInputBase-root Mui-disabled"
        inputType="number"
        containerStyle="otp"
      />
      <FormHelperText error={false}></FormHelperText>
      <div className="h-list">
        <Typography variant="caption">
          Nu ai primit cod? Asteapta <b>{timeLeft} sec.</b>
        </Typography>

        <Button
          color="primary"
          /*className="link-btn"*/
          endIcon={<Refresh />}
          onClick={sendPhone}
        >
          Retrimite SMS
        </Button>
      </div>
      <Button
        variant="contained"
        color="primary"
        /*className="login-btn"*/
        onClick={confirmPhone}
        endIcon={<ArrowRight fontSize="large" />}
        disabled={!smsCode}
      >
        Confirma telefon
      </Button>
    </React.Fragment>
  );
};

export default observer(PhonePage);
