import axios from "axios";
import { set, observable } from "mobx";
import { parse } from "content-disposition-attachment";

export default class Api {
  @observable logout = null;
  @observable addError = null;
  @observable sendError = null;
  @observable redirectUrl = null;
  constructor(props) {
    set(this, props);
  }

  fetch = async (url, params) => {
    this.redirectUrl = window.location.pathname + window.location.search;
    const uri = `${url}${this.getQueryParams(params)}`;
    //console.log("fetch, redirecturl: ", this.redirectUrl, url, params);
    return axios
      .get(uri)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(this.getError);
  };

  post = async (url, params) => {
    this.redirectUrl = window.location.pathname + window.location.search;
    //console.log("post, redirecturl: ", this.redirectUrl, url, params);
    return axios
      .post(url, params) //, config)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(this.getError);
  };
  upload = async (url, file, params) => {
    this.redirectUrl = window.location.pathname + window.location.search;
    var data = new FormData();
    data.append("file", file);
    //console.log("upload, redirecturl: ", this.redirectUrl);
    return axios({
      url: url,
      method: "post",
      data: data,
      params: params,
      //headers: config.headers
    })
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(this.getError);
  };

  downloadFile = async (url, params, fileName) => {
    this.redirectUrl = window.location.pathname + window.location.search;
    //console.log("download, redirecturl: ", this.redirectUrl);
    return axios({
      url: url,
      method: "post",
      data: params,
      //headers: config.headers,
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const hiddenElement = document.createElement("a");
        hiddenElement.href = url;
        var contentTxt = response.headers["content-disposition"];
        if (!fileName && contentTxt) {
          var content = parse(contentTxt);
          fileName = content.filename;
        }

        hiddenElement.download = fileName; //+ '.csv'
        hiddenElement.click();
      })
      .catch(this.getError);
  };

  getConfigs = async () => {
    const headers = {};
    /*  if (this.getAccessToken) {
        const token = await this.getAccessToken();
        headers["Authorization"] = token ? `Bearer ${token}` : null
      }*/
    if (this.isDebug) {
      headers["Access-Control-Allow-Origin"] = `*`;
    }
    return { headers: headers };
  };

  getQueryString = (params) => {
    if (!params) {
      return "";
    }
    var serialized = Object.keys(params)
      .map((x) => {
        return encodeURIComponent(x) + "=" + encodeURIComponent(params[x]);
      })
      .join("&");
    return serialized;
  };

  getQueryParams = (params) => {
    if (!params) {
      return "";
    }

    var serialized = Object.keys(params)
      .map((x) => {
        return encodeURIComponent(x) + "=" + encodeURIComponent(params[x]);
      })
      .join("&");
    return `?${serialized}`;
  };

  getError = async (error) => {
    this.log("error", error);
    //this.log(error);
    //console.log(error);
    /*TODO Aici e problema de revenire, cind ramanem cu /login in loc de /request... - redirectUrl e login, nu tot URL-ul*/
    //console.log("error, redirecturl: ", this.redirectUrl);
    if (!error.response && !error.message) {
      return { error: error.toString() };
    }
    var msg;
    if (error.response) {
      if (error.response && error.response.status === 401) {
        //console.log(error.response);
        //const redirectUrl=window.location.pathname;
        //console.log("error logout, redirecturl: ", this.redirectUrl);
        await this.logout({ url: this.redirectUrl });
        return "401";
      }
      msg = error?.response?.data?.title
        ? error.response.data.title
        : error.response?.data?.toString();
    }

    msg = msg ? msg : error.message;
    //this.addError({level: 'error' , timestamp: new Date(), renderedMessage: ' sa vedem ------- '+ msg })
    return { error: msg };
  };
  //log = async (level, msg) => {
  log = async (param1, param2) => {
    const _addError = this.addError;
    const timestamp = new Date();
    var level = param2 ? param1 : "info";
    var msg = param2 ? param2.toString() : param1.toString();
    var errorObj = { level: level, timestamp: timestamp, renderedMessage: msg };
    var params = { events: [errorObj] };
    return axios
      .post("/api/common/log-extern", params)
      .then(function (response) {})
      .catch(function (error) {
        //console.log(error);
        _addError(errorObj);
      });
  };

  logs = async (errors) => {
    var params = { events: errors };
    return axios
      .post("/api/common/log-extern", params)
      .then(function (response) {
        return true;
      })
      .catch(function (error) {
        //console.log(error);
        return false;
      });
  };
}
