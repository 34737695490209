import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { observer, inject } from "mobx-react";

//import Home from "./home";
import Login from "./login/login";
import LoginUser from "./login/loginUser";
import LoginUserPwd from "./login/loginUserPwd";
//import LoginUserOtp from "./loginUserOtp";
import LoginUserChangePwd from "./login/loginUserChangePwd";
import LoginUserResetPwd from "./login/loginUserResetPwd";

import CerereWeb from "CerereWeb/src";
import CerereNouaWeb from "CerereNouaWeb/src";

import CereriWeb from "CereriWeb/src";
import Cerere from "Cerere/src";
import CerereDesk from "CerereDesk/src";
import Credit from "Credit/src";

import NoMatchPage from "./nomatch";
import GoodBye from "./goodbye";
import Navigate from "./navigate";
import { creditTypes } from "Common/src";
import Notification from "./notification";
//import Feedback from "./feedback";
const Routes = (props) => {
  //console.log("Routes");
  console.log("Routes", props);
  const _creditTypes = creditTypes;
  return (
    <Switch>
      <Route //ruta pentru autentificare cu SMS pentru adaugare cerere noua
        path="/credit/:voucher?"
        //component={Credit}
      >
        <Credit init={_creditTypes.credit} />
      </Route>
      <Route //ruta pentru autentificare cu SMS pentru adaugare cerere noua
        path="/new/:type/:sim/:voucher?"
      >
        <CerereNouaWeb appType="online" />
      </Route>

      <Route //ruta pentru autentificare cu SMS pentru adaugare cerere noua
        path="/new/:type"
      >
        <CerereNouaWeb appType="online" />
      </Route>

      <Route //ruta pentru autentificare cu SMS pentru editare cerere existenta
        path="/request/:type/:id/:auto?"
        component={CerereWeb}
      />

      <Route //ruta pentru autentificare cu user si parola pentru meniu
        exact
        path="/app"
        //component={CereriWeb}
      >
        <CereriWeb appType="app" />
      </Route>
      <Route //ruta pentru autentificare cu user si parola pentru vizualizare cerere
        path="/app/request/:type/:id/:auto?"
        //component={Cerere}
      >
        <Cerere appType="app" />
      </Route>

      <Route //ruta pentru autentificare cu user si parola pentru adaugare cerere noua
        path="/app/new/:type"
        component={() => <CerereNouaWeb isUser={true} appType="app" />}
      />

      <Route //ruta pentru autentificare cu user si parola pentru cererile mele
        exact
        path="/app/:type"
      >
        <CereriWeb appType="app" />
      </Route>

      <Route //ruta pentru autentificare cu user si parola pentru vizualizare cerere
        path="/desk/request/:type/:id/:auto?"
      >
        <CerereDesk appType="desk" />
      </Route>

      <Route //ruta pentru autentificare cu user si parola pentru adaugare cerere noua
        path="/desk/new/:type"
      >
        <CerereNouaWeb appType="desk" isUser={true} />
      </Route>

      <Route //ruta pentru autentificare cu user si parola pentru meniu sau cererile mele
        exact
        path={["/desk/:type", "/desk"]}
      >
        <CereriWeb appType="desk" />
      </Route>

      <Route path="/login" component={Login} />
      <Route path="/applogin" >
        <LoginUser appType="app" />
      </Route>
      <Route path="/pwd" component={LoginUserPwd} />
      <Route path="/changePwd" component={LoginUserChangePwd} />
      <Route path="/resetPwd" component={LoginUserResetPwd} />

      <Route path="/desklogin">
        <LoginUser appType="desk" />
      </Route>
      <Route path="/notification" component={Notification} />
      {/*<Route path="/feedback/:id" component={Feedback} />*/}
      <Route path="/goodbye" component={GoodBye} />
      <Route
        path="/navigate"
        component={(routeProps) => <Navigate history={routeProps.history} />}
      />
      {/* <Redirect from="/navigate" to={url} />*/}

      <Route>
        <NoMatchPage updateValue={props.mainStore.updateValue} />
      </Route>
    </Switch>
  );
};
//export default observer(Routes);
export default inject((stores) => stores)(observer(Routes));
