import React from "react";
import { observer } from "mobx-react";
import DefaultToolbar from "./toolbar";

const AppToolbar = (props) => {
  
  if (props.appType === "desk") return <DefaultToolbar {...props}/>;
  if (props.jprop.theme === "retail") return null;
  return <DefaultToolbar/>;
};
export default observer(AppToolbar)
;
