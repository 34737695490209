import React from "react";
import { observer } from "mobx-react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/HelpOutline";

//import "../../index.css";

function InitialPage() {
    return (
      <div className="acord-group">
        <List dense alignItems="flex-start">
          <ListItem>
            <ListItemText
              primary={
                <span>
                  <b className="acord-title">Am</b> peste 18 ani
                </span>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography variant="body2">
                      Serviciul este disponibil doar pentru persoanele fizice
                      care au peste 18 ani impliniti.
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton edge="end" aria-label="delete">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  <b className="acord-title">Nu</b> sunt client al bancii
                </span>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  Sunt beneficiarul real al fondurilor (deschid contul{" "}
                  <b className="acord-title">pentru mine</b>, nu pentru
                  altcineva)
                </span>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography variant="body2">
                      In calitate de beneficiar real al fondurilor, ma oblig sa
                      folosesc contul online numai in interes personal si nu voi
                      tranzita fonduri apartinand altor persoane.{" "}
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton edge="end" aria-label="delete">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  <b className="acord-title">Nu</b> doresc sa deschid un Cont de
                  plati cu servicii de baza
                </span>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  <b className="acord-title">Nu</b> sunt persoana expusa public
                  (PEP)
                </span>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography variant="body2">
                      Persoanele expuse public (PEP) sunt persoanele fizice,
                      romane sau straine, care exercita sau au exercitat functii
                      publice importante, membrii familiilor acestora, precum si
                      persoanele cunoscute public ca asociati apropiati ai
                      persoanelor fizice care exercita functii publice
                      importante ( sefi de stat, sefi de guverne, membrii
                      parlamentelor, comisarii europeni, membrii guvernelor,
                      consilierii prezidentiali/stat, secretarii de stat;
                      <ul>
                        <li>
                          membrii curtilor constitutionale, membrii curtilor
                          supreme sau ai altor inalte instante judecatoresti ale
                          caror hotarari nu pot fi atacate decat prin
                          intermediul unor cai extraordinare de atac;
                        </li>
                        <li>
                          membrii curtilor de conturi sau asimilate acestora,
                          membrii consiliilor de administratie ale bancilor
                          centrale;
                        </li>
                        <li>
                          ambasadorii, insarcinatii cu afaceri, ofiterii de rang
                          inalt din cadrul fortelor armate;
                        </li>
                        <li>
                          conducatorii institutiilor si autoritatilor publice;
                        </li>
                        <li>
                          membrii consiliilor de administratie si ai consiliilor
                          de supraveghere si persoanele care detin functii de
                          conducere ale regiilor autonome, ale societatilor
                          comerciale cu capital majoritar de stat si ale
                          companiilor nationale.
                        </li>
                      </ul>
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton edge="end" aria-label="delete">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  <b className="acord-title">Nu</b> fac parte dintr-un grup de
                  persoane fizice cu influenta semnificativa
                </span>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography variant="body2">
                      Grup de persoane fizice cu influenta semnificativa asupra
                      unei/unor entitati din cadrul grupului Bancii – orice grup
                      format din membrii, persoane fizice, ai unui grup de
                      clienti aflati in legatura care are/exercita o influenta
                      semnificativa asupra grupului Bancii (ex: administratorii,
                      directorii, auditorii, actionarii semnificativi sau
                      persoane cu statut similar sau membrii familiilor
                      persoanelor fizice mentionate mai sus – sot/sotie, rudele
                      si afinii de gradul intai, etc)
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton edge="end" aria-label="delete">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  <b className="acord-title">Nu</b> fac parte dintr-un grup de
                  clienti aflati in legatura cu banca
                </span>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography variant="body2">
                      Doua sau mai multe persoane fizice si/sau juridice care se
                      afla intr-una din urmatoarele situatii:
                      <ul>
                        <li>
                          a) constituie, daca nu se dovedeste altfel, un singur
                          risc, deoarece una dintre ele detine direct sau
                          indirect, controlul asupra celeilalte/celorlalte;
                        </li>
                        <li>
                          b) intre care nu exista o relatie de control in sensul
                          exprimat la lit .a), dar care trebuie sa fie
                          considerate ca reprezentand un singur risc, deoarece
                          sunt interconectate in asemenea masura incat, in cazul
                          in care una dintre acestea s-ar confrunta cu probleme
                          financiare, in special dificultati de finantare ori de
                          rambursare, cealalta sau toate celelalte ar intampina
                          probabil dificultati de finantare ori de rambursare.
                        </li>
                      </ul>
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton edge="end" aria-label="delete">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  <b className="acord-title">Nu</b> sunt persoana din SUA, in
                  conformitate cu prevederile referitoare FATCA
                </span>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography variant="body2">
                      Pentru persoanele din SUA, Banca va trebui sa repsecte un
                      set de masuri legislative aprobate de Statele Unite ale
                      Americii cu scopul de a preveni evaziunea fiscal generate
                      de activitate transfrontaliera a contribuabililor
                      americani.
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton edge="end" aria-label="delete">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  <b className="acord-title">Sunt</b> cetatean roman, cu
                  cetatenie si rezidenta fiscala numai in Romania, nascut in
                  Romania
                </span>
              }
            />
          </ListItem>
        </List>
      </div>
    );
  }
export default observer(InitialPage);
