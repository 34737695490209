import React from "react";
import { observer } from "mobx-react";
import Dialog from "@mui/material/Dialog";

function Loading(props) {
    const { isOpen, suspense, waiting, force } = props;
    return (
      <Dialog
        open={isOpen}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div className={"loading "+(suspense ?"suspense ":"")+(waiting ?"waiting ":"")+(force ?"force ":"")+(isOpen ?"isOpen ":"")}>
          <div className="loader"></div>
        </div>
      </Dialog>
    );
  }
export default observer(Loading);
