import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import TextField from "@mui/material/TextField";

import { NumericFormat } from "react-number-format";

import "../index.css";

function Field6_Edit_N(props) {
  const { row, updateRow, col, tableId, decimalScale, inputProps, isReadonly } =
    props;
  const TextFieldCss = styled(TextField)`
    ${col && col.eventProps ? col.eventProps.style : null}
  `;
  const NumericFormatCustom = (props) => {
    const { inputRef, onChange, tip, colkey, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onBlur={(prop) =>
          updateRow(row, tip, colkey, prop.target.value, tableId)
        }
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        valueIsNumericString
        style={{ textAlign: "right" }}
      />
    );
  };
  return (
    <TextFieldCss
      id={`enb-${col.key}`}
      value={row ? row[col.key] : null}
      disabled={col.isReadonly || isReadonly}
      className={`doc-table-value${
        col.isReadonly || isReadonly ? " disabled" : ""
      }`}
      variant="outlined"
      label={col.inline ? col.Caption : null}
      onChange={(prop) =>
        updateRow(row, col.tip, col.key, prop.target.value, tableId)
      }
      InputProps={{
        ...inputProps,
        inputComponent: NumericFormatCustom,
        inputProps: {
          decimalScale: decimalScale ?? 2,
          tip: col.tip,
          colkey: col.key,
        },
      }}
      size="small"
      {...props}
    />
  );
}
export default observer(Field6_Edit_N);
