import React from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

import "../index.css";

function Filter_D(props) {
  const { cimp, valoare, end, updateFilter } = props;

  return (
    <DatePicker
      //defaultValue={valoare  ? valoare.toString() : ""}
      value={
        valoare && valoare !== "01/01/1900"
          ? moment(valoare, "YYYY-MM-DD")
          : null
      }
      //label={inline ? item.Caption : null}
      onAccept={(prop) => {
        if (prop && prop._isValid) {
          var value = moment(prop, "DD/MM/YYYY").format("YYYY-MM-DD");
          updateFilter(cimp, value, end);
        }
        if (!prop) {
          //am sters valoarea
          updateFilter(cimp, null);
        }
      }}
      format="DD/MM/YYYY"
      clearable
      className="doc-table-value"
      inputVariant="outlined"
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      //style={{width: 210}}
      size="small"
      sx={{
        width: "-webkit-fill-available",
        input: { padding: "8.5px 14px" },
      }}
      slots={{
        textField: (params) => (
          <TextField
            {...params}
            defaultValue={params.value}
            onBlur={(prop) => {
              var value = moment(prop.target.value, "DD/MM/YYYY");
              value = value.isValid() ? value.format("YYYY-MM-DD") : null;
              updateFilter(cimp, value, end);
            }}
          />
        ),
      }}
    />
  );
}
export default observer(Filter_D);
