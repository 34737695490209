import React, { useState } from "react";
import { observer } from "mobx-react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ArrowRight } from "@mui/icons-material";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import { IconCheck, IconInfo } from "TemplateCerere";
import { IconsSvg } from "TemplateCerere";
import { EditDialog } from "TemplateCerere";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const AcordLine = ({ children, tooltip, tooltipClass }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <span className="normal-text">
      <SvgIcon
        component={IconCheck}
        className="initial-check"
        viewBox="0 0 16 16.001"
      />

      {children}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          arrow
          title={
            <Typography component="span" variant="body2">
              {tooltip}
            </Typography>
          }
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          classes={{ tooltip: tooltipClass }}
        >
          <SvgIcon
            component={IconInfo}
            className="initial-info"
            viewBox="0 0 16 16.001"
            onClick={handleTooltipOpen}
            onMouseEnter={handleTooltipOpen}
          />
        </Tooltip>
      </ClickAwayListener>
    </span>
  );
};

const InitialPage = (props) => {
  const { handlerChangePage, className } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IconsSvg icon="IllustrationHello" />

      <p className="page-title">Buna,</p>
      <p className="normal-text">
        Ne bucuram sa te intalnim, chiar si virtual.
        <br />
        Pentru ca timpul tau este pretios, inainte sa incepem, asigura-te ca:
      </p>

      <div className="acord-group">
        <AcordLine tooltip="Serviciul este disponibil doar pentru persoanele fizice care au peste 18 ani impliniti.">
          Ai<b> 18 ani</b> impliniti
        </AcordLine>
        <AcordLine tooltip="Serviciul este disponibil doar pentru persoanele fizice care au cetatenie si rezidenta romana si locul nasterii este in Romania.">
          Esti nascut, rezident fiscal si cu<b> cetatenie in Romania</b>
        </AcordLine>

        <AcordLine
          tooltipClass="tooltip-max"
          tooltip={
            <div>
              Persoanele expuse public (PEP) sunt persoanele fizice, romane sau
              straine, care exercita sau au exercitat functii publice
              importante, membrii familiilor acestora, precum si persoanele
              cunoscute public ca asociati apropiati ai persoanelor fizice care
              exercita functii publice importante (sefi de stat, sefi de
              guverne, membrii parlamentelor, comisarii europeni, membrii
              guvernelor, consilierii prezidentiali/stat, secretarii de stat);
              <ul>
                <li>
                  membrii curtilor constitutionale, membrii curtilor supreme sau
                  ai altor inalte instante judecatoresti ale caror hotarari nu
                  pot fi atacate decat prin intermediul unor cai extraordinare
                  de atac;
                </li>
                <li>
                  membrii curtilor de conturi sau asimilate acestora, membrii
                  consiliilor de administratie ale bancilor centrale;
                </li>
                <li>
                  ambasadorii, insarcinatii cu afaceri, ofiterii de rang inalt
                  din cadrul fortelor armate;
                </li>
                <li>conducatorii institutiilor si autoritatilor publice;</li>
                <li>
                  membrii consiliilor de administratie si ai consiliilor de
                  supraveghere si persoanele care detin functii de conducere ale
                  regiilor autonome, ale societatilor comerciale cu capital
                  majoritar de stat si ale companiilor nationale.
                </li>
              </ul>
            </div>
          }
        >
          Nu esti persoana expusa public <b>(PEP)</b>
        </AcordLine>

        <AcordLine
          tooltip="Pentru persoanele din SUA, Banca va trebui sa respecte un
                    set de masuri legislative aprobate de Statele Unite ale
                    Americii cu scopul de a preveni evaziunea fiscala generata de
                    activitatea transfrontaliera a contribuabililor americani."
        >
          Nu esti persoana din SUA, in conformitate cu prevederile referitoare
          <b> FATCA</b>
        </AcordLine>
        <AcordLine
          tooltip="Grup de persoane fizice cu influenta semnificativa asupra
          unei/unor entitati din cadrul grupului Bancii – orice grup
          format din membrii, persoane fizice, ai unui grup de clienti
          aflati in legatura care are/exercita o influenta
          semnificativa asupra grupului Bancii (ex: administratorii,
          directorii, auditorii, actionarii semnificativi sau persoane
          cu statut similar sau membrii familiilor persoanelor fizice
          mentionate mai sus – sot/sotie, rudele si afinii de gradul
          intai, etc)"
        >
          Nu faci parte dintr-un grup de persoane fizice cu
          <b> influenta semnificativa</b>
        </AcordLine>
        <AcordLine tooltip="Daca ai cont la noi, te invitam in aplicatia de internet si mobile banking unde iti poti gestiona usor si rapid banii">
          Inca<b> nu ai cont </b>la noi
        </AcordLine>

        <AcordLine
          tooltip="In calitate de beneficiar real al fondurilor, ma oblig sa
                    folosesc contul online numai in interes personal si nu voi
                    tranzita fonduri apartinand altor persoane."
        >
          Esti<b> beneficiarul real </b>al fondurilor (deschizi contul pentru
          tine , nu pentru altcineva)
        </AcordLine>

        <AcordLine tooltip="Contul de plati cu servicii de baza iti permite sa executi un numar nelimitat de operatiuni specifice utilizand anumite servicii mentionate conform prevederilor Legii 258/2017.">
          Nu doresti sa deschizi un <b>Cont de plati cu servicii de baza</b>)
        </AcordLine>
        <AcordLine
          tooltip={
            <div>
              Doua sau mai multe persoane fizice si/sau juridice care se afla
              intr-una din urmatoarele situatii:
              <ul>
                <li>
                  a) constituie, daca nu se dovedeste altfel, un singur risc,
                  deoarece una dintre ele detine direct sau indirect, controlul
                  asupra celeilalte/celorlalte;
                </li>
                <li>
                  b) intre care nu exista o relatie de control in sensul
                  exprimat la lit .a), dar care trebuie sa fie considerate ca
                  reprezentand un singur risc, deoarece sunt interconectate in
                  asemenea masura incat, in cazul in care una dintre acestea
                  s-ar confrunta cu probleme financiare, in special dificultati
                  de finantare ori de rambursare, cealalta sau toate celelalte
                  ar intampina probabil dificultati de finantare ori de
                  rambursare.
                </li>
              </ul>
            </div>
          }
        >
          Nu fac parte dintr-un grup de
          <b> clienti aflati in legatura cu banca </b>
        </AcordLine>
      </div>
      <p className="doc-helper-text">
        Daca te intrebi de ce trebuie sa stim aceste lucruri despre tine, este
        pentru ca ele fac parte din reglementarile in vigoare si avem nevoie de
        ele pentru ca tu sa-ti poti deschide contul 100% online!
      </p>
      <div className="login-btn-group">
        <Button
          variant="contained"
          color="secondary"
          className="login-btn"
          onClick={() => {
            setIsOpen(true);
          }}
          /*endIcon={<ArrowRight fontSize="large" />}*/
        >
          Nu indeplinesc toate criteriile
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={handlerChangePage}
          endIcon={<ArrowRight fontSize="large" />}
        >
          Indeplinesc toate criteriile
        </Button>
      </div>
      <EditDialog
        className={"retail-dialog-error " + (className || "")}
        isOpen={isOpen}
        header="Nu indeplinesti conditiile necesare pentru a deschide un cont 100% online?"
        showSave={true}
        saveCaption="Afla mai multe"
        showClose={true}
        closeCaption="Inchide"
        maxWidth="sm"
        save={() => {
          window.location.href = "https://www.patriabank.ro/persoane-fizice/";
        }}
        close={() => {
          setIsOpen(false);
        }}
      >
        <div className="normal-text">
          Nicio problema. Te asteptam in orice sucursala PatriaBank.
        </div>
        <div className="normal-text">
          Pana atunci, gaseste varianta potrivita pentru tine, afland mai multe
          despre ofertele, produsele si serviciile disponibile!
        </div>
      </EditDialog>
    </>
  );
};
export default observer(InitialPage);
