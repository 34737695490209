import React, { useState, useEffect } from "react";
//import { observer } from "mobx-react";
//import * as icons from "../icons/index";

import {
  IconCheck,
  IconInfo,
  IconLinesHelpdesk,
  IconLinesList,
  IllustrationHello,
  IllustrationIdentity,
  IllustrationSignature,
  IllustrationSuccess,
  IllustrationSleep,
} from "../icons/index";

export default function IconsSvg (props) {
  const { icon } = props;
  const [IconSvg, setIconSvg] = useState(null);
  useEffect (()=>{

    var icons = {
      IconCheck: IconCheck,
      IconInfo: IconInfo,
      IconLinesHelpdesk: IconLinesHelpdesk,
      IconLinesList: IconLinesList,
      IllustrationHello: IllustrationHello,
      IllustrationIdentity: IllustrationIdentity,
      IllustrationSignature: IllustrationSignature,
      IllustrationSuccess: IllustrationSuccess,
      IllustrationSleep: IllustrationSleep,
    };

    setIconSvg( icons[icon]);
  },[])

  
    let { className, ...others } = props;
    className = className ?? "illustration";
    return (
      <div className={className} {...others}>
        {IconSvg && <IconSvg />}
      </div>
    );
  }


//export default observer(IconsSvg);
