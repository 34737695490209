import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import ReactQuill from "react-quill";

import { getStyle } from "Utils";

import "../index.css";

function Field7(props) {
  const { item, actions } = props;
  const styleProps = getStyle(item.eventProps);
  const ReactQuillCss = styled(ReactQuill)`
    ${styleProps.input}
  `;
  const DivCss = styled.div`
    ${styleProps.field}
  `;
  return (
    <DivCss
      id={`f7-${item.Id}`}
      key={item.Id}
      className={`doc-field ${
        item.Jprop.className ? item.Jprop.className : ""
      }`}
    >
      <div id={`f7l-${item.Id}`} className="doc-field-label">
        {item.Caption + (item.Obligatoriu ? " *" : "")}
      </div>
      <ReactQuillCss
        id={`f7f-${item.Id}`}
        theme="snow"
        defaultValue={item.Valoare}
        onBlur={(prop, a, editor) => {
          !item.isReadonly && actions.updateValue(item, editor.getHTML());
        }}
        readOnly={item.isReadonly}
        className={"rich-text-editor" + (item.isReadonly ? " disabled" : "")}
        style={item.eventProps.style}
      />
    </DivCss>
  );
}
export default observer(Field7);
