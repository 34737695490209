import React from "react";
import { observer } from "mobx-react";

import Field6EditC from "./field6_edit_c";
import Field6EditLS from "./field6_edit_ls";
import Field6EditD from "./field6_edit_d";
import Field6EditN from "./field6_edit_n";
import Field6EditT from "./field6_edit_t";

import "../index.css";

function Field6_Edit_Field(props) {
  const { tableId, updateRow, actions, row, col, isReadonly } = props;
  return (
    <div className="doc-table-value">
      {col.tip === "c" && (
        <Field6EditC
          tableId={tableId}
          row={row}
          col={col}
          updateRow={updateRow}
          isReadonly={isReadonly}
        />
      )}
      {col.tip === "l" && (
        <Field6EditLS
          tableId={tableId}
          row={row}
          col={col}
          updateRow={updateRow}
          store={actions.store}
          runEvent={actions.runEvent}
          isReadonly={isReadonly}
        />
      )}
      {col.tip === "s" && (
        <Field6EditLS
          tableId={tableId}
          row={row}
          col={col}
          updateRow={updateRow}
          store={actions.store}
          runEvent={actions.runEvent}
          isReadonly={isReadonly}
        />
      )}
      {col.tip === "d" && (
        <Field6EditD
          tableId={tableId}
          row={row}
          col={col}
          updateRow={updateRow}
          isReadonly={isReadonly}
        />
      )}
      {col.tip === "n" && (
        <Field6EditN
          tableId={tableId}
          row={row}
          col={col}
          updateRow={updateRow}
          isReadonly={isReadonly}
        />
      )}
      {col.tip === "t" && (
        <Field6EditT
          tableId={tableId}
          row={row}
          col={col}
          updateRow={updateRow}
          actions={actions}
          isReadonly={isReadonly}
        />
      )}
    </div>
  );
}
export default observer(Field6_Edit_Field);
