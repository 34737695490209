import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import EditFields from "./OnboardingDepozitePF_Edit";
import ViewFields from "./OnboardingDepozitePF_View";
import Switch from "@mui/material/Switch";

import "./OnboardingDepozitePF.scss";
function OnboardingDepozitePF(props) {
  const { doc, actions, isMobile } = props;
  const [valute, setValute] = useState([]);
  useEffect(() => {
    var item = actions.getField(103);
    if  (item.visibleCurrencies) {
      setValute(item.visibleCurrencies() || []);
    }
  }, []);

  const depoziteAll = actions.getField(doc.Jprop.template.fields.depozite);
  const isReadonly = depoziteAll.isReadonly || depoziteAll.docReadOnly;
  actions.addToChangedTables(depoziteAll);
  var depozite = [];
  if (valute) {
    depozite = depoziteAll.data.rows.filter((r) => {
      return valute.indexOf(r["VALUTA"]) > -1;
    });
  }
  const handlerSelect = (row, isSelected, tableId, columns, actions) => {
    var colSelected = columns.find((x) => x.ColumnName === "IS_SELECTED");
    if (isSelected) {
      actions.updateRow(
        row,
        colSelected.tip,
        colSelected.key,
        isSelected ? "1" : "0",
        tableId
      );
    } else {
      var edit = {
        className: "retail-dialog-warning",
        isOpen: true,
        //header="Important!"
        showSave: true,
        saveCaption: "Pastrez depozit",
        showClose: true,
        closeCaption: "Renunt la depozit",
        maxWidth: "sm",
        child: `Daca renunti, toate datele despre depozitul in ${row["VALUTA"]} pe care le-ai completat vor fi sterse.`,
        close: () => {
          actions.updateRow(
            row,
            colSelected.tip,
            colSelected.key,
            isSelected ? "1" : "0",
            tableId
          );
          actions.closeEditDialog();
        },
        save: () => {
          actions.closeEditDialog();
        },
      };
      actions.updateStore(edit, "editDialog");
    }
  };
  return (
    <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
      <div className="e-card-title">{doc.Jprop.title || doc.Descriere}</div>
      <div className="e-card-subtitle">{doc.Jprop.subtitle}</div>
      <div className="e-card-content-document">
        <div className="depozite" key={`dc${doc.Id}`}>
          {depozite.map((row, index) => {
            var isSelected = row["IS_SELECTED"] === "1";
            return (
              <div key={`ds${doc.Id}_${row.Id}_${index}`} className="depozit">
                <div className="h-list">
                  <span>
                    Vreau sa deschid depozit in <b>{row["VALUTA"]}</b>
                  </span>
                  <Switch
                    checked={isSelected}
                    onChange={(prop) =>
                      handlerSelect(
                        row,
                        prop.target.checked,
                        depoziteAll.Id,
                        depoziteAll.data.columns,
                        actions
                      )
                    }
                    color="primary"
                    disabled={isReadonly}
                    className={isReadonly ? "disabled" : null}
                  />
                </div>
                <div className="card-depozit">
                  {isSelected ? (
                    <EditFields
                      row={row}
                      tableId={depoziteAll.Id}
                      selectedHeader={depoziteAll.selectedHeader}
                      columns={depoziteAll.data.columns}
                      isReadonly={isReadonly}
                      updateRow={actions.updateRow}
                      queryData={actions.queryData}
                      processMsg={actions.processMsg}
                      isMobile={isMobile}
                    />
                  ) : (
                    <ViewFields
                      row={row}
                      downloadAcord={actions.downloadAcord}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default observer(OnboardingDepozitePF);
