import React from "react";
import { observer } from "mobx-react";

import { NumericFormat } from "react-number-format";

import imgSuma from "../img/widget-suma.png";
import imgRata from "../img/widget-rata.png";
import imgDob from "../img/widget-dob.png";
import imgPer from "../img/widget-per.png";
import "./CrediteSimulare.scss";

const CrediteSimularePanou = (props) => {
  const { simulare } = props;
  return (
    <div className="widget-credit">
      <div className="widget-header">{simulare.title ?? "Creditul ales"}</div>
      <div className="widget-title">{simulare.fullName}</div>
      {simulare.suma &&
        <div className="widget-line">
          <img src={imgSuma} alt=""></img>
          <NumericFormat
            value={simulare.suma}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            suffix={` ${simulare.valuta}`}
            prefix={simulare.labelAmount ?? "Suma creditului: "}
            //decimalScale={2}
            fixedDecimalScale
            renderText={(value) => <div className="widget-detail">{value}</div>}
          />
        </div>
      }
      {!simulare.partnerId &&
        <>
          <div className="widget-line">
            <img src={imgRata} alt=""></img>
            <NumericFormat
              value={simulare.rata_lunara}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              suffix={` ${simulare.valuta}`}
              prefix="Rata lunara: "
              decimalScale={2}
              fixedDecimalScale
              renderText={(value) => <div className="widget-detail">{value}</div>}
            />
          </div>

          <div className="widget-line">
            <img src={imgDob} alt=""></img>
            <NumericFormat
              value={simulare.dobanda}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              suffix=" %"
              prefix={
                simulare.dobanda_fixa ? "Dobanda fixa: " : "Dobanda variabila: "
              }
              decimalScale={2}
              fixedDecimalScale
              renderText={(value) => <div className="widget-detail">{value}</div>}
            />
          </div>
          <div className="widget-line">
            <img src={imgPer} alt=""></img>
            <NumericFormat
              value={simulare.perioada}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              suffix=" Luni"
              prefix="Perioada: "
              //decimalScale={2}
              fixedDecimalScale
              renderText={(value) => <div className="widget-detail">{value}</div>}
            />
          </div>
        </>}
      {(simulare.partnerId === 1) &&
        <>
          <div className="widget-line">
            <div className="widget-detail">{simulare.labelContract} {simulare.infoContract}</div>
          </div>
        </>}
    </div>
  );
};

export default observer(CrediteSimularePanou);
