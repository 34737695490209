import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Alert from "@mui/material//Alert";
import {
  PackageIcon,
  SMSAlertIcon,
  CardIcon,
  ContIcon,
  IBIcon,
} from "../components/icons";

import "../index.css";
import "./OnboardingPachetPF.scss";

const DigitalLendingConfirmarePasiv = (props) => {
  const getPachet = (c) => {
    var p = {};
    if (c?.grid?.dt?.Rows?.length > 0) {
      var r = c.grid.dt.Rows[0];
      p.cod_produs = r[0];
      p.denumire = r[1];
      p.opt = JSON.parse(r[2]) || [];
      p.card = { dst: r[6], adresa: r[7], ext: r[8] };
    }
    return p;
  };

  //const [isOpen, setIsOpen] = useState(false);

  const { doc, actions } = props;
  const fld = doc.Jprop.template.fields;
  const pachet = getPachet(actions.getField(fld.pachet));
  const mesaj = actions.processMsg(actions.getField(fld.mesaj)?.Valoare);
  useEffect(() => {
    actions.lendingActivate();
  }, []);

  //    const isReadonly = docs.isReadonly || docs.docReadOnly;

  const getIcon = (cod) => {
    if (!cod) {
      return null;
    }
    if (cod.startsWith("P001")) {
      return cod.length === 8 ? (
        <ContIcon viewBox="0 0 16 12" />
      ) : (
        <CardIcon viewBox="0 0 16 16" />
      );
    }
    return cod.startsWith("P006_IB") ? (
      <IBIcon viewBox="0 0 16 16" />
    ) : (
      <SMSAlertIcon viewBox="0 0 16 20" />
    );
  };
  //console.log(pachet);
  return (
    <>
      <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
        <div className="e-card-title">{doc.Jprop.title || doc.Descriere}</div>
        <div className="e-card-subtitle">{doc.Jprop.subtitle}</div>
        <div className="e-card-content-document">
          <div key={`dc${doc.Id}`}>
            <Alert variant="outlined" severity="error">
              {mesaj}
            </Alert>

            <div className="pachet-info">
              Daca doresti, poti sa continui sa devii clientul nostru, pastrand
              numai pachetul de cont curent{" "}
              <span className="pachet-nume">{pachet.denumire}</span> conform
              sumarului de mai jos:
            </div>
            <div className="pachet-extra pachet-extra-confirm">
              <div className="pachet-extra-column">
                <div className="card-header-title">Pachet</div>
                <div className="pachet-control">
                  <div className={"produs " + pachet.cod_produs}>
                    <span>
                      <PackageIcon viewBox="0 0 16 16"></PackageIcon>
                    </span>
                    {pachet.denumire}
                  </div>

                  <ul className="optiuni">
                    {pachet.opt &&
                      pachet.opt.map((o) => (
                        <li
                          className={
                            "opt " +
                            o.cod_produs +
                            " " +
                            o.tip_produs.toLowerCase()
                          }
                          key={o.cod_produs}
                        >
                          {getIcon(o.cod_produs)}
                          <span>{o.denumire}</span> <span>{o.nume}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              {pachet.card?.dst && (
                <div item xs={12} className="pachet-extra-helper">
                  {`Cardul tau va fi livrat la ${pachet.card?.dst} (${pachet.card?.adresa}). ${pachet.card?.ext}`}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default observer(DigitalLendingConfirmarePasiv);
