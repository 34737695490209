import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { getStyle } from "Utils";
import { TooltipIcon, TextLine, LinkLabel } from "TemplateCerere";

import "../index.css";

function Field1(props) {
  const { item, inline, actions, css, isMobile } = props;
  const { infoLabel } = item.Jprop || {};
  const styleProps = getStyle(item.eventProps);
  const TextFieldCss = styled(TextField)`
    ${styleProps.input}
  `;
  const DivCss = styled.div`
    ${styleProps.field}
  `;
  var cssLabel =
    css && css.field && css.field.label ? css.field.label : "inline-label";
  cssLabel = `doc-field ${!inline ? cssLabel : ""} ${
    item.Jprop.className ? item.Jprop.className : ""
  }`;
  const eventProps = item.eventProps ? item.eventProps.props : null;
  var controlType = item.Jprop?.control;
  var rows = item.Lungime || 7;
  if (isMobile) {
    rows = !item.Valoare ? rows : null;
  }
  if (controlType == "textline") {
    return (
      <TextLine {...item.Jprop}>{item.Jprop?.Caption ?? item.Caption}</TextLine>
    );
  }
  if (controlType == "linkline") {
    return (
      <LinkLabel
        linkClick={item?.Jprop?.linkClick}
        actions={actions}
        label={item.Caption + (item.Obligatoriu ? " *" : "")}
      />
    );
  }

  return (
    !!item.isVisible && (
      <DivCss
        id={`f1-${item.Id}`}
        key={item.Id}
        className={
          cssLabel
        } /*className={'doc-field' + (!inline ? ' inline-label' : '')}*/
      >
        {!inline && (
          <div id={`f1l-${item.Id}`} className="doc-field-label">
            {item.Caption + (item.Obligatoriu ? " *" : "")}
            {infoLabel && <TooltipIcon tooltip={infoLabel} />}
          </div>
        )}

        <TextFieldCss
          id={`f1f-${item.Id}`}
          defaultValue={item.Valoare ? item.Valoare : ""}
          label={inline ? item.Caption : null}
          onBlur={(prop) => actions.updateValue(item, prop.target.value)}
          disabled={item.isReadonly || item.docReadOnly}
          className={`text-area${
            item.isReadonly || item.docReadOnly ? " disabled" : ""
          }`}
          variant="outlined"
          multiline
          rows={rows}
          fullWidth
          {...eventProps}
          required={item.Obligatoriu}
          error={!!item.error}
          helperText={item.error || item.Jprop.helperText}
        />
      </DivCss>
    )
  );
}
export default observer(Field1);
