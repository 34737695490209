import React from "react";
import { observer } from "mobx-react";
//import DocViewer from "react-doc-viewer";
import "./gifRenderer.scss";
const GIFRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <div id="gif-renderer">
      <img id="gif-img" src={currentDocument.fileData} alt="" />
    </div>
  );
};
GIFRenderer.fileTypes = ["gif", "image/gif"];
GIFRenderer.weight = 1;
export default observer(GIFRenderer);
