export const convertBytesToMbsOrKbs = (filesize) => {
  var size = "";
  if (filesize >= 1048576) {
    size = filesize / 1048576 + " megabytes";
  } else if (filesize >= 1024) {
    size = filesize / 1024 + " kilobytes";
  } else {
    size = filesize + " bytes";
  }
  return size;
};
export const acceptedFileType = (acceptedFiles, type) => {
  //extrag tipul fisierului
  type = type.split("/").reverse()[0];
  //verific daca corespunde cu tipurile de fisier acceptate (dupa inlocuirea caracterelor "." si "*")
  var ok = acceptedFiles.find(
    (x) =>
      x.replace(".", "").replace("*", "").toLowerCase() === type.toLowerCase()
  );
  return !ok;
};
