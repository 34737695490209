export const titleCase = (s) => {
  if (s) {
    var x = "",
      cap = true;
    for (var i = 0; i < s.length; i++) {
      x += cap ? s[i].toUpperCase() : s[i].toLowerCase();
      cap = s[i] === " " || s[i] === "-" || s[i] === "'";
    }
    return x;
  } else {
    return s;
  }
};
