import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import { checkCNP } from "Utils";

//import { InitialPage, AcordPage, EmailPage, PhonePage } from "TemplateCerere";
import { CustomRetail } from "TemplateCerere";

import Button from "@mui/material/Button";
import { ArrowLeft } from "@mui/icons-material";
import { ProgressBar } from "TemplateCerere";
import { Prompt } from "react-router-dom";

function RegisterCustomRetail(props) {
  const { updateValue, sendPhone, confirmPhone, errors, setStepId } =
    props.store;
  const {
    isMobile,
    getStepsInfo,
    preventGoBack,
    preventGoBackMessage,
    stepId,
    codeRequest,
    timeLeft,
    downloadAcord,
  } = props.store;
  let { jprop } = props.store;
  jprop = jprop || {};
  let { register } = jprop;
  register = register || {};
  let { agreement } = register;
  agreement = agreement || {};

  const [statePage, setStatePage] = useState({
    page: 1,
    acord: {
      a1: false, //termeni si conditii
      a1_err: null,
      a2: false, //nota de informare
      a2_err: null,
      a3: false,
      a3_err: null,
    },
    cnp: { value: null, error: null },
    phone: { value: null, error: null },
    firstDigits: { value: null, error: null },
    lastDigits: { value: null, error: null },
    smsCode: null,
    firstPage: 1,
  });

  const [prevPage, setPrevPage] = useState({});
  useEffect(() => {
    window.addEventListener("load", (event) => {
      event.preventDefault();
    });

    updateValue({ preventGoBack: true });
  }, []);

  const handlerUpdateValue = (name, value) => {
    var s = { ...statePage };
    s[name] = name === "smsCode" ? value : { value: value, error: null };
    setStatePage(s);
  };

  const handlerUpdateAcord = (event) => {
    var s = { ...statePage };
    s.acord[event.target.name] = event.target.checked;
    s.acord[event.target.name + "_err"] = false;
    setStatePage(s);
  };

  const validare = () => {
    const { phone, firstDigits, lastDigits, cnp, page, acord, firstPage } =
      statePage;
    var errorMsgs = [];
    if (page === firstPage) {
      if (agreement.a1 && agreement.a1.label && !acord.a1) {
        errorMsgs.push({
          msg: agreement.a1.error,
          check: "a1",
        });
      }
      if (agreement.a2 && agreement.a2.label && !acord.a2) {
        errorMsgs.push({
          msg: agreement.a2.error,
          check: "a2",
        });
      }
      if (agreement.a3 && agreement.a3.label && !acord.a3) {
        errorMsgs.push({
          msg: agreement.a3.error,
          check: "a3",
        });
      }

      if (!(phone && phone.value && phone.value.length === 10)) {
        errorMsgs.push({
          msg: "[ADPF08]Introduceti corect numarul de telefon (de forma: 07xxxxxxxx)!",
          ctrl: "phone",
        });
      }
      if (register.cnp && register.cnp.validate && !checkCNP(cnp.value)) {
        errorMsgs.push({
          msg: "[ADPF05]Campul CNP este incorect!",
          ctrl: "cnp",
        });
      }
      if (
        register.amCard &&
        (!firstDigits.value ||
          firstDigits.value.length !== 4 ||
          isNaN(firstDigits.value))
      ) {
        errorMsgs.push(
          "[LP005] Introduceti corect PRIMELE 4 cifre ale  numarului de card!"
        );
      }
      if (
        register.amCard &&
        (!lastDigits.value ||
          lastDigits.value.length !== 4 ||
          isNaN(lastDigits.value))
      ) {
        errorMsgs.push(
          "[LP006] Introduceti corect ULTIMELE 4 cifre ale  numarului de card!"
        );
      }
    }
    var s = { ...statePage };
    errorMsgs.forEach((e) => {
      if (e.ctrl) {
        s[e.ctrl] = {
          value: statePage[e.ctrl].value,
          error: errors.processMsg(e.msg),
        };
        e.ctrl = null;
      }
      if (e.check) {
        s.acord[e.check + "_err"] = errors.processMsg(e.msg);
      }
    });
    setStatePage(s);
    return errorMsgs;
  };

  const handlerConfirmPhone = () => {
    const { phone, firstDigits, lastDigits, smsCode, cnp, acord } = statePage;
    var errorMsgs = validare();
    if (!smsCode || smsCode.length !== 6) {
      errorMsgs.push("[OPF10]Introduceti codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    updateValue({ phone: phone.value, smsCode: smsCode, preventGoBack: false });

    var confirmProps = {
      cnp: cnp.value,
      clientType: register.clientType ?? "X",
    };

    if (register.amCard) confirmProps.firstDigits = firstDigits.value;
    if (register.amCard) confirmProps.lastDigits = lastDigits.value;

    if (agreement.a1 && agreement.a1.label)
      confirmProps.acordA = acord.a1 ? "1" : "0";
    if (agreement.a2 && agreement.a2.label)
      confirmProps.acordB = acord.a2 ? "1" : "0";
    if (agreement.a3 && agreement.a3.label)
      confirmProps.acordC = acord.a3 ? "1" : "0";

    confirmPhone(confirmProps, props.history);
  };
  const handlerSendPhone = async () => {
    const { phone, firstDigits, lastDigits, cnp, acord } = statePage;
    var s = { ...statePage };
    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs, false);
      return;
    }
    updateValue({ phone: phone.value });
    var sendProps = {
      cnp: cnp.value,
      clientType: register.clientType ?? "X",
    };

    if (register.amCard) sendProps.firstDigits = firstDigits.value;
    if (register.amCard) sendProps.lastDigits = lastDigits.value;

    if (agreement.a1 && agreement.a1.label)
      sendProps.acordA = acord.a1 ? "1" : "0"; //.toString();
    if (agreement.a2 && agreement.a2.label)
      sendProps.acordB = acord.a2 ? "1" : "0";
    if (agreement.a3 && agreement.a3.label)
      sendProps.acordC = acord.a3 ? "1" : "0";
    await sendPhone(sendProps);
    s.smsCode = props.store.smsCode;
    setStatePage(s);
  };

  const handlerPrevPage = () => {
    var s = { ...statePage };
    s.page--;
    setStatePage(s);
    setStepId("O" + s.page);
  };

  var toolbarCss = "paper-toolbar";
  toolbarCss =
    isMobile && !prevPage[statePage.page] ? toolbarCss + " first" : toolbarCss;
  return (
    <div className={toolbarCss}>
      <Prompt when={preventGoBack} message={preventGoBackMessage} />
      {isMobile && jprop.theme === "retail" && (
        <div className="nav-bar">
          <ProgressBar
            goBack={handlerPrevPage}
            prevStep={!!prevPage[statePage.page]}
            stepId={stepId}
            getStepsInfo={getStepsInfo}
          />
        </div>
      )}

      <CssBaseline />
      <div className="login-content">
        {!isMobile && prevPage[statePage.page] && (
          <div className="doc-nav">
            <Button
              color="primary"
              className="link-btn"
              startIcon={<ArrowLeft />}
              onClick={handlerPrevPage}
            >
              {prevPage[statePage.page]}
            </Button>
          </div>
        )}
        <CustomRetail.PhonePage
          updateValue={handlerUpdateValue}
          codeRequest={codeRequest}
          state={statePage}
          timeLeft={timeLeft}
          sendPhone={handlerSendPhone}
          confirmPhone={handlerConfirmPhone}
          className={jprop.className || ""}
          updateState={setStatePage}
          updateAcord={handlerUpdateAcord}
          downloadAcord={downloadAcord}
          register={register}
        />
      </div>
    </div>
  );
}

export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(
    observer(RegisterCustomRetail)
  )
);
