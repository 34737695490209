import React from "react";
import { observer } from "mobx-react";

import Typography from "@mui/material/Typography";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
//import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";

import ListItemIcon from "@mui/material/ListItemIcon";

import ListItemText from "@mui/material/ListItemText";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Checkbox } from "TemplateCerere";

import "../index.css";

function OnboardingDocumentePF(props) {
    const { doc, actions } = props;
    const fld = doc.Jprop.template.fields;
    const docs = actions.getField(fld.docs);
    const acord15 = actions.getField(fld.acord15);
    const acordDoc = actions.getField(fld.acordDoc);

    const pkg = actions.getField(103);
    //    const pkg_cont_ron = actions.getField(114);
    const pkg_cont_val = actions.getField(115);
    const pkg_card = actions.getField(116);
    const pkg_sms = actions.getField(118);
    const pkg_ib = actions.getField(117);
    const ext_cont = actions.getField(119);
    const ext_card = actions.getField(121);
    const ext_sms = actions.getField(123);
    const ext_ib = actions.getField(122);
    //const ext_saving = actions.getField(fld.saving);
    const depo = actions.getField(128);

    if (!pkg) {
      return null;
    }
    //    const isReadonly = docs.isReadonly || docs.docReadOnly;

    const visibleDocs = () => {
      if (acordDoc.isReadonly || acordDoc.docReadOnly) {
        return docs.data.rows;
      } else {
        var p = ["P001"]; //array cu produsele selectate. in functie de selectie adaug coduri de produse aici
        p.push(pkg.Valoare);
        /*
        if (pkg_cont_ron.Valoare === "1") {
          p.push("P001_RON")
        }
        */
        p.push(pkg_cont_val.Valoare);
        p.push(pkg_card.Valoare);
        if (pkg_sms.Valoare === "1" || ext_sms.Valoare !== "") {
          p.push("P006_R");
        }
        if (pkg_ib.Valoare === "1" || ext_ib.Valoare === "1") {
          p.push("P006_IB");
        }
        p = p.concat((ext_cont.Valoare || "").split(","));
        p = p.concat((ext_card.Valoare || "").split(","));

        var v = pkg.visibleCurrencies();
        if (
          depo.data.rows
            .filter((r) => {
              return v.indexOf(r["VALUTA"]) > -1;
            })
            .find((r) => r["IS_SELECTED"] === 1)
        ) {
          p.push("P002");
        }
        p = p.filter((p) => p);
        var dx = docs.data.rows.filter((e) => {
          var vp = e["COD_PRODUS"].split(",");
          return vp.some((ve) => p.some((pe) => pe.startsWith(ve)));
        });
        /*Daca are pachet nu e nevoie de documentul 
ONPF_010	Document informare cu privire la comisioane cont curent Lei
*/
        if (pkg.Valoare !== "P007_OI") {
          dx = dx.filter((e) => e.COD_DOC !== "ONPF_010");
        }
        return dx;
      }
    };
    return (
      <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
        <div className="e-card-title">
          {doc.Jprop.template.header || doc.Descriere}
        </div>
        <div className="e-card-content-document">
          {pkg.Valoare && (
            <div key={`dc${doc.Id}`}>
              <FormControl
                id={`f5c-${acord15.Id}`}
                required={acord15.Obligatoriu}
                error={!!acord15.error}
              >
                <FormGroup id={`f5g-${acord15.Id}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`f5f-${acord15.Id}`}
                        checked={acord15.Valoare === "1"}
                        onChange={(prop) =>
                          actions.updateValue(
                            acord15,
                            prop.target.checked ? "1" : "0"
                          )
                        }
                        color="primary"
                        disabled={acord15.isReadonly || acord15.docReadOnly}
                        className={
                          acord15.isReadonly || acord15.docReadOnly
                            ? "disabled"
                            : null
                        }
                        variant="outlined"
                        required={acord15.Obligatoriu}
                      />
                    }
                    label={acord15.Caption}
                  />
                </FormGroup>
                <FormHelperText>
                  {acord15.error || acord15.Jprop.helperText}
                </FormHelperText>
              </FormControl>

              <List
                dense
                subheader={
                  <FormControl
                    id={`f5c-${acordDoc.Id}`}
                    required={acordDoc.Obligatoriu}
                    error={!!acordDoc.error}
                  >
                    <FormGroup id={`f5g-${acordDoc.Id}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`f5f-${acordDoc.Id}`}
                            checked={acordDoc.Valoare === "1"}
                            onChange={(prop) =>
                              actions.updateValue(
                                acordDoc,
                                prop.target.checked ? "1" : "0"
                              )
                            }
                            color="primary"
                            disabled={
                              acordDoc.isReadonly || acordDoc.docReadOnly
                            }
                            className={
                              acordDoc.isReadonly || acordDoc.docReadOnly
                                ? "disabled"
                                : null
                            }
                            variant="outlined"
                            required={acordDoc.Obligatoriu}
                          />
                        }
                        label={acordDoc.Caption}
                      />
                    </FormGroup>
                    <FormHelperText>
                      {acordDoc.error || acordDoc.Jprop.helperText}
                    </FormHelperText>
                  </FormControl>
                }
              >
                {visibleDocs().map((e) => (
                  <ListItem
                    button
                    component="a"
                    onClick={() => actions.downloadAcord(e["ACORD"])}
                    key={e["COD_DOC"]}
                  >
                    <ListItemIcon>
                      <PictureAsPdfIcon />
                    </ListItemIcon>
                    <ListItemText>{e["DENUMIRE_DOCUMENT"]}</ListItemText>
                  </ListItem>
                ))}
              </List>
              <Typography variant="body2" gutterBottom>
                Declar pe proprie raspundere ca toate informatiile inscrise in
                aceasta cerere precum si toate documentele prezentate sunt
                complete, reale si valide, luand la cunostinta despre
                consecintele legale rezultate in urma furnizarii de date false.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Subsemnatul confirm ca, inainte de a semna am verificat toate
                declaratiile si optiunile selectate in prezentul document si ca
                acestea reflecta in mod corect vointa mea.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Banca isi rezerva dreptul de a accepta sau nu intrarea in
                relatii cu clientul, inclusiv in cazul in care Clientul refuza
                sa furnizeze informatii marcate ca fiind obligatorii. In
                situatia in care, la orice moment in timp, Banca constata ca
                oricare din datele si/sau documentele furnizate Bancii au fost
                sau sunt nereale, incomplete sau neactuale sub orice aspect,
                aceasta isi rezerva dreptul de a inceta imediat relatia cu
                Clientul, fara a fi necesara indeplinirea oricaror notificari
                prealabile, conditii, formalitati sau interventia unei instante
                de judecata.
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
export default observer(OnboardingDocumentePF);
