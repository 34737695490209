import React from "react";
import { observer } from "mobx-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DocViewer from "@cyntler/react-doc-viewer";

import "./index.css";

function Preview(props) {
  const { close, isOpen, name, file, fileType, content } = props;
  const docs = [{ uri: file, fileType: fileType }];
  return (
    <Dialog scroll="paper" open={isOpen} maxWidth={false}>
      <DialogTitle>Vizualizare Fisier</DialogTitle>
      <DialogContent>
        {file && (
          <DocViewer
            className="preview-doc"
            documents={docs}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
              },
            }}
          />
        )}

        {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        <div className="preview-name">{`Nume Fisier: ${name}`}</div>
        <div className="dialog-buttons">
          <Button variant="contained" color="primary" onClick={close}>
            Inchide
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
export default observer(Preview);
