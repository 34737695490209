import React from "react";
import { observer } from "mobx-react";
import Link from "@mui/material/Link";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Checkbox } from "TemplateCerere";
import { ArrowRight } from "@mui/icons-material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { titleCase } from "Utils";
//import "../../index.css";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

function AcordPage(props) {
  const { downloadAcord, handleChange, handlerChangePage, partnerId } = props;
  const { updateValue, name, simulare } = props;
  const {
    tc, //termeni si conditii
    ni, //nota de informare
    proc, //acord general de procesare a datelor. bifarea lui arata pe ecran cele de mai jos
    com, //comunicari marketing
    sondaj, //oferte de produse
    grup, //procesarea datelor de catre grup patria,
    sms, //comunicare prin SMS
    email, //comunicare prin SMS
    dp, //Documente preofertare asigurare de viata allianz
    //canale, //comunicare prin SMS
    tc_err,
    ni_err,
    proc_err,
    com_err,
    dp_err,
  } = props;
  return (
    <>
      <p className="page-title">Hai sa incepem</p>
      <p className="subtitle-text">Care este prenumele tau?</p>
      <div className="form">
        <TextField
          autoFocus
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Prenumele tau"
          name="name"
          value={name && name.value ? name.value : ""}
          onChange={(prop) =>
            updateValue("name", titleCase(prop.target.value) /*.toUpperCase()*/)
          }
          onBlur={(prop) =>
            updateValue("name", prop.target.value?.trim() /*.toUpperCase()*/)
          }
          className="login-txt"
          error={!!name.error}
          helperText={name.error}
        />
      </div>
      <div className="acord">
        <Checkbox
          name="tc"
          checked={tc}
          onChange={handleChange}
          color="primary"
        />
        <div className="acord-txt">
          Accept
          <Link onClick={() => downloadAcord(1 - partnerId * 100)} className="termenii">
            Termenii si Conditiile Generale
          </Link>
          de utilizare a Platformei Online *
          <FormHelperText error={!!tc_err}>{tc_err}</FormHelperText>
        </div>
      </div>
      <div className="acord">
        <Checkbox
          name="ni"
          checked={ni}
          onChange={handleChange}
          color="primary"
        />
        <div className="acord-txt">
          Am citit si am inteles prevederile
          <Link onClick={() => downloadAcord(2 - partnerId * 100)} className="termenii">
            Notei de informare
          </Link>
          vizand prelucrarea datelor cu caracter personal pentru scopul
          desfasurarii sesiunii pe Platforma Online si sunt de acord cu
          incheierea contractului la distanta *
          <FormHelperText error={!!ni_err}>{ni_err}</FormHelperText>
        </div>
      </div>
      {simulare.asigurare && false && (
        <div className="acord">
          <Checkbox
            name="dp"
            checked={dp}
            onChange={handleChange}
            color="primary"
          />
          <div className="acord-txt">
            Am citit si am inteles
            <Link onClick={() => downloadAcord(3)} className="termenii">
              Documentele de pre-ofertare asigurare
            </Link>
            de viata Allianz-Tiriac Asigurari, atasata creditului solicitat cu
            care sunt de acord si le accept *
            <FormHelperText error={!!dp_err}>{dp_err}</FormHelperText>
          </div>
        </div>
      )}
      {tc && ni && (
        <List
          dense
          subheader={
            <React.Fragment>
              <Checkbox name="proc" checked={proc} onChange={handleChange} />{" "}
              Sunt de acord cu procesarea datelor personale in scop de marketing
              <FormHelperText error={!!proc_err}>{proc_err}</FormHelperText>
            </React.Fragment>
          }
        >
          {proc && (
            <React.Fragment>
              <ListItem>
                <ListItemIcon>
                  <Checkbox name="com" checked={com} onChange={handleChange} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <div className="acord-txt">
                      Sa primesc comunicari oficiale si oferte directe aferente
                      produselor si serviciilor Patria Bank
                    </div>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Checkbox
                    name="sondaj"
                    checked={sondaj}
                    onChange={handleChange}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <div className="acord-txt">
                      Sa primesc sondaje de piata sau formulare de tip “customer
                      satisfaction” aferente produselor si serviciilor Patria
                      Bank
                    </div>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Checkbox
                    name="grup"
                    checked={grup}
                    onChange={handleChange}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <div className="acord-txt">
                      Banca sa trimita informatii cu privire la numele,
                      prenumele, adresa, telefonul si emailul meu catre membrii
                      grupului Patria
                    </div>
                  }
                />
              </ListItem>
              {(com || sondaj || grup) && (
                <FormControl component="fieldset">
                  <FormLabel component="div">
                    Comunicarile se trimit prin
                  </FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sms"
                          checked={sms}
                          onChange={handleChange}
                        />
                      }
                      label="SMS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="email"
                          checked={email}
                          onChange={handleChange}
                        />
                      }
                      label="Email"
                    />
                    {/*<FormControlLabel
                        control={
                          <Checkbox
                            name="canale"
                            checked={canale}
                            onChange={handleChange}
                          />
                        }
                        label="Canale alternative"
                      />*/}
                  </FormGroup>
                  <FormHelperText error={!!com_err}>{com_err}</FormHelperText>
                </FormControl>
              )}
            </React.Fragment>
          )}
        </List>
      )}
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={handlerChangePage}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Confirma
      </Button>
    </>
  );
}
export default observer(AcordPage);
