import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import moment from "moment";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Prompt } from "react-router-dom";
import { Fields } from "TemplateCerere";

import "./index.css";
import Toolbar from "./toolbar";

import RegisterBoardPF from "../../../Main/src/views/login/registerBoardPF";
import RegisterUpdatePF from "../../../Main/src/views/login/registerUpdatePF";
import RegisterReevaluarePF from "../../../Main/src/views/login/registerReevaluarePF";

const RegisterPage = (props) => {
  const { registerView, actions } = props;

  switch (registerView) {
    case "registerBoardPF":
      return <RegisterBoardPF actions={actions} />;
    case "registerUpdatePF":
      return <RegisterUpdatePF actions={actions} />;
    case "registerReevaluarePF":
      return <RegisterReevaluarePF actions={actions} />;

    default:
      return null;
  }
};

function Cerere(props) {
  let { noMatch, jprop, preventGoBack, preventGoBackMessage } = props.main;
  const {
    error,
    tipClient,
    nume,
    identificator,
    client,
    verificare,
    cereri,
    idVechi,
    nomenclatoare,
    cimpuri,
    erroare,
    fieldsVisible,
    type,
  } = props.store;

  const {
    save,
    changeClientType,
    setClient,
    updateValue,
    updateField,
    getCaen,
    getLocalitati,
    setCerere,
    sursaLista,
    rowChecked,
    doActivate,
  } = props.store;
  const filterSursaLista = props.filterSursaLista;
  const actions = {
    updateField,
    updateValue,
    sursaLista,
    rowChecked,
    filterSursaLista,
  };

  useEffect(() => {
    console.log("cerereNouaWeb - Constructor", props);
    props.main.updateValue({ preventGoBack: false });
    if (props.isUser) {
      props.main.checkLoginUser(
        props.history,
        props.match.url,
        props.appType,
        props.match.params
      );
    } else {
      //pe cerere noua daca sunt autentificat trebuie logout
      if (props.main.isAuthenticated) {
        props.main.logout({ isOut: true });
      } else {
        props.main.checkLogin(
          props.history,
          props.location.pathname, //props.match.url,
          props.match.params
        );
      }
    }
    props.main.updateValue({ preventGoBack: true });
    window.addEventListener("load", (event) => {
      event.preventDefault();
    });
  }, [props.isUser, props.main.isAuthenticated]);

  useEffect(() => {
    doActivate(props.match.params, props.isUser);
  }, [props.match.params, props.isUser, props.main.isAuthenticated]);
  /*
  useEffect(() => {
    props.store.doDeactivate();
  }, []);
*/
  jprop = jprop ?? {};
  if (noMatch) {
    return (
      <div className="nomatch" dangerouslySetInnerHTML={{ __html: noMatch }} />
    );
  }
  const handlerSave = async () => {
    await props.main.updateValue({ preventGoBack: false });
    save(props.history.push);
  };
  const handlerClose = async () => {
    await props.main.updateValue({ preventGoBack: false });
    props.history.push(`/${props.main.appType}/${type}`);
  };

  if (props.main.registerView) {
    return (
      <RegisterPage registerView={props.main.registerView} actions={actions} />
    );
  }
  if (error) {
    return <div className="error">{error}</div>;
  }
  return (
    <div className="paper-toolbar">
      <Prompt when={preventGoBack} message={preventGoBackMessage} />
      {jprop.theme !== "retail" && (
        <Toolbar
          title={nume}
          save={handlerSave}
          isUser={props.main.isUser}
          close={handlerClose}
        />
      )}
      {tipClient !== "X" && (
        <div className="e-card e-card-border">
          <div className="e-card-content-document">
            <div className="radio-btn-group">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="tipclient"
                  row
                  name="Tip Client"
                  value={identificator.checked}
                  onChange={(prop) => changeClientType(prop.target.value)}
                >
                  {(tipClient === "F" || tipClient === "%") && (
                    <FormControlLabel
                      value="F"
                      control={<Radio color="primary" />}
                      label="Persoana Fizica"
                      disabled={!(tipClient === "F" || tipClient === "%")}
                    />
                  )}

                  {(tipClient === "J" || tipClient === "%") && (
                    <FormControlLabel
                      value="J"
                      control={<Radio color="primary" />}
                      label="Persoana Juridica"
                      disabled={!(tipClient === "F" || tipClient === "%")}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </div>
            <div className="field-inline-label">
              <div className="doc-field-label short-label">
                {identificator[identificator.checked]}
              </div>
              <TextField
                value={identificator.value ? identificator.value : ""}
                //label={inline ? item.Caption : null}
                //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                onChange={(prop) =>
                  updateField({ value: prop.target.value }, "identificator")
                }
                onBlur={setClient}
                //onBlur={(props)=>setClient(props.target.value)}
                variant="outlined"
                style={{ width: "250px" }}
                size="small"
              />
            </div>
          </div>
        </div>
      )}

      {erroare && erroare.length !== 0 && (
        <div className="e-card e-card-border">
          <div className="e-card-content">
            <div className="msg-error-new">{erroare}</div>
          </div>
        </div>
      )}
      {tipClient !== "X" && (
        <div>
          {verificare && verificare !== "EROARE" && (
            <div>
              <div className="e-card e-card-border">
                <div className="e-card-content">
                  <div className="hlist">
                    <div className="doc-field">
                      <TextField
                        value={client.Id ? client.Id : ""}
                        label="ID"
                        //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                        onChange={(props) => setClient(props.target.value)}
                        variant="filled"
                        style={{ width: "200px" }}
                        disabled
                        size="small"
                        //cssClass="header-field-request"
                        //readonly={true}
                      />
                    </div>
                    <div>
                      {client.Categorie === "J" && (
                        <div className="doc-field">
                          <TextField
                            value={
                              client.Atribut_Fiscal ? client.Atribut_Fiscal : ""
                            }
                            label="Atribut Fiscal"
                            //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                            onChange={(prop) =>
                              updateField(
                                { Atribut_Fiscal: prop.target.value },
                                "client"
                              )
                            }
                            variant="outlined"
                            style={{ width: "100px" }}
                            size="small"
                            // cssClass="header-field-request"
                          />
                        </div>
                      )}
                    </div>
                    <div className="doc-field">
                      <TextField
                        value={client.Nume ? client.Nume : ""}
                        label="Nume Client"
                        //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                        onChange={(prop) =>
                          updateField({ Nume: prop.target.value }, "client")
                        }
                        variant="outlined"
                        style={{ width: 420 }}
                        size="small"
                        // cssClass="header-field-request"
                      />
                    </div>
                    <div>
                      {client.Categorie === "J" && (
                        <div className="doc-field">
                          <FormControl
                            variant="outlined"
                            style={{ width: 200 }}
                            size="small"
                          >
                            <InputLabel>Forma Legala</InputLabel>
                            <Select
                              value={
                                client.Forma_Legala ? client.Forma_Legala : ""
                              }
                              onChange={(prop) =>
                                updateField(
                                  { Forma_Legala: prop.target.value },
                                  "client"
                                )
                              }
                              label="Forma Legala"
                              inputProps={{
                                name: "Forma Legala",
                              }}
                            >
                              {nomenclatoare.formeLegale.map((value) => (
                                <MenuItem
                                  key={`dd${value[0]}`}
                                  value={value[0]}
                                >
                                  {value[1]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                    </div>
                  </div>
                  {client.Categorie === "J" && (
                    <div>
                      <div className="doc-field">
                        <FormControl
                          variant="outlined"
                          style={{ width: 650 }}
                          size="small"
                        >
                          <InputLabel>Clasifcare Interna</InputLabel>
                          <Select
                            value={
                              client.Segment_Risc ? client.Segment_Risc : ""
                            }
                            onChange={(prop) =>
                              updateField(
                                { Segment_Risc: prop.target.value },
                                "client"
                              )
                            }
                            label="Clasifcare Interna"
                            inputProps={{
                              name: "Clasifcare Interna",
                            }}
                          >
                            {nomenclatoare.segmentRisc.map((value) => (
                              <MenuItem key={`dd${value[0]}`} value={value[0]}>
                                {value[1]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="hlist">
                        <div className="doc-field">
                          <TextField
                            value={client.CAEN ? client.CAEN : ""}
                            label="CAEN"
                            //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                            onChange={(prop) => getCaen(prop.target.value)}
                            variant="outlined"
                            style={{ width: "50px" }}
                            size="small"
                            // cssClass="header-field-request"
                          />
                        </div>

                        <div className="doc-field">
                          <TextField
                            value={client.CAEN ? client.CAEN : ""}
                            label="CAEN"
                            //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                            onChange={(prop) => getCaen(prop.target.value)}
                            variant="outlined"
                            style={{ width: "50px" }}
                            size="small"
                            // cssClass="header-field-request"
                          />
                        </div>

                        <div className="doc-field">
                          <TextField
                            value={
                              client.CAEN_Denumire ? client.CAEN_Denumire : ""
                            }
                            label="Denumire CAEN"
                            variant="outlined"
                            style={{ width: "550px" }}
                            disabled
                            size="small"
                            // cssClass="header-field-request"
                          />
                        </div>

                        <div className="doc-field">
                          <TextField
                            value={client.Procent ? client.Procent : ""}
                            label="Procent"
                            //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                            onChange={(prop) =>
                              updateField(
                                { Procent: prop.target.value },
                                "client"
                              )
                            }
                            variant="outlined"
                            style={{ width: "50px" }}
                            disabled
                            size="small"
                            // cssClass="drop-field"
                          />
                        </div>
                      </div>
                      <div className="hlist">
                        <div className="doc-field">
                          <TextField
                            value={
                              client.Cod_Registru ? client.Cod_Registru : ""
                            }
                            label="Nr. Inregistrar Fiscala"
                            //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                            onChange={(prop) =>
                              updateField(
                                { Cod_Registru: prop.target.value },
                                "client"
                              )
                            }
                            variant="outlined"
                            style={{ width: "200px" }}
                            size="small"
                            // cssClass="header-field-request"
                          />
                        </div>

                        <div className="doc-field">
                          <DatePicker
                            value={
                              client.Data_Infiintare &&
                              client.Data_Infiintare !== "01/01/1900"
                                ? moment(client.Data_Infiintare, "DD/MM/YYYY")
                                : null
                            }
                            label="Data Infiintare"
                            onChange={(prop) =>
                              updateField({ Data_Infiintare: prop }, "client")
                            }
                            format="DD/MM/YYYY"
                            inputVariant="outlined"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            style={{ width: 250 }}
                            autoOk
                            size="small"
                            sx={{
                              width: "-webkit-fill-available",
                              input: { padding: "8.5px 14px" },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="hlist">
                    <div className="doc-field">
                      <TextField
                        value={
                          client.Analitic_Client ? client.Analitic_Client : ""
                        }
                        label="Analitic Client"
                        variant="outlined"
                        style={{ width: 420 }}
                        disabled
                        size="small"
                        // cssClass="header-field-request"
                      />
                    </div>

                    <div className="doc-field">
                      <TextField
                        value={client.Data_Inrolare ? client.Data_Inrolare : ""}
                        label="Data Inrolare"
                        variant="outlined"
                        style={{ width: "200px" }}
                        disabled
                        size="small"
                        // cssClass="header-field-request"
                      />
                    </div>
                  </div>
                  <div className="hlist">
                    <div className="doc-field">
                      <FormControl
                        variant="outlined"
                        style={{ width: 200 }}
                        size="small"
                      >
                        <InputLabel>Judet</InputLabel>
                        <Select
                          value={client.Cod_Judet ? client.Cod_Judet : ""}
                          onChange={(prop) =>
                            updateField(
                              { Cod_Judet: prop.target.value },
                              "client",
                              {
                                function: getLocalitati,
                                value: {
                                  id: prop.target.value,
                                  update: true,
                                },
                              }
                            )
                          }
                          label="Judet"
                          inputProps={{
                            name: "Judet",
                          }}
                        >
                          {nomenclatoare.judete.map((value) => (
                            <MenuItem key={`dd${value.Cod}`} value={value.Cod}>
                              {value.Denumire}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="doc-field">
                      <FormControl
                        variant="outlined"
                        style={{ width: 420 }}
                        size="small"
                      >
                        <InputLabel>Oras</InputLabel>
                        <Select
                          value={
                            client.Cod_Localitate ? client.Cod_Localitate : ""
                          }
                          onChange={(prop) =>
                            updateField(
                              { Cod_Localitate: prop.target.value },
                              "client"
                            )
                          }
                          label="Oras"
                          inputProps={{
                            name: "Oras",
                          }}
                        >
                          {nomenclatoare.localitati &&
                            nomenclatoare.localitati.map((value) => (
                              <MenuItem
                                key={`dd${value.Cod}`}
                                value={value.Cod}
                              >
                                {value.Denumire}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="hlist">
                    <div className="doc-field">
                      <TextField
                        value={client.Strada ? client.Strada : ""}
                        label="Strada"
                        //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                        onChange={(prop) =>
                          updateField({ Strada: prop.target.value }, "client")
                        }
                        variant="outlined"
                        style={{ width: "420px" }}
                        size="small"
                        // cssClass="header-field-request"
                      />
                    </div>

                    <div className="doc-field">
                      <TextField
                        value={client.Numar ? client.Numar : ""}
                        label="Numar"
                        //onChange={(prop)=> actions.updateValue(item, prop.target.value)}
                        onChange={(prop) =>
                          updateField({ Numar: prop.target.value }, "client")
                        }
                        variant="outlined"
                        style={{ width: "200px" }}
                        size="small"
                        // cssClass="header-field-request"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {fieldsVisible && cimpuri.length !== 0 && (
        <div className="e-card e-card-border">
          <div className="e-card-content">
            <div className={"doc-fields inline"}>
              <Fields data={cimpuri} actions={actions} />
            </div>
          </div>
        </div>
      )}

      {tipClient !== "X" && (
        <div className="e-card e-card-border">
          <div className="e-card-content">
            <div className="field-inline-label">
              <div className="doc-field-label">Preia date din Cererea</div>
              <FormControl
                variant="outlined"
                style={{ width: 450 }}
                size="small"
              >
                <InputLabel>Cerere</InputLabel>
                <Select
                  value={idVechi ? idVechi : ""}
                  onChange={(prop) => setCerere(prop.target.value)}
                  label="Cerere"
                  inputProps={{
                    name: "Cerere",
                  }}
                >
                  {cereri &&
                    cereri.map((value) => (
                      <MenuItem
                        key={`dd${value.Id_Cerere}`}
                        value={value.Id_Cerere}
                      >
                        {value.Denumire}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default inject((stores) => ({
  store: stores.cerereNouaStore,
  main: stores.mainStore,
  filterSursaLista: stores.commonStore.filterSursaLista,
}))(withRouter(observer(Cerere)));
