import React from "react";
import { observer } from "mobx-react";
import { jsonParse } from "Utils";
import { IconGift } from "TemplateCerere";
import SvgIcon from "@mui/material/SvgIcon";
import "./OnboardingDepozitePF.scss";

function OnboardingDepozitePF_View (props) {
    const { row, downloadAcord } = props;
    var info = jsonParse(row.INFO) || {};
    return (
      <div id="edf6w">
        <div className="card-header">
          <div className="e-card-content-document">
            <div className="depozit-list">
              <div className="depozit-card">
                {/*<div className="depozit-valuta">{`Detalii depozit in ${row.VALUTA}:`}</div>*/}
                <div className="depozit-valuta">{row.DENUMIRE}</div>

                <div className="depozit-name">
                  {`Valoare minima: ${info.sumaMinima}`}
                </div>

                <div className="depozit-name">{`Termene: ${info.termene}`}</div>
                <div
                  className="depozit-link"
                  onClick={() => downloadAcord(117)}
                >
                  Vezi lista dobanzilor in vigoare
                </div>
              </div>
              {info.bonus && (
                <div className="depozit-card depozit-bonus">
                  <div className="h-list top">
                    <SvgIcon
                      component={IconGift}
                      className="depozit-bonus-icon"
                      viewBox="0 0 16 16.001"
                    />
                    <span>
                      <b className="depozit-bonus-label">Bonus: </b>
                      {info.bonus}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
export default observer(OnboardingDepozitePF_View);
