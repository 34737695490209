import React from "react";
import { observer } from "mobx-react";

import styled from "styled-components";

import TextField from "@mui/material/TextField";

import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import { Update } from "@mui/icons-material";
import { getValueKey, getValueOfList } from "Utils";

import "../index.css";

function Field6_Edit_LS(props) {
  const { row, updateRow, col, tableId, store, runEvent, isReadonly } = props;
  const AutocompleteCss = styled(Autocomplete)`
    ${col && col.eventProps ? col.eventProps.style : null}
  `;
  return (
    <div className="refresh-autocomplete">
      <AutocompleteCss
        id={`eac-${col.key}`}
        controled="true"
        options={col.visibleList(row)}
        getOptionLabel={(option) => getValueKey(option, 1)}
        //clearOnEscape
        //value={row ? getValueOfList(col.lista, row[col.key]) :null }
        value={
          row && row[col.key] != null
            ? getValueOfList(col.lista, row[col.key].toString())
            : null
        }
        //style={{ width: 350}}
        size="small"
        onChange={(event, newValue) => {
          updateRow(
            row,
            col.tip,
            col.key,
            newValue && getValueKey(newValue, 0),
            tableId
          );
        }}
        //autoComplete
        disabled={col.isReadonly || isReadonly}
        className={`doc-table-value${
          col.isReadonly || isReadonly ? " disabled" : ""
        }`}
        label={col.inline && col.Caption}
        renderInput={(params) => (
          <TextField
            {...params}
            //label={col.headerText}
            variant="outlined"
          />
        )}
      />
      {col.hasButton && !(col.isReadonly || isReadonly) && (
        <IconButton
          aria-label="toggle "
          onClick={() => {
            runEvent(store, col, false, true, row);
          }}
          size="small"
        >
          <Update />
        </IconButton>
      )}
    </div>
  );
}
export default observer(Field6_Edit_LS);
