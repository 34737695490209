export const checkFullName = (value)=>{
  if (!value){
    return false;
  }
  if (typeof value!=="string"){
    return false;
  }
  value = value.toLowerCase();
//Validare pentru nume
  var re=/^([a-z]{1,2}[' ])?([a-z]{3,})(-[a-z]{2,})*( ([a-z]{1,2}[' ])*([a-z]{3,})(-[a-z]{2,})*)+$/igm;

/*https://www.regextester.com/


optional (1/2 litere urmate de ' sau spatiu) minim 3 litere optional (- minim 2 litere)
(spatiu si se retepat modelul de mai sus) apare de 1 sau mai multe ori
*/

return re.test(value);
}

export const checkName = (value)=>{
  if (!value){
    return false;
  }
  if (typeof value!=="string"){
    return false;
  }
  value = value.toLowerCase();
//Validare pentru nume
//  var re=/^([a-z]{1,2}[' ])?([a-z]{3,})(-[a-z]{2,})*( ([a-z]{1,2}[' ])*([a-z]{3,})(-[a-z]{2,})*)+$/igm;

/*https://www.regextester.com/


optional (1/2 litere urmate de ' sau spatiu) minim 3 litere optional (- minim 2 litere)
(spatiu si se retepat modelul de mai sus) apare de 1 sau mai multe ori
*/

//Validare doar pentru prenumenume
  var re=/^([a-z]{3,})(-[a-z]{2,})*( ([a-z]{1,2}[' ])*([a-z]{3,})(-[a-z]{2,})*)*$/igm;

return re.test(value);
}

export const checkCNP = (value)=>{
  if (!value){
    return false;
  }
  if (typeof value!=="string"){
    return false;
  }
  var re=/[1-8][0-9]{12}/;
  if (!re.test(value)){
    return false;
  }
  var cnp='279146358279';
  var t = 0;
  for (var i=0;i<12; i++){
    t += cnp[i] * value[i]; 
  }
  t %=11;
  return (t===10?1:t).toString()===value[12];
}

export const checkEmail = (value) => {
  if (!value){
    return false;
  }
  if (typeof value!=="string"){
    return false;
  }
  var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
  return re.test(value.toLowerCase());
}
