import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";

function Notification(props) {
  const { msgHtml, getNotification } = props.store;
  useEffect(() => {
    let query = new URLSearchParams(props.location.search);
    var params = {};
    query.forEach(function (value, key) {
      params[key] = value;
      console.log(value, key);
    });
    getNotification(params);
  }, []);

  return (
    <div className="card-notification">
      <div className="page-notification">
        <div
          id="msgHtml"
          className="msgHtml"
          dangerouslySetInnerHTML={{ __html: msgHtml }}
        />
      </div>
    </div>
  );
}
export default inject((stores) => ({ store: stores.mainStore }))(
  observer(Notification)
);
