export const setupEvent = (item, event, eventDef) => {
  event = event || eventDef;
  if (!item || !event) {
    return;
  }
  item.event = typeof event === "function" ? event : eventDef;
  item.eventType = "array";
  ///verific daca e clickable///
  if (typeof event === "function") {
    item.eventType = "function";
    if (event.length >= 2) {
      item.hasButton = true;
    }
    //    console.log("function ", event);
  }
};

export const runEvent = (_this, item, isLoad, isClick, obj) => {
  if (!(item && item.event)) {
    return;
  }
  console.debug("run event", [item.id, item.Caption, isLoad, isClick, obj]);
  var _allFields = _this.allFields;
  if (item.eventType === "function") {
    if (!(isLoad && item.Tip === 0 && item.Lungime === 0)) {
      try {
        item.event.call(_this, isLoad, isClick, obj);
      } catch (error) {
        console.log("runEvent", item.Id);
        console.log(item.Eveniment);
        console.log(error);
      }
    }
  } // e array de evenimente
  else {
    var e = item.event.call(_this, item); //reevaluez expresia
    e.forEach((eventProps) => {
      var cimp = _allFields[eventProps.id];
      if (!cimp) {
        return;
      }

      if (eventProps.filter) {
        _this.filterSursaLista(cimp, eventProps.filter);
      }

      if (eventProps.readOnly !== undefined) {
        cimp.isReadonly = eventProps.readOnly || cimp.docReadOnly;
      }
      if (eventProps.isReadonly !== undefined) {
        cimp.isReadonly = eventProps.isReadonly || cimp.docReadOnly;
      }
      if (eventProps.isVisible !== undefined) {
        cimp.isVisible = eventProps.isVisible;
      }

      if (eventProps.Valoare !== undefined) {
        cimp.Valoare = eventProps.Valoare;
      }

      if (eventProps.Caption !== undefined) {
        cimp.Caption = eventProps.Caption;
      }
      cimp.eventProps = eventProps; //styles
    });
  }
};
