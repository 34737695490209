import { useState } from "react";
import { observer } from "mobx-react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconText } from "TemplateCerere";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { IconButton } from "@mui/material";

//import "./acord.scss";

const TextLine = ({
  children,
  tooltip,
  tooltipClass,
  iconStart,
  iconTooltip,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <span className="normal-text icon-text">
      {iconStart && <IconText {...iconStart} />}
      <div>{children}</div>
      {tooltip && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            arrow
            title={
              <Typography component="span" variant="body2">
                {tooltip}
              </Typography>
            }
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            classes={{ tooltip: tooltipClass }}
          >
            {iconTooltip && (
              <IconButton
                onClick={handleTooltipOpen}
                onMouseEnter={handleTooltipOpen}
              >
                <IconText {...iconTooltip} />
              </IconButton>
            )}
          </Tooltip>
        </ClickAwayListener>
      )}
    </span>
  );
};
export default observer(TextLine);
