import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import "./index.css";

import { Preview, PrintDocuments } from "TemplateCerere";
import Toolbar from "./toolbar";
import Tab from "./tab";
import { EditDialog } from "TemplateCerere";
import { runEvent } from "Utils";
import { Prompt } from "react-router-dom";

function Cerere(props) {
  useEffect(() => {
    props.main.checkLoginUser(
      props.history,
      props.match.url,
      props.appType ?? "cerere",
      props.match.params
    );
    props.main.updateValue({ preventGoBack: true });
    window.addEventListener("load", (event) => {
      event.preventDefault();
    });
    props.store.doActivate(props.match.params);
  }, []);

  const {
    noMatch,
    isMobile,
    preventGoBack,
    preventGoBackMessage,
    downloadAcord,
    errors,
    downloadDocument,
  } = props.main;
  if (noMatch) {
    return (
      <div
        className="nomatch"
        /*fullWidth*/ dangerouslySetInnerHTML={{ __html: noMatch }}
      />
    );
  }
  const {
    changeTab,
    dialog,
    preview,
    docListareCerere,
    editDialog,
    closeEditDialog,
    saveEditDialog,
  } = props.store;
  const {
    /*taburi,*/ sectiuni,
    currentTab,
    caption,
    openPrintParameters,
    type,
  } = props.store;
  const {
    doDeactivate,
    updateField,
    updateValue,
    rowChecked,
    uploadFile,
    updateFile,
    deleteFile,
    downloadFile,
    previewFile,
    closePreviewFile,
    showFile,
    //close,
    save,
    openEditTable,
    updateRow,
    addTableRow,
    deleteTableRow,
    //changedFields,
    changedTabels,
    canPass,
    nextStep,
    //nextSteps,
    getRequest,
    culoare,
    addToChangedTables,
    downloadCloud,
    runFieldEvents,
    savePhoto,
    updateStore,
    getPrintFile,
    lendingActivate,
    addSimulation,
  } = props.store;
  const openError = errors.open;
  const processMsg = errors.processMsg;

  const {
    changedFields,
    getField,
    queryData,
    tab,
    doc,
    getHeaders,
    getFields,
  } = props.common;
  const store = props.store;
  const filterSursaLista = props.filterSursaLista;

  const actions = {
    doDeactivate,
    updateField,
    updateValue,
    rowChecked,
    uploadFile,
    updateFile,
    deleteFile,
    downloadFile,
    previewFile,
    showFile,
    filterSursaLista,
    openEditTable,
    updateRow,
    addTableRow,
    deleteTableRow,
    runFieldEvents,
    savePhoto,
    runEvent,
    store,
    addToChangedTables,
    getField,
    downloadAcord,
    queryData,
    openError,
    //getStepsInfo,
    processMsg,
    updateStore,
    closeEditDialog,
    getPrintFile,
    lendingActivate,
    addSimulation,
    downloadDocument,
    downloadCloud,
    getHeaders,
    getFields,
    //updateMainStore,
  };
  const handlerClose = async () => {
    //const { type } = this.props.store;

    await props.main.updateValue({ preventGoBack: false });
    props.history.push(`/${props.main.appType}/${type}`);
  };
  const hasChanges = function () {
    return !(
      Object.keys(changedFields).length === 0 && changedTabels.length === 0
    );
  };
  return (
    <div className="flux-request">
      <Prompt when={preventGoBack} message={preventGoBackMessage} />
      <Preview close={closePreviewFile} {...preview} />
      <EditDialog
        close={closeEditDialog}
        save={saveEditDialog}
        {...editDialog}
        className={editDialog.className || ""}
      />
      <Dialog open={dialog.isOpen} height="250px">
        <DialogTitle>{dialog.header}</DialogTitle>
        <DialogContent>
          <div className="dialog-message">{dialog.message}</div>
          <div className="dialog-buttons">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dialog.isOpen = false;
                getRequest(
                  dialog.isNextStep ? null : currentTab
                ); /*close(this.props.history.push)*/
              }}
            >
              Inchide
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Toolbar
        title={caption}
        taburi={tab}
        currentTab={currentTab}
        changeTab={changeTab}
        close={handlerClose}
        save={() => save(currentTab, true, 0)}
        nextStep={nextStep}
        hasChanges={hasChanges}
        canPass={canPass}
        //nextSteps={nextSteps}
        //nextStepHandler={save}
        color={culoare}
      />
      {docListareCerere.length !== 0 && (
        <PrintDocuments
          item={docListareCerere}
          openPrintParameters={openPrintParameters}
        />
      )}
      {currentTab && (
        <Tab
          sectiuni={sectiuni.filter((s) => s.tab == currentTab.Id)}
          actions={actions}
          doc={doc}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}

export default inject((stores) => ({
  store: stores.cerereStore,
  main: stores.mainStore,
  common: stores.commonStore,
  filterSursaLista: stores.commonStore.filterSursaLista,
}))(withRouter(observer(Cerere)));
