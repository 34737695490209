import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

//import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Update } from "@mui/icons-material";

import "../index.css";
import { TextFieldMask } from "../components";

function Field6_Edit_T(props) {
  const { row, updateRow, col, tableId, actions, isReadonly } = props;
  const TextFieldCss = styled(TextFieldMask)`
    ${col && col.eventProps ? col.eventProps.style : null}
  `;
  return (
    <TextFieldCss
      id={`etb-${col.key}`}
      defaultValue={
        row && row[col.key] !== undefined && row[col.key] != null
          ? row[col.key].toString()
          : ""
      }
      onBlur={(prop) => {
        var newValue = prop.target.value;
        if (col.editMask === "U") {
          newValue = newValue.toUpperCase();
        }
        updateRow(row, col.tip, col.key, newValue, tableId);
      }}
      //value={ row && row[col.key] ? row[col.key].toString() : ""}
      //onChange={(prop)=> updateRow(row, col.tip, col.key, prop.target.value, tableId)}
      variant="outlined"
      readOnly={col.isReadonly || isReadonly}
      disabled={col.isReadonly || isReadonly}
      className={`doc-table-value${
        col.isReadonly || isReadonly ? " disabled" : ""
      }`}
      label={col.inline ? col.Caption : null}
      size="small"
      mask={col.mask}
      maskChar=""
      InputProps={{
        endAdornment: col.hasButton &&
          !(col.isReadonly || isReadonly) && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle "
                onClick={() => {
                  actions.runEvent(actions.store, col, false, true, row);
                }}
                size="small"
              >
                <Update />
              </IconButton>
            </InputAdornment>
          ),
      }}
    />
  );
}

export default observer(Field6_Edit_T);
