import React from "react";
import { observer } from "mobx-react";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";

const CheckBox = (props) => {
  return (
    <Checkbox
      {...props}
      icon={<CheckBoxOutlineBlankSharpIcon />}
      checkedIcon={<CheckBoxSharpIcon />}
    />
  );
};
export default observer(CheckBox);
