import { lazy, useEffect, useState } from "react";
import { observer } from "mobx-react";

const ThemeRetail = lazy(() => import("./retail"));
const ThemeFree = lazy(() => import("./free"));
const ThemeDefault = lazy(() => import("./default"));
/*
const Theme = lazy((themeName) =>
import(`./${themeName}`).then((theme) => {
  updateValue({ themeSelected: true });
  return theme;
})
);
*/

const SelectedTheme = (props) => {
  const updateValue = props.updateValue;
  let { theme } = props;
  theme = props?.theme ?? "default";
  useEffect(() => {
    updateValue({ themeSelected: true });
  }, [theme]);
  if (theme === "retail") {
    return <ThemeRetail {...props} />;
  }

  if (theme === "free") {
    return <ThemeFree {...props} />;
  } else {
    return <ThemeDefault {...props} />;
  }
};

export default observer(SelectedTheme);
