import React from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";

import "../index.css";

const TextFieldMask = (props) => {
  const {
    id,
    label,
    //value,
    error,
    helperText,
    className,
    variant,
    type,
    textProps,
    inputProps,
    maskChar,
    isDecimal,
    inputComponent,
    ...other
  } = props;
  function NumericFormatCustom(props) {
    const { inputRef, onChange, tip, colkey, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        valueIsNumericString
        style={{ textAlign: "right" }}
      />
    );
  }
  const component = isDecimal ? NumericFormatCustom : inputComponent;
  return (
    <InputMask
      id={id}
      label={label}
      //value={value}
      error={error}
      helperText={helperText}
      className={className}
      variant={variant}
      maskChar={maskChar}
      {...other}
    >
      {() => (
        <TextField
          id={id}
          label={label}
          //value={value}
          error={error}
          helperText={helperText}
          className={className}
          variant={variant}
          type={type || "text"}
          InputProps={{
            ...inputProps,
            inputComponent: component,
          }}
          {...other}
        />
      )}
    </InputMask>
  );
};
export default observer(TextFieldMask);
