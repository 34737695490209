import React from "react";
import { observer } from "mobx-react";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { getStyle } from "Utils";
import DivCss from "../components/divCss";
import "../index.css";
import { TooltipIcon } from "TemplateCerere";

function Field3(props) {
  const { item, inline, actions } = props;

  const styleProps = getStyle(item.eventProps);
  const TextFieldCss = styled(TextField)`
    ${styleProps.input}
  `;
  const CompDivCss = styled(DivCss)`
    ${styleProps.field}
  `;
  function NumericFormatCustom(props) {
    const { inputRef, /* onChange,*/ ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onBlur={(prop) => actions.updateValue(item, prop.target.value)}
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        valueIsNumericString
        style={{ textAlign: "right" }}
      />
    );
  }
  const infoLabel = item?.Jprop?.infoLabel;
  return (
    item.isVisible && (
      <CompDivCss {...props} id={`f3-${item.Id}`} key={item.Id}>
        {!inline && (
          <div id={`f3l-${item.Id}`} className="doc-field-label">
            {item.Caption + (item.Obligatoriu ? " *" : "")}
            {infoLabel && <TooltipIcon tooltip={infoLabel} />}
          </div>
        )}
        <TextFieldCss
          id={`f3f-${item.Id}`}
          defaultValue={item.Valoare}
          label={inline ? item.Caption : null}
          disabled={item.isReadonly || item.docReadOnly}
          className={item.isReadonly || item.docReadOnly ? "disabled" : null}
          variant="outlined"
          InputProps={{
            inputComponent: NumericFormatCustom,
            inputProps: { decimalScale: item.Lungime },
          }}
          size="small"
          required={item.Obligatoriu}
          error={!!item.error}
          helperText={item.error || item.Jprop.helperText}
        />
      </CompDivCss>
    )
  );
}
export default observer(Field3);
