import { extendObservable, set } from "mobx";
import moment from "moment";

const model = {
  isOpen: false,
  header: "Parametrii de listare",
  parametrii: {},
  doc: { Id: "printParameters", Headers: [] },
  print: null,
  showError: null,
};
export default class PrintParameters {
  constructor() {
    extendObservable(this, model);
  }

  open = ({ parametrii, headers, print, showError }) => {
    this.parametrii = parametrii;
    this.print = print;
    this.showError = showError;
    this.doc.Headers = headers;

    if (this.parametrii.length !== 0) {
      this.isOpen = true;
    }
  };

  printFile = () => {
    var errors = this.validateParameters();
    if (errors.length !== 0 && this.showError) {
      this.showError(errors);
      return;
    }

    if (this.print) {
      this.print(this.parametrii);
      this.close();
    }
  };

  updateValue = (item, value) => {
    if (value === undefined) {
      value = null;
    }
    /*trebuie sa permit schimbarea daca value=""*/
    var sValue = "";
    switch (item.Tip) {
      case 0: //textbox
        sValue = value;
        break;
      case 1: //memo
        sValue = value;
        break;
      case 2: //data
        sValue = value ? moment(value).format("DD/MM/YYYY") : "";
        //sValue =  value ? value : "";
        break;
      case 3: //numeric
        //sValue = parseFloat(value.split('.').join('').replace(",","."));
        //sValue = parseFloat(value.replace(/[.]/g, '').replace(",",".")).toString();
        sValue = value.replace(/[.]/g, "").replace(",", ".");
        break;
      case 4: //listbox
        sValue = value.toString();
        break;
      case 5: //checkbox
        sValue = value ? "1" : "0";
        break;
      case 6: //grid
        sValue = value;
        break;
      case 7: //richedit
        sValue = value;
        break;
      default:
        sValue = value;
    }

    if (item.Valoare !== sValue) {
      this.parametrii.param[item.Param] = sValue.toString();
      item.Valoare = sValue;
    }
  };

  validateParameters = () => {
    var errors = [];
    if (this.doc.Headers.lengt !== 0) {
      this.doc.Headers.forEach((h) => {
        h.Cimpuri.forEach((item) => {
          if (item.Obligatoriu) {
            var param = this.parametrii.param[item.Param];
            if (param === null || param === undefined) {
              errors.push(`Parametrul "${item.Caption} " nu este completat!`);
            }
          }
        });
      });
    }
    return errors;
  };

  close = () => {
    set(this, model);
  };
}
