import React from "react";
import { observer } from "mobx-react";
import "./widgetInfo.scss";
import * as imgs from "../img";
const WidgetInfo = (props) => {
  const { widget } = props;
  if (!widget?.data) {
    return null;
  }
  return (
    <div className={widget?.className ?? "widget-info"}>
      <div className="widget-header">{widget.title}</div>
      <div className="widget-title">{widget.subtitle}</div>
      {widget?.data?.map((m, i) => (
        <div className={`widget-line ${m.c}`} key={i}>
          {m.i && <img src={imgs[m.i]} alt=""></img>}
          <span className="widget-field">{m.f} </span>
          <span className="widget-value">{m.v}</span>
        </div>
      ))}
    </div>
  );
};
export default observer(WidgetInfo);
