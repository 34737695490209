import React from "react";
import { observer } from "mobx-react";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";

import "../index.css";

function Filter_N(props) {
  const { cimp, valoare, updateFilter, end, decimalScale } = props;
  function NumericFormatCustom(props) {
    const { inputRef, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onBlur={(prop) => {
          var value = prop.target.value.replace(/[.]/g, "").replace(",", ".");
          updateFilter(cimp, value, end);
        }}
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        valueIsNumericString
        style={{ textAlign: "right" }}
      />
    );
  }
  return (
    <TextField
      id={`nf${cimp}`}
      value={valoare} //{valoare ? valoare : null}
      className="doc-table-value"
      variant="outlined"
      /* onBlur={(prop) =>
          updateFilter(cimp, prop.target.value.replace(/[.]/g, "").replace(",", "."))
        }*/
      InputProps={{
        inputComponent: NumericFormatCustom,
        inputProps: { decimalScale: decimalScale },
      }}
      //style={this.eventProps.style}
      size="small"
    />
  );
}
export default observer(Filter_N);
