import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
//import { makeStyles } from "@mui/material/styles";
//import { createTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import FormHelperText from "@mui/material/FormHelperText";

import {
  DeleteRounded,
  PageviewRounded,
  GetApp,
  CloudUpload,
  PhotoCamera,
  FlipCameraIosOutlined,
  SaveOutlined,
  Close,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import Button from "@mui/material/Button";
import { base64ToArrayBuffer, guid } from "Utils";
import { DropZone } from "../components";
import "react-html5-camera-photo/build/css/index.css";

import "../index.css";

function Document2(props) {
  const { doc, actions, isMobile, error } = props;
  const { uploadFile } = actions;

  const [open, setOpen] = useState(false);
  const [openPicture, setOpenPicture] = useState(false);
  const [facingMode, setFacingMode] = useState(FACING_MODES.USER);
  const [imageType, setImageType] = useState(IMAGE_TYPES.PNG);
  const [dataUri, setDataUri] = useState(null);
  const [disabled, setDisabled] = useState(null);
  const handlePhotoSave = () => {
    var contentFileData = dataUri.replace(
      `data:image/${imageType};base64,`,
      ""
    );
    var contentFile = base64ToArrayBuffer(contentFileData);
    var file = new File([contentFile], `${guid()}.${imageType}`, {
      type: `image/${imageType}`,
    });
    setOpenPicture(false);
    setDataUri(null);
    uploadFile(file, doc);
  };

  const handleSave = (files) => {
    setOpen(false);
    setOpenPicture(false);
    uploadFile(files[0], doc);
  };
  const changeFacingMode = () => {
    setFacingMode(
      facingMode === FACING_MODES.ENVIRONMENT
        ? FACING_MODES.USER
        : FACING_MODES.ENVIRONMENT
    );
  };
  const setVisibilityToCamera = (doc) => {
    if (doc.Params.usecamera === 1) return "";
    return "none";
  };
  return (
    <div className="file-doc">
      <DropZone
        doc={doc}
        onSave={handleSave}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      />

      <Dialog
        open={openPicture}
        onClose={() => {
          setOpenPicture(false);
        }}
        //fullScreen={true}
        fullWidth={false}
        //maxWidth="md"
        maxWidth={false}
      >
        <DialogContent className="camera-dialog-content">
          <div className="camera-top">
            <div className="camera-dialog-title">
              <div>Poza buletin</div>
              <Tooltip title="Inchide">
                <Fab
                  onClick={() => {
                    setOpenPicture(false);
                  }}
                >
                  <Close fontSize="large" />
                </Fab>
              </Tooltip>
            </div>
          </div>
          <div className="camera-bottom">
            {!dataUri && (
              <Tooltip title="Schimba Camera">
                <Fab onClick={changeFacingMode}>
                  <FlipCameraIosOutlined fontSize="large" />
                </Fab>
              </Tooltip>
            )}
            {dataUri && (
              <Tooltip title="Salveaza">
                <Fab onClick={handlePhotoSave}>
                  <SaveOutlined fontSize="large" />
                </Fab>
              </Tooltip>
            )}
          </div>
          {dataUri ? (
            <img src={dataUri} /*className='download-icon-img'*/ alt="" />
          ) : (
            <Camera
              onTakePhoto={(dataUri) => setDataUri(dataUri)}
              idealResolution={{ width: 640, height: 480 }}
              imageType={imageType}
              isMaxResolution={false}
              isImageMirror={false}
              isSilentMode={true}
              isDisplayStartCameraError={true}
              //isFullscreen={true}
              //sizeFactor={1}
              idealFacingMode={facingMode}
              imageCompression={1}
            />
          )}
        </DialogContent>
      </Dialog>
      {doc.Incarcare && !isMobile && setVisibilityToCamera(doc) != "none" && (
        <div className="card-header">
          <div className="card-header-title">
            <div className="take-photo">
              <Button
                onClick={() => {
                  setOpenPicture(true);
                }}
                variant="contained"
                color="primary"
                disabled={disabled}
                startIcon={<PhotoCamera />}
                style={{ display: setVisibilityToCamera(doc) }}
              >
                Deschide camera
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="e-card-content-document">
        {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        <DocTable
          doc={doc}
          actions={actions}
          error={error}
          setOpen={setOpen}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      </div>
    </div>
  );
}

function DocTable(props) {
  const { doc, actions, setOpen, disabled, setDisabled } = props;
  const [limitFile, setLimitFile] = useState("");
  const [docs, setDocs] = useState([]);
  useEffect(() => {
    const limit = (doc.Params.limit || "0") * 1;
    const docsx = doc.DocFile.filter((x) => x.Id !== null);
    setDocs(docsx);
    setDisabled((limit !== 0 && limit <= docsx.length) || !doc.Incarcare);
    setLimitFile(
      limit !== 0
        ? `Fisiere ${docsx.length} din limita ${limit} `
        : `Fisiere ${docsx.length} `
    );
  }, [doc.DocFile]);
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              style={{
                padding: "5px",
                maxWidth: "200px",
                wordWrap: "break-word",
              }}
            >
              {limitFile}
            </TableCell>

            {doc.Incarcare && (
              <TableCell width={150} style={{ padding: "5px" }}>
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                  startIcon={<CloudUpload />}
                >
                  Incarca Fisier
                </Button>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
      </Table>
      <Table size="small">
        <TableBody>
          {docs.map((item, index) => (
            <TableRow key={`${doc.Id}-${index}`}>
              <TableCell
                component="th"
                scope="row"
                style={{
                  padding: "5px",
                  maxWidth: "15px",
                  wordWrap: "break-word",
                }}
              >
                {item.NumeFisier}
              </TableCell>
              {/*<TableCell>{item.Info}</TableCell>*/}
              {doc.Incarcare && !doc.Stergere && (
                <TableCell width={5} style={{ padding: "5px" }}>
                  <Tooltip title="Sterge">
                    <IconButton
                      onClick={() => actions.deleteFile(item, doc)}
                      size="small"
                    >
                      <DeleteRounded
                        color="secondary"
                        style={{ fontSize: 30 }}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}
              <TableCell width={5} style={{ padding: "5px" }}>
                <Tooltip title="Descarca">
                  <IconButton
                    onClick={() => actions.downloadFile(item)}
                    size="small"
                  >
                    <GetApp color="primary" style={{ fontSize: 30 }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell width={5} style={{ padding: "5px" }}>
                <Tooltip title="Vizualizare">
                  <IconButton
                    onClick={() => actions.previewFile(item)}
                    size="small"
                  >
                    <PageviewRounded
                      style={{ color: "lightgreen", fontSize: 35 }}
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default observer(Document2);
