import React from "react";
import { observer } from "mobx-react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import { IconInfo } from "TemplateCerere";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const TooltipLine = ({ children, tooltip, className, tooltipClass }) => {
  const ct =
    Object.prototype.toString.call(tooltip) === "[object Array]"
      ? tooltip
      : Array(tooltip);
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div className={`tooltip-wrapper ${className ?? ""}`}>
      {children}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          arrow
          title={
            <>
              {ct.map((t, index) => (
                <Typography key={`t-${index}`} component="div" variant="body2">
                  {t}
                </Typography>
              ))}
            </>
          }
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          classes={{ tooltip: tooltipClass }}
        >
          <SvgIcon
            component={IconInfo}
            className="initial-info"
            viewBox="0 0 16 16.001"
            onClick={handleTooltipOpen}
            onMouseEnter={handleTooltipOpen}
          />
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
};
export default observer(TooltipLine);
