import React, { useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import { ThumbUp, ArrowRight } from "@mui/icons-material";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { EditDialog, IconDocuments, IconDownload, Acord } from "TemplateCerere";
import {
  PackageIcon,
  SMSAlertIcon,
  CardIcon,
  ContIcon,
  IBIcon,
} from "../components/icons";

import "../index.css";
import "./OnboardingPachetPF.scss";
import { NumericFormat } from "react-number-format";

const DigitalLendingConfirmare = (props) => {
  const getPachet = (c) => {
    var p = {};
    if (c?.grid?.dt?.Rows?.length > 0) {
      var r = c.grid.dt.Rows[0];
      p.cod_produs = r[0];
      p.denumire = r[1];
      p.opt = JSON.parse(r[2]) || [];
      p.extra = JSON.parse(r[3]) || [];
      p.depo = JSON.parse(r[4]) || [];
      p.estePachet = !!r[5];
      p.card = { dst: r[6], adresa: r[7], ext: r[8] };
    }
    return p;
  };

  const getCredit = (c) => {
    var p = {};
    if (c?.grid?.dt?.Rows?.length > 0) {
      var r = c.grid.dt.Rows[0];
      c.grid.dt.Columns.forEach((col, index) => {
        p[col.ColumnName.toLowerCase()] = r[index];
      });
    }
    return p;
  };
  const [isOpen, setIsOpen] = useState(false);

  const { doc, actions, nextStep } = props;
  const fld = doc.Jprop.template.fields;
  const pachet = getPachet(actions.getField(fld.pachet));
  const docs = actions.getField(fld.docs);
  const credit = getCredit(actions.getField(fld.credit));
  const acord15 = actions.getField(fld.acord15);
  const acordDoc = actions.getField(fld.acordDoc);
  const acordInformare = actions.getField(fld.acordInformare);
  const solicitareDraft = actions.getField(fld.solicitareDraft);
  const acordCorespondenta = actions.getField(fld.acordCorespondenta);

  //    const isReadonly = docs.isReadonly || docs.docReadOnly;

  const visibleDocs = () => {
    return docs.data.rows;
  };

  const getIcon = (cod) => {
    if (!cod) {
      return null;
    }
    if (cod.startsWith("P001")) {
      return cod.length === 8 ? (
        <ContIcon viewBox="0 0 16 12" />
      ) : (
        <CardIcon viewBox="0 0 16 16" />
      );
    }
    return cod.startsWith("P006_IB") ? (
      <IBIcon viewBox="0 0 16 16" />
    ) : (
      <SMSAlertIcon viewBox="0 0 16 20" />
    );
  };
  //console.log(pachet);
  return (
    <>
      <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
        <div className="e-card-title">{doc.Jprop.title || doc.Descriere}</div>
        <div className="e-card-subtitle">{doc.Jprop.subtitle}</div>
        <div className="e-card-content-document">
          <div key={`dc${doc.Id}`}>
            <div className="sumar-credit">
              <div className="depozit-title">{`${
                credit.descriere
              } ales: cu dobanda
              ${credit.dobanda_fixa ? "fixa" : "variabila"}
              ${
                credit.asigurare ? "cu asigurare" : "fara asigurare"
              } Allianz`}</div>
              <div className="depozite-grid">
                <div className="document-line">
                  <div className="item center">
                    <div className="header center">Tip rate lunare</div>
                    <div>{credit.rate_egale ? "egale (anuitati)" : "descrescatoare"}</div>
                  </div>
                  <div className="item center">
                    <div className="header center">Ziua Scadenta</div>
                    <div>{moment(credit.scadenta).format("D [ale lunii]")}</div>
                  </div>

                  <div className="item center">
                    <div className="header center">DAE</div>
                    <div>
                      <NumericFormat
                        displayType="text"
                        value={credit.dae}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        valueIsNumericString
                        decimalScale={2}
                        suffix="%"
                      />
                    </div>
                  </div>
                  <div className="item center">
                    <div className="header center">
                      Valoarea totala de rambursat
                    </div>
                    <div>
                      <NumericFormat
                        displayType="text"
                        value={credit.valoare_totala}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        valueIsNumericString
                        decimalScale={2}
                        suffix={credit.valuta}
                      />
                    </div>
                  </div>
                  <div className="item center">
                    <div className="header center">Prima asigurare</div>
                    <div>
                      <NumericFormat
                        displayType="text"
                        value={credit.prima_asigurare}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        valueIsNumericString
                        decimalScale={2}
                        suffix={credit.valuta}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pachet-extra-confirm">
              <Grid container spacing={0}>
                {pachet.estePachet && (
                  <Grid
                    item
                    xs={12}
                    lg={pachet.extra?.length ? 6 : 12}
                    className="pachet-extra-column"
                  >
                    <div className="card-header-title">Pachet</div>
                    <div className="pachet-control">
                      <div className={"produs " + pachet.cod_produs}>
                        <span>
                          <PackageIcon viewBox="0 0 16 16"></PackageIcon>
                        </span>
                        {pachet.denumire}
                      </div>

                      <ul className="optiuni">
                        {pachet.opt &&
                          pachet.opt.map((o) => (
                            <li
                              className={
                                "opt " +
                                o.cod_produs +
                                " " +
                                o.tip_produs.toLowerCase()
                              }
                              key={o.cod_produs}
                            >
                              {getIcon(o.cod_produs)}
                              <span>{o.denumire}</span> <span>{o.nume}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Grid>
                )}
                {pachet.extra && pachet.extra.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    lg={pachet.estePachet ? 6 : 12}
                    className="pachet-extra-column"
                  >
                    <div className="card-header-title">Sumarul produselor</div>
                    <div className="pachet-control">
                      <ul className="extra">
                        {pachet.extra.map((o) => (
                          <li
                            className={
                              "extra " +
                              o.cod_produs +
                              " " +
                              o.tip_produs.toLowerCase()
                            }
                            key={o.cod_produs}
                          >
                            {getIcon(o.cod_produs)}
                            <span>{o.denumire}</span> <span>{o.nume}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                )}
              </Grid>
              {pachet.card?.dst && (
                <Grid container spacing={0}>
                  <Grid item xs={12} className="pachet-extra-helper">
                    {`Cardul tau va fi livrat la ${pachet.card?.dst} (${pachet.card?.adresa}). ${pachet.card?.ext}`}
                  </Grid>
                </Grid>
              )}
            </div>

            {pachet.depo && pachet.depo.length > 0 && (
              <div className="sumar-depozit">
                <div className="depozit-title">Depozit ales:</div>
                <div className="depozite-grid">
                  {pachet.depo.map((row, index) => (
                    <div className="document-line" key={index}>
                      <div className="item">
                        <div className="header">Tip depozit:</div>
                        <div>{row.tip_produs}</div>
                      </div>
                      <div className="item">
                        <div className="header">Moneda depozit:</div>
                        <div>{row.valuta}</div>
                      </div>

                      <div className="item right">
                        <div className="header right">Suma depusa:</div>
                        <NumericFormat
                          displayType="text"
                          value={row.suma}
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale
                          valueIsNumericString
                          decimalScale={2}
                        />
                        {` ${row.valuta}`}
                      </div>
                      <div className="item">
                        <div className="header right">Perioada:</div>
                        <div>{`${row.perioada} ${
                          row.perioada === 1 ? "Luna" : "Luni"
                        }`}</div>
                      </div>
                      <div className="item">
                        <div className="header right">Procent dobanda:</div>
                        <div>
                          <NumericFormat
                            displayType="text"
                            value={row.procent}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                            valueIsNumericString
                            decimalScale={2}
                          />
                          %
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Acord updateValue={actions.updateValue} acord={acordDoc} />
            <div className="depozit-title">Documentele tale</div>
            <div className="documents-download">
              {visibleDocs().map((e) => (
                <span key={e["COD_DOC"]} className="document-line">
                  <SvgIcon
                    component={IconDocuments}
                    className="document-icon"
                    viewBox="0 0 14 16"
                  />
                  <div>{e["DENUMIRE_DOCUMENT"]}</div>
                  <div
                    className="group"
                    onClick={() => actions.downloadAcord(e["ACORD"])}
                  >
                    <div className="save-text">Salveaza</div>
                    <Tooltip
                      arrow
                      title={
                        <Typography variant="body2">{`Salveaza document`}</Typography>
                      }
                    >
                      <SvgIcon
                        component={IconDownload}
                        className="save-icon"
                        viewBox="0 0 16 16"
                      />
                    </Tooltip>
                  </div>
                </span>
              ))}
            </div>
            <Acord updateValue={actions.updateValue} acord={acord15} />
            <Acord updateValue={actions.updateValue} acord={acordInformare} />
            <Acord updateValue={actions.updateValue} acord={solicitareDraft} />
            {credit && credit.asigurare && (
              <Acord
                updateValue={actions.updateValue}
                acord={acordCorespondenta}
                cssprops="check-group"
              />
            )}
          </div>
          <div className="e-card-footer">
            {doc.Jprop && doc.Jprop.footer ? doc.Jprop.footer : null}
          </div>
        </div>
      </div>

      <div className="doc-nav">
        {!doc.docReadOnly ? (
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight fontSize="large" />}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Continua cu creditul
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight fontSize="large" />}
            onClick={() => nextStep()}
          >
            Continua cu creditul
          </Button>
        )}
      </div>
      <EditDialog
        className="retail-dialog-info"
        isOpen={isOpen}
        header="Important!"
        showSave={true}
        saveCaption="Confirm"
        showClose={true}
        closeCaption="Renunta"
        maxWidth="md"
        save={() => {
          setIsOpen(false);
          nextStep();
        }}
        close={() => {
          setIsOpen(false);
        }}
      >
        <Typography variant="body2" gutterBottom className="text-confirm">
          <ThumbUp />
          Declar pe proprie raspundere ca toate informatiile inscrise in aceasta
          cerere precum si toate documentele prezentate sunt complete, reale si
          valide, luand la cunostinta despre consecintele legale rezultate in
          urma furnizarii de date false.
        </Typography>
        <Typography variant="body2" gutterBottom className="text-confirm">
          <ThumbUp />
          Subsemnatul confirm ca, inainte de a semna am verificat toate
          declaratiile si optiunile selectate in prezentul document si ca
          acestea reflecta in mod corect vointa mea.
        </Typography>
        <Typography variant="body2" gutterBottom className="text-confirm">
          <ThumbUp />
          Banca isi rezerva dreptul de a accepta sau nu intrarea in relatii cu
          clientul, inclusiv in cazul in care Clientul refuza sa furnizeze
          informatii marcate ca fiind obligatorii. In situatia in care, la orice
          moment in timp, Banca constata ca oricare din datele si/sau
          documentele furnizate Bancii au fost sau sunt nereale, incomplete sau
          neactuale sub orice aspect, aceasta isi rezerva dreptul de a inceta
          imediat relatia cu Clientul, fara a fi necesara indeplinirea oricaror
          notificari prealabile, conditii, formalitati sau interventia unei
          instante de judecata.
        </Typography>
      </EditDialog>
    </>
  );
};
export default observer(DigitalLendingConfirmare);
