import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import { checkCNP } from "Utils";

//import { InitialPage, AcordPage, EmailPage, PhonePage } from "TemplateCerere";
import { ActualizareDate } from "TemplateCerere";

import Button from "@mui/material/Button";
import { ArrowLeft } from "@mui/icons-material";
import { ProgressBar } from "TemplateCerere";
import { Prompt } from "react-router-dom";

function RegisterActualizareDate(props) {
  const { updateValue, sendPhone, confirmPhone, errors, setStepId } =
    props.store;
  const {
    isMobile,
    getStepsInfo,
    preventGoBack,
    preventGoBackMessage,
    stepId,
    codeRequest,
    timeLeft,
    downloadAcord,
  } = props.store;
  let { jprop } = props.store;
  jprop = jprop || {};

  const [statePage, setStatePage] = useState({
    page: 1,
    acord: {
      tc: false, //termeni si conditii
      ni: false, //nota de informare
    },
    cnp: { value: null, error: null },
    phone: { value: null, error: null },
    firstDigits: { value: null, error: null },
    lastDigits: { value: null, error: null },
    smsCode: null,
    cetatean: true,
    amCard: true,
    firstPage: 1,
  });

  const [prevPage, setPrevPage] = useState({});
  useEffect(() => {
    window.addEventListener("load", (event) => {
      event.preventDefault();
    });

    updateValue({ preventGoBack: true });
  }, []);

  const handlerUpdateValue = (name, value) => {
    var s = { ...statePage };
    s[name] = name === "smsCode" ? value : { value: value, error: null };
    setStatePage(s);
  };

  const handlerUpdateAcord = (event) => {
    var s = { ...statePage };
    s.acord[event.target.name] = event.target.checked;
    s.acord[event.target.name + "_err"] = false;
    setStatePage(s);
  };

  const validare = () => {
    const {
      phone,
      firstDigits,
      lastDigits,
      cnp,
      page,
      acord,
      amCard,
      cetatean,
      firstPage,
    } = statePage;
    var errorMsgs = [];

    if (page === firstPage) {
      if (!acord.tc) {
        errorMsgs.push({
          msg: "[ADPF02]Trebuie sa acceptati termenii si conditiile",
          check: "tc",
        });
      }
      if (!acord.ni) {
        errorMsgs.push({
          msg: "[ADPF03]Trebuie sa acceptati nota de informare",
          check: "ni",
        });
      }

      if (cetatean & !(phone?.value?.length === 10)) {
        errorMsgs.push({
          msg: "[ADPF08]Introduceti corect numarul de telefon (de forma: 07xxxxxxxx)!",
          ctrl: "phone",
        });
      }
      if (
        !cetatean & !(phone?.value?.length >= 8 && phone?.value?.length <= 15)
      ) {
        errorMsgs.push({
          msg: "Introduceti corect numarul de telefon (Incepe cu 00 si contine intre 8 si 15 cifre)!",
          ctrl: "phone",
        });
      }
      if (cetatean & !checkCNP(cnp.value)) {
        errorMsgs.push({
          msg: "[ADPF05]Campul CNP este incorect!",
          ctrl: "cnp",
        });
      }
      if (
        amCard &&
        (!firstDigits.value ||
          firstDigits.value.length !== 4 ||
          isNaN(firstDigits.value))
      ) {
        errorMsgs.push(
          "[LP005] Introduceti corect PRIMELE 4 cifre ale  numarului de card!"
        );
      }
      if (
        amCard &&
        (!lastDigits.value ||
          lastDigits.value.length !== 4 ||
          isNaN(lastDigits.value))
      ) {
        errorMsgs.push(
          "[LP006] Introduceti corect ULTIMELE 4 cifre ale  numarului de card!"
        );
      }
    }
    var s = { ...statePage };
    errorMsgs.forEach((e) => {
      if (e.ctrl) {
        s[e.ctrl] = {
          value: statePage[e.ctrl].value,
          error: errors.processMsg(e.msg),
        };
        e.ctrl = null;
      }
      if (e.check) {
        s.acord[e.check + "_err"] = errors.processMsg(e.msg);
      }
    });
    setStatePage(s);
    return errorMsgs;
  };

  const handlerConfirmPhone = () => {
    const {
      phone,
      firstDigits,
      lastDigits,
      smsCode,
      cnp,
      acord,
      amCard,
      cetatean,
    } = statePage;

    var errorMsgs = validare();
    if (!smsCode || smsCode.length !== 6) {
      errorMsgs.push("[OPF10]Introduceti codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    updateValue({ phone: phone.value, smsCode: smsCode, preventGoBack: false });
    confirmPhone(
      {
        acord_tc: acord.tc ? "1" : "0", //termeni si conditii
        acord_ni: acord.ni ? "1" : "0", //nota de informare
        cnp: cnp.value,
        firstDigits: amCard ? firstDigits.value : "",
        lastDigits: amCard ? lastDigits.value : "",
        cetatean: cetatean ? "1" : "0",
      },
      props.history
    );
  };
  const handlerSendPhone = async () => {
    const { phone, firstDigits, lastDigits, cnp, amCard, cetatean } = statePage;

    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs, false);
      return;
    }
    updateValue({ phone: phone.value });
    await sendPhone({
      cnp: cnp.value,
      firstDigits: amCard ? firstDigits.value : "",
      lastDigits: amCard ? lastDigits.value : "",
      cetatean: cetatean ? "1" : "0",
    });
    var s = { ...statePage };
    s.smsCode = props.store.smsCode;
    setStatePage(s);
  };
  const handlerPrevPage = () => {
    var p = { ...statePage };
    p.page--;
    setStatePage(p);
    setStepId("O" + p.page);
  };

  var toolbarCss = "paper-toolbar";
  toolbarCss =
    isMobile && !prevPage[statePage.page] ? toolbarCss + " first" : toolbarCss;
  return (
    <div className={toolbarCss}>
      <Prompt when={preventGoBack} message={preventGoBackMessage} />
      {isMobile && jprop.theme === "retail" && (
        <div className="nav-bar">
          <ProgressBar
            goBack={handlerPrevPage}
            prevStep={!!prevPage[statePage.page]}
            stepId={stepId}
            getStepsInfo={getStepsInfo}
          />
        </div>
      )}

      <CssBaseline />
      <div className="login-content">
        {!isMobile && prevPage[statePage.page] && (
          <div className="doc-nav">
            <Button
              color="primary"
              className="link-btn"
              startIcon={<ArrowLeft />}
              onClick={handlerPrevPage}
            >
              {prevPage[statePage.page]}
            </Button>
          </div>
        )}
        <ActualizareDate.PhonePage
          updateValue={handlerUpdateValue}
          codeRequest={codeRequest}
          state={statePage}
          timeLeft={timeLeft}
          sendPhone={handlerSendPhone}
          confirmPhone={handlerConfirmPhone}
          className={jprop.className || ""}
          updateState={setStatePage}
          updateAcord={handlerUpdateAcord}
          downloadAcord={downloadAcord}
        />
      </div>
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(
    observer(RegisterActualizareDate)
  )
);
