import React from "react";
import { observer } from "mobx-react";

import Fields from "../fields";
import "../index.css";
function Document3(props) {
  const { doc, actions, css, isMobile } = props;
  const headers = actions.getHeaders(doc.Id);
  return (
    <div key={`dc${doc.Id}`} className="data-doc">
      {headers.map((head, index) => {
        var fld = actions.getFields(head);
        return (
          fld.filter((e) => e.isVisible).length !== 0 && (
            <div key={`hd$${doc.Id}_${index}`} className="card-header">
              <div className="card-header-title">{head.Caption}</div>
              <div className="e-card-content-document">
                <Fields
                  key={`fd${doc.Id}_${index}`}
                  data={fld}
                  inline={head.Inline}
                  css={css}
                  actions={actions}
                  isMobile={isMobile}
                  jprop={doc.Jprop} //este intotdeauna setat
                />
              </div>
            </div>
          )
        )
      }
      )}
    </div>
  );
}
export default observer(Document3);
