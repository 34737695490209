import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import PachetCont from "../components/pachetCont";
import PachetHeader from "../components/pachetHeader";
import PachetImplicit from "../components/pachetImplicit";
import PachetCarousel from "../components/pachetCarousel";
import * as Field from "../field";
import "./DigitalLendingPachetPF.scss";
import Button from "@mui/material/Button";
import { ArrowRight } from "@mui/icons-material";
import Linkify from "linkify-react";

function DigitalLendingPachetPF(props) {
  const { doc, actions, nextStep, isMobile } = props;
  var jprop = doc.Jprop || {};
  var readonly = doc.docReadOnly;
  const tmp = jprop.template;
  const fld = tmp.fields;
  const { pachetDefault, afisareExtraOptiuni, infoPachet } = tmp;
  const pachet = actions.getField(fld.pachet);
  const acordInrolareRespins = actions.getField(fld.acord_inrolare_respins);
  const [hasGoPrev, setHasGoPrev] = useState(true);
  const toFirsLower = (word) => {
    return word ? word[0].toLowerCase() + word.slice(1) : word;
  };
  /*daca am pachet inseamna ca e selectat si cand merg inapoi il deselectez*/
  /*cand e readonly nu sterg pachetul si nu merg in pasul de alegere pachet*/
  const setGoPrev = () => {
    if (!pachet.isReadonly) {
      let goPrev = () => {
        actions.updateStore({ goPrev: null });
        actions.updateValue(pachet, null);
        setShouldUpdate(true);
        setPachetSelectat(undefined);
        return true;
      };
      actions.updateStore({ goPrev: goPrev });
    }
  };
  if (hasGoPrev && pachet.Valoare) {
    setGoPrev();
  }

  const listDataJson = (listData, fields) => {
    var fld = fields.map((f) => f.toLowerCase());
    return listData.map((e) => {
      var j = {};
      fld.forEach((f, index) => {
        var s = e[index];
        if (
          typeof s ==
          "string" /*&& s.trim().startsWith("{") && s.trim().endsWith("}")*/
        ) {
          try {
            s = JSON.parse(s) || {};
          } catch {}
        }
        j[f] = s;
      });
      return j;
    });
  };

  const pachete = listDataJson(pachet.listData, pachet.fields).filter((l) => {
    return l.info;
  });
  /*Setez informatiile despre produse disponibile*/

  pachete.forEach((p) => {
    /*daca are detalii custom si trebuie folosite inlocuiesc campul detalii cu cel de detaliiCustom*/
    if (p.info?.areDetaliiCustom && p.info?.detaliiCustom) {
      p.info.detalii = p.info.detaliiCustom;
    }
    Object.keys(tmp.fields).forEach((f) => {
      var c = p.opt.find((o) => o.id === tmp.fields[f]);
      if (c) {
        c.included = c.isVisible;
        p[f] = c;

        c.captionSup =
          c.captionSup || (!!p.info?.estePachet || c.included ? "" : "2");
        if (c.caption) {
          var cap = c.caption.split("|");
          c.caption = cap[0];
          c.captionSelected = cap[1] || c.caption;
          c.captionMobile = cap[2] || c.captionSelected;
          if (!c.captionSup) {
            var x = c.caption.toLowerCase();
            if (x.includes("disponibil")) {
              c.captionSup = "1";
            }
            if (x.includes("optional")) {
              c.captionSup = "1";
            }
            if (x.includes("nu este inclus")) {
              c.captionSup = "2";
            }
          }
        }
      }
    });
  });

  const getPachetSelectat = (px) => {
    return pachete.find((p) => p.cod_produs === px);
  };

  const setPachetProperties = () => {
    if (!doc.Incarcare || !pachetSelectat) return;
    if (shouldUpdate) {
      actions.updateValue(pachet, pachetSelectat.cod_produs);
    }
  };

  const [pachetSelectat, setPachetSelectat] = useState(
    getPachetSelectat(pachet.Valoare)
  );

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const handleSelect = (p) => {
    if (hasGoPrev) {
      setGoPrev();
      setHasGoPrev(false);
    }
    setShouldUpdate(true);
    setPachetSelectat(getPachetSelectat(p.cod_produs));
  };
  useEffect(() => {
    setPachetProperties(pachetSelectat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pachetSelectat]);

  return (
    <div className="pachet" id={`dco${doc.Id}`} key={doc.Id}>
      {!pachetSelectat && (
        <>
          <div className="e-card-title">{jprop.title}</div>
          <div className="e-card-subtitle">{jprop.subtitle}</div>
          <div className="e-card-content-document">
            {isMobile ? (
              <div className="carousel-wrapper">
                <PachetCarousel className="pachet-list">
                  {pachete.map((p, index) => (
                    <PachetCont
                      key={index}
                      onSelect={() => {
                        setHasGoPrev(true);
                        handleSelect(p);
                      }}
                      {...p.info}
                      readonly={readonly}
                      cont={{
                        included: p.cont.included,
                        caption:
                          "Cont curent(RON) " +
                          toFirsLower(p.cont.captionMobile),
                        helperText: p.cont.captionHelper,
                        supText: p.cont.captionSup,
                      }}
                      card={{
                        included: p.card.included,
                        caption:
                          "Card debit (RON) " +
                          toFirsLower(p.card.captionMobile),
                        helperText: p.card.captionHelper,
                        supText: p.card.captionSup,
                      }}
                      ib={{
                        included: p.ib.included,
                        caption:
                          "Internet & Mobile Banking " +
                          toFirsLower(p.ib.captionMobile),
                        helperText: p.ib.captionHelper,
                        supText: p.ib.captionSup,
                      }}
                      sms={{
                        included: p.sms.included,
                        caption:
                          "SMS Alert " + toFirsLower(p.sms.captionMobile),
                        helperText: p.sms.captionHelper,
                        supText: p.sms.captionSup,
                      }}
                      contval={{
                        included: p.contval.included,
                        caption:
                          "Cont curent in valuta " +
                          toFirsLower(p.contval.captionMobile),
                        helperText: p.contval.captionHelper,
                        supText: p.contval.captionSup,
                      }}
                      depo={{
                        show: false,
                      }}
                      eco={{
                        show: false,
                      }}
                      helperFirst={true}
                    />
                  ))}
                </PachetCarousel>
              </div>
            ) : (
              <>
                <PachetHeader isMobile={isMobile} depo={false} contval={false}>
                  <div className="pachet-list-wrapper">
                    <div className="pachet-list">
                      {pachete.map((p, index) => (
                        <PachetCont
                          key={index}
                          onSelect={() => {
                            setHasGoPrev(true);
                            handleSelect(p);
                          }}
                          {...p.info}
                          readonly={readonly}
                          cont={{
                            included: p.cont.included,
                            caption: p.cont.caption,
                            helperText: p.cont.captionHelper,
                            supText: p.cont.captionSup,
                          }}
                          card={{
                            included: p.card.included,
                            caption: p.card.caption,
                            helperText: p.card.captionHelper,
                            supText: p.card.captionSup,
                          }}
                          ib={{
                            included: p.ib.included,
                            caption: p.ib.caption,
                            helperText: p.ib.captionHelper,
                            supText: p.ib.captionSup,
                          }}
                          sms={{
                            included: p.sms.included,
                            caption: p.sms.caption,
                            helperText: p.sms.captionHelper,
                            supText: p.sms.captionSup,
                          }}
                          contval={{
                            show: false,
                          }}
                          depo={{
                            show: false,
                          }}
                          eco={{
                            show: false,
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </PachetHeader>
              </>
            )}
            <Linkify
              as="p"
              className="e-card-footer"
              options={{
                target: "_blank",
                rel: "noopener noreferrer",
              }}
            >
              Detalii suplimentare despre Pachetul AVANTAJ sau Contul curent vei
              gasi in documentul Lista de Tarife si Comisioane – Persoane
              Fizice, document pe care il poti consulta intr-un pas urmator, cat
              si pe site-ul Bancii (www.patriabank.ro)
            </Linkify>
            <p className="e-card-footer">
              <sup>1</sup> Renuntarea la cel putin unul dintre produsele sau
              serviciile bancare obligatorii incluse in pachet determina
              inchiderea automata a pachetului
            </p>
            <p className="e-card-footer">
              <sup>2</sup> Pot fi solicitate ulterior, in unitatile teritoriale
              Patra Bank; implica costuri suplimentare
            </p>
            {afisareExtraOptiuni && (
              <p className="e-card-footer">
                <sup>3</sup> Extraoptiune, configurare in pasii urmatori
              </p>
            )}
          </div>
        </>
      )}
      {pachetSelectat && (
        <>
          <div className="e-card-title">
            {pachetSelectat.info.estePachet
              ? `Configureaza ${pachetSelectat.denumire}`
              : "Cont curent in Lei, fara extraoptiuni"}
          </div>
          <div className="e-card-content-document pachet-detaliu-simplu dl-pachet">
            <PachetImplicit
              pachet={pachetSelectat}
              readonly={readonly}
              onReset={() => handleSelect("")}
              onSelect={() => {
                setHasGoPrev(false);
                nextStep();
              }}
              doc={doc}
              fields={fld}
              actions={actions}
              afisareExtraOptiuni={afisareExtraOptiuni}
              gridItem={isMobile}
              infoPachet={infoPachet}
            />
          </div>
          {
            pachetSelectat.info.detalii &&
              (Array.isArray(pachetSelectat.info.detalii) ? (
                pachetSelectat.info.detalii.map((s, index) => (
                  <Linkify
                    key={index}
                    as="div"
                    className="e-card-footer"
                    options={{
                      target: "_blank",
                      rel: "noopener noreferrer",
                    }}
                  >
                    {s}
                  </Linkify>
                ))
              ) : (
                <Linkify
                  as="div"
                  className="e-card-footer"
                  options={{
                    target: "_blank",
                    rel: "noopener noreferrer",
                  }}
                >
                  {pachetSelectat.info.detalii}
                </Linkify>
              ))
            //{pachetSelectat.info.detalii}
            //<div className="e-card-footer">{pachetSelectat.info.detalii}</div>
          }
          {pachetSelectat.info.estePachet &&
            !pachetSelectat.info?.areDetaliiCustom && (
              <div className="e-card-footer">
                <div>
                  Alegand acest pachet, in cazul acordarii creditului solicitat:
                </div>
                <div>
                  1. poți retrage banii direct din orice unitatea teritorială
                  Patria Bank, fară comision de retragere
                </div>
                <div>SAU</div>
                <div>
                  2. poți utiliza serviciile de Internet și/sau Mobile Banking,
                  pentru a transfera banii din contul de utilizare credit în
                  contul tău curent in RON, fară comision de retragere, de unde
                  îi poți utiliza ulterior pentru diverse operațiuni bancare
                  (transferuri, schimb valutar sau retragere de la ATM-uri),
                  pentru care vei fi comisionat conform Pachetului Avantaj.
                </div>
              </div>
            )}
          {pachetSelectat.info.continuarePasiv && acordInrolareRespins && (
            <Field.Field5
              key={acordInrolareRespins.Id}
              item={acordInrolareRespins}
              inline={acordInrolareRespins.Jprop?.inline ?? true}
              actions={actions}
              isMobile={isMobile}
              gridItem={false}
            />
          )}
          <div className="pachet-cont-chooser">
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRight fontSize="large" />}
              onClick={() => {
                actions.updateStore({ goPrev: null });
                setHasGoPrev(false);
                nextStep();
              }}
            >
              {!readonly ? "Confirma alegerea" : "Continua"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
export default observer(DigitalLendingPachetPF);
