import React from "react";
import { observer } from "mobx-react";
import { DropzoneDialog } from "react-mui-dropzone";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { convertBytesToMbsOrKbs } from "Utils";

//import "react-html5-camera-photo/build/css/index.css";

//import "../index.css";

function DropZone(props) {
  const { doc, onClose, onSave, open } = props;
  const maxFileSize = (doc.Params.maxfilesize || 5 * 1024 * 1024) * 1;
  /*function convertBytesToMbsOrKbs(filesize) {
    var size = "";

    if (filesize >= 1048576) {
      size = filesize / 1048576 + " megabytes";
    } else if (filesize >= 1024) {
      size = filesize / 1024 + " kilobytes";
    } else {
      size = filesize + " bytes";
    }
    return size;
  }*/
  return (
    <FormControl required={doc.Obligatoriu} error={!!doc.error}>
      <FormGroup>
        {
          <DropzoneDialog
            open={open}
            onSave={onSave}
            acceptedFiles={doc.Params.ext ? doc.Params.ext.split(",") : []}
            showPreviewsInDropzone={true}
            showPreviews={false}
            dropzoneText="Trage Fisierul aici sau Click"
            onClose={onClose}
            submitButtonText="Incarca"
            cancelButtonText="Renunta"
            dialogTitle="Incarcare Fisier"
            filesLimit={1}
            maxFileSize={maxFileSize}
            showFileNames={true}
            dropzoneParagraphClass="dropzoneParagraph"
            previewGridClasses={{ item: "dropZoneItem" }}
            previewGridProps={{
              container: {
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                spacing: 0,
              },
              item: { zeroMinWidth: true },
            }}
            //dropzoneClass="dropZone"
            //previewGridClasses={{container:"dropZoneContainer", item: "dropZoneItem"}}

            getFileLimitExceedMessage={(filesLimit) => {
              return "Numarul maxim de fisiere permise este depasit. Numarul de fisiere permise este ".concat(
                filesLimit,
                "."
              );
            }}
            getFileAddedMessage={(fileName) => {
              return "Fisierul ".concat(fileName, " a fost adaugat cu succes.");
            }}
            getFileRemovedMessage={(fileName) => {
              return "Fisierul ".concat(fileName, " a fost eliminat.");
            }}
            getDropRejectMessage={(
              rejectedFile,
              acceptedFiles,
              maxFileSize
            ) => {
              var message = "Fisierul ".concat(
                rejectedFile.name,
                " este respins. "
              );

              if (!acceptedFiles.includes(rejectedFile.type)) {
                message += "Acest tip de fisier nu este suportat. ";
              }

              if (rejectedFile.size > maxFileSize) {
                message +=
                  "Fisierul este prea mare. Marimea maxima admisa este " +
                  convertBytesToMbsOrKbs(maxFileSize) +
                  ". ";
              }

              return message;
            }}
          />
        }
      </FormGroup>
      <FormHelperText>{doc.error /*|| doc.Jprop.helperText*/}</FormHelperText>
    </FormControl>
  );
}
export default observer(DropZone);
