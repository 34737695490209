import React, { useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import Button from "@mui/material/Button";
import { TextFieldMask } from "TemplateCerere";
import { ArrowRight, Refresh } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import OtpInput from "react-otp-input";
import FormHelperText from "@mui/material/FormHelperText";
import { IconsSvg } from "TemplateCerere";
import CssBaseline from "@mui/material/CssBaseline";
import { onEnter } from "Utils";
import Link from "@mui/material/Link";

function PhonePage(props) {
  const { timeLeft, codeRequest, flow } = props.store;

  const [statePage, setStatePage] = useState({
    phone: { value: null, error: null },
    smsCode: null,
  });

  const handlerUpdateValue = (name, value) => {
    var s = { ...statePage };
    s[name] = name === "smsCode" ? value : { value: value, error: null };
    setStatePage(s);
  };

  const validare = () => {
    const { phone } = statePage;
    const { errors } = props.store;
    var errorMsgs = [];
    var s = { ...statePage };

    if (!(phone && phone.value && phone.value.length === 10)) {
      errorMsgs.push({
        msg: "[OPF8]Introduceti corect numarul de telefon (de forma: 07xxxxxxxx)!",
        ctrl: "phone",
      });
    }

    errorMsgs.forEach((e) => {
      if (e.ctrl) {
        s[e.ctrl] = {
          value: statePage[e.ctrl].value,
          error: errors.processMsg(e.msg),
        };
        e.ctrl = null;
      }
      if (e.check) {
        s.acord[e.check + "_err"] = errors.processMsg(e.msg);
      }
    });
    setStatePage(s);
    return errorMsgs;
  };

  const handlerConfirmPhone = () => {
    const { phone, smsCode } = statePage;
    const { updateValue, confirmPhone, errors } = props.store;

    var errorMsgs = validare();
    if (!smsCode || smsCode.length !== 6) {
      errorMsgs.push("[OPF10]Introduceti codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    updateValue({ phone: phone.value, smsCode: smsCode });
    confirmPhone(null, props.history);
  };
  const handlerSendPhone = async () => {
    var s = { ...statePage };
    const { updateValue, sendPhone, errors } = props.store;
    var errorMsgs = validare();
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs, false);
      return;
    }
    updateValue({ phone: s.phone.value });
    await sendPhone();
    s.smsCode = props.store.smsCode;
    setStatePage(s);
  };

  return (
    <div className="paper-toolbar first">
      <CssBaseline />
      <IconsSvg icon="IllustrationSleep" />
      <div className="e-card-title">Sesiunea a expirat!</div>
      <div className="e-card-subtitle">
        Completeaza numarul de telefon si codul primit prin SMS pentru a
        continua.
      </div>

      <div className="form">
        <TextFieldMask
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Nr Telefon"
          /* nu merge cu enter
            defaultValue={phone && phone.value ? phone.value : "07"}
            onBlur={(prop) =>
              this.handlerUpdateValue("phone", prop.target.value)
            }
            */
          value={
            statePage.phone && statePage.phone.value
              ? statePage.phone.value
              : "07"
          }
          onChange={(prop) => handlerUpdateValue("phone", prop.target.value)}
          className="phone"
          mask="0799999999"
          maskChar=""
          error={!!statePage.phone.error}
          helperText={statePage.phone.error}
          type="tel"
          onKeyPress={(event) => onEnter(event, handlerSendPhone)}
        />
      </div>
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={handlerSendPhone}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Trimite Cod
      </Button>
      <Typography variant="subtitle2">
        Introdu codul primit prin SMS:
      </Typography>

      <OtpInput
        value={statePage.smsCode ? statePage.smsCode : ""}
        onChange={(value) => handlerUpdateValue("smsCode", value)}
        numInputs={6}
        renderInput={(prop) => <input {...prop} disabled={!!codeRequest} />}
        inputStyle="MuiInputBase-root MuiInputBase-input MuiOutlinedInput-input otp-input"
        focusStyle="otp-focus"
        disabledStyle="MuiInputBase-root Mui-disabled"
        inputType="number"
        containerStyle="otp"
      />
      <FormHelperText error={false}></FormHelperText>
      <div className="h-list">
        <Typography variant="caption">
          Nu ai primit cod? Asteapta <b>{timeLeft} sec.</b>
        </Typography>

        <Button
          color="primary"
          /*className="link-btn"*/
          endIcon={<Refresh />}
          onClick={handlerSendPhone}
        >
          Retrimite SMS
        </Button>
      </div>
      <Button
        variant="contained"
        color="primary"
        /*className="login-btn"*/
        onClick={handlerConfirmPhone}
        endIcon={<ArrowRight fontSize="large" />}
        disabled={!statePage.smsCode}
      >
        Confirma telefon
      </Button>
      <br></br>
      <Link
        variant="body2"
        //onClick={this.handlerChangePwd}
        href={`/new/${flow}`}
        //className="new-request"
      >
        Cerere noua
      </Link>
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(PhonePage))
);
