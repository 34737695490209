import React from "react";
import { observer } from "mobx-react";

import * as Field from "./field";
import Grid from "@mui/material/Grid";
//import "./index.css";

function Fields(props) {
  var { data, inline, jprop } = props;
  jprop = jprop || {};
  const { actions, css, isMobile } = props;
  const content = (item, gridItem) => {
    var inline = item.Jprop?.inline ?? props.inline;
    switch (item.Tip) {
      case 0:
        if (item.Lungime === 0) {
          return (
            <Field.Field00
              key={item.Id}
              item={item}
              actions={actions}
              css={css}
              isMobile={isMobile}
              gridItem={gridItem}
            />
          );
        } else {
          return (
            <Field.Field0
              key={item.Id}
              item={item}
              inline={inline}
              actions={actions}
              css={css}
              isMobile={isMobile}
              gridItem={gridItem}
            />
          );
        }
      case 1:
        return (
          <Field.Field1
            key={item.Id}
            item={item}
            inline={inline}
            actions={actions}
            css={css}
            isMobile={isMobile}
            gridItem={gridItem}
          />
        );
      case 2:
        return (
          <Field.Field2
            key={item.Id}
            item={item}
            inline={inline}
            actions={actions}
            css={css}
            isMobile={isMobile}
            gridItem={gridItem}
          />
        );
      case 3:
        return (
          <Field.Field3
            key={item.Id}
            item={item}
            inline={inline}
            actions={actions}
            css={css}
            isMobile={isMobile}
            gridItem={gridItem}
          />
        );
      case 4:
        return (
          <Field.Field4
            key={item.Id}
            item={item}
            inline={inline}
            actions={actions}
            css={css}
            isMobile={isMobile}
            gridItem={gridItem}
          />
        );
      case 5:
        return (
          <Field.Field5
            key={item.Id}
            item={item}
            inline={inline}
            actions={actions}
            css={css}
            isMobile={isMobile}
            gridItem={gridItem}
          />
        );
      case 6:
        return (
          <Field.Field6
            key={item.Id}
            item={item}
            inline={inline}
            actions={actions}
            css={css}
            isMobile={isMobile}
            gridItem={gridItem}
          />
        );
      case 7:
        return (
          <Field.Field7
            key={item.Id}
            item={item}
            inline={inline}
            actions={actions}
            css={css}
            isMobile={isMobile}
            gridItem={gridItem}
          />
        );
      case 8:
        return (
          <Field.Field8
            key={item.Id}
            item={item}
            inline={inline}
            //actions={actions}
            css={css}
            isMobile={isMobile}
            gridItem={gridItem}
          />
        );
      default:
        return <div key={item.Id}>nedefinit</div>;
    }
  };

  return (
    /*Daca e grid nu se mai seteaza inline. Marchez grid doar pentru cele care sunt inline */
    jprop.grid && inline ? (
      <Grid container spacing={jprop.gridSpacing || 1}>
        {data.length !== 0 &&
          data.map((item) => content(item, jprop.grid && inline))}
      </Grid>
    ) : (
      <div className={"doc-fields" + (inline ? " inline" : "")}>
        {data.length !== 0 &&
          data.map((item) => content(item, jprop.grid && inline))}
      </div>
    )
  );
}

export default observer(Fields);
