import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import CerereWeb from "CerereWeb/src";
import Cerere from "Cerere/src";

const CerereDesk = (props) => {
  const [token, setToken] = useState(null); //dummy, ca sa pot folosi useEffect de mai jos ca un constructor
  const [isActive, setActive] = useState(true);
  useEffect(() => {
    props.main.checkLoginUser(
      props.history,
      props.match.url,
      props.appType,
      props.match.params
    );
    if (!props.main.flowType) {
      props.main.getFlow(props.match.params.type).then(() => {
        if (isActive) {
          setToken(true);
        }
      });
    } else {
      setToken(true);
    }
    return () => {
      setActive(false);
    };
  }, [props.appType, props.history, props.main.flowType, props.match]);

  if (token) {
    if (props.main.flowType === "desk") {
      return <Cerere appType="desk" />;
    }
    if (props.main.flowType === "desk-new") {
      return <CerereWeb appType="desk" />;
    }
    return <div>flowType not set</div>;
  } else {
    return null;
  }
};

export default inject((stores) => ({
  main: stores.mainStore,
}))(withRouter(observer(CerereDesk)));
