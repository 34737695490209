import React from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
//import { TextFieldMask } from "TemplateCerere";

//import "../../index.css";

function EmailPage(props) {
    const { updateValue, email, email2, name } = props;
    return (
      <div className="form">
        <TextField
          autoFocus
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nume si prenume"
          name="name"
          value={name && name.value ? name.value : ""}
          onChange={(prop) =>
            updateValue("name", prop.target.value.toUpperCase())
          }
          className="login-txt"
          error={name.error}
          helperText={name.error}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="Email"
          value={email && email.value ? email.value : ""}
          onChange={(prop) => updateValue("email", prop.target.value)}
          helperText={
            email.error || "Adresa de email folosita pentru corespondenta"
          }
          error={!!email.error}
          className="login-txt"
          type="email"
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email2"
          label="Confirmare Email"
          name="Email2"
          defaultValue={email2 && email2.value ? email2.value : ""}
          onChange={(prop) => updateValue("email2", prop.target.value)}
          className="login-txt"
          helperText={
            email2.error ||
            "Confirmarea adresei de email folosita pentru corespondenta"
          }
          error={!!email2.error}
          type="email"
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </div>
    );
  }
export default observer(EmailPage);
