import React from "react";

export default function PachetCarousel(props) {
  const { children } = props;
  const ref = React.useRef()
/*
  const {
    hasItemsOnLeft,
    hasItemsOnRight,
    scrollRight,
    scrollLeft,
  } = usePosition(ref)
*/
  usePosition(ref);

  function getPrevElement(list) {
    const sibling = list[0].previousElementSibling

    if (sibling instanceof HTMLElement) {
      return sibling
    }

    return sibling
  }

  function getNextElement(list) {
    const sibling = list[list.length - 1].nextElementSibling

    if (sibling instanceof HTMLElement) {
      return sibling
    }

    return null
  }

  function usePosition(ref) {
    const [prevElement, setPrevElement] = React.useState(null)
    const [nextElement, setNextElement] = React.useState(null)

    React.useEffect(() => {
      const element = ref.current

      const update = () => {
        const rect = element.getBoundingClientRect()

        const visibleElements = Array.from(element.children).filter((child) => {
          const childRect = child.getBoundingClientRect()


          return childRect.left >= rect.left && childRect.right <= rect.right
        })

        if (visibleElements.length > 0) {
          setPrevElement(getPrevElement(visibleElements))
          setNextElement(getNextElement(visibleElements))
        }
      }

      update()

      element.addEventListener('scroll', update, { passive: true })

      return () => {
        element.removeEventListener('scroll', update, { passive: true })
      }
    }, [ref])

    const scrollToElement = React.useCallback(
      (element) => {
        const currentNode = ref.current

        if (!currentNode || !element) return

        let newScrollPosition

        newScrollPosition =
          element.offsetLeft +
          element.getBoundingClientRect().width / 2 -
          currentNode.getBoundingClientRect().width / 2

        currentNode.scroll({
          left: newScrollPosition,
          behavior: 'smooth',
        })
      },
      [ref],
    )

    const scrollRight = React.useCallback(() => scrollToElement(nextElement), [
      scrollToElement,
      nextElement,
    ])

    const scrollLeft = React.useCallback(() => scrollToElement(prevElement), [
      scrollToElement,
      prevElement,
    ])

    return {
      hasItemsOnLeft: prevElement !== null,
      hasItemsOnRight: nextElement !== null,
      scrollRight,
      scrollLeft,
    }
  }
/*
  const CarouselButton = styled.button`
  position: absolute;

  cursor: pointer;

  top: 50%;
  z-index: 1;

  transition: transform 0.1s ease-in-out;

  background: white;
  border-radius: 15px;
  border: none;
  padding: 0.5rem;
`

  const LeftCarouselButton = styled(CarouselButton)`
  left: 0;
  transform: translate(-100%, -50%);

  .carousel-container:hover & {
    transform: translate(0%, -50%);
  }
  visibility: ${({ hasItemsOnLeft }) => (hasItemsOnLeft ? `all` : `hidden`)};
`

  const RightCarouselButton = styled(CarouselButton)`
  right: 0;
  transform: translate(100%, -50%);

  .carousel-container:hover & {
    transform: translate(0%, -50%);
  }

  visibility: ${({ hasItemsOnRight }) => (hasItemsOnRight ? `all` : `hidden`)};
`
  const ArrowLeft = ({ size = 30, color = '#000000' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 12H6M12 5l-7 7 7 7" />
    </svg>
  )


  const ArrowRight = ({ size = 30, color = '#000000' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h13M12 5l7 7-7 7" />
    </svg>
  )
  */
  return (
    <div className="carousel-container" role="region" aria-label="Colors carousel">
      <div className="carousel-container-inner" ref={ref}>
        {React.Children.map(children, (child, index) => (
          <div className="carousel-item" key={index}>{child}</div>
        ))}
      </div>
      {/*
      <LeftCarouselButton hasItemsOnLeft={hasItemsOnLeft} onClick={scrollLeft} aria-label='Previous slide'>
        <ArrowLeft />
      </LeftCarouselButton>
      <RightCarouselButton
        hasItemsOnRight={hasItemsOnRight}
        onClick={scrollRight}
        aria-label='Next slide'
      >
        <ArrowRight />
      </RightCarouselButton>
      */}
    </div>
  )
};
