import React from 'react';
import View from "./views";


const  Cerere = (props) => {
  return (
      	<View {...props} />
  );
}

export default Cerere;

