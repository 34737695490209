import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { onEnter } from "Utils";

function LoginCode(props) {
  const { handlerConfirmPhone } = props;
  const { smsCode, timeLeft, codeExpierd } = props.store;
  const { updateValue, codeRequest } = props.store;

  return (
    <div>
      <div className="hlist">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="code"
          name="Cod"
          label="Cod"
          value={smsCode ? smsCode : ""}
          onChange={(prop) => updateValue({ smsCode: prop.target.value })}
          autoFocus
          className="login-code"
          //type="password"
          disabled={codeRequest}
          onKeyPress={(event) => onEnter(event, handlerConfirmPhone)}
          //size="small"
        />
        <TextField
          variant="outlined"
          margin="normal"
          id="valabilitate"
          label=""
          name="Time"
          value={timeLeft}
          className="login-time disabled"
          disabled
        />
      </div>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        className="submit"
        onClick={() => handlerConfirmPhone()}
        //onClick={() => confirmPhone(null, history)}
        disabled={codeRequest || !smsCode || codeExpierd}
      >
        Confirma
      </Button>
    </div>
  );
}

export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(LoginCode))
);
