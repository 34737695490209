import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./AcceptanceAgreement.scss";
import Fields from "../fields";
import PdfAllPages from "../components/pdfViewAllPages";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

function AcceptanceAgreementWithDialog(props) {
  const { actions, doc, errors } = props;
  const [viewElement, setViewElement] = useState(null);
  const [state, setState] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  var jprop = doc.Jprop || {};
  useEffect(() => {
    const tmp = jprop.template || {};
    const fields = tmp.fields || {};
    const documents = tmp.documents || {};
    const hiddenFld = tmp.hiddenFields || [];
    const errors = tmp.errors || {};
    const save = tmp.save || {};
    setState({
      fields: fields,
      acordDoc: documents.acord,
      hiddenFld: hiddenFld,
      btnCaption: tmp.btnCaption,
      errors: errors,
      save: save,
    });
  }, [jprop]);

  const handleViewAcord = () => {
    var acordFld = actions.getField(state?.fields.acord);
    const acordBtn = state?.fields?.btn
      ? actions.getField(state?.fields?.btn)
      : null;
    if (acordFld?.Valoare == (acordFld?.Jprop.valueTrue ?? "1")) {
      errors.open([
        state.errors.open ??
          "Interogarea se va face in baza acordului tau initial, nu este necesar sa semnezi un nou acord!",
      ]);
      return;
    } else {
      //daca am buton definit pentru download rulez evenimentele
      if (acordBtn)
        actions.runEvent(actions.store, acordBtn, false, true /*, this.state*/);
      else {
        actions.getPrintFile({ id: state.acordDoc });
      }
      setIsOpen(true);
      setBtnDisabled(true);
    }
  };
  const handleAcceptAcord = () => {
    var acordFld = actions.getField(state?.fields.acord);
    actions.updateValue(acordFld, acordFld?.Jprop.valueTrue ?? "1");
    setIsOpen(false);
    if (state.save.accept) {
      actions.store.nextStep("A"); //salveaza cererea cu param auto=A
    }
  };
  const handleCancelAcord = () => {
    var acordFld = actions.getField(state?.fields.acord);
    var cancelHandler = null;
    if (state.save.cancel) {
      cancelHandler = () => {
        actions.store.nextStep("C");
      }; //salveaza cererea cu param auto=C
    }
    actions.updateValue(acordFld, acordFld?.Jprop.valueFalse ?? "0");
    setIsOpen(false);
    errors.open(
      [
        state.errors.cancel ??
          "Este necesara acceptarea conditiilor pentru a continua!",
      ],
      false,
      cancelHandler
    );
  };

  const handleScroll = (e) => {
    //get how much pixels left to scrolling our ReactElement
    const x = e.target.getBoundingClientRect();
    const limit = x.height + x.top;
    const r = viewElement?.getBoundingClientRect();
    const top = r.top;
    setBtnDisabled(top > limit);
  };

  const { isMobile, css } = props;
  const { file } = props.template;
  const headers = actions.getHeaders(doc.Id);;
  const className = props.className ?? "retail-lending";
  let setRef = (el) => {
    setViewElement(el);
  };
  const acordBtn = state?.fields?.btn
    ? actions.getField(state?.fields?.btn)
    : null;
  return (
    <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
      <Dialog open={!!file && isOpen} maxWidth="lg" className={className}>
        <DialogTitle>{doc.Jprop.template.header}</DialogTitle>
        <DialogContent dividers={true} onScroll={handleScroll}>
          <div className="acceptance-dialog">
            <div className="body">
              <PdfAllPages
                file={file}
                onLoadSuccess={() => {
                  //this.setState({ isLoading: false });
                  //setIsLoading(false);
                  actions.updateMainStore({ forceWaiting: false });
                }}
              />
              <div className="bottom" ref={setRef}></div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            className="btn"
            disabled={btnDisabled}
            onClick={handleCancelAcord}
          >
            Renunta
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="btn"
            onClick={handleAcceptAcord}
            disabled={btnDisabled}
          >
            De acord
          </Button>
        </DialogActions>
      </Dialog>

      <div className="e-card-title">
        {doc.Jprop.template.header || doc.Descriere}
      </div>
      {doc.Jprop.subtitle && (
        <div className="e-card-subtitle">{doc.Jprop.subtitle}</div>
      )}

      <div className="e-card-content-document">
        <div key={`dc${doc.Id}`} className="data-doc">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleViewAcord}
            disabled={
              !doc.Incarcare || acordBtn?.isReadonly || acordBtn?.docReadOnly
            }
            {...acordBtn?.eventProps?.props}
            {...acordBtn?.Jprop?.ctrlProps}
          >
            {state.btnCaption || "Acord"}
          </Button>

          {headers.length !== 0 &&
            headers.map((head, index) => {
              var fld = actions.getFields(head);
              return (
                fld.filter((e) => e.isVisible).length !== 0 && (
                  <div key={`hd${doc.Id}_${index}`} className="card-header">
                    <div className="card-header-title">{head.Caption}</div>
                    <div className="e-card-content-document">
                      <Fields
                        data={fld.filter(
                          (c) => !state.hiddenFld?.includes(c.Id)
                        )}
                        //data={item.Cimpuri}
                        inline={head.Inline}
                        css={css}
                        actions={actions}
                        isMobile={isMobile}
                        jprop={doc.Jprop || {}} //este intotdeauna setat
                      />
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
      <div className="e-card-footer">
        {doc.Jprop && doc.Jprop.footer ? doc.Jprop.footer : null}
      </div>
    </div>
  );
}
export default observer(AcceptanceAgreementWithDialog);
