import React from "react";
import { observer } from "mobx-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
//import * as Document from './document'
import { Document3 } from "./document";
import "./index.css";

function ParametersDialog(props) {
  const { close, isOpen, header, doc, printFile, updateValue, getHeaders, getFields } =
    props;
  return (
    <Dialog open={isOpen} maxWidth="lg">
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <Document3
          doc={doc}
          actions={{ updateValue, getHeaders, getFields }}
          css={{ field: { label: "field-inline-label" } }}
        />
      </DialogContent>
      <div className="dialog-buttons">
        <Button
          className="dialog-button"
          variant="contained"
          color="primary"
          onClick={printFile}
        >
          Listeaza
        </Button>
        <Button
          className="dialog-button"
          variant="contained"
          color="secondary"
          onClick={close}
        >
          Inchide
        </Button>
      </div>
    </Dialog>
  );
}
export default observer(ParametersDialog);
