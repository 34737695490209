import React from 'react';
import View from "./views";


const  CerereNoua = (props) => {

  return (
      	<View {...props} />
  );
}

export default CerereNoua;

