import React from "react";
import { observer } from "mobx-react";
import Dialog from "@mui/material/Dialog";

function MobileDialog(props) {
    const { isOpen, children, ...prop } =props;

    return (
      <Dialog open={isOpen} scroll="paper" fullScreen {...prop}>
        {children}
      </Dialog>
    );
  }
export default observer(MobileDialog);
