import React from "react";
import PachetHeaderOption from "./pachetHeaderOption";
import {
  HomeIcon,
  CardIcon,
  IBIcon,
  ContIcon,
  ContValutaIcon,
  SMSAlertIcon,
} from "./icons";

export default function PachetHeader(props) {
  const { className, depo, contval, eco } = props;
  return (
    <div className={["pachet-header", className].join(" ")}>
      <div className="pachet-header-background">
        <div className="pachet-header-empty" />
        <PachetHeaderOption
          type="cont"
          caption="Cont curent (RON)"
          icon={<ContIcon />}
        />
        <PachetHeaderOption
          type="card"
          caption="Card debit (RON)"
          icon={<CardIcon />}
        />
        <PachetHeaderOption
          type="ib"
          caption="Internet & Mobile Banking"
          icon={<IBIcon />}
        />
        <PachetHeaderOption
          type="sms"
          caption="Alerta SMS"
          icon={<SMSAlertIcon />}
        />
        {contval && (
          <PachetHeaderOption
            type="contval"
            caption="Cont curent valuta"
            icon={<ContValutaIcon />}
          />
        )}
        {depo && (
          <PachetHeaderOption
            type="depo"
            caption="Depozit bancar"
            icon={<HomeIcon />}
          />
        )}
        {eco && (
          <PachetHeaderOption
            type="eco"
            caption="Cont de economii (RON)"
            icon={<HomeIcon />}
          />
        )}
      </div>
      {props.children}
    </div>
  );
}
