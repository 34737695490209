import React from "react";
import { observer } from "mobx-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { ArrowRight } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import "./index.css";

function EditDialog(props) {
  const {
    close,
    isOpen,
    header,
    child,
    save,
    saveCaption,
    closeCaption,
    showSave,
    showClose,
    fullScreen,
    error,
    validate,
    className,
    children,
    id,
  } = props;
  const elements = children ? (
    children
  ) : Array.isArray(child) ? (
    <div id={`edi-${id}`}>
      {child.map((x, i) => {
        return (
          <Typography
            variant="body2"
            gutterBottom
            className="text-confirm"
            id={`edc-${i}`}
            key={i}
          >
            {x}
          </Typography>
        );
        /*<div id={`edc-${i}`} key={i}></div>*/
      })}
    </div>
  ) : (
    child || ""
  );

  return (
    <Dialog
      open={isOpen}
      maxWidth={props.maxWidth || "lg"}
      scroll={props.scroll || "paper"}
      fullScreen={fullScreen}
      classes={{ root: className }}
    >
      <div className="MuiDialog-border"></div>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>{elements}</DialogContent>
      <div className="dialog-error">{error}</div>
      <div className="dialog-buttons">
        {showClose && (
          <Button
            className="dialog-button"
            variant="contained"
            color="secondary"
            onClick={close}
          >
            {closeCaption || "Inchide"}
          </Button>
        )}
        {showSave && (
          <Button
            className="dialog-button"
            variant="contained"
            color="primary"
            endIcon={<ArrowRight fontSize="large" />}
            onClick={() => save(validate)}
          >
            {saveCaption || "Salveaza"}
          </Button>
        )}
      </div>
    </Dialog>
  );
}
export default observer(EditDialog);
