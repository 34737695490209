import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";

import { withRouter } from "react-router";
import Routes from "./routes";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Errors, PrintParametersDialog, RetailToolbar } from "TemplateCerere";
//import MUICookieConsent from "material-ui-cookie-consent";
import { createGlobalStyle } from "styled-components";
import AppToolbar from "./appToolbar";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { IconText } from "TemplateCerere";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./main.scss";

function Main(props) {
  //  console.log('Main View', this.props);
  const { renderCount, checkGeoLocation } = props;
  const {
    navigate,
    fluxuri,
    changeFlux,
    appType,
    isAuthenticated,
    updateValue,
  } = props.store;
  const {
    errors,
    printParameters,
    environment,
    themeSelected,
    theme,
    position,
  } = props.store;
  const { getHeaders, getFields } = props.common;

  let { jprop, hideAppBar } = props.store;
  jprop = jprop || {};
  var classRoot =
    (jprop?.className ?? "") +
    " " +
    renderCount +
    (!themeSelected ? " hidden" : "");
  if (jprop.theme === "retail") {
    hideAppBar = true;
    classRoot += " retail";
  }
  if (jprop.theme === "free") {
    classRoot += " free";
  }

  const resize = useMediaQuery("(min-width:960px)");
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = (isOpen) => {
    setOpen(isOpen);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    var isOpen = !hideAppBar && isAuthenticated;
    if (!resize) {
      isOpen = false;
    }
    handleDrawerOpen(isOpen);
  }, [resize, hideAppBar, isAuthenticated]);

  useEffect(() => {
    if (
      position.enabled &&
      jprop?.geolocation &&
      (!position.latitude || !position.longitude)
    ) {
      errors.open(
        "Activarea locatiei este obligatorie pentru a continua!",
        true,
        () => {
          checkGeoLocation({ updateValue });
        }
      );
    }
  }, [position, jprop]);

  //if (appType=='desk' && isau)
  if (open && appType && !hideAppBar) {
    classRoot += " " + appType;
  }
  const GlobalStyle = createGlobalStyle`${jprop ? jprop.css : null}`;
  return (
    <div
      className={"app " + classRoot}
      id="app-back-to-top-anchor"
      data-theme={theme}
      data-jprop-theme={jprop.theme}
    >
      <GlobalStyle />
      {/*<MUICookieConsent
          cookieName="PatriaBankCookie"
          //componentType="Dialog" // default value is Snackbar
          componentType="Snackbar" // default value is Snackbar
          message="Acest site foloseşte cookies! Continuarea navigării implică acceptarea lor!"
          //acceptButtonLabel="Accept cookies"
        />*/}
      <Errors {...errors} theme={jprop.theme} />
      <PrintParametersDialog
        getHeaders={getHeaders}
        getFields={getFields}
        {...printParameters}
      />

      {!hideAppBar && (
        <AppBar color={environment === "test" ? "tertiary" : "primary"}>
          <AppToolbar
            drawerOpen={handleDrawerOpen}
            showMenu={!open}
            jprop={jprop}
            appType={appType}
          />
        </AppBar>
      )}
      {appType == "desk" && !hideAppBar && (
        <Drawer
          variant={resize ? "persistent" : "temporary"}
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className="drawer-title">
            <Typography
              variant="h6"
              className="title"
              sx={{ paddingTop: "8px", paddingBottom: "8px" }}
            >
              Meniu
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {fluxuri && (
            <List>
              {fluxuri.map((item, index) => (
                <ListItem
                  key={item.uid_flux}
                  onClick={() => changeFlux(item, props.history)}
                  sx={{ padding: "0px 0px" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <IconText icon={item.icon} />
                    </ListItemIcon>
                    <ListItemText primary={item.Denumire} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </Drawer>
      )}
      {jprop.theme === "retail" && <RetailToolbar />}
      <Container maxWidth={false} /*className="container"*/>
        <Paper className="paper">
          <Routes navigate={navigate} />
        </Paper>
      </Container>
    </div>
  );
}

export default withRouter(
  inject((stores) => ({ store: stores.mainStore, common: stores.commonStore }))(
    observer(Main)
  )
);
