import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import { onEnter } from "Utils";
import Copyright from "../copyright";
import LoginCode from "./loginCode";

function LoginPhone(props) {
  const { phone, smsCode, flowName, flow } = props.store;
  const { updateValue, confirmPhone, sendPhone, errors } = props.store;
  const history = props.history;
  const handlerSendPhone = () => {
    if (!phone || phone.length === 0) {
      errors.open("[LP001] Completati numarul de telefon!");
    } else {
      sendPhone();
    }
  };

  const handlerConfirmPhone = () => {
    var errorMsgs = [];
    if (!phone || phone.length === 0) {
      errorMsgs.push("[LP001] Completati numarul de telefon!");
    }
    if (!smsCode || smsCode.length === 0) {
      errorMsgs.push("[LP002] Completati codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }

    confirmPhone(null, history);
  };

  return (
    <div /*className="paper"*/>
      <CssBaseline />
      <div className="login">
        <Avatar className="avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          className="login-title"
          align="center"
        >
          {flowName}
        </Typography>
        <form>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Nr Telefon"
            name="Nr Telefon"
            value={phone ? phone : ""}
            onChange={(prop) => updateValue({ phone: prop.target.value })}
            autoFocus
            className="phone"
            autoComplete="numar telefon"
            onKeyPress={(event) => onEnter(event, handlerSendPhone)}
            //margin="dense"
            //error={this.errorPhone}
            //helperText={this.errorPhone && "Completati numarul de telefon"}
            //disabled={!codeRequest}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="submit"
            onClick={() => handlerSendPhone()}
            //disabled={!phone}
          >
            Trimite
          </Button>
          <LoginCode handlerConfirmPhone={handlerConfirmPhone} />

          <Link
            variant="body2"
            //onClick={this.handlerChangePwd}
            href={`/new/${flow}`}
            //className="new-request"
          >
            Cerere noua
          </Link>
        </form>
      </div>

      <Copyright />
    </div>
  );
}

export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(LoginPhone))
);
