import React from 'react';
import View from "./views";


const  CerereWeb = (props) => {
  return (
      	<View {...props} />
  );
}

export default CerereWeb;

