import React from "react";
import { observer } from "mobx-react";
import { TooltipIcon } from "TemplateCerere";

import "../index.css";

/*
Lungime 10 --> dropdown - o singura valoare interogare db 				--> item.coldef.dt
Lungime 11 --> selectie multipla (lista de checknox-uri) - interogare db 		--> item.coldef.dt
Lungime 12 --> combobox - o singura valoare (inclusiv alte valori) - interogare db	--> item.coldef.dt
Lungime 20 --> dropdown - o singura valoare - lista valori 				--> item.coldef.list
Lungime 30 --> dropdown - o singura valoare - lista globala 				--> liste[item.SursaLista.toUpperCase()]
*/
function Field4_Label(props) {
  const { item, inline } = props;
  const { infoLabel } = item.Jprop;
  if (!item.listData) {
    return null;
  }
  if (item.isVisible) {
    return (
      (!inline || item.Lungime === 11 || item.Lungime === 21) && (
        <div id={`f4l-${item.Id}`} className="doc-field-label">
          {item.Caption + (item.Obligatoriu ? " *" : "")}
          {infoLabel && <TooltipIcon tooltip={infoLabel} />}
        </div>
      )
    );
  }
  return;
}
export default observer(Field4_Label);
