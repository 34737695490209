import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";

import { logoBlue } from "TemplateCerere";

function GoodBye(props) {
  const { getGoodbyeInfo, msgHtml } = props.store;
  useEffect(() => {
    let query = new URLSearchParams(props.location.search);
    const id = query.get("ref");
    getGoodbyeInfo(id);
  }, []);
  return (
    <div className="card-goodbye">
      {msgHtml ? (
        <div className="page-goodbye">
          <div
            id="msgHtml"
            className="msgHtml"
            dangerouslySetInnerHTML={{ __html: msgHtml }}
          />
          <div>
            <a href="https://www.patriabank.ro" className="link-goodbye">
              <img src={logoBlue} className="logo-goodbye" alt="" />
            </a>
          </div>
        </div>
      ) : (
        <div>
          <div className="page-goodbye">
            <div className="goodbye">
              Va multumim ca ati apelat la serviciile Patria Bank!
            </div>
            <div className="goodbye">
              Vrei sa afli mai multe despre produsele si serviciile oferite de
              Patria Bank? Click <a href="https://www.patriabank.ro">aici</a>.
            </div>
          </div>

          <div className="page-goodbye">
            <div>
              <a href="https://www.patriabank.ro" className="link-goodbye">
                <img src={logoBlue} className="logo-goodbye" alt="" />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default inject((stores) => ({ store: stores.mainStore }))(
  observer(GoodBye)
);
