import { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { creditTypes } from "Common/src";

import { Preview, EditDialog, CrediteSimulare } from "TemplateCerere";

const Credit = (props) => {
  useEffect(() => {
    async function internalStart() {
      await props.store.doActivate(props.match.params, props.init);
    }
    internalStart();
  }, []);

  const Page = () => {
    let { jprop, flow, errors, downloadDocument, voucher } = props.main;
    jprop = jprop ?? {};
    const { produse, simulare, scadentar, comisioane, interval_scadenta } = props.store;
    const { addSimulation, updateStore } = props.store;
    const actions = {
      processMsg: errors.processMsg,
      addSimulation,
      updateStore,
      downloadDocument,
    };
    /*TODO De modificat - trebuie adus din mainstore.voucher*/
    //const voucher = props.match.params.voucher;
    if (voucher?.err) {
      return (
        <div>{voucher.msg} {voucher.value}</div>
      )
    }
    else {
      switch (flow) {
        case creditTypes.credit:
          return (
            <CrediteSimulare
              produse={produse}
              interval_scadenta={interval_scadenta}
              simulare={simulare}
              scadentar={scadentar}
              comisioane={comisioane}
              jprop={jprop}
              navigate={props.history.push}
              actions={actions}
              downloadDocument={downloadDocument}
              errors={errors}
              title="Salut,"
              subtitle="Hai sa facem cateva calcule impreuna!"
              title2="Simularea arata bine. Ce zici, continuam?"
              subtitle2="Pe baza datelor introduse la pasul anterior poti vedea datele aferente creditului."
              voucher={voucher?.value}
            />
          );
        case creditTypes.prescoring:
          return (
            <CrediteSimulare
              produse={produse}
              interval_scadenta={interval_scadenta}
              simulare={simulare}
              scadentar={scadentar}
              comisioane={comisioane}
              jprop={jprop}
              navigate={props.history.push}
              actions={actions}
              downloadDocument={downloadDocument}
              errors={errors}
              title="Salut,"
              subtitle="Hai sa facem cateva calcule impreuna!"
              title2="Simularea arata bine. Ce zici, continuam?"
              subtitle2="Pe baza datelor introduse la pasul anterior poti vedea datele aferente creditului."
              voucher={voucher?.value}
            />
          );
        default:
          return <div>template nedefinit {flow}</div>;
      }
    }
  };

  //render() {
  const { noMatch } = props.main;

  if (noMatch) {
    return (
      <div
        className="nomatch"
          /*fullWidth*/ dangerouslySetInnerHTML={{ __html: noMatch }}
      />
    );
  }
  let { jprop } = props.main;
  jprop = jprop ?? {};

  const {
    error,
    dialog,
    preview,
    editDialog,
    closeEditDialog,
    saveEditDialog,
    close,
    closePreviewFile,
    produse,
  } = props.store;

  if (error) {
    return <div className="error">{error}</div>;
  }
  return (
    <>
      <div className="paper-toolbar">
        {/*<Prompt when={preventGoBack} message={preventGoBackMessage} />*/}
        <Preview close={closePreviewFile} {...preview} />
        <EditDialog
          close={closeEditDialog}
          save={saveEditDialog}
          {...editDialog}
          className={jprop.className || editDialog.className || ""}
        />
        <Dialog open={dialog.isOpen} height="250px">
          <DialogTitle>{dialog.header}</DialogTitle>
          <DialogContent>
            <div className="dialog-message">{dialog.message}</div>
            <div className="dialog-buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={() => close(props.history.push)}
              >
                Inchide
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {produse && Page()}
      </div>
    </>
  );
  //}
}
export default inject((stores) => ({
  store: stores.creditStore,
  main: stores.mainStore,
  //  filterSursaLista: stores.commonStore.filterSursaLista,
  //  getField: stores.commonStore.getField,
  //  queryData: stores.commonStore.queryData,
}))(withRouter(observer(Credit)));
