import React from "react";
import { observer } from "mobx-react";

import FilterC from "./filter_c";
import FilterLS from "./filter_ls";
import FilterD from "./filter_d";
import FilterN from "./filter_n";
import FilterT from "./filter_t";

import "../index.css";

const filter = (props) => {
  const { tip } = props;
  return (
    <div className="doc-table-value">
      {tip === "bool" && <FilterC {...props} />}
      {tip === "select" && <FilterLS {...props} />}

      {tip === "data" && <FilterD {...props} />}
      {tip === "numeric" && <FilterN decimalScale={2} {...props} />}
      {tip === "int" && <FilterN {...props} />}
      {tip === "string" && <FilterT {...props} />}
    </div>
  );
};

export default observer(filter);
