import React, { useState } from "react";
import {
  Document,
  Page,
} from "@cyntler/react-doc-viewer/node_modules/react-pdf";

import {
  Remove,
  Add,
  CloudDownloadOutlined,
  Refresh,
} from "@mui/icons-material";
import "./pdfView.scss";
import { NumericFormat } from "react-number-format";
import { base64ToArrayBuffer } from "Utils";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export default function PdfViewAllPages(props) {
  const [numPages, setNumPages] = useState(null);
  const [zoom, setZoom] = useState(1);
  const { file, onLoadSuccess, downloadDisabled, ...others } = props;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setTimeout(onLoadSuccess, 1000);
  }
  const downloadPdf = () => {
    const fileData = base64ToArrayBuffer(file.data);
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const hiddenElement = document.createElement("a");
    hiddenElement.href = url;
    hiddenElement.download = file.fileName;
    hiddenElement.click();
    URL.revokeObjectURL(hiddenElement.href);
  };
  return (
    <div className="pdf-doc">
      <div className="pdf-btn-group">
        {!downloadDisabled && (
          <Tooltip arrow title="Descarca document">
            <IconButton className="pdf-btn" onClick={downloadPdf}>
              <CloudDownloadOutlined />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip arrow title="Micsoreaza">
          <IconButton
            className="pdf-btn"
            onClick={() => {
              setZoom(zoom - 0.1);
            }}
          >
            <Remove />
          </IconButton>
        </Tooltip>

        <div className="pdf-btn view">
          <NumericFormat
            value={zoom * 100}
            displayType="text"
            suffix="%"
            decimalScale={0}
            renderText={(value) => <div className="percent">{value}</div>}
          />
        </div>
        <Tooltip arrow title="Mareste">
          <IconButton
            className="pdf-btn"
            onClick={() => {
              setZoom(zoom + 0.1);
            }}
          >
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Reseteaza zoom">
          <IconButton
            className="pdf-btn"
            onClick={() => {
              setZoom(1);
            }}
          >
            <Refresh />
          </IconButton>
        </Tooltip>
      </div>
      <div className="pdf-doc-page">
        <Document
          file={file && `data:application/pdf;base64,${file.data}`}
          // options={pdfjsWorker}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => {
            alert("Error while retrieving the outline! " + error.message);
          }}
          {...others}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              //width={2024}
              scale={zoom}
            />
          ))}
        </Document>
      </div>
    </div>
  );
}
