import React from "react";
import { observer } from "mobx-react";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Checkbox } from "TemplateCerere";
import "./acord.scss";

const Acord = (props) => {
  const { updateValue, acord, cssprops } = props;
  return (
    <div className={"acord " + cssprops}>
      <FormControl
        id={`f5c-${acord.Id}`}
        required={acord.Obligatoriu}
        error={!!acord.error}
      >
        <FormGroup id={`f5g-${acord.Id}`}>
          <FormControlLabel
            control={
              <Checkbox
                id={`f5f-${acord.Id}`}
                checked={acord.Valoare === "1"}
                onChange={(prop) => {
                  updateValue(acord, prop.target.checked ? "1" : "0");
                }}
                color="primary"
                disabled={acord.isReadonly || acord.docReadOnly}
                className={
                  "checkbox-check" +
                  (acord.isReadonly || acord.docReadOnly ? "disabled" : "")
                }
                variant="outlined"
                required={acord.Obligatoriu}
              />
            }
            label={acord.Caption}
            className="checkbox-label"
            id={`f5cl-${acord.Id}`}
          />
        </FormGroup>
        <FormHelperText>{acord.error || acord.Jprop.helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};
export default observer(Acord);
