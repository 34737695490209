import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";

function Navigate(props) {
  const { navigate } = props.store;
  useEffect(() => {
    let query = new URLSearchParams(props.location.search);
    const url = query.get("ref");
    navigate(props.history, url);
  }, []);
  return null;
}

export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(Navigate))
);
