import React from "react";
import { observer } from "mobx-react";
import Link from "@mui/material/Link";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Checkbox } from "TemplateCerere";
import { ArrowRight } from "@mui/icons-material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { titleCase } from "Utils";
//import "../../index.css";
import SvgIcon from "@mui/material/SvgIcon";
import { IconCheck, IconInfo } from "TemplateCerere";
import { IconsSvg } from "TemplateCerere";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const AcordLine = ({ children, tooltip }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <span className="normal-text">
      <SvgIcon
        component={IconCheck}
        className="initial-check"
        viewBox="0 0 16 16.001"
      />

      {children}
      {tooltip &&
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            arrow
            title={
              <Typography component="span" variant="body2">
                {tooltip}
              </Typography>
            }
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
          >
            <SvgIcon
              component={IconInfo}
              className="initial-info"
              viewBox="0 0 16 16.001"
              onClick={handleTooltipOpen}
              onMouseEnter={handleTooltipOpen}
            />
          </Tooltip>
        </ClickAwayListener>
      }
    </span>
  );
};

function AcordPage(props) {
  const { downloadAcord, handleChange, handlerChangePage } = props;
  const { updateValue, name, simulare } = props;
  const {
    tc, //termeni si conditii
    ni, //nota de informare
    tc_err,
    ni_err,
  } = props;
  return (
    <>
      <IconsSvg icon="IllustrationHello" />

      <p className="page-title">Salut,</p>
      <p className="normal-text">
        În doar câțiva pași online poti afla dacă poți lua un credit de la Patria Bank, dar și suma maximă la care te poți încadra, în baza informațiilor declarate de tine, utilizând simulatorul Patria Bank.*
        <br></br><em>*Acesta este un simulator bazat pe datele completate de tine. Te rugăm să ai in vedere ca în răspunsul final al Băncii se vor lua în considerare mai multe criterii care pot duce la rezultate diferite.
        </em>
      </p>
      <p className="normal-text">
        Completează câteva informații despre tine, caracteristicile creditului pe care vrei sa îl accesezi (valoarea, durata, tip dobânda, modalitate de rambursare etc), iar calculatorul iti va afisa detaliile creditului dorit.
      </p>
      <p className="subtitle-text">
        Folosind calculatorul de rate vei afla:
      </p>
      <div className="acord-group">
        <AcordLine tooltip="">
          Suma maximă pe care o poți solicita
        </AcordLine>

        <AcordLine tooltip="">
          Valoarea ratei lunare
        </AcordLine>
        <AcordLine tooltip="">
          Valoare dobânzii
        </AcordLine>

        <AcordLine tooltip="">
          Valoarea totală de rambursat
        </AcordLine>
        <AcordLine tooltip="">
          Dobanda Anuală Efectivă (DAE)
        </AcordLine>

      </div>
      <p className="normal-text">
        Pentru a accesa simulatorul, trebuie sa fii de acord cu urmatoarele:
      </p>
      <div className="acord">
        <Checkbox
          name="tc"
          checked={tc}
          onChange={handleChange}
          color="primary"
        />
        <div className="acord-txt">
          Sunt de acord cu
          <Link onClick={() => downloadAcord(1)} className="termenii">
            Termenii si Conditiile Generale de utilizare a site-ului
          </Link>
          si am luat la cunostinta informatiile privind Protectia si confidentialitatea datelor *
          <FormHelperText error={!!tc_err}>{tc_err}</FormHelperText>
        </div>
      </div>
      <div className="acord">
        <Checkbox
          name="ni"
          checked={ni}
          onChange={handleChange}
          color="primary"
        />
        <div className="acord-txt">
          Am citit si am inteles prevederile
          <Link onClick={() => downloadAcord(2)} className="termenii">
            Notei de informare
          </Link>
          vizand prelucrarea datelor cu caracter personal pentru scopul
          desfasurarii sesiunii pe Platforma Online *
          <FormHelperText error={!!ni_err}>{ni_err}</FormHelperText>
        </div>
      </div>
      <p className="footer-text mkt">
        Campurile marcate cu * sunt obligatoriu de completat
      </p>
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        onClick={handlerChangePage}
        endIcon={<ArrowRight fontSize="large" />}
      >
        Confirma
      </Button>
    </>
  );
}
export default observer(AcordPage);
