import React from "react";
import _ from "lodash";
import "./radialSeparators.scss";

function Separator(props) {
  return (
    <div
      style={{
        position: "absolute",
        height: "102%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} className="radial-separator" />
    </div>
  );
}

function RadialSeparators(props) {
  const turns = 1 / props.count;
  return _.range(props.count).map((index) => (
    <Separator key={index} turns={index * turns} style={props.style} />
  ));
}

export default RadialSeparators;
