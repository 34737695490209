import React from "react";
import { observer } from "mobx-react";

//import styled from "styled-components";

import TextField from "@mui/material/TextField";

import Autocomplete from "@mui/material/Autocomplete";

import { getValueKey, getValueOfList } from "Utils";

import "../index.css";

function Filter_LS(props) {
  const { cimp, valoare, listData, updateFilter } = props;
  const defaultValue = getValueOfList(listData, valoare);
  return (
    <Autocomplete
      id={"fcb" + cimp}
      controled="true"
      options={listData}
      getOptionLabel={(option) => getValueKey(option, 1)}
      clearOnEscape
      value={defaultValue}
      style={{ width: 350 }}
      size="small"
      onChange={(event, newValue) => {
        updateFilter(cimp, newValue && getValueKey(newValue, 0));
      }}
      autoComplete
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
}
export default observer(Filter_LS);
