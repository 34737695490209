import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import Container from "@mui/material/Container";

import { PasswordField } from "TemplateCerere";
import { onEnter } from "Utils";

import Copyright from "../copyright";
import LoginCode from "./loginCode";

function LoginPhoneCNP(props) {
  const { cnp, phone, smsCode, flowName } = props.store;
  const { updateValue, confirmPhone, sendPhone, errors } = props.store;
  const history = props.history;

  const validare = () => {
    var errorMsgs = [];
    if (!cnp || cnp.length !== 13 || isNaN(cnp)) {
      errorMsgs.push("[LP003] Campul CNP este incorect!");
    }
    if (!phone || phone.length === 0) {
      errorMsgs.push("[LP001] Completati numarul de telefon!");
    }
    return errorMsgs;
  };

  const handlerSendPhone = () => {
    var errorMsgs = validare();

    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    sendPhone({ cnp: cnp });
  };

  const handlerConfirmPhone = () => {
    var errorMsgs = validare();
    if (!smsCode || smsCode.length === 0) {
      errorMsgs.push("[LP002] Completati codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    confirmPhone({ cnp: cnp }, history);
  };

  return (
    <div component="main" /*maxWidth="xs"*/ maxWidth={false}>
      <CssBaseline />
      <div className="login">
        <Avatar className="avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          className="login-title"
          align="center"
        >
          {flowName}
        </Typography>
        <div className="form">
          <PasswordField
            id="cnp"
            label="CNP"
            //name="CNP"
            value={cnp ? cnp : ""}
            //error={error}
            //helperText={error}
            className="login-pwd"
            variant="outlined"
            onChange={(prop) => updateValue({ cnp: prop.target.value })}
            mask="9999999999999"
            maskChar=""
            //onChange={(prop) => actionIsNumber(prop.target.value, updateValue, "cnp") }
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Nr Telefon"
            name="Nr Telefon"
            value={phone ? phone : ""}
            onChange={(prop) => updateValue({ phone: prop.target.value })}
            autoFocus
            className="phone"
            autoComplete="numar telefon"
            onKeyPress={(event) => onEnter(event, handlerSendPhone)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="submit"
            onClick={() => handlerSendPhone()}
          >
            Trimite
          </Button>

          <LoginCode handlerConfirmPhone={handlerConfirmPhone} />
        </div>
      </div>

      <Copyright />
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(LoginPhoneCNP))
);
