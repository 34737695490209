import React from "react";
import { observer } from "mobx-react";
import * as Document from "./document";
import OnboardingPachetPF from "./templates/OnboardingPachetPF";
import OnboardingDepozitePF from "./templates/OnboardingDepozitePF";
import OnboardingDocumentePF from "./templates/OnboardingDocumentePF";
import OnboardingConfirmarePF from "./templates/OnboardingConfirmarePF";
import ImageDocument from "./templates/ImageDocument";
import Button from "@mui/material/Button";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { ProgressBar, IconsSvg, ScrollTop } from "./components";
//import CrediteSimularePanou from "./templates/CrediteSimularePanou";
import AcceptanceAgreement from "./templates/AcceptanceAgreement";
import DigitalLendingSimulare from "./templates/DigitalLendingSimulare";
import DigitalLendingConfirmare from "./templates/DigitalLendingConfirmare";
import DigitalLendingConfirmarePasiv from "./templates/DigitalLendingConfirmarePasiv";
import AcceptanceAgreementWithDialog from "./templates/AcceptanceAgreementWithDialog";
import DigitalLendingPachetPF from "./templates/DigitalLendingPachetPF";
import Alert from "@mui/material//Alert";
import AlertTitle from "@mui/material//AlertTitle";
import DigitalLendingSelectieOferta from "./templates/DigitalLendingSelectieOferta";
import DigitalPrescoringSelectieOferta from "./templates/DigitalPrescoringSelectieOferta";
import { WidgetInfo } from "./components";

function Content  (props) {
  const {doc, isMobile, actions, ...other } = props;
  switch (doc.Tip) {
    /*case 0:
                    return(<Document.Document0 key={doc.Id} doc={doc} />)
                case 1:
                    return(<Document.Document1 key={doc.Id} doc={doc} />)
                */
    case 2:
      return (
        <Document.Document2
          key={doc.Id}
          doc={doc}
          actions={actions}
          isMobile={isMobile}
          {...other}
        />
      );
    case 3:
      return (
        <Document.Document3
          key={doc.Id}
          doc={doc}
          actions={actions}
          isMobile={isMobile}
          {...other}
        />
      );
    default:
      return <div>document nedefinit {doc.tip}</div>;
  }
};


function Documents(props){
  const {
    documents,
    actions,
    navigateBack,
    navigateForward,
    nextStep,
    goStep,
    currentStep,
    countSteps,
    info,
    canSave,
    canPass,
    jprop,
    children,
    isMobile,
    simulare,
    errors,
    warning,
    widget,
    stepId,
    ...other
  } = props;

  
  

    //var isMobile = useMediaQuery("(max-width:960px)");

    var lenDocuments = documents.length;
    var firstDoc =
      lenDocuments !== 0 && documents[0].Jprop ? documents[0].Jprop : {};
    var prevAction =
      firstDoc && firstDoc.actionNamePrev ? firstDoc.actionNamePrev : "Inapoi";
    if (firstDoc.actionNamePrev === false) {
      prevAction = false;
    }
    var nextAction = "Continua";
    if (lenDocuments !== 0 && documents[lenDocuments - 1].Jprop) {
      if (documents[lenDocuments - 1].Jprop.actionNameNext === false) {
        nextAction = false;
      } else if (documents[lenDocuments - 1].Jprop.actionNameNext) {
        nextAction = documents[lenDocuments - 1].Jprop.actionNameNext;
      }
    }
    return (
      <div className="request-content">
        {/*<div id="back-to-top-anchor" />*/}
        {isMobile && jprop.theme === "retail" && (
          <div className="nav-bar">
            <ProgressBar
              goBack={() => goStep(currentStep - 1)}
              prevStep={currentStep !== 0 && prevAction}
              stepId={stepId}
              getStepsInfo={actions.getStepsInfo}
            />
          </div>
        )}
        <div id="doc-nav-prev" className="doc-nav doc-nav-prev">
          {(!isMobile && prevAction && currentStep !== 0 && (
            <Button
              color="primary"
              className="link-btn"
              startIcon={<ArrowLeft />}
              onClick={() => goStep(currentStep - 1)}
            >
              {prevAction}
            </Button>
          )) || <div id="doc-nav-prev-btn" />}
          <div className="info">{info}</div>
        </div>
        {warning?.text && (
          <Alert variant="outlined" severity={warning.severity ?? "warning"}>
            <AlertTitle>{warning.title}</AlertTitle>
            {warning.text}
          </Alert>
        )}
        {/*simulare && (
          <div className="widget">
            <CrediteSimularePanou simulare={simulare} />
          </div>
        )*/}
        {lenDocuments !== 0 &&
          !documents[0].Jprop?.hideWidget &&
          widget?.data && <WidgetInfo widget={widget} />}
        {documents &&
          lenDocuments !== 0 &&
          documents.map((doc) => {
            if (doc.Jprop && doc.Jprop.template && doc.Jprop.template.id) {
              switch (doc.Jprop.template.id) {
                case "OnboardingPachetPF":
                  return (
                    <OnboardingPachetPF
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      nextStep={nextStep}
                      isMobile={isMobile}
                      {...other}
                    />
                  );
                case "OnboardingDepozitePF":
                  return (
                    <OnboardingDepozitePF
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      isMobile={isMobile}
                      {...other}
                    />
                  );
                case "OnboardingDocumentePF":
                  return (
                    <OnboardingDocumentePF
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      {...other}
                    />
                  );
                case "OnboardingConfirmarePF":
                  return (
                    <OnboardingConfirmarePF
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      nextStep={nextStep}
                      canPass={canPass}
                      {...other}
                    />
                  );
                case "ImageDocument":
                  return (
                    <ImageDocument
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      isMobile={isMobile}
                      {...other}
                    />
                  );
                case "AcceptanceAgreement":
                  return (
                    <AcceptanceAgreement
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      isMobile={isMobile}
                      {...other}
                    />
                  );
                case "DigitalLendingSimulare":
                  return (
                    <DigitalLendingSimulare
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      isMobile={isMobile}
                      nextStep={nextStep}
                      errors={errors}
                      nextAction={nextAction}
                      simulare={simulare}
                      {...other}
                    />
                  );
                case "DigitalLendingConfirmare":
                  return (
                    <DigitalLendingConfirmare
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      nextStep={nextStep}
                      simulare={simulare}
                      {...other}
                    />
                  );
                case "DigitalLendingConfirmarePasiv":
                  return (
                    <DigitalLendingConfirmarePasiv
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      nextStep={nextStep}
                      simulare
                      {...other}
                    />
                  );

                case "AcceptanceAgreementWithDialog":
                  return (
                    <AcceptanceAgreementWithDialog
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      isMobile={isMobile}
                      errors={errors}
                      className = {jprop?.className}
                      {...other}
                    />
                  );
                case "DigitalLendingPachetPF":
                  return (
                    <DigitalLendingPachetPF
                      key={doc.Id}
                      doc={doc}
                      actions={actions}
                      nextStep={nextStep}
                      isMobile={isMobile}
                      {...other}
                    />
                  );
                  case "DigitalLendingSelectieOferta":
                    return (
                      <DigitalLendingSelectieOferta
                        key={doc.Id}
                        doc={doc}
                        actions={actions}
                        nextStep={nextStep}
                        isMobile={isMobile}
                        errors={errors}
                        simulare={simulare}
                        {...other}
                      />
                    );
  
                    case "DigitalPrescoringSelectieOferta":
                      return (
                        <DigitalPrescoringSelectieOferta
                          key={doc.Id}
                          doc={doc}
                          actions={actions}
                          nextStep={nextStep}
                          isMobile={isMobile}
                          errors={errors}
                          simulare={simulare}
                          {...other}
                        />
                      );
    
                      default:
                  return <div>template nedefinit {doc.tip}</div>;
              }
            } else {
              return (
                <div
                  className={
                    "e-card e-card-border " +
                    (doc.Jprop?.className ?? "") +
                    (!doc.Incarcare ? " doc-readonly" : "")
                  }
                  id={`dco${doc.Id}`}
                  key={doc.Id}
                >
                  <IconsSvg icon={doc.Jprop ? doc.Jprop.icon : null} />

                  {(doc.Jprop?.title || doc.Descriere) && (
                    <div className="e-card-title">
                      {doc.Jprop?.title ?? doc.Descriere}
                    </div>
                  )}
                  {doc.Jprop?.subtitle && (
                    <div className="e-card-subtitle">{doc.Jprop.subtitle}</div>
                  )}
                  {/*<div className="e-card-separator" />*/}
                  <div className="e-card-content-documentx">
                    <Content doc={doc} {...props}/>
                  </div>
                  {doc.Jprop?.footer && (
                    <div className="e-card-footer">{doc.Jprop.footer}</div>
                  )}
                </div>
              );
            }
          })}

        {canPass && nextAction && (
          <ScrollTop target="app-back-to-top-anchor">
            <div className="doc-nav doc-nav-next">
              <Button
                color="primary"
                variant="contained"
                endIcon={<ArrowRight fontSize="large" />}
                onClick={() => nextStep()}
              >
                {nextAction}
              </Button>
            </div>
          </ScrollTop>
        )}
        {children}
      </div>
    );
  }
export default observer(Documents);
