import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { getStyle } from "Utils";
import DivCss from "../components/divCss";
import "../index.css";
import { TextFieldMask, ConfirmPhone } from "../components";
import { TooltipIcon } from "TemplateCerere";
import { IconText } from "TemplateCerere";

function Field0(props) {
  const { item, inline, actions, gridItem } = props;
  const lungime = item.Valoare && item.Valoare.length;
  const width = () => {
    switch (true) {
      /* case (lungime <= 10):
                    return '10ch';*/
      case gridItem:
        return "100%";
      case lungime <= 20:
        return "210px";
      case lungime <= 30:
        return "30ch";
      default:
        return "50ch";
    }
  };
  const styleProps = getStyle(item.eventProps);
  const TextFieldCss = styled(TextFieldMask)`
    ${styleProps.input}
  `;
  const CompDivCss = styled(DivCss)`
    ${styleProps.field}
  `;
  const { infoLabel } = item.Jprop || {};
  const eventProps = item.eventProps?.props;
  var controlType = item.Jprop?.control;
  return (
    !!item.isVisible && (
      <CompDivCss {...props} id={`f0-${item.Id}`} key={item.Id}>
        {!inline && (
          <div id={`f0l-${item.Id}`} className="doc-field-label">
            {item.Caption + (item.Obligatoriu ? " *" : "")}
            {infoLabel && <TooltipIcon tooltip={infoLabel} />}
          </div>
        )}
        {controlType === "otp" ? (
          <ConfirmPhone
            key={item.Id}
            item={item}
            onBlur={(prop) => actions.updateValue(item, prop.target.value)}
            label={inline ? item.Caption : item.Jprop?.placeholder}
            disabled={item.isReadonly || item.docReadOnly}
            actions={actions}
            gridItem={gridItem}
          />
        ) : (
          <TextFieldCss
            id={`f0f-${item.Id}`}
            defaultValue={item.Valoare ? item.Valoare : ""}
            label={inline ? item.Caption : item.Jprop?.placeholder}
            readOnly={item.isReadonly || item.docReadOnly}
            onBlur={(prop) => actions.updateValue(item, prop.target.value)}
            disabled={item.isReadonly || item.docReadOnly}
            variant="outlined"
            className={item.isReadonly ? "disabled" : null}
            style={{
              width: width(),
              maxWidth: gridItem ? "" : "calc(100% - 40px)",
            }}
            size="small"
            {...item.Jprop.ctrlProps}
            InputProps={{
              endAdornment: item.hasButton &&
                !(item.isReadonly || item.docReadOnly) && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle "
                      onClick={() => {
                        actions.runEvent(actions.store, item, false, true);
                      }}
                    >
                      <IconText
                        icon={item.Jprop?.ctrlProps?.icon ?? "Update"}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
            }}
            required={item.Obligatoriu}
            error={!!item.error}
            helperText={item.error || item.Jprop.helperText}
            onPaste={(e) => {
              if (item.Jprop.nopaste) {
                e.preventDefault();
                if (typeof item.Jprop.nopaste === "string") {
                  item.error = item.Jprop.nopaste;
                }
                return false;
              }
            }}
            {...eventProps}
          />
        )}
        {inline && infoLabel && <TooltipIcon tooltip={infoLabel} />}
      </CompDivCss>
    )
  );
}
export default observer(Field0);
