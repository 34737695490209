import React from "react";
import { observer } from "mobx-react";
import Link from "@mui/material/Link";

const LinkLabel = (props) => {
  let { linkClick, label, actions } = props;

  const onLinkClick = () => {
    if (linkClick?.function) {
      actions[linkClick.function](linkClick.params);
    }
  };
  const startLink = label.indexOf("${");
  const endLink = label.indexOf("}$");
  const label1 = startLink > -1 ? label.substring(0, startLink) : label;
  const label2 = endLink > -1 ? label.substring(endLink + 2) : "";
  const labelLink =
    startLink > -1 && endLink > -1
      ? label.substring(startLink + 2, endLink)
      : "";

  return (
    <>
      {label1}
      {startLink > -1 && (
        <Link
          id="linklabel-url"
          onClick={onLinkClick}
          href={linkClick?.url}
          target={linkClick?.target}
        >
          {labelLink}
        </Link>
      )}
      {endLink > -1 && label2}
    </>
  );
};
export default observer(LinkLabel);
