import React from "react";
import "./pachetContIcon.scss";

export default function PachetContIcon(props) {
  const { estePachet, prefix, caption, className } = props;
  return (
    <div
      className={[
        "pachet-cont-icon",
        className,
        estePachet ? "pachet-card-icon" : "",
      ].join(" ")}
    >
      {/*
      <div className="pachet-logo">
        <img src={logoIcon} alt=""/>
        <div>PATRIA BANK</div>
      </div>
      */}
      {estePachet ? (
        <>
          <div className="pachet-card-icon-prefix">{prefix}</div>
          <div className="pachet-card-icon-caption">{caption}</div>
          <div className="pachet-card-number">0000 0000 0000 0000</div>
          {/*
          <div className="pachet-card-name">
          0000
          </div>
          */}
        </>
      ) : (
        <div className="pachet-cont-icon-caption">{caption}</div>
      )}
    </div>
  );
}
