import React from "react";
import PachetContIcon from "./pachetContIcon";
import PachetContOption from "./pachetContOption";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Button from "@mui/material/Button";
import { ArrowRight } from "@mui/icons-material";

export default function PachetCont(props) {
  const getIcon = (e) => {
    return e ? (
      <CheckCircleIcon style={{ color: "#009479" }} />
    ) : (
      <RemoveCircleOutlineIcon style={{ fill: "#C22821" }} />
    );
  };

  const {
    className,
    pachet,
    readonly,
    onSelect,
    cont,
    card,
    ib,
    sms,
    contval,
    depo,
    helperFirst,
    eco,
  } = props;
  return (
    <div
      className={["pachet-cont", className].join(" ")}
      id={pachet}
      onDoubleClick={onSelect}
    >
      <PachetContIcon
        prefix={props.prefix}
        caption={props.caption}
        estePachet={props.estePachet}
      ></PachetContIcon>
      <div className="pachet-cont-promo">
        {props.promo && <span className="promo">{props.promo}</span>}
        {props.promo2 && <span className="promo2">{props.promo2}</span>}
      </div>
      <div className="pachet-cont-pret">
        <span>{props.pret}</span> <span>Lei</span>
        <span>/luna</span>
      </div>
      {helperFirst && (
        <div className="pachet-cont-helper">{props.helperText}</div>
      )}
      <PachetContOption
        type="cont"
        caption={cont.caption}
        helperText={cont.helperText}
        icon={getIcon(cont.included)}
        supText={cont.supText}
      />
      <PachetContOption
        type="card"
        caption={card.caption}
        helperText={card.helperText}
        icon={getIcon(card.included)}
        supText={card.supText}
      />
      <PachetContOption
        type="ib"
        caption={ib.caption}
        helperText={ib.helperText}
        icon={getIcon(ib.included)}
        supText={ib.supText}
      />
      <PachetContOption
        type="sms"
        caption={sms.caption}
        helperText={sms.helperText}
        icon={getIcon(sms.included)}
        supText={sms.supText}
      />
      {contval.show && (
        <PachetContOption
          type="contval"
          caption={contval.caption}
          helperText={contval.helperText}
          icon={getIcon(contval.included)}
          supText={contval.supText}
        />
      )}
      {depo.show && (
        <PachetContOption
          type="depo"
          caption={depo.caption}
          helperText={depo.helperText}
          icon={getIcon(depo.included)}
          supText={depo.supText}
        />
      )}
      {eco?.show && (
        <PachetContOption
          type="eco"
          caption={eco.caption}
          helperText={eco.helperText}
          icon={getIcon(eco.included)}
          supText={eco.supText}
        />
      )}
      {!readonly && (
        <div className="pachet-cont-chooser">
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight fontSize="large" />}
            style={{ width: "100%" }}
            onClick={onSelect}
          >
            Alege
          </Button>
        </div>
      )}
      {!helperFirst && (
        <div className="pachet-cont-helper">{props.helperText}</div>
      )}
    </div>
  );
}
