import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { logoIcon } from "TemplateCerere";
//import "./index.css";

import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import { ExitToApp, Home } from "@mui/icons-material";
//import {useIdleTimer} from 'react-idle-timer';
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import Navbar from "./navbar";

function DefaultToolbar(props) {
  const { logout } = props.store;
  const { isAuthenticated, title, version, info, isUser, appType, flux } =
    props.store;
  var appUrl = appType === "desk" ? "desk" : "app";
  const history = props.history;
  const url = history.location.pathname;
  const handleHome = () => {
    props.store.updateValue({ preventGoBack: false });
    history.push(`/${appUrl}`);
  };
  const matches = useMediaQuery("(min-width:800px)");
  const { drawerOpen, showMenu } = props;
  return (
    <Toolbar variant="dense" id="top">
      {isAuthenticated && appType == "desk" && showMenu && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => drawerOpen(true)}
          edge="start"
          //className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
      )}
      <img src={logoIcon} className="logo-menu" alt="" />
      {(matches || !info) && (
        <Typography variant="h6" className="title">
          {title}
        </Typography>
      )}
      {(matches || !info) && (
        <Typography variant="caption" className="title">
          {appType} {version}
        </Typography>
      )}
      {info && (
        <Typography variant="subtitle1" className="title">
          {info}
        </Typography>
      )}
      {isAuthenticated &&
        (appType == "desk" ? flux?.uid_flux && flux?.Denumire : <Navbar />)}
      <div style={{ flexGrow: 1 }} />
      {isAuthenticated && (
        <div className="hlist">
          {isUser && (
            <Tooltip title="Acasa">
              <IconButton
                aria-label="home"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleHome}
                color="inherit"
              >
                <Home />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Iesire">
            <IconButton
              aria-label="logout"
              aria-controls="menu-appbar"
              //aria-haspopup="true"
              //onClick={handleMenu}
              onClick={() => logout({ url: url })}
              color="inherit"
            >
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Toolbar>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(DefaultToolbar))
);
