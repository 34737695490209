/* eslint-disable eqeqeq */

import { action, extendObservable, set } from "mobx";
import {
  renameObjectKeys,
  runEvent,
  getValueOfList,
  getValueKey,
  jsonParse,
} from "Utils";
import moment from "moment";
import { Buffer } from "buffer";
import { creditTypes } from "Common/src";
//import { Api } from "Common/src";

const modelPreview = {
  isOpen: false,
  name: "",
  image: null,
  content: null,
};
const modelDialog = {
  isOpen: false,
  message: "Asteptati!",
  header: "Salvare document",
  url: null,
  className: null,
};
const modelEditDialog = {
  isOpen: false,
  header: "Modificare",
  child: null,
  save: null,
  showSave: true,
  showClose: true,
  fullScreen: false,
  error: null,
  validate: null,
  className: null,
  id: "",
};
const modelVideoCall = {
  isOpen: false,
  header: "Video Call",
  src: "",
};
const modelEditTable = {
  table: {},
  newId: 0,
};
const modelShape = {
  uidCerere: null,
  type: null,
  error: null,
  sectiuni: [],
  countSteps: 0,
  currentStep: 0,
  id: null,
  idFlux: null,
  caption: null,
  canPass: null,
  canSave: null,
  //changedFields: {},
  param: {},
  dialog: modelDialog,
  preview: modelPreview,
  errors: {},
  eventsFields: [],
  nextPhase: {},
  docListareCerere: [],
  editDialog: modelEditDialog,
  editTable: modelEditTable,
  videoCall: modelVideoCall,
  changedTabels: [],
  endJourney: true,
  culoare: "#000000",
  simulare: {},
  template: {},
  credit: {},
  widget: {},
};

class CerereWebStore {
  //api = null;
  keepAlive = null;
  //liste = [];

  constructor(props) {
    this.rootStore = props;
    //this.rootStore.api = new Api({ logout: props.mainStore.logout});
    extendObservable(this, modelShape);
    extendObservable(this.preview, modelPreview);
    extendObservable(this.dialog, modelDialog);
    extendObservable(this.editDialog, modelEditDialog);
    extendObservable(this.editTable, modelEditTable);
    extendObservable(this.videoCall, modelVideoCall);
  }

  @action
  doActivate = async (params) => {
    if (this.rootStore.mainStore.tryLogin) {
      return;
    }
    this.uidCerere = params.id;
    this.type = params.type;
    var result = await this.rootStore.mainStore.getFlow(params.type);
    if (result && result.noMatch) {
      return;
    }
    this.rootStore.mainStore.isWaiting = true;
    await this.getRequest();
    this.rootStore.mainStore.isWaiting = false;
    //this.rootStore.mainStore.hideAppBar = false;
  };
  getSimulareWidget = async () => {
    //this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.fetch("/api/request/simulare-get", {
      id: this.uidCerere,
    });
    if (results && !results.error && results != "401") {
      this.simulare = results.Result;
      this.template.credit = results.Result;
      /*TODO de verificat*/
      this.rootStore.mainStore.voucher = {
        value: results?.Result?.voucher,
        err: -1,
        msg: "",
      };
    }
    //this.rootStore.mainStore.isWaiting = false;
  };
  @action
  getSimulare = async (id) => {
    if (!id) {
      return;
    }
    //this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.fetch(
      "/api/common/simulare-creditare-get",
      {
        id: id,
      }
    );
    if (results && !results.error && results != "401") {
      this.simulare = results;
    }
    //this.rootStore.mainStore.isWaiting = false;
  };

  @action
  getRequest = async (url) => {
    //this.rootStore.mainStore.isWaiting = true;
    this.rootStore.commonStore.changedFields = {};
    this.changedTabels = [];
    this.sectiuni = [];
    this.step = {};
    this.countSteps = 0;
    this.currentStep = 0;
    this.tab = [];
    this.doc = [];
    this.sec = [];
    this.head = [];
    this.fld = [];
    this.currentTab = {};
    /*TODO - de lamurit cum facem sa apelam doar cand e cazul*/
    var results = await this.rootStore.api.fetch("/api/request/show-new", {
      type: this.type,
      uidCerere: this.uidCerere,
      reload: false,
    });
    if (
      results &&
      results.o &&
      !results.error &&
      !results.r &&
      results !== "401"
    ) {
      if (!results.o) {
        console.log(results);
      }
      this.aft = results.o.aft;
      this.rootStore.commonStore.warning = {};
      if (results.msg?.startsWith("OK|")) {
        var w = {};
        var mx = results.msg.slice(3);
        [w.text, w.title, w.severity] = mx.split("|");
        set(this.rootStore.commonStore.warning, w);
      }
      var resultsJson = await renameObjectKeys(results.o);
      this.rootStore.mainStore.info = resultsJson.caption;
      /*HACK TODO - de implementat ca parametrizare*/
      if (resultsJson.jprop && resultsJson.jprop.theme === "retail") {
        this.rootStore.mainStore.info = "ID #" + resultsJson.indiceCerere;
      }
      if (resultsJson.tab && resultsJson.tab.length !== 0) {
        //determinare primul tab editabil
        this.rootStore.commonStore.liste = resultsJson.liste;
        this.currentTab =
          resultsJson.tab.find((x) => x.Editable) || resultsJson.tab[0];
        //Daca nu am nici un tab editabil, ma duc pe primul tab
        //determinarea taburilor care au id-ul mai mic decat primul tab editabil
        //incarcare sectiunilor de pe taburile determinate anterior
        this.sectiuni = resultsJson.sec
          .filter((s) => s.tab <= this.currentTab.Id)
          .map((s) => {
            return { ...s, Editable: true };
          });
        this.rootStore.commonStore.allFields = {};

        this.rootStore.commonStore.allFields["uidCerere"] = this.uidCerere;
        this.rootStore.commonStore.allFields["idPas"] = this.idPas || 0;

        this.rootStore.commonStore.tab = [];
        this.rootStore.commonStore.sec = [];
        this.rootStore.commonStore.doc = [];
        this.rootStore.commonStore.head = [];
        this.rootStore.commonStore.fld = [];

        set(this.rootStore.commonStore.tab, resultsJson.tab);
        set(this.rootStore.commonStore.sec, resultsJson.sec);
        set(this.rootStore.commonStore.doc, resultsJson.doc);
        set(this.rootStore.commonStore.head, resultsJson.head);
        set(this.rootStore.commonStore.fld, resultsJson.fld);

        this.tab = this.rootStore.commonStore.tab;
        this.sec = this.rootStore.commonStore.sec;
        this.doc = this.rootStore.commonStore.doc;
        this.head = this.rootStore.commonStore.head;
        this.fld = this.rootStore.commonStore.fld;

        this.getAllFields(this.rootStore.commonStore.allFields);
        //rulez evenimentele
        //Object.values(this.rootStore.commonStore.allFields)
        this.fld
          .filter((item) => item.event)
          .forEach((item) =>
            runEvent(
              {
                showErrors: this.rootStore.mainStore.errors,
                allFields: this.rootStore.commonStore.allFields,
                filterSursaLista: this.rootStore.commonStore.filterSursaLista,
                refreshDocument: this.refreshDocument,
              },
              item,
              true,
              false
            )
          );
        var step = {};
        // setarea pasului curent pe prima sectiune din tabul editabil
        // daca am parametru de sectiune, atunci ma duc fortat la acea sectiune
        var _currentStep = -1;
        if (resultsJson.section > 0) {
          _currentStep = this.sectiuni.findIndex(
            (x) => x.Id === resultsJson.section && x.Editable
          );
        }
        if (_currentStep === -1) {
          _currentStep = this.sectiuni.findIndex((x) => x.Editable);
          if (_currentStep === -1 && resultsJson.section > 0) {
            _currentStep = this.sectiuni.findIndex(
              (x) => x.Id === resultsJson.section
            );
          }
          if (_currentStep === -1) {
            _currentStep = 0;
          }
        }
        step.countSteps = this.sectiuni.length;
        step.currentStep = _currentStep;
        set(this, step);
        this.setToolbarStepId();

        // determinarea pasului urmator
        var pasiiUrmatori = resultsJson.pasi.filter((x) => x.Urmator === 1);
        //preluarea primului pas disponibil
        this.nextPhase = pasiiUrmatori.length > 0 ? pasiiUrmatori[0] : {};
        this.endJourney = resultsJson.endJourney;
        this.canSave = resultsJson.canSave;
        this.canPass = resultsJson.canPass;
        if (this.canSave) {
          //daca singurul control care are incarcare e Text cu lungime 0 (buton), atunci canSave=False
          if (
            Object.values(this.rootStore.commonStore.allFields).findIndex(
              (item) =>
                item.isVisible &&
                !(item.isReadonly || item.docReadOnly) &&
                !(item.Tip === 0 && item.Lungime === 0)
            ) === -1
          ) {
            this.canSave = false;
          }
        }
        this.template.simulare = {};
        this.template.comisioane = [];
        this.simulare = null;
        this.widget = null;
        /*
        if (this.type == creditTypes.credit) {
          await this.getSimulareWidget();
        }
        else*/ if (this.type == creditTypes.prescoring) {
          await this.getSimulareWidget();
        }
        if (
          resultsJson?.jprop &&
          resultsJson.jprop?.widget &&
          resultsJson?.info
        ) {
          this.widget = jsonParse(resultsJson.info);
        }
      }
    }
    if (results && (results.error || results.r)) {
      //console.log(results, results.error, results.r.msg);
      var m = results.error ?? results.msg;
      //console.log(m);
      var closeHandler = null;
      if (m.startsWith("XOK")) {
        m = m.slice(3);
        closeHandler = () => {
          this.rootStore.mainStore.logout();
        };
      }
      /*Daca mesajul incepe cu ROK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de un refresh de aplicatie*/
      if (m.startsWith("ROK")) {
        m = m.slice(3);
        closeHandler = () => {
          this.getRequest();
        };
      }
      /*Daca mesajul incepe cu GOK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de redirectionare pe pagina de goodbye*/
      if (m.startsWith("GOK")) {
        this.rootStore.mainStore.errors.type = "info";
        this.rootStore.mainStore.errors.header = null;
        this.rootStore.mainStore.errors.className = "retail-dialog-info";
        m = m.slice(3);
        closeHandler = async () => {
          await this.rootStore.mainStore.updateValue({
            preventGoBack: false,
          });
          this.rootStore.mainStore.history.push("/goodbye");
        };
      }
      this.rootStore.mainStore.isWaiting = false;
      this.rootStore.mainStore.errors.open(m, false, closeHandler);
    }
  };

  setToolbarStepId = () => {
    var documents = null;
    if (this.countSteps > 0) {
      documents = this.doc.filter(
        (doc) => this.sectiuni[this.currentStep].Id == doc.sec
      );
      if (documents) {
        //var doc = documents[documents.length - 1];
        var docs = documents.filter((x) => x.Jprop && !!x.Jprop.stepId);
        var doc = docs[docs.length - 1];
        this.rootStore.mainStore.stepId =
          doc && doc.Jprop ? doc.Jprop.stepId : null;
      }
    }
    window.scrollTo(0, 1);
  };

  @action
  doDeactivate = async () => {
    await this.clear();
  };
  /*Colecteaza cimpurile si seteaza proprietatile tuturor cimpurilor*/
  @action
  getAllFields = (_allFields) => {
    var _setupFields = [];
    //Corectie Jprop
    this.doc
      .filter((doc) => doc.tab <= this.currentTab.Id)
      .forEach((doc) => (doc.Jprop = doc.Jprop || {}));

    this.fld
      .filter((fld) => fld.tab <= this.currentTab.Id)
      .forEach((fld) => {
        fld.docReadOnly =
          this.doc.find((doc) => doc.Id == fld.doc)?.Incarcare == 0;
        _allFields[fld.Id] = fld;
        _setupFields.push(fld);
      });

    this.rootStore.commonStore.setupFields(_setupFields, {
      type: this.type,
      uidCerere: this.uidCerere,
    });
    /*refac sectiune.Editable*/
    this.sectiuni.forEach((sec) => {
      //verific daca are documente editabile
      sec.Editable =
        this.doc.findIndex(
          (doc) => doc.sec == sec.Id && doc.Tip === 2 && doc.Incarcare
        ) > -1;
      //daca nu am gasit in documente caut in cimpuri
      if (!sec.Editable) {
        sec.Editable =
          this.fld.findIndex((fld) => fld.sec == sec.Id && !fld.isReadonly) >
          -1;
      }
    });

    /*Setez relatii de master/detail la griduri*/
    //    Object.values(_allFields)
    this.fld
      .filter((d) => d.masterId) //este detail pentru un alt grid
      .forEach((d) => {
        //marchez in master care sunt gridurile detail
        var m = this.rootStore.commonStore.getField(d.masterId);
        d.master = m;
        m.detailIds = m.detailIds || [];
        if (m.detailIds.indexOf(d.Id) == -1) {
          m.detailIds.push(d.Id);
        }
      });
  };

  @action
  rowChecked = (row, item) => {
    row.data.isChecked = row.isChecked;
    var sursa = this.sursaLista(item);
    var value = sursa.data
      .filter((s) => s.isChecked)
      .map((x) => x[sursa.fields.id])
      .toString();
    this.updateValue(item, value);
  };
  @action
  updateStore = (value, key) => {
    if (key) {
      set(this[key], value);
      return;
    }
    set(this, value);
  };

  @action
  updateValue = (item, value) => {
    if (value === undefined) {
      value = null;
    }
    /*trebuie sa permit schimbarea daca value=""*/
    var sValue = "";
    switch (item.Tip) {
      case 0: //textbox
        sValue = value;
        if (item.Lungime > 2000) {
          sValue = sValue?.toLowerCase();
        } else {
          if (item.Lungime > 1000) {
            sValue = sValue?.toUpperCase();
          }
        }
        break;
      case 1: //memo
        sValue = value;
        break;
      case 2: //data
        sValue = value ? moment(value).format("DD/MM/YYYY") : "";
        //sValue =  value ? value : "";
        break;
      case 3: //numeric
        if (typeof value !== "string") {
          sValue = value?.toString();
        } else {
          sValue = value;
        }
        sValue = sValue.replace(/[.]/g, "").replace(",", ".");
        break;
      case 4: //listbox
        sValue = value;
        break;
      case 5: //checkbox
        //sValue = value ? "1" : "0"; //Am eliminat partea asta pentru ca valorile true si false pot fi altceva decat 1 si 0
        sValue = value ?? "0";
        break;
      case 6: //grid
        sValue = value;
        break;
      case 7: //richedit
        sValue = value;
        break;
      default:
        sValue = value;
    }
    if (item.Valoare != sValue) {
      this.rootStore.commonStore.changedFields[item.Id] = sValue;
      item.Valoare = sValue;
      this.rootStore.mainStore.setLogoutTime();
      runEvent(
        {
          showErrors: this.rootStore.mainStore.errors,
          allFields: this.rootStore.commonStore.allFields,
          filterSursaLista: this.rootStore.commonStore.filterSursaLista,
          refreshDocument: this.refreshDocument,
        },
        item
      );
    }
    item.error = null;
  };
  @action
  uploadFile = async (file, document) => {
    this.rootStore.mainStore.isWaiting = true;
    var idDoc = document.Id;
    var resultDocs = await this.rootStore.api.upload(
      "/api/request/upload-file",
      file,
      {
        type: this.type,
        uidCerere: this.uidCerere,
        idDoc: idDoc,
        fileInfo: "",
      }
    );
    //de verificat daca a fost cu succes
    if (document && document.uploadObj) {
      document.uploadObj.clearAll();
    }
    if (resultDocs) {
      if (resultDocs.error || resultDocs.r) {
        var m = resultDocs.error ?? resultDocs.msg;
        if (resultDocs.o && resultDocs.o.clear) {
          this.sectiuni = [];
        }
        var closeHandler = null;
        if (m.startsWith("XOK")) {
          m = m.slice(3);
          closeHandler = () => {
            this.rootStore.mainStore.logout();
          };
        }
        /*Daca mesajul incepe cu ROK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de un refresh de aplicatie*/
        if (m.startsWith("ROK")) {
          m = m.slice(3);
          closeHandler = () => {
            this.getRequest();
          };
        }
        /*Daca mesajul incepe cu GOK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de redirectionare pe pagina de goodbye*/
        if (m.startsWith("GOK")) {
          this.rootStore.mainStore.errors.type = "info";
          this.rootStore.mainStore.errors.header = null;
          this.rootStore.mainStore.errors.className = "retail-dialog-info";
          m = m.slice(3);
          closeHandler = async () => {
            await this.rootStore.mainStore.updateValue({
              preventGoBack: false,
            });
            this.rootStore.mainStore.history.push("/goodbye");
          };
        }
        this.rootStore.mainStore.isWaiting = false;
        this.rootStore.mainStore.errors.open(m, false, closeHandler);
      } else {
        //succes
        if (resultDocs.o.doc && resultDocs.o.doc.length !== 0) {
          //var sec = this.sectiuni[this.currentStep];
          resultDocs.o.doc.forEach((newDoc) => {
            var doc = this.doc.find(
              (x) => x.Id === newDoc.Id /*&& x.sec === sec.Id*/
            );
            if (doc) {
              this.rootStore.commonStore.mergeDocs(doc, newDoc, {
                type: this.type,
                uidCerere: this.uidCerere,
                data: resultDocs.o,
              });
              console.log(this.doc);
              if (newDoc.Tip === 2) {
                set(doc, newDoc); //necesar pentru modulul de incarcare CI.
              }
            }
          });
        }
      }
    } else {
      var errors = [
        "A aparut o eroare la incarcare fisierului!",
        " Incercati din nou!",
      ];
      this.rootStore.mainStore.errors.open(errors);
    }
    this.rootStore.mainStore.isWaiting = false;
  };
  @action
  updateFile = async (item, document) => {
    this.rootStore.mainStore.isWaiting = true;
    var idUpload = item.Id;
    var fileInfo = item.Info;
    var idDoc = document.Id;
    /*var result =*/ await this.rootStore.api.post("/api/request/update-file", {
      type: this.type,
      uidCerere: this.uidCerere,
      idDoc: idDoc,
      idUpload: idUpload,
      fileInfo: fileInfo,
    });

    //de verificat daca a fost cu succes
    var resultDocs = await this.refreshDocument({ document: document });
    if (!resultDocs) {
      this.rootStore.mainStore.errors.open(
        "A aparut o eroare la actualizarea informatiilor despre fisier! Incercati din nou!"
      );
    }
    this.rootStore.mainStore.isWaiting = false;
  };
  @action
  deleteFile = async (item, document) => {
    this.rootStore.mainStore.isWaiting = true;
    var idUpload = item.Id;
    var idDoc = document.Id;
    /*var result =*/
    await this.rootStore.api.post("/api/request/delete-file", {
      type: this.type,
      uidCerere: this.uidCerere,
      idDoc: idDoc,
      idUpload: idUpload,
    });

    //de verificat daca a fost cu succes
    var resultDocs = await this.refreshDocument({ document: document });
    if (!resultDocs) {
      this.rootStore.mainStore.errors.open(
        "A aparut o eroare la stergerea fisierului! Incercati din nou!"
      );
    }
    this.rootStore.mainStore.isWaiting = false;
  };

  @action
  downloadFile = async (doc) => {
    //var result = await this.rootStore.api.downloadFile("/api/request/download-file", {type: this.type, uidCerere: this.uidCerere, id: doc.Id}, doc.NumeFisier);
    await this.rootStore.api.downloadFile(
      "/api/request/download-file",
      { type: this.type, uidCerere: this.uidCerere, id: doc.Id },
      doc.NumeFisier
    );
  };
  @action
  getPrintFile = async (parameters) => {
    this.rootStore.mainStore.updateValue({
      forceWaiting: true,
      isWaiting: true,
    });
    this.template.file = null;
    var params = parameters || {};
    params.uidCerere = this.uidCerere;
    params.type = this.type;
    var results = await this.rootStore.api.post(
      "/api/request/preview-print-file",
      params
    );

    //		await this.rootStore.api.downloadFile("/api/request/download-print-file", {type: this.type, uidCerere: this.uidCerere, id: doc.Id_doc});
    this.rootStore.mainStore.lastCall = moment();

    if (results?.error) {
      this.rootStore.mainStore.updateValue({
        forceWaiting: false,
        isWaiting: false,
      });
      this.rootStore.mainStore.errors.open(results.error);
      return;
    }
    if (results?.NumeFisier) {
      //var fileType = results.NumeFisier.split(".").pop().toLowerCase();
      //var file = `data:*;base64,${results.Content}`;
      //var file = `data:application/pdf;base64,${results.Content}`;
      var file = { data: results.Content, fileName: results.NumeFisier };
      /*
      var docs = [
        {
          uri: file,
          fileType: fileType,
          //content: results.Content,
        },
      ];*/

      this.template.file = file;
      this.rootStore.mainStore.isWaiting = false;
      //return file;
    }
    this.rootStore.mainStore.isWaiting = false;
  };
  @action
  openPrintParameters = async (doc) => {
    this.rootStore.mainStore.isWaiting = true;
    //prima data cer parametrii de generare raport
    var results = await this.rootStore.api.post(
      "/api/request/parameters-print-file",
      {
        type: this.type,
        uidCerere: this.uidCerere,
        id: doc.Id_doc,
        addFiles: doc.addFiles,
      }
    );
    if (results && !results.error && results != "401") {
      var parameters = {
        type: this.type,
        uidCerere: this.uidCerere,
        id: results.Id || doc.Id_doc,
        addFiles: doc.addFiles,
      };
      /*Deocamdata ramane asa cum e*/
      if (results.Headers.length == 0) {
        //nu exista parametri pentru acest raport
        this.downloadPrintFile(parameters);
      } else {
        this.rootStore.mainStore.isWaiting = false;
        parameters.param = {};
        /*
        results.Headers.forEach((header) =>
          header.Cimpuri.forEach((cimp) => {
            this.rootStore.commonStore.setupField(cimp, {
              type: this.type,
              uidCerere: this.uidCerere,
            });
          })
        );
        */
        this.rootStore.commonStore.setPrintParameters(results.Headers, {
          type: this.type,
          uidCerere: this.uidCerere,
        });

        this.rootStore.mainStore.printParameters.open({
          parametrii: parameters,
          headers: results.Headers,
          print: this.downloadPrintFile,
          showError: this.rootStore.mainStore.errors.open,
        });
      }
    } else {
      this.rootStore.mainStore.isWaiting = false;
      if (results.error) {
        this.rootStore.mainStore.errors.open(results.error);
      }
    }
  };

  @action
  downloadPrintFile = async (parameters) => {
    this.rootStore.mainStore.isWaiting = true;
    await this.rootStore.api.downloadFile(
      "/api/request/download-print-file",
      parameters
    );
    //		await this.rootStore.api.downloadFile("/api/request/download-print-file", {type: this.type, uidCerere: this.uidCerere, id: doc.Id_doc});
    this.rootStore.mainStore.isWaiting = false;
  };

  /*REMOVE
  @action
  refreshDocument = async ({ document, sectiune }) => {
    //this.rootStore.mainStore.isWaiting = true;
    var idDoc = document
      ? typeof document === "object"
        ? document.Id
        : document
      : "";
    var idSectiune = sectiune ? sectiune.Id : -1;
    var results = await this.rootStore.api.fetch("/api/request/refresh", {
      type: this.type,
      uidCerere: this.uidCerere,
      idDoc: idDoc,
      idSectiune: idSectiune,
    });
    if (results && results !== "401") {
      var resultsDoc = []; //results.o;
      if (results.error || results.r) {
        //console.log(results, results.error, results.r.msg);
        var m = results.error ?? results.msg;
        //console.log(m);
        var closeHandler = null;
        if (m.startsWith("XOK")) {
          m = m.slice(3);
          closeHandler = () => {
            this.rootStore.mainStore.logout();
          };
        }
        //Daca mesajul incepe cu ROK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de un refresh de aplicatie
        if (m.startsWith("ROK")) {
          m = m.slice(3);
          closeHandler = () => {
            this.getRequest();
          };
        }
        //Daca mesajul incepe cu GOK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de redirectionare pe pagina de goodbye
        if (m.startsWith("GOK")) {
          this.rootStore.mainStore.errors.type = "info";
          this.rootStore.mainStore.errors.header = null;
          this.rootStore.mainStore.errors.className = "retail-dialog-info";
          m = m.slice(3);
          closeHandler = async () => {
            await this.rootStore.mainStore.updateValue({
              preventGoBack: false,
            });
            this.rootStore.mainStore.history.push("/goodbye");
          };
        }
        this.rootStore.mainStore.isWaiting = false;
        this.rootStore.mainStore.errors.open(m, false, closeHandler);
      } else {
        //succes
        resultsDoc = await renameObjectKeys(results.o);
        if (resultsDoc.doc && resultsDoc.doc.length !== 0) {
          this.rootStore.commonStore.warning = {};
          if (results.msg?.startsWith("OK|")) {
            var w = {};
            var mx = results.msg.slice(3);
            [w.text, w.title, w.severity] = mx.split("|");
            set(this.rootStore.commonStore.warning, w);
          }
          //          resultsDoc = await renameObjectKeys(results.o);
          var sec = this.sectiuni[this.currentStep];
          resultsDoc.doc.forEach((newDoc) => {
            var doc =  this.doc.find(x => x.Id === newDoc.Id && x.sec===sec?.Id);
            if (doc) {
              this.rootStore.commonStore.mergeDocs(doc, newDoc, {
                type: this.type,
                uidCerere: this.uidCerere,
              });
              if (newDoc.Tip === 3) {
                doc = newDoc;
              } else {
                set(doc, newDoc);
              }
            }
          });
        }
        if (resultsDoc.pasi) {
          this.nextPhase = resultsDoc.pasi.length > 0 ? resultsDoc.pasi[0] : {};
          this.endJourney = resultsDoc.endJourney;
          this.canPass = resultsDoc.canPass;
        }
        //if (resultsDoc?.jprop && resultsDoc.jprop?.widget && resultsDoc?.info) {
        if (resultsDoc?.info) {
          this.widget = jsonParse(resultsDoc.info);
        }
      }
    }
    return resultsDoc;
  };

  */
  @action
  refreshDocument = async ({ document, sectiune }) => {
    //this.rootStore.mainStore.isWaiting = true;
    var idDoc = document
      ? typeof document === "object"
        ? document.Id
        : document
      : "";
    var idSectiune = sectiune ? sectiune.Id : -1;
    console.log(
      "start merging",
      idSectiune,
      this.fld.filter((f) => f.sec == idSectiune),
      this.rootStore.commonStore.allFields
    );
    var results = await this.rootStore.api.fetch("/api/request/refresh-new", {
      type: this.type,
      uidCerere: this.uidCerere,
      idDoc: idDoc,
      idSectiune: idSectiune,
    });
    if (results && results !== "401") {
      var resultsDoc = []; //results.o;
      if (results.error || results.r) {
        //console.log(results, results.error, results.r.msg);
        var m = results.error ?? results.msg;
        //console.log(m);
        var closeHandler = null;
        if (m.startsWith("XOK")) {
          m = m.slice(3);
          closeHandler = () => {
            this.rootStore.mainStore.logout();
          };
        }
        /*Daca mesajul incepe cu ROK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de un refresh de aplicatie*/
        if (m.startsWith("ROK")) {
          m = m.slice(3);
          closeHandler = () => {
            this.getRequest();
          };
        }
        /*Daca mesajul incepe cu GOK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de redirectionare pe pagina de goodbye*/
        if (m.startsWith("GOK")) {
          this.rootStore.mainStore.errors.type = "info";
          this.rootStore.mainStore.errors.header = null;
          this.rootStore.mainStore.errors.className = "retail-dialog-info";
          m = m.slice(3);
          closeHandler = async () => {
            await this.rootStore.mainStore.updateValue({
              preventGoBack: false,
            });
            this.rootStore.mainStore.history.push("/goodbye");
          };
        }
        this.rootStore.mainStore.isWaiting = false;
        this.rootStore.mainStore.errors.open(m, false, closeHandler);
      } else {
        //succes
        resultsDoc = await renameObjectKeys(results.o);
        if (resultsDoc.doc?.length !== 0) {
          this.rootStore.commonStore.warning = {};
          if (results.msg?.startsWith("OK|")) {
            var w = {};
            var mx = results.msg.slice(3);
            [w.text, w.title, w.severity] = mx.split("|");
            set(this.rootStore.commonStore.warning, w);
          }
          /*Pentru ducomentele aduse de request verific daca s-a modificat ceva*/
          resultsDoc.doc.forEach((newDoc) => {
            /*Am gasit documentul, fac merge la el*/
            var oldDoc = this.doc.find((x) => x.Id === newDoc.Id);
            /*mergeDocs - daca oldDoc nu exista atunci newDoc se adauga, altfel se face combinatie intre ele*/
            this.rootStore.commonStore.mergeDocs(oldDoc, newDoc, {
              type: this.type,
              uidCerere: this.uidCerere,
              data: resultsDoc,
            });
          });
          /*Pentru toate documentele pe care le am in sectiune curenta verific daca s-a sters ceva
          Nu ar trebui sa mai apara in resultsDoc.doc
          mergeDocs - daca newDoc nu exista atunci oldDoc se sterge
          */
          this.rootStore.commonStore.doc
            .filter(
              (x) =>
                x.sec == idSectiune &&
                resultsDoc.doc.findIndex((n) => n.Id === x.Id) === -1
            )
            .forEach((oldDoc) => {
              this.rootStore.commonStore.mergeDocs(oldDoc, null, {
                type: this.type,
                uidCerere: this.uidCerere,
              });
            });
        }
        if (resultsDoc.pasi) {
          this.nextPhase = resultsDoc.pasi.length > 0 ? resultsDoc.pasi[0] : {};
          this.endJourney = resultsDoc.endJourney;
          this.canPass = resultsDoc.canPass;
        }
        //if (resultsDoc?.jprop && resultsDoc.jprop?.widget && resultsDoc?.info) {
        if (resultsDoc?.info) {
          this.widget = jsonParse(resultsDoc.info);
        }
      }
    }
    console.log(
      "end merging",
      idSectiune,
      this.fld.filter((f) => f.sec == idSectiune),
      this.rootStore.commonStore.allFields
    );
    return resultsDoc;
  };
  @action
  previewFile = async (doc) => {
    this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.fetch(
      "/api/request/preview-file",
      { type: this.type, uidCerere: this.uidCerere, id: doc.Id },
      doc.NumeFisier
    );
    if (results.NumeFisier) {
      var file, content;
      var fileType = doc.NumeFisier.split(".").pop().toLowerCase();

      if (fileType == "xml") {
        content = Buffer.from(results.Content, "base64").toString("utf-8");
      } else {
        file = `data:*;base64,${results.Content}`;
      }
      var preview = {
        name: doc.NumeFisier,
        file: file,
        fileType: fileType,
        content: content,
        isOpen: true,
      };
      this.rootStore.mainStore.isWaiting = false;
      set(this.preview, preview);
    }
  };

  @action
  showFile = async (doc) => {
    var results = await this.getFile(doc);
    if (results && results.NumeFisier) {
      var file = `data:*;base64,${results.Content}`;
      return file;
      //set(this.template, {file});
    }
  };
  @action
  closePreviewFile = async () => {
    set(this.preview, modelPreview);
  };

  getFile = async (doc) => {
    var results = await this.rootStore.api.fetch(
      "/api/request/preview-file",
      { type: this.type, uidCerere: this.uidCerere, id: doc.Id },
      doc.NumeFisier
    );
    return results;
  };
  @action
  nextStep = (auto) => {
    if (
      this.countSteps !== this.currentStep + 1 ||
      !(this.canPass || this.canSave)
    ) {
      this.goStep(this.currentStep + 1, auto);
      return;
    }
    if (
      this.countSteps === this.currentStep + 1 &&
      (this.canPass || this.canSave) &&
      (this.endJourney || !this.nextPhase.Denumire)
    ) {
      this.save(this.currentStep, true, true, auto);
    } else {
      this.save(this.currentStep, true, false, auto);
    }
  };
  @action
  goStep = async (step, auto) => {
    if (this.goPrev) {
      const stop = await this.goPrev();
      this.goPrev = null;
      if (stop) return;
    }
    this.rootStore.mainStore.isWaiting = true;
    var needsSave = this.sectiuni[this.currentStep].Editable;
    //daca sesiunea este editabila si am modificari fac salvarea datelor
    if (needsSave) {
      const ok = await this.save(step);
      if (!ok) {
        this.rootStore.mainStore.isWaiting = false;
        return;
      }
    }
    this.currentStep = step;
    //daca nu am facut save, nu are rost sa fac refresh;
    if (!needsSave) {
      this.rootStore.mainStore.isWaiting = false;
      this.setToolbarStepId();
      return;
    }
    this.rootStore.mainStore.isWaiting = true;
    var sectiune = this.sectiuni[this.currentStep];
    //fac refresh la document
    var result = await this.refreshDocument({ sectiune: sectiune });
    if (result && !result.error && !result.r) {
      //rulez evenimentele pentru cimpurile din documentul actualizat;
      this.fld
        .filter((f) => f.sec == sectiune.Id && f.event)
        .forEach((item) =>
          runEvent(
            {
              showErrors: this.rootStore.mainStore.errors,
              allFields: this.rootStore.commonStore.allFields,
              filterSursaLista: this.rootStore.commonStore.filterSursaLista,
              refreshDocument: this.refreshDocument,
            },
            item,
            true,
            false
          )
        );

      await this.setToolbarStepId();
      this.rootStore.mainStore.isWaiting = false;
      //this.rootStore.mainStore.updateValue({titleName: `Cerere - ${sectiune.Denumire}`});
    } else {
      this.rootStore.mainStore.isWaiting = false;
      if (!this.rootStore.mainStore.errors.isOpen) {
        //daca nu exista deja eroare afisata de refreshDocument, pun o eroare generica
        this.rootStore.mainStore.errors.open("A aparut o eroare!");
      } else {
        //cel mai probabil e o eroare de acces, asa ca o sa curat imaginea
        this.canPass = false;
        this.canSave = false;
        this.clear();
      }
    }
  };

  @action
  save = async (step, isEnd, isEndJourney, auto) => {
    this.param.latitude = this.rootStore.mainStore.position.latitude;
    this.param.longitude = this.rootStore.mainStore.position.longitude;

    /* step - sectiunea in care sunt
     * isEnd - e ultima sectiune si am dat clic;
     * isEndJourney - true: inchid sesiunea, false - salvez cererea cu pasNou = -1 dar fac un refresh la cerere
     */
    var closeHandler = null;
    this.rootStore.mainStore.isWaiting = true;
    var idStep = this.sectiuni[this.currentStep].Id;
    var idNewStep = this.sectiuni[step].Id;
    this.param["idStep"] = `${idStep}`;
    this.param["idNewStep"] = `${idNewStep}`;

    /* Daca sectiunea de pe pasul curent nu are nimic editabil, atunci nu e nevoie sa fac salvare
     * Salvez daca sunt pe ultima sectiune
     * sau sectiunea curenta e editabila
     * sau am vreo modificare
     */
    var needsSave =
      isEnd ||
      this.sectiuni[this.currentStep].Editable ||
      Object.keys(this.rootStore.commonStore.changedFields).length !== 0 ||
      this.changedTabels.length !== 0 ||
      auto;
    //daca sunt tabele modificate construiesc xml-ul cu liniiile acestora
    if (!isEnd && !needsSave) {
      if (isEnd) {
        this.dialog.url = null; //sa vedem daca il redirectam undeva
        this.dialog.message = "Documentul a fost salvat cu succes!";
        this.dialog.isOpen = true;
      } else {
        this.currentStep = step;
      }
      this.rootStore.mainStore.isWaiting = false;
      return true;
    }
    //daca sunt tabele modificate construiesc xml-ul cu liniile acestora
    this.rootStore.commonStore.saveDataTable(this.changedTabels);
    this.rootStore.mainStore.url = window.location.pathname;
    this.param["auto"] = auto;
    var results = await this.rootStore.api.post("/api/request/save", {
      type: this.type,
      uidCerere: this.uidCerere,
      idPas: this.idPas,
      fld: this.rootStore.commonStore.changedFields,
      param: this.param,
      //pasNou: isEnd ? -1 : 0,
      pasNou: isEndJourney ? -2 : isEnd ? -1 : 0,
      aft: this.aft,
    });
    this.param = {};

    if (!results || (results && results.r !== 0)) {
      var errors = [];
      if (!results && results !== "401") {
        errors.push("Unknown network error");
        console.log("Save - Unknown Network error", results);
      } else {
        if (results.msg) {
          var m = results.msg;
          /*Daca mesajul incepe cu XOK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de un logout*/
          if (m.startsWith("XOK")) {
            m = m.slice(3);
            closeHandler = () => {
              this.rootStore.mainStore.logout();
            };
          }
          /*Daca mesajul incepe cu ROK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de un refresh de aplicatie*/
          if (m.startsWith("ROK")) {
            m = m.slice(3);
            closeHandler = () => {
              this.getRequest();
            };
          }
          /*Daca mesajul incepe cu ROK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de un refresh de aplicatie*/
          if (m.startsWith("MOK")) {
            m = m.slice(3);
            closeHandler = () => {
              return;
            };
          }
          /*Daca mesajul incepe cu GOK - e un caz special in care vreau sa transmit o eroare dar apoi e nevoie de redirectionare pe pagina de goodbye*/
          if (m.startsWith("GOK")) {
            this.rootStore.mainStore.errors.type = "info";
            this.rootStore.mainStore.errors.header = null;
            this.rootStore.mainStore.errors.className = "retail-dialog-info";
            m = m.slice(3);
            closeHandler = async () => {
              await this.rootStore.mainStore.updateValue({
                preventGoBack: false,
              });
              this.rootStore.mainStore.history.push("/goodbye");
            };
          }

          errors.push(m);
        }
        if (results.error) {
          errors.push(results.error);
        }
        if (results.o && results.o.o) {
          var _allFields = this.rootStore.commonStore.allFields;
          results.o.o.forEach((x) => {
            const tip = (x.tip || "").toLowerCase();
            var ctrl = tip == "fld" ? _allFields[x.id] : null;
            ctrl =
              tip == "doc"
                ? this.doc.find((d) => d.Id === x.id && d.Tip == 2)
                : ctrl;

            errors.push({ msg: x.eroare, ctrl: ctrl });
          });
        }
      }
      this.rootStore.mainStore.isWaiting = false;
      this.rootStore.mainStore.errors.open(errors, false, closeHandler);
      return false;
    }
    if (results && results.r === 0) {
      this.currentStep = step;
      this.rootStore.commonStore.changedFields = {};
      var [message, title, className] = (results.msg || "").split("|");
      if (isEndJourney) {
        if (className === "nomsg") {
          this.dialog.url = results.o.url;
          this.close(this.rootStore.mainStore.history.push);
        } else {
          this.dialog.header = title ?? this.dialog.header;
          this.dialog.url = results.o.url;
          this.dialog.message =
            message ?? "Documentul a fost salvat cu succes!";
          this.dialog.className = className;
          this.dialog.isOpen = true;
        }
      } else {
        if (isEnd) {
          await this.getRequest();
        }
      }
    }
    this.rootStore.mainStore.isWaiting = false;
    return true;
  };

  @action
  close = async (history) => {
    await this.rootStore.mainStore.updateValue({ preventGoBack: false });
    var url = this.dialog.url;
    set(this.dialog, modelDialog);
    var msgHtml =
      url && url.startsWith("msgHtml:") && url.replace("msgHtml:", "");
    if (url && !msgHtml) {
      var externalUrl =
        url.startsWith("externalUrl:") && url.replace("externalUrl:", "");
      if (externalUrl) {
        await this.rootStore.mainStore.logout({ isOut: true });
        window.location.href = externalUrl;
        return;
      }
      var localUrl =
        url.startsWith("localUrl:") && url.replace("localUrl:", "");
      if (localUrl) {
        history(`/${localUrl}`);
        return;
      }
    } else {
      if (this.rootStore.mainStore.loginView === "username") {
        await this.rootStore.mainStore.logout({ url: url });
        return;
      }
      this.rootStore.mainStore.msgHtml = msgHtml;
      if (history) {
        history("/goodbye");
      }
    }
  };

  clear = () => {
    this.rootStore.mainStore.info = null;
    set(this, modelShape);
  };

  //actiunea de adaugare a unei linii in tabel
  @action
  addTableRow = (table) => {
    if (table.limit > 0) {
      var rc = table.masterId
        ? table.data.rows.filter((r) => table.isVisibleRow(r)).length
        : table.data.rows.length;
      if (table.limit <= rc) {
        this.rootStore.mainStore.errors.open(
          `Nu se poate adauga. Exista o limita de ${table.limit} inregistrari.`
        );
        return false;
      }
    }
    table.selectedRow = -1;
    var newRow = { _rowid: table._rowid };
    table._rowid++;
    var autoinc = table.data.columns.find((col) => col.autoinc == 1);
    var newId = 0;
    if (autoinc) {
      autoinc = autoinc.key;
      newId = Math.max(
        ...table.data.rows.map((r) => {
          return r[autoinc];
        })
      );
      if (newId === -Infinity) {
        newId = 0;
      }
      newId++;
      newRow[autoinc] = newId;
    }
    this.editTable.newId = newId;

    /*Valori default*/
    table.data.columns.forEach((col) => {
      if (col.defaultValue != null) {
        newRow[col.key] = col.defaultValue;
      }
    });
    if (table.masterId) {
      var m = this.rootStore.commonStore.allFields[table.masterId];
      var ix = m.selectedRow;
      if (ix == -1) {
        //nu e nici o linie selectata in master
        if (m.data.rows.length > 0) {
          ix = 0; //iau date din prima linie
        } else {
          //nu exista inregistrari in master, nu afisez nimic
          return false;
        }
      }
      var mr = m.data.rows[ix];
      var df = table.detailFields;
      table.masterFields.forEach((mField, index) => {
        // adaug valorile din master
        newRow[df[index]] = mr[mField];
      });
    }
    table.data.row = newRow;
    return true;
  };

  //actiune de update cand se modifica o celula a tabelului
  @action
  updateRow = (row, tip, key, value, tableId) => {
    this.editDialog.error = null;
    if (value === undefined) {
      value = null;
    }
    var sValue = "";
    switch (tip) {
      case "t": //textbox
        sValue = value;
        break;
      case "d": //data
        sValue = value ? moment(value).format("DD/MM/YYYY") : "";
        break;
      case "n": //numeric
        sValue = value.replace(/[.]/g, "").replace(",", ".");
        break;
      case "l": //listbox
        sValue = value;
        break;
      case "s": //listbox
        sValue = value;
        break;
      case "c": //checkbox
        sValue = value ? "1" : "0";
        break;
      default:
        sValue = value;
    }
    if (row[key] !== sValue) {
      row[key] = sValue;
      var item = this.rootStore.commonStore.getField(tableId);
      var col = item.data.columns.find((col) => col.key === key);
      runEvent(
        {
          showErrors: this.rootStore.mainStore.errors,
          allFields: this.rootStore.commonStore.allFields,
          filterSursaLista: this.rootStore.commonStore.filterSursaLista,
        },
        col
      );
      if ((tip == "l" || tip == "s") && col.headerSelector >= 0) {
        item.data.selectedHeader = getValueKey(
          getValueOfList(col.lista, sValue),
          col.headerSelector
        );
      }
    }
  };

  addToChangedTables = (tbl) => {
    var _allFields = this.rootStore.commonStore.allFields;
    var changedTable = this.changedTabels.find((x) => x === tbl.Id);
    if (!changedTable) {
      this.changedTabels.push(tbl.Id);
      tbl.Caption = "*" + tbl.Caption;
      //adaug si tabelele de detail daca am modificat masterul
      if (tbl.detailIds) {
        tbl.detailIds.forEach((d) => this.addToChangedTables(_allFields[d]));
      }
    }
  };
  //actiune de salvare pentru o linie a tabelului
  @action
  saveRow = (validate) => {
    if (validate) {
      var error = validate();
      if (error) {
        this.editDialog.error = error;
        return;
      }
    }

    var editRow = this.editTable.table.data.row;
    var rows = this.editTable.table.data.rows;
    var row = rows.find((r) => r._rowid === editRow._rowid);
    if (!row) {
      this.editTable.table.data.rows.push(editRow);
    } else {
      set(row, editRow);
    }
    this.addToChangedTables(this.editTable.table);
    this.editTable.newId = null;
    this.closeEditDialog();
  };
  @action
  setObservableObj = (row, editRow) => {
    set(row, editRow);
  };
  // actiune de stergere a unei linii dn tabel
  // eventual trebuie pus un pas intermediar de confirmare a stergerii liniei
  @action
  deleteTableRow = (table, rowid) => {
    var detailIds = table.detailIds || [];
    var index = table.data.rows.findIndex((r) => r._rowid === rowid);
    if (index === -1) {
      return;
    }
    var mr = table.data.rows[index];
    if (!mr) {
      return;
    }
    detailIds.forEach((id) => {
      var dTable = this.rootStore.commonStore.allFields[id];
      var dRow = dTable.data.rows.find((row) => {
        return dTable.masterFields.every((mField, index) => {
          // fiecare cimp din master e egal cu cimpul corespondent din detail
          return (
            mr[mField].toString().toUpperCase() ==
            row[dTable.detailFields[index]].toString().toUpperCase()
          );
        });
      });
      if (dRow) {
        this.deleteTableRow(dTable, dRow._rowid);
      }
    });
    table.data.rows.splice(index, 1);
    this.addToChangedTables(table);
  };

  //deschiderea formei de editare pentru o linie din tabel
  @action
  openEditTable = ({ table, child, row, saveCaption, header, validate }) => {
    if (!row && table.data.hasDetails) {
      //caut cimpul filtrat. daca nu am nimic, adaug o linie noua;
      row = table.data.rows.find((r) => table.isVisibleRow(r));
      table.selectedRow = 0;
      if (!row) {
        if (!this.addTableRow(table)) {
          return;
        }
        table.selectedRow = -1;
      }
    }
    if (row) {
      table.data.row = JSON.parse(JSON.stringify(row)); //deep copy
    }
    this.editTable.table = table;
    this.editDialog.validate = validate;
    this.editDialog.child = child;
    this.editDialog.isOpen = true;
    this.editDialog.saveCaption = saveCaption;
    this.editDialog.save = this.saveRow;
    this.editDialog.header = header ? header : this.editDialog.header;
    /*Initializare Selected Header*/
    var cHeader = table.data.columns.find((c) => c.headerSelector);
    if (cHeader) {
      table.data.selectedHeader = getValueKey(
        getValueOfList(cHeader.lista, row[cHeader.key]),
        cHeader.headerSelector
      );
    } else {
      table.data.selectedHeader = null;
    }
    //rulare evenimente la afisare macheta
    table.data.columns
      .filter((c) => c.event && c.isVisibleEdit && !c.isMasterCol)
      .forEach((col) => {
        runEvent(
          {
            showErrors: this.rootStore.mainStore.errors,
            allFields: this.rootStore.commonStore.allFields,
            filterSursaLista: this.rootStore.commonStore.filterSursaLista,
          },
          col,
          true
        );
      });
  };

  //actiunea de salvare la inchiderea dialogului de editare deschis (de exemplu editare tabel)
  //actiune poate fi folosita si pentru alte tipuri de editare in dialog
  @action
  saveEditDialog = () => {
    if (this.editDialog.save) {
      this.editDialog.save();
    }
  };

  //actiunea de inchidere a dialogului de editare
  @action
  closeEditDialog = () => {
    set(this.editDialog, modelEditDialog);
  };

  @action
  stopVideoCall = () => {
    this.closeVideo({ event: "stop" });
    this.dialog.message = "Sesiunea video s-a incheiat";
    this.dialog.header = "Status sesiune video";
    //pt test
    //this.dialog.url = this.rootStore.mainStore.urlFlow;

    this.dialog.isOpen = true;
  };

  @action
  startVideoCall = (p) => {
    p = p || {};

    // mentine sesiunea live atata timp cat este deschisa fereastra
    this.keepAlive = setInterval(() => {
      //console.log('--------- keepAlive --------- ', moment());
      this.rootStore.mainStore.lastCall = moment();
      this.rootStore.mainStore.setLogoutTime();
    }, 10000); //10 secunde

    var url = this.prepareQueryString(p);
    window.removeEventListener("message", this.videoEventListener);
    this.videoCall.src = url;
    this.videoCall.header = "Video Call";
    this.videoCall.isOpen = true;

    window.addEventListener("message", this.videoEventListener);
    setTimeout(this.videoPost, 200, url);
    //    startVideoCall(p);
  };

  prepareQueryString = (p) => {
    var json = this.SetupVideoParams(p);
    var query = new URLSearchParams({
      lang: "ro",
      id_organization: p.org || "PTRBNK",
      id_process: p.prc,
      json: JSON.stringify(json),
    });
    return p.src + "?" + query.toString();
  };

  videoPost = (url) => {
    this.rootStore.api.post("/api/common/liveid", {
      event: "init",
      otherParams: {
        type: this.type,
        uid_cerere: this.uidCerere,
      },
      data: url,
    });
  };

  closeVideo = ({ status, event }) => {
    //inchide fereastra de editare
    this.videoCall.isOpen = false;
    // face clear la functia care tine live sesiunea
    clearInterval(this.keepAlive);

    this.rootStore.api.post("/api/common/liveid", {
      message: { closeStatus: status },
      event: event + "/u",
      otherParams: {
        type: this.type,
        uid_cerere: this.uidCerere,
      },
    });
  };

  sendVideoLog = (message) => {
    var err = message.error;
    try {
      err = JSON.stringify(message);
    } catch {}
    this.rootStore.api.post("/api/common/liveid", {
      //message: { closeStatus: message.event },
      event: message.event,
      data: err,
      otherParams: {
        type: this.type,
        uid_cerere: this.uidCerere,
      },
    });
  };

  videoEventListener = (event) => {
    var d = event.data;
    console.log("video event", d);
    this.sendVideoLog(d);
    /*TODO evenimentul trebuie trimisi in backend*/
    //this.dialog.header = "Status sesiune video";
    //this.rootStore.mainStore.isWaiting = true;
    switch (d.event) {
      case "WEBRTCTEST_CALLNOTANSWERED":
        if (d.retry) {
          //clientul a reincercat apelul
          //aici as putea sa numar incercarile
        } else {
          this.closeVideo({ status: "", event: d.event });
          //this.dialog.message = "Nu exista operator disponibil!";
          //this.dialog.isOpen = true;
        }
        break;
      //WEBRTCTEST_OK
      //event: "WEBRTCTEST_KO", error: "Dacă nu puteți auzi fișierele audio sau nu puteți …wserul dvs.↵↵După aceea efectuați din nou testul."}
      case "close":
        switch (d.message.closeStatus) {
          case "N": //process closed correctly
            this.closeVideo({ status: d.message.closeStatus, event: d.event });
            //this.dialog.message = "Sesiunea video s-a incheiat!";
            //this.dialog.isOpen = true;
            //this.dialog.url = this.rootStore.mainStore.urlFlow;
            this.getRequest(); //refresh page
            break;
          case "S": //process suspended by operator
            this.closeVideo({ status: d.message.closeStatus, event: d.event });
            //this.dialog.message = "Sesiunea video s-a incheiat!";
            //this.dialog.isOpen = true;
            //this.getRequest(); //refresh page
            break;
          case "A": //process aborted by operator due to errors
            this.closeVideo({ status: d.message.closeStatus, event: d.event });
            //this.dialog.message = "Sesiunea video s-a incheiat!";
            //this.dialog.isOpen = true;
            //this.dialog.url = this.rootStore.mainStore.urlFlow;
            this.getRequest(); //refresh page
            break;
          case "K": //process sets to KO by system in presence of unmanageable errors
            this.closeVideo({ status: d.message.closeStatus, event: d.event });
            //this.dialog.message = "Sesiunea video s-a incheiat!";
            //this.dialog.isOpen = true;
            //this.getRequest(); //refresh page
            break;
          default:
            break;
        }
        break;
      case "error":
        this.closeVideo({ event: d.event });
        //this.dialog.message = "Sesiunea video s-a incheiat!";
        //this.dialog.isOpen = true;
        break;
      default:
        console.log("*********** video event neprelucrat ***************", d);
        break;
    }
    //this.rootStore.mainStore.isWaiting = false;
  };

  SetupVideoParams(p) {
    const _allFields = this.rootStore.commonStore.allFields;
    var nume = {
      full:
        typeof p.num === "number" ? (_allFields[p.num] || {}).Valoare : p.num,
    };
    var i = nume.full.indexOf(" ");
    nume.name = i > -1 ? nume.full.substring(i + 1) : "";
    nume.surname = i > -1 ? nume.full.substring(0, i) : nume.full;
    var email =
      typeof p.eml === "number"
        ? (_allFields[(p.eml || {}).fld] || {}).Valoare
        : p.eml;

    var json = {
      data: {},
      lang: "ro",
      restore_email: email,
      liveid_email: email,
      liveid_name: nume.name,
      liveid_surname: nume.surname,
      liveid_servercallback: {
        url: p.url,
        otherParams: {
          uid_cerere: this.uidCerere,
          type: this.type,
        },
      },
      opInfo: this.type,
      skipAVrecTest: true,
      disableRTCTest: p.disableRTCTest,
    };
    p.fld = p.fld || [];

    p.fld.forEach((f) => {
      json.data[f.id] = {
        label: f.label,
        datatype: f.datatype || "string",
        required: !!f.required,
        readonly: f.readonly ?? true,
        value:
          typeof f.value === "string"
            ? f.value
            : f.value
                .map((m) =>
                  typeof m == "string" ? m : (_allFields[m] || {}).displayText()
                )
                .join(""),
      };
    });
    return json;
  }
  @action
  getProducts = async () => {
    //this.rootStore.mainStore.isWaiting = true;

    // de adus nomenclatoare
    var results = await this.rootStore.api.fetch(
      "/api/common/produse-creditare",
      {
        type: this.type,
        voucher: this.rootStore.mainStore.voucher?.value,
        uidCerere: this.uidCerere,
      }
    );
    if (results && !results.error && results != "401") {
      this.template.produse = results;
    }
    //this.rootStore.mainStore.isWaiting = false;
  };
  @action
  lendingActivate = async () => {
    this.rootStore.mainStore.isWaiting = true;
    this.template.simulare = {};
    this.template.comisioane = [];
    await this.getSimulareWidget();
    //this.simulare = null;
    await this.getProducts();
    if (this.template.produse[0].zi_default) {
      await this.getDueDate();
    }
    this.rootStore.mainStore.isWaiting = false;
    //}
  };
  @action
  getDueDate = async () => {
    //this.rootStore.mainStore.isWaiting = true;

    // de adus nomenclatoare
    var results = await this.rootStore.api.fetch(
      "/api/common/credit-interval-scadenta"
    );
    if (results && !results.error && results != "401") {
      this.template.interval_scadenta = results;
    }

    //this.rootStore.mainStore.isWaiting = false;
  };
  @action
  addSimulation = async (params) => {
    this.rootStore.mainStore.isWaiting = true;
    var apiPath = `/api/common/simulare-creditare`;
    if (this.uidCerere) {
      params.thread = "UID:" + this.uidCerere;
    }
    var results = await this.rootStore.api.fetch(apiPath, params);
    // sa vedem ce facem aici
    //if (params?.persistent != 99) {
    var errors = [];
    if (results.error) {
      errors.push(results.error);
      this.rootStore.mainStore.isWaiting = false;
      this.rootStore.mainStore.errors.open(errors);
    } else {
      if (params?.persistent === 99) {
        results.simulare.uid = null;
      }
      set(this.template, results);
      var stelute = "**";
      this.template.comisioane
        .filter((x) => x.nota)
        .forEach((c) => {
          stelute = stelute + "*";
          c.nota = `${stelute} ${c.nota}`;
          c.denumire_comision = `${c.denumire_comision} ${stelute}`;
        });
    }
    //}
    this.rootStore.mainStore.isWaiting = false;
    return results;
  };
  @action
  downloadCloud = async ({ table, row }) => {
    this.rootStore.mainStore.isWaiting = true;
    var results = await this.rootStore.api.downloadFile(
      "/api/request/download-cloud",
      {
        type: this.type,
        uidCerere: this.uidCerere,
        id: table.Id,
        file: row.ID,
      },
      row.FILENAME?.split("/").pop()
    );
    this.rootStore.mainStore.isWaiting = false;
    if (results && results.error) {
      this.rootStore.mainStore.errors.open("Fisierul nu poate fi descarcat!");
    }
  };
}

export default CerereWebStore;
