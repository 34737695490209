import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import "./index.css";
import { Documents, Preview, PrintDocuments } from "TemplateCerere";
import Toolbar from "./toolbar";
import { EditDialog } from "TemplateCerere";
import { runEvent } from "Utils";
import VideoCall from "./videoCall";

import { Prompt } from "react-router-dom";

function CerereWeb(props) {
  const {
    downloadAcord,
    errors,
    isMobile,
    navigateBack,
    navigateForward,
    info,
    noMatch,
    getStepsInfo,
    preventGoBack,
    preventGoBackMessage,
    downloadDocument,
    stepId,
  } = props.main;
  const updateMainStore = props.main.updateValue;
  useEffect(() => {
    if (props.appType !== "desk" || props.appType !== "desk-new") {
      window.addEventListener("load", (event) => {
        event.preventDefault();
      });
    }
  }, []);
  useEffect(() => {
    if (props.appType === "desk") {
      props.main.updateValue({ preventGoBack: false });
      props.main.checkLoginUser(
        props.history,
        props.match.url,
        props.appType,
        props.match.params
      );
    } else {
      props.main.updateValue({ preventGoBack: true });

      props.main.checkLogin(
        props.history,
        props.match.url,
        props.match.params,
        props.appType ?? "new"
      );
    }
    props.store.doActivate(props.match.params);
  }, [props.match, props.appType]);

  if (noMatch) {
    return (
      <div
        className="nomatch"
        /*fullWidth*/ dangerouslySetInnerHTML={{ __html: noMatch }}
      />
    );
  }
  let { jprop } = props.main;
  jprop = jprop ?? {};
  const {
    save,
    error,
    dialog,
    preview,
    docListareCerere,
    editDialog,
    closeEditDialog,
    saveEditDialog,
    videoCall,
    stopVideoCall,
  } = props.store;
  const {
    sectiuni,
    countSteps,
    currentStep,
    nextPhase,
    goStep,
    saveCompleted,
    close,
    downloadPrintFile,
    nextStep,
    simulare,
    widget,
    template,
  } = props.store;

  const {
    doDeactivate,
    updateField,
    updateValue,
    rowChecked,
    uploadFile,
    updateFile,
    deleteFile,
    downloadFile,
    previewFile,
    closePreviewFile,
    showFile,
    openEditTable,
    updateRow,
    addTableRow,
    deleteTableRow,
    runFieldEvents,
    endJourney,
    canSave,
    canPass,
    savePhoto,
    addToChangedTables,
    updateStore,
    getPrintFile,
    lendingActivate,
    addSimulation,
  } = props.store;

  const store = props.store;
  const filterSursaLista = props.filterSursaLista;
  const { getField,
    queryData,
    warning,
    getHeaders,
    getFields,
  } = props.commonStore;
  const openError = errors.open;
  const processMsg = errors.processMsg;
  const actions = {
    doDeactivate,
    updateField,
    updateValue,
    rowChecked,
    uploadFile,
    updateFile,
    deleteFile,
    downloadFile,
    previewFile,
    showFile,
    filterSursaLista,
    openEditTable,
    updateRow,
    addTableRow,
    deleteTableRow,
    runFieldEvents,
    savePhoto,
    runEvent,
    store,
    addToChangedTables,
    getField,
    downloadAcord,
    queryData,
    openError,
    getStepsInfo,
    processMsg,
    updateStore,
    closeEditDialog,
    getPrintFile,
    lendingActivate,
    addSimulation,
    downloadDocument,
    updateMainStore,
    getHeaders,
    getFields
  };

  if (sectiuni.length === 0) {
    return null;
  }
  const sectiune = sectiuni[currentStep];
  const stepName = (name) => {
    const chrIndex = name.indexOf(".");
    var title = chrIndex ? name.substring(chrIndex + 1) : name;
    title = title ? title : "titlu";
    return title;
  };
  if (error) {
    return <div className="error">{error}</div>;
  }
  return (
    <>
      <div className="paper-toolbar">
        <Prompt when={preventGoBack} message={preventGoBackMessage} />
        <Preview close={closePreviewFile} {...preview} />
        <EditDialog
          close={closeEditDialog}
          save={saveEditDialog}
          {...editDialog}
          className={jprop.className || editDialog.className || ""}
        />
        {/*Dialog de sfarit de flux*/}
        <Dialog
          open={dialog.isOpen}
          height="250px"
          classes={{ root: dialog.className }}
        >
          <div className="MuiDialog-border"></div>
          <DialogTitle>{dialog.header}</DialogTitle>
          <DialogContent>
            <div className="dialog-message">{dialog.message}</div>
            <div className="dialog-buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={() => close(props.history.push)}
              >
                Inchide
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {countSteps !== 0 && jprop.theme !== "retail" && (
          <Toolbar
            title={sectiune.Denumire}
            countSteps={countSteps}
            currentStep={currentStep}
            save={save}
            goStep={goStep}
            saveCompleted={saveCompleted}
            sectiuni={sectiuni}
            nextPhase={nextPhase}
            stepName={stepName}
            endJourney={endJourney}
            canSave={canSave}
            canPass={canPass}
          />
        )}
        {countSteps !== 0 && docListareCerere.length !== 0 && (
          <PrintDocuments
            item={docListareCerere}
            downloadPrintFile={downloadPrintFile}
          />
        )}
        {countSteps !== 0 && (
          <Documents
            documents={store.doc.filter(doc => doc.sec === sectiune.Id && doc.Vizualizare)}
            actions={actions}
            isMobile={isMobile}
            navigateBack={navigateBack}
            navigateForward={navigateForward}
            nextStep={nextStep}
            currentStep={currentStep}
            save={save}
            goStep={goStep}
            countSteps={countSteps}
            info={info}
            canSave={canSave}
            canPass={
              canPass || currentStep < countSteps - 1
            } /*ca sa am buton de COntinua pe pasii anteriori care sunt readonly*/
            jprop={jprop}
            simulare={simulare}
            template={template}
            errors={errors}
            warning={warning}
            widget={widget}
            stepId={stepId}
          ></Documents>
        )}
      </div>
      <VideoCall videoCall={videoCall} stopVideoCall={stopVideoCall} />
    </>
  );
}
export default inject((stores) => ({
  store: stores.cerereWebStore,
  main: stores.mainStore,
  filterSursaLista: stores.commonStore.filterSursaLista,
  commonStore: stores.commonStore,
}))(withRouter(observer(CerereWeb)));
