import React from "react";
import { observer } from "mobx-react";
import {
  Delete,
  Close,
  ScreenRotation,
  FlipCameraIosOutlined,
} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { base64ToArrayBuffer } from "Utils";
import { guid } from "Utils";
import Fab from "@mui/material/Fab";
import "../index.css";
import "./ImageDocument.scss";
import { DropzoneArea } from "react-mui-dropzone";
import {
  IconUpload,
  IconWebcam,
  IconPhoto,
  IllustrationIdentity,
} from "TemplateCerere";
import Webcam from "react-webcam";
import { PreferredCameraType, getCameras } from "./SelectCamera";
import { convertBytesToMbsOrKbs, acceptedFileType } from "Utils";

class ImageDocument extends React.Component {
  constructor(props) {
    console.log("ImageDocument - constructor");
    super(props);
    this.webcamRef = React.createRef();
    this.imageCropRef = React.createRef();
    const maxFileSize = (props.doc.Params.maxfilesize || 5 * 1024 * 1024) * 1;
    this.state = {
      hasCamera: false, //este camera
      openPicture: false, //deschide camera
      facingMode: "environment",
      imageCss: null,
      resolution: null,
      loading: true,
      devices: [],
      deviceIndex: -1,
      maxFileSize: maxFileSize,
      file: "",
    };
  }
  iconUpload() {
    console.log("ImageDocument - iconUpload");
    return (
      <div>
        <IconUpload className="icon-upload" />
        <div className="image-text">Click pentru a incarca un fisier</div>
        <div className="image-text-note">
          (Poti incarca un fisier inclusiv prin "drag and drop")
        </div>
      </div>
    );
  }

  componentDidMount = async () => {
    console.log("ImageDocument - componentDidMount");
    const { doc } = this.props;
    var cameras = [];
    try {
      cameras = await getCameras(PreferredCameraType.BackFacingCamera);
    } catch (e) {
      console.log("error getting camera", e);
    }
    var preferredDeviceIndex = -1;
    var hasCamera = cameras.length > 0;
    console.log("final cameras", cameras);
    let tmp = doc.Jprop.template;
    //tmp.resolution = null;
    tmp.resolution = tmp.resolution || { width: 1280, height: 900 };
    tmp.imageSize = tmp.imageSize || { width: 1280, height: 900 };
    var state = {
      hasCamera: !!hasCamera,
      imageCss: tmp.imageCss,
      resolution: tmp.resolution,
      imageSize: tmp.imageSize,
      facingMode: tmp.facingMode,
      loading: true,
      devices: cameras,
      deviceIndex: preferredDeviceIndex === -1 ? 0 : preferredDeviceIndex,
      file: "",
    };

    this.getFile(state);
  };

  getFile = async (state) => {
    console.log("ImageDocument - getFile");
    state = state || {};
    const { doc, actions } = this.props;
    const item = doc.DocFile.find((x) => x.Id !== null);
    if (item) {
      state.file = await actions.showFile(item);
    } else {
      state.loading = false;
    }
    this.setState(state);
  };
  handleImageLoaded = () => {
    this.setState({ loading: false });
  };
  handleOpenTakePicture = () => {
    this.setState({ openPicture: true });
  };

  handleDeletePicture = async () => {
    const { doc, actions } = this.props;
    this.setState({ loading: true });
    const item = doc.DocFile.find((x) => x.Id !== null);
    await actions.deleteFile(item, doc);
    this.setState({ loading: false, file: null });
    //actions.updateStore({ file: null }, "template");
  };

  handleCloseCamera = () => {
    //const { actions } = this.props;
    this.setState({ openPicture: false, file: null });
    //actions.updateStore({ file: null, }, "template");
  };

  /* incarcare fisier cu drag&drop*/
  handleUploadSave = async (files) => {
    const { doc, actions } = this.props;
    var file = files[0];
    this.setState({ loading: true });
    await actions.uploadFile(file, doc);
    this.getFile();
  };

  handleError = (error) => {
    console.log(error);
    const { actions } = this.props;
    this.handleCloseCamera();
    if (
      error.toString().includes("Permission denied") ||
      error.toString().includes("not allowed")
    ) {
      actions.openError(
        "Eroare: browserul nu are drepturi pentru accesarea camerei!"
      );
      return;
    }
    var msg = error.message || error.name || error.toString();
    actions.openError(msg);
  };

  handleTakePhoto = async () => {
    const { doc, actions } = this.props;
    let imageSize = doc.Jprop.template.imageSize || {
      width: 1280,
      height: 900,
    };
    let crop = {
      width: this.imageCropRef.current.clientWidth,
      height: this.imageCropRef.current.clientHeight,
    };
    var dataImage = this.webcamRef.current.getScreenshot();
    var image = new Image();
    image.width = this.webcamRef.current.canvas.width;
    image.height = this.webcamRef.current.canvas.height;
    //recalibrare crop in functie de rezolutie
    // rezolutia se preia din jprop din definitia fluxului si e definita pentru modul landscape
    if (
      this.webcamRef.current.canvas.width >
        this.webcamRef.current.video.clientWidth ||
      this.webcamRef.current.canvas.height >
        this.webcamRef.current.video.clientHeight
    ) {
      var rx =
        this.webcamRef.current.canvas.width /
        this.webcamRef.current.video.clientWidth;
      var ry =
        this.webcamRef.current.canvas.height /
        this.webcamRef.current.video.clientHeight;

      crop.width = crop.width * (rx > ry ? rx : ry);
      crop.height = crop.height * (rx > ry ? rx : ry);
    }

    image.src = dataImage;
    image.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = imageSize.width;
      canvas.height = imageSize.height;
      const ctx = canvas.getContext("2d");
      //calcul punct pornire pt crop
      var sx = (image.width - crop.width) / 2;
      var sy = (image.height - crop.height) / 2;
      ctx.drawImage(
        image,
        sx,
        sy,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      var dataUri = canvas.toDataURL("image/png");
      var indexEnd = dataUri.lastIndexOf("base64,");
      var contentFileData = dataUri.slice(indexEnd + 7);
      var typeFileData = dataUri
        .slice(0, indexEnd - 1)
        .replace("data:", "")
        .replace("image/", "");
      var contentFile = await base64ToArrayBuffer(contentFileData);
      var file = await new File([contentFile], `${guid()}.${typeFileData}`);
      this.setState({ loading: true, openPicture: false });
      await actions.uploadFile(file, doc);
      var state = { openPicture: false };
      this.getFile(state);
    };
  };

  changeCamera = () => {
    /*
    const facingMode =
      this.state.facingMode == "environment" ? "user" : "environment";
    this.setState({
      facingMode: facingMode,
    });
    */
    if (this.state.devices && this.state.devices.length > 0) {
      var i = this.state.deviceIndex + 1;
      if (i >= this.state.devices.length) {
        i = 0;
      }
      this.setState({
        deviceIndex: i,
      });
    }
  };
  getCamName = () => {
    console.log("ImageDocument - getCamName");
    if (this.state.devices && this.state.devices.length > 0) {
      return this.state.devices[this.state.deviceIndex].label;
    }
    return "No camera";
  };
  render() {
    console.log("ImageDocument - render");
    const { doc, isMobile, actions } = this.props;
    const file = this.state.file;
    const errors = doc.Jprop.errors || {};
    /*    const css = this.state.imageCss;
    const ImgCss = styled.img`
      ${css}
    `;*/

    return (
      <div className="e-card" id={`dco${doc.Id}`} key={doc.Id}>
        <div className="e-card-title">{doc.Jprop.title}</div>
        {doc.Jprop.subtitle && (
          <div className="e-card-subtitle">{doc.Jprop.subtitle}</div>
        )}
        {doc.Jprop.notice && (
          <div className="e-card-notice">{doc.Jprop.notice}</div>
        )}
        <div className="e-card-content-document image-wrapper">
          <div className="image-document">
            <div
              className={
                isMobile && this.state.openPicture
                  ? "image-back-full-screen"
                  : "image-back"
              }
            >
              {this.state.openPicture ? (
                <>
                  <Webcam
                    className="web-cam"
                    audio={false}
                    ref={this.webcamRef}
                    screenshotFormat="image/png"
                    onUserMediaError={this.handleError}
                    videoConstraints={{
                      //facingMode: this.state.facingMode,
                      deviceId:
                        this.state.devices[this.state.deviceIndex].deviceId,
                      //width: this.state.resolution.width,
                      //height: this.state.resolution.height,
                      //aspectRatio: 1.42
                    }}
                    //forceScreenshotSourceSize
                  />
                </>
              ) : (
                <div>
                  {file && (
                    <img
                      src={file}
                      alt=""
                      //className={file ? "image-preview" : "image-preview-empty"}
                      className="image-preview"
                      onLoad={this.handleImageLoaded}
                    />
                  )}
                  {!file && <div className="image-preview-empty" />}
                  {!file && <IllustrationIdentity className="icon-id" />}
                </div>
              )}
              {this.state.openPicture && !this.state.loading && (
                <>
                  <div className="image-btn-group">
                    {this.state.devices && this.state.devices.length > 0 && (
                      <div className="image-btn-close">
                        <Tooltip title="Schimba camera">
                          <Fab
                            onClick={this.changeCamera}
                            className="image-switch-btn"
                          >
                            <FlipCameraIosOutlined fontSize="large" />
                          </Fab>
                        </Tooltip>
                      </div>
                    )}

                    <div className="image-btn-close">
                      <Tooltip title="Fotografiaza">
                        <Fab
                          onClick={this.handleTakePhoto}
                          className="image-photo"
                        >
                          <IconPhoto className="image-photo-icon" />
                        </Fab>
                      </Tooltip>
                    </div>

                    <div className="image-btn-close">
                      <Tooltip title="Renunta la fotografiere">
                        <Fab
                          onClick={this.handleCloseCamera}
                          className="image-switch-btn"
                        >
                          <Close fontSize="large" />
                        </Fab>
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
              {!this.state.openPicture && !this.state.loading && (
                <div className="image-btn-group note-camera">
                  {!file && this.state.hasCamera && (
                    <div className="image-btn-note">Porneste camera</div>
                  )}
                  <div className="image-btn">
                    {
                      /*stergere fotografie din db */
                      doc.Incarcare && !doc.Stergere && file && (
                        <Tooltip title="Sterge imaginea">
                          <Fab
                            size="medium"
                            color="secondary"
                            onClick={this.handleDeletePicture}
                          >
                            <Delete />
                          </Fab>
                        </Tooltip>
                      )
                    }

                    {!file && this.state.hasCamera && (
                      <Tooltip
                        title="Fotografiaza cartea ta de identitate"
                        leaveDelay={100}
                      >
                        <Fab
                          size="medium"
                          onClick={this.handleOpenTakePicture}
                          //color="primary"
                          className="image-webcam"
                        >
                          <IconWebcam className="image-webcam-icon" />
                        </Fab>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}
              {this.state.openPicture && !this.state.loading && (
                <>
                  <div className="image-rotate">
                    Roteste telefonul
                    <ScreenRotation
                      style={{
                        height: "3em",
                        width: "3em",
                        paddingTop: "15px",
                      }}
                    />
                  </div>
                  <div ref={this.imageCropRef} className="image-crop">
                    <span>Incadreaza documentul in chenar</span>
                    <div>{this.getCamName()}</div>
                  </div>
                </>
              )}
            </div>
            {!file && !this.state.openPicture && !this.state.loading && (
              <DropzoneArea
                acceptedFiles={doc.Params.ext ? doc.Params.ext.split(",") : []}
                onDrop={this.handleUploadSave}
                showFileNames
                showAlerts={false}
                filesLimit={1}
                showPreviewsInDropzone={false}
                showPreviews={false}
                maxFileSize={this.state.maxFileSize}
                dropzoneParagraphClass="dropzoneParagraph"
                dropzoneClass="image-drop"
                Icon={this.iconUpload}
                dropzoneText=""
                inputProps={{ capture: "environment" }}
                onAlert={actions.openError}
                getFileLimitExceedMessage={(filesLimit) => {
                  return (
                    errors.filesLimit ||
                    "Numarul maxim de fisiere permise este depasit. Numarul de fisiere permise este ".concat(
                      filesLimit,
                      "."
                    )
                  );
                }}
                getFileAddedMessage={(fileName) => {
                  return "Fisierul ".concat(
                    fileName,
                    " a fost adaugat cu succes."
                  );
                }}
                getFileRemovedMessage={(fileName) => {
                  return "Fisierul ".concat(fileName, " a fost eliminat.");
                }}
                getDropRejectMessage={(
                  rejectedFile,
                  acceptedFiles,
                  maxFileSize
                ) => {
                  if (acceptedFileType(acceptedFiles, rejectedFile.type)) {
                    return (
                      errors.acceptedFiles ||
                      "Acest tip de fisier nu este suportat. "
                    );
                  }

                  if (rejectedFile.size > maxFileSize) {
                    return (
                      errors.maxFileSize ||
                      "Fisierul este prea mare. Marimea maxima admisa este " +
                        convertBytesToMbsOrKbs(maxFileSize) +
                        ". "
                    );
                  }
                  return (
                    errors.rejectedFile ||
                    "Fisierul ".concat(rejectedFile.name, " este respins. ")
                  );

                  //return message;
                }}
              />
            )}
          </div>
          <div className="image-values">
            <ul>
              {/*this.state.devices && this.state.devices.map(d =>
                <li key={d.deviceId}>{JSON.stringify(d)}</li>
              )
              */}
              <li>
                Serie <span>{doc.Jprop.ocr.serie}</span>, numar{" "}
                <span>{doc.Jprop.ocr.numar}</span>
              </li>
              <li>
                CNP <span>{doc.Jprop.ocr.cnp}</span>
              </li>
              <li>
                Nume <span>{doc.Jprop.ocr.nume}</span> prenume{" "}
                <span>{doc.Jprop.ocr.prenume}</span>
              </li>
              <li>
                Nationalitate <span>{doc.Jprop.ocr.nationalitate}</span>
              </li>
              <li>
                Loc.nastere <span>{doc.Jprop.ocr.loc_nastere}</span>
              </li>
              <li>
                Adresa <span>{doc.Jprop.ocr.adresa1}</span>{" "}
                <span>{doc.Jprop.ocr.adresa2}</span>
              </li>
              <li>
                Emitent <span>{doc.Jprop.ocr.emitent}</span>
              </li>
              <li>
                Data <span>{doc.Jprop.ocr.data_emitere}</span> -{" "}
                <span>{doc.Jprop.ocr.data_expirare}</span>
              </li>
              <li>
                MRZ1 <span>{doc.Jprop.ocr.mrz1}</span>
              </li>
              <li>
                MRZ2 <span>{doc.Jprop.ocr.mrz2}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(ImageDocument);
