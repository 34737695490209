import React from "react";
import { observer } from "mobx-react";

import styled from "styled-components";

import Paper from "@mui/material/Paper";

import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import { Add, Edit } from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";

import "../index.css";

import Table from "./field6_table";
import TableTranspose from "./field6_table_transpose";
import Fields from "./field6_fields";
import EditFields from "./field6_edit";

import { getStyle } from "Utils";
import TableDiv from "./field6_table_div";

function Field6(props) {
  const { item, inline, actions, css } = props;
  const styleProps = getStyle(item.eventProps);

  const TableContainerCss = styled(TableContainer)`
    ${styleProps.input}
  `;
  const DivCss = styled.div`
    ${styleProps.field}
  `;

  var cssLabel =
    css && css.field && css.field.label ? css.field.label : "inline-label";
  cssLabel = `doc-field ${!inline ? cssLabel : ""} ${
    item.Jprop.className ? item.Jprop.className : ""
  }`;

  if (!item.data ?? !item.isVisible) {
    return null;
  }
  if (item?.Jprop?.template == "TableDiv" && item.isVisible)
    return (
      <DivCss key={item.Id} id={`f6tbl${item.Id}`}>
        {!inline && (
          <div className="doc-field-label">
            {item.Caption + (item.Obligatoriu ? " *" : "")}
          </div>
        )}
        <TableDiv {...props} />
      </DivCss>
    );

  const handlerAdd = () => {
    if (actions.addTableRow(item)) {
      actions.openEditTable({
        table: item,
        child: (
          <EditFields
            data={item.data}
            tableId={item.Id}
            updateRow={actions.updateRow}
            actions={actions}
          />
        ),
        row: item.data.row,
      });
    }
  };
  const canEdit = () => {
    if (item.masterId) {
      return item.master.data.rows.length > 0;
    } else {
      return true;
    }
  };

  return (
    item.isVisible && (
      <DivCss
        key={item.Id}
        className={
          cssLabel
        } /*className={'doc-field' + (!inline ? ' inline-label' : '')}*/
        id={`f6tbl${item.Id}`}
      >
        {!inline && (
          <div className="doc-field-label">
            {item.Caption + (item.Obligatoriu ? " *" : "")}
          </div>
        )}
        <div className="doc-field-table">
          {!(item.isReadonly || item.docReadOnly) &&
            (item.append || (item.edit && item.data.hasDetails)) &&
            canEdit() && (
              <div className="doc-table-toolbar">
                {item.height !== 0 &&
                  !(item.isReadonly || item.docReadOnly) &&
                  item.append &&
                  canEdit() && (
                    <Tooltip title="Adauga">
                      <Fab
                        size="small"
                        style={{ backgroundColor: "#9c27b0", color: "white" }}
                        onClick={handlerAdd}
                        className="doc-table-btn"
                      >
                        <Add />
                      </Fab>
                    </Tooltip>
                  )}

                {item.data.hasDetails &&
                  item.height === 0 &&
                  !(item.isReadonly || item.docReadOnly) &&
                  item.edit &&
                  canEdit() &&
                  !item.editInPlace && (
                    <Tooltip title="Modifica">
                      <Fab
                        size="small"
                        color="primary"
                        onClick={() =>
                          actions.openEditTable({
                            table: item,
                            child: (
                              <EditFields
                                data={item.data}
                                updateRow={actions.updateRow}
                                tableId={item.Id}
                                actions={actions}
                              />
                            ),
                          })
                        }
                        //disabled={currentStep === 0}
                        className="doc-table-btn"
                      >
                        <Edit />
                      </Fab>
                    </Tooltip>
                  )}
              </div>
            )}
          {item.height !== 0 && (
            <TableContainerCss
              component={Paper}
              style={{ minHeight: 100, maxHeight: 300 }}
            >
              {item?.Jprop?.isTransposed ? (
                <TableTranspose
                  item={item}
                  eventProps={item.eventProps}
                  actions={actions}
                  //onSelectRow={item?.Jprop?.onSelectRow}
                />
              ) : (
                <Table
                  item={item}
                  eventProps={item.eventProps}
                  actions={actions}
                />
              )}
            </TableContainerCss>
          )}
          {item.data.hasDetails && (
            <TableContainerCss
              component={Paper}
              style={{ minHeight: 100, maxHeight: 300 }}
            >
              <Fields
                item={item}
                data={item.data}
                eventProps={item.eventProps}
              />
            </TableContainerCss>
          )}
        </div>
      </DivCss>
    )
  );
}
export default observer(Field6);
