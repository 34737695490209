import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import Container from "@mui/material/Container";

import LoginUserOtp from "./loginUserOtp";
import Copyright from "../copyright";

function LoginUser(props) {
  const { username, pwdDetails, smsCode, requestOtp, errors } = props.store;
  const { oldPassword, newPassword, confirmPassword, isPasswordSet } =
    pwdDetails;
  const { updateValue, changePwd, checkUser } = props.store;
  let query = new URLSearchParams(props.location.search);
  const url = query.get("ref");

  useEffect(() => {
    if (isPasswordSet === null) {
      //let query = new URLSearchParams(props.location.search);
      //const url = query.get("ref");
      checkUser({ history: props.history, url: url });
    }
  }, [isPasswordSet, url, props.history]);

  const validare = () => {
    var errorMsgs = [];
    if (isPasswordSet && !oldPassword) {
      errorMsgs.push("[LU003] Introduceti parola currenta!");
    }
    if (newPassword !== confirmPassword) {
      errorMsgs.push("[LU002] Parola nu este confirmata!");
    }
    return errorMsgs;
  };

  const handlerPassword = () => {
    var errorMsgs = validare();

    if (!smsCode) {
      errorMsgs.push("[LU001] Introduceti codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    changePwd(props.history, url);
  };

  return (
    <div className="login-card">
      <div component="main">
        <CssBaseline />
        <div className="login">
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            className="login-title"
            align="center"
          >
            {isPasswordSet ? "Schimbare Parola" : "Setare Parola"}
          </Typography>
          <div className="form">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              label="Utilizator"
              name="Utilizator"
              value={username ? username : ""}
              //onChange= {(prop) => updateValue({ username: prop.target.value })}
              //autoFocus
              className="login-txt disabled"
              disabled={true}
              //autoComplete="Utilizator"
            />
            {isPasswordSet && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="oldPassword"
                name="Parola Veche"
                label="Parola Veche"
                value={oldPassword ? oldPassword : ""}
                onChange={(prop) =>
                  updateValue({ oldPassword: prop.target.value }, "pwdDetails")
                }
                autoFocus
                type="password"
              />
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="newPassword"
              name="Parola"
              label="Parola"
              value={newPassword ? newPassword : ""}
              onChange={(prop) =>
                updateValue({ newPassword: prop.target.value }, "pwdDetails")
              }
              autoFocus={!isPasswordSet}
              type="password"
              className="login-txt"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              name="Confirma Parola"
              label="Confirma Parola"
              value={confirmPassword ? confirmPassword : ""}
              onChange={(prop) =>
                updateValue(
                  { confirmPassword: prop.target.value },
                  "pwdDetails"
                )
              }
              type="password"
              className="login-txt"
            />
            <LoginUserOtp
              smsCode={smsCode}
              updateValue={updateValue}
              requestOtp={requestOtp}
              validare={validare}
              errors={errors}
            />
            <Button
              //type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              onClick={handlerPassword}
            >
              Salveaza
            </Button>
          </div>
        </div>

        <Copyright />
      </div>
    </div>
  );
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(observer(LoginUser))
);
