import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

function RetailMenu({ steps, id }) {
  const [menuSteps, setMenuSteps] = useState(steps); //dummy, ca sa pot folosi useEffect de mai jos ca un constructor
  useEffect(() => {
    if (!steps) {
      return null;
    }
    if (!id) {
      if (steps) {
        if (steps[0].steps) {
          id = steps[0].steps[0].id;
        } else {
          id = steps[0].id;
        }
      } else {
        return null;
      }
    }
    var sIndex = -1,
      pIndex;
    /*resetez statusul in caz ca da back*/
    steps.forEach((s) => {
      s.status = "";
      if (s.steps) {
        s.steps.forEach((p) => (p.status = ""));
      }
    });

    steps.forEach((s, index) => {
      if (sIndex > -1) {
        return true;
      }
      if (s.steps) {
        pIndex = s.steps.findIndex((p) => p.id === id);
        if (pIndex > -1) {
          for (var i = 0; i < pIndex; i++) {
            s.steps[i].status = "completed";
          }
          s.steps[pIndex].status = "current";
          sIndex = index;
        }
      }
      if (sIndex === -1 && s.id === id) {
        sIndex = index;
      }
      if (sIndex > -1) {
        for (var j = 0; j < sIndex; j++) {
          steps[j].status = "completed";
        }
        s.status = "current";
        return false;
      }
    });
    setMenuSteps(steps);
  }, [steps, id]);

  return (
    <ul className="sidebar-list">
      {menuSteps.map((s) => {
        return (
          <React.Fragment key={s.id}>
            <li className={s.status} key={`s${s.id}`}>
              <span className="diamond"></span>
              {s.name}
            </li>
            {s.status === "current" && s.steps && (
              <ul>
                {s.steps.map((p) => (
                  <li className={p.status} key={`s${s.id}-p${p.id}`}>
                    {p.name}
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
}
export default observer(RetailMenu);
