import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import Container from "@mui/material/Container";
import { checkName, checkEmail, checkCNP } from "Utils";
import Copyright from "../copyright";
import InitialPage from "./registerOnBoardingOld/initialPage";
import AcordPage from "./registerOnBoardingOld/acordPage";
import EmailPage from "./registerOnBoardingOld/emailPage";
import PhonePage from "./registerOnBoardingOld/phonePage";
//import RetailErrorsModel from "../themes/retailErrors.js";

class RegisterOnboardingPF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      acord: {
        tc: false, //termeni si conditii
        ni: false, //nota de informare
        proc: false, //acord general de procesare a datelor. bifarea lui arata pe ecran cele de mai jos
        com: false, //comunicari marketing
        sondaj: false, //sondaje
        grup: false, //procesarea datelor de catre grup patria,
        sms: false, //comunicare prin SMS
        email: false, //comunicare prin SMS
        canale: false, //comunicare prin SMS
      },
      name: { value: null, error: null },
      email: { value: null, error: null },
      email2: { value: null, error: null },
      cnp: { value: null, error: null },
      phone: { value: null, error: null },
      smsCode: null,
    };
    /*Schimb modul de procesare al erorilor*/
    //  var errorMessages = props.errors.errorMessages;
    //  props.store.errors = new RetailErrorsModel();
    //  props.errors.errorMessages = errorMessages;

    this.pages = {
      initial: 1,
      acord: 2,
      email: 3,
      phone: 4,
    };
    this.handlerUpdateValue = this.handlerUpdateValue.bind(this);
    this.handlerUpdateAcord = this.handlerUpdateAcord.bind(this);
  }

  handlerUpdateValue = (name, value) => {
    var s = { ...this.state };
    s[name] = name === "smsCode" ? value : { value: value, error: null };
    this.setState(s);
  };

  handlerUpdateAcord = (event) => {
    var s = { acord: { ...this.state.acord } };
    s.acord[event.target.name] = event.target.checked;
    s.acord[event.target.name + "_err"] = false;
    if (event.target.name === "proc" && !event.target.checked) {
      s.acord.com = false;
      s.acord.sondaj = false;
      s.acord.grup = false;
      s.acord.sms = false;
      s.acord.email = false;
      s.acord.canale = false;
      s.acord.com_err = false;
    }
    if (
      event.target.name === "sms" ||
      event.target.name === "email" ||
      event.target.name === "canale"
    ) {
      s.acord.com_err = false;
    }
    if (
      event.target.name === "com" ||
      event.target.name === "sondaj" ||
      event.target.name === "grup"
    ) {
      s.acord.proc_err = false;
    }
    this.setState(s);
  };

  validare = () => {
    const { phone, name, email, email2, cnp, page, acord } = this.state;
    const { errors } = this.props.store;
    var errorMsgs = [];

    if (page === this.pages.acord) {
      if (!acord.tc) {
        errorMsgs.push({
          msg: "[OPFA1]Trebuie sa acceptati termenii si conditiile",
          check: "tc",
        });
      }
      if (!acord.ni) {
        errorMsgs.push({
          msg: "[OPFA2]Trebuie sa acceptati nota de informare",
          check: "ni",
        });
      }
      if (acord.proc && !(acord.com || acord.sondaj || acord.grup)) {
        errorMsgs.push({
          msg: "[OPFA4]Daca sunteti de acord cu procesarea datelor trebuie sa acceptati o comunicare",
          check: "proc",
        });
      }
      if (
        acord.proc &&
        (acord.com || acord.sondaj || acord.grup) &&
        !(acord.sms || acord.email || acord.canale)
      ) {
        errorMsgs.push({
          msg: "[OPFA5]Trebuie sa alegeti cel putin o modalitate de comunicare",
          check: "com",
        });
      }
    }

    if (page === this.pages.email) {
      if (!checkName(name.value)) {
        errorMsgs.push({
          msg: "[OPF1]Nu ati introdus corect numele dumneavoastra",
          ctrl: "name",
        });
      }
      if (!checkEmail(email.value)) {
        errorMsgs.push({
          msg: "[OPF5]Adresa de email nu are un format valid",
          ctrl: "email",
        });
      }
      if (!checkEmail(email2.value)) {
        errorMsgs.push({
          msg: "[OPF5]Adresa de email nu are un format valid",
          ctrl: "email2",
        });
      }
      if (email.value !== email2.value) {
        errorMsgs.push({
          msg: "[OPF77]Adresele de email nu corespund",
          ctrl: "email2",
        });
      }
    }
    if (page === this.pages.phone) {
      if (!checkName(name.value)) {
        errorMsgs.push({
          msg: "[OPF1]Nu ati introdus corect numele dumneavoastra",
          ctrl: "name",
        });
      }
      if (!(phone && phone.value && phone.value.length === 10)) {
        errorMsgs.push({
          msg: "[OPF8]Introduceti corect numarul de telefon (de forma: 07xxxxxxxx)!",
          ctrl: "phone",
        });
      }
      if (!checkCNP(cnp.value)) {
        errorMsgs.push({ msg: "[OPF2]Campul CNP este incorect!", ctrl: "cnp" });
      }
    }
    var s = { ...this.state };
    errorMsgs.forEach((e) => {
      if (e.ctrl) {
        s[e.ctrl] = {
          value: this.state[e.ctrl].value,
          error: errors.processMsg(e.msg),
        };
        e.ctrl = null;
      }
      if (e.check) {
        s.acord[e.check + "_err"] = errors.processMsg(e.msg);
      }
    });
    this.setState(s);
    return errorMsgs;
  };

  handlerConfirmPhone = () => {
    const { phone, name, email, smsCode, cnp, acord } = this.state;

    const { updateValue, confirmPhone, errors } = this.props.store;

    var errorMsgs = this.validare();
    if (!smsCode || smsCode.length === 0) {
      errorMsgs.push("[OPF10]Introduceti codul primit prin SMS!");
    }
    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    updateValue({ phone: phone.value, smsCode: smsCode });
    confirmPhone(
      {
        acord_tc: acord.tc ? "1" : "0", //termeni si conditii
        acord_ni: acord.ni ? "1" : "0", //nota de informare
        acord_proc: acord.proc ? "1" : "0", //acord general de procesare a datelor. bifarea lui arata pe ecran cele de mai jos
        acord_com: acord.com ? "1" : "0", //comunicari marketing
        acord_sondaj: acord.sondaj ? "1" : "0", //sondaje
        acord_grup: acord.grup ? "1" : "0", //procesarea datelor de catre grup patria,
        acord_sms: acord.sms ? "1" : "0", //comunicare prin SMS
        acord_email: acord.email ? "1" : "0", //comunicare prin email
        acord_canale: acord.canale ? "1" : "0", //comunicare prin canale
        cnp: cnp.value,
        name: name.value,
        email: email.value,
      },
      this.props.history
    );
  };
  handlerSendPhone = async () => {
    const { phone, name, email, cnp } = this.state;
    const { updateValue, sendPhone, errors } = this.props.store;
    var errorMsgs = this.validare();

    if (errorMsgs.length !== 0) {
      errors.open(errorMsgs);
      return;
    }
    updateValue({ phone: phone.value });
    await sendPhone({
      cnp: cnp.value,
      name: name.value,
      email: email.value,
    });
    this.setState({ smsCode: this.props.store.smsCode });
  };
  handlerChangePage = () => {
    const { errors } = this.props.store;
    var page = this.state.page;
    if (page === this.pages.phone) {
      this.handlerConfirmPhone();
    } else {
      var errorMsgs = this.validare();
      if (errorMsgs.length !== 0) {
        errors.open(errorMsgs);
        return;
      }

      page++;
      this.setState({ page: page });
    }
  };

  handlerExit = () => {
    this.props.store.updateValue({ preventGoBack: false });
    this.props.history.push("/goodbye");
  };

  Page = () => {
    switch (this.state.page) {
      case this.pages.initial:
        return <InitialPage />;
      case this.pages.acord:
        return (
          <AcordPage
            {...this.state.acord}
            handleChange={this.handlerUpdateAcord}
            downloadAcord={this.props.store.downloadAcord}
          />
        );
      case this.pages.email:
        return (
          <EmailPage
            updateValue={this.handlerUpdateValue}
            name={this.state.name}
            email={this.state.email}
            email2={this.state.email2}
          />
        );
      case this.pages.phone:
        return (
          <PhonePage
            updateValue={this.handlerUpdateValue}
            codeRequest={this.props.store.codeRequest}
            name={this.state.name}
            phone={this.state.phone}
            cnp={this.state.cnp}
            smsCode={this.state.smsCode}
            timeLeft={this.props.store.timeLeft}
            sendPhone={this.handlerSendPhone}
            confirmPhone={this.handlerConfirmPhone}
          />
        );
      default:
        return <InitialPage />;
    }
  };

  render() {
    const { flowName } = this.props.store;
    return (
      <div component="main" /*maxWidth="sm"*/ maxWidth={"md"}>
        <CssBaseline />
        <div className="login">
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            className="login-title"
            align="center"
          >
            {flowName}
          </Typography>
          <div className="form">
            {this.Page()}
            <div className="login-group-btn">
              <Button
                variant="contained"
                color="secondary"
                className="login-btn"
                onClick={this.handlerExit}
              >
                Nu confirm
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="login-btn"
                onClick={this.handlerChangePage}
              >
                Confirm
              </Button>
            </div>
          </div>
          <p class="mkt">
            Campurile marcate cu * sunt obligatoriu de completat
          </p>
        </div>

        <Copyright />
      </div>
    );
  }
}
export default withRouter(
  inject((stores) => ({ store: stores.mainStore }))(
    observer(RegisterOnboardingPF)
  )
);
